import React, {
  useEffect,
  useState,
  Fragment,
  useRef,
  useLayoutEffect,
} from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import classnames from "classnames";
import cuid from "cuid";

import setImage from "@assets/js/set-image";
import getImageSizes from "@assets/js/get-image-sizes";
import {
  PORTFOLIO_TYPE,
  OVERLAY,
  RESPONSIVE_IMAGE_WIDTHS,
  ASPECT,
} from "@constants";
import { useSubmissionMode, useSubmissionContent } from "@store/portfolio";
import useHeaderStore from "@store/header";
import { PlusSvg } from "@assets/js/icons";
import { log } from "@assets/js/utils";

import "./media-focal.scss";

const MediaFocal = ({
  className = "",
  fields,
  noSave,
  hasVideoControls,
  onImageLoaded = null,
}) => {
  const { t } = useTranslation();
  const texts = {
    saveImage: t(
      "FritzHansen_Project_ConsumersAndProfessionals_General_SaveImage"
    ),
  };

  const [imageLoaded, setImageLoaded] = useState(false);
  const [, setSubmissionMode] = useSubmissionMode();
  const [, setSubmissionContent] = useSubmissionContent();
  const setMenuOverlayActive = useHeaderStore(
    (store) => store.setMenuOverlayActive
  );
  const videoRef = useRef();

  const [sizes] = useState(getImageSizes(fields?.image));

  const mediaClasses = classnames({
    "media-focal": true,
    "media-focal--reveal": imageLoaded,
  });

  const imageClasses = classnames({
    "media-focal__image": true,
  });

  const overlayClasses = classnames({
    "media-focal__overlay": true,
    "media-focal__overlay--10pct": fields?.overlayStrength?.value === "10",
    "media-focal__overlay--20pct": fields?.overlayStrength?.value === "20",
    "media-focal__overlay--30pct": fields?.overlayStrength?.value === "30",
  });

  const highSrc = () => {
    if (!fields) return "";
    return setImage(fields.image?.value.src, {
      width: sizes.width,
      height: sizes.height,
      src: fields.image?.value.src,
    });
  };

  const lowSrc = () => {
    if (!fields) return "";
    return setImage(fields.image?.value.src, {
      width: Math.round(sizes.width / 10),
      height: Math.round(sizes.height / 10),
    });
  };

  const loadedHandler = () => {
    setImageLoaded(true);
    if (onImageLoaded) onImageLoaded();
  };

  // useEffect(() => {
  //   if (fields?.video?.value?.href != "") {
  //     loadedHandler();
  //   }
  // }, []);

  const canSave = noSave ? false : true;

  const openSaveOverlay = (event) => {
    // button inside a tag click issue
    // trying to solve https://alphasolutionsdk.atlassian.net/browse/FHBC-736?focusedCommentId=73147
    event.preventDefault();
    event.stopPropagation();

    let content = {
      imageSrcHigh: highSrc(),
      imageSrcLow: lowSrc(),
      imageWidth: sizes.width,
      imageHeight: sizes.height,
      downloadHref: highSrc(),

      portfolioItem: {
        imageUrl: highSrc(),
        imageId: fields.image.value.id,
        imageTitle: fields.image.value.alt,
        imageDescription: "_NO_DESCRIPTION_",
        imageAspect: sizes.aspect,
      },
    };

    const hasProducts = fields?.image?.value?.products;
    if (hasProducts) {
      content.portfolioItem.imageProductIds = fields.image.value.products.split(
        "|"
      );
    }
    setSubmissionContent(content);
    setSubmissionMode(PORTFOLIO_TYPE.MEDIA);
    setMenuOverlayActive(OVERLAY.MEDIA);
  };

  const getSrcSets = () => {
    let srcSetsString = "";
    let srcSetLength = RESPONSIVE_IMAGE_WIDTHS.length;
    for (let i = 0; i < srcSetLength; i++) {
      let imageWidth = RESPONSIVE_IMAGE_WIDTHS[i];
      srcSetsString += `${setImage(fields.image?.value.src, {
        width: Math.round(sizes.modifier * imageWidth),
      })} ${imageWidth}w`;
      if (i < srcSetLength - 1) srcSetsString += ", ";
    }
    return srcSetsString;
  };

  useLayoutEffect(() => {
    if (videoRef?.current && fields?.video?.value) {
      log("useLayout");
      videoRef.current.play();
    }
  }, [videoRef, fields]);

  return (
    <Fragment key={fields?.image?.value?.id || fields?.video?.value?.id}>
      {fields && (
        <div className={`${className} ${mediaClasses}`}>
          {fields?.video?.value && fields?.video?.value?.href !== "" ? (
            <>
              <video
                ref={videoRef}
                className="media-focal__video"
                controls={hasVideoControls}
                loop
                muted="muted"
                autoPlay="autoplay"
                preload="auto"
                playsInline
                onLoadedData={(e) => {
                  loadedHandler();
                }}
                style={
                  fields.videoFocalPoint && {
                    objectPosition: `${
                      fields.videoFocalPoint.value?.split(",")[0]
                    }% ${fields.videoFocalPoint.value?.split(",")[1]}%`,
                  }
                }
              >
                <source src={`${fields.video.value.href}`} type="video/mp4" />
              </video>
              {fields.overlayStrength?.value?.length > 0 && (
                <span className={overlayClasses}></span>
              )}
            </>
          ) : (
            <>
              <LazyLoadImage
                className={imageClasses}
                alt={fields.image?.value?.alt}
                src={highSrc()}
                srcSet={getSrcSets()}
                // sizes={"(max-width:768px) 500px, (max-width:1280px) 1024px, 2560px"}
                placeholderSrc={lowSrc()} // use normal <img> attributes as props
                // height={fields.image?.value.height}
                // width={fields.image?.value.width}
                afterLoad={loadedHandler}
                effect="opacity"
                style={
                  fields.image?.value?.focalpoint && {
                    objectPosition: `${
                      fields.image.value.focalpoint.split(",")[1]
                    }% ${fields.image.value.focalpoint.split(",")[0]}%`,
                  }
                }
              />

              {fields.overlayStrength?.value && (
                <span className={overlayClasses}></span>
              )}

              {canSave && (
                <button
                  className="media-focal__save-button"
                  onClick={openSaveOverlay}
                >
                  <span className="text">{texts.saveImage}</span>
                  <span className="icon">
                    <PlusSvg isInverted={true} />
                  </span>
                </button>
              )}
            </>
          )}
        </div>
      )}
    </Fragment>
  );
};

MediaFocal.propTypes = {
  onImageLoaded: PropTypes.func,
};

export default MediaFocal;
