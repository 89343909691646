import React, { useEffect, useState } from "react";
import { RichText } from "@sitecore-jss/sitecore-jss-react";
import ActionButton from "../ActionButton";
import { motion, AnimatePresence } from "framer-motion";
import classnames from "classnames";
import isTrue from "@assets/js/is-true";
import getUniqueId from "@assets/js/get-unique-id";
import { useTranslation } from "react-i18next";

import "./rich-text-module.scss";

const RichTextModule = ({ fields }) => {
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState(false);

  const hasReadMoreBodyText = fields.readMoreBodyText?.value?.length > 0;

  const expandedTextId = "readMore" + getUniqueId();

  const expand = () => {
    setExpanded(true);
  };

  const texts = {
    readMoreText: t(
      "FritzHansen_Project_ConsumersAndProfessionals_ReadMoreButton"
    ),
  };

  useEffect(() => {
    try {
      document
        .querySelector(`#readMore${expandedTextId}`)
        .scrollIntoView({ behavior: "smooth" });
    } catch (e) {}
  }, [expanded]);

  const classList = classnames({
    "rich-text-module": true,
    "rich-text-module--inverted": isTrue(fields.isInverted),
    "rich-text-module--custom-bgc": fields?.backgroundColorHex,
    "rich-text-module--expanded": expanded,
  });

  let animate = {
    hidden: {
      opacity: 0,
      transition: { duration: "0.5" },
    },
    visible: {
      opacity: 1,
      transition: { duration: "0.5" },
    },
  };

  return (
    <div
      className={classList}
      style={
        fields.backgroundColorHex && {
          "--rich-text-module-bgc": fields.backgroundColorHex.value,
        }
      }
    >
      <div className="rich-text-module__content rich-text-module__content--initial">
        <RichText className="rich-text" field={fields.bodyText} />
        {hasReadMoreBodyText && !expanded && (
          <div className="rich-text-module__fade-out-div"></div>
        )}
      </div>

      {hasReadMoreBodyText && !expanded && (
        <div className="rich-text-module__read-more-button">
          <ActionButton
            clickHandler={expand}
            buttonType="solid"
            text={
              fields?.readMoreButtonText?.value
                ? fields.readMoreButtonText.value
                : texts.readMoreText
            }
            hasArrow={true}
            isInverted={!(fields?.readMoreButtonIsInverted && fields?.readMoreButtonIsInverted?.value)}
          />
        </div>
      )}

      {hasReadMoreBodyText && expanded && (
        <AnimatePresence>
          <motion.div
            initial="hidden"
            variants={animate}
            animate={expanded ? "visible" : "hidden"}
            className="rich-text-module__content"
            id={expandedTextId}
          >
            <RichText className="rich-text" field={fields.readMoreBodyText} />
          </motion.div>
        </AnimatePresence>
      )}
    </div>
  );
};

export default RichTextModule;
