import React, { useEffect, useState } from "react";
import axios from "axios";
import cuid from "cuid";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import ProductTile from "../../ProductTile";
import ActionMenu from "../../ActionMenu";
import ActionButton from "../../ActionButton";
import { BUTTONSTYLE, NOTIFICATION, OVERLAY } from "@assets/js/constants";

import path from "@assets/js/browser-path";
import testForAvailability from "@assets/js/test-for-availability";

import useGlobalStore, { useCommerceMarket, useCommerceMarketSupportsEcom } from "@store/global";
import useBasketStore from "@store/basket";
import useHeaderStore from "@store/header";
import useNotificationStore from "@store/notifications";


import "./portfolio-product-tile.scss";

const fetchProduct = (productId, lang) =>
  axios.get(
    path.join(
      process.env.REACT_APP_PRODUCTSAPI,
      "Configuration",
      lang,
      "solrformat",
      "get",
      productId
    )
  );

const fetchAllowOrder = (modelTypeId) =>
  axios.post(path.join(process.env.REACT_APP_ROOTAPI, "ProductApi", "AllowOrder", "list"), [
    modelTypeId,
  ]);

const PortfolioProductTile = ({ data, canEdit, onRemove, storesHref }) => {
  const { t } = useTranslation();

  const texts = {
    info: t("FritzHansen_Project_ConsumersAndProfessionals_Portfolio_Info"),
    addToBasket: t("FritzHansen_Project_ConsumersAndProfessionals_Portfolio_AddToBasket"),
    addingToBasket: t("FritzHansen_Project_ConsumersAndProfessionals_PDP_AddingToBasket"),
    findInStore: t("FritzHansen_Project_ConsumersAndProfessionals_Portfolio_FindInStore"),
    removeFromWishlist: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Portfolio_RemoveFromWishlist"
    ),
  };
  const sc_lang = useGlobalStore((state) => state.sc_lang);

  const [commerceMarketSupportsEcom] = useCommerceMarketSupportsEcom();
  const [commerceMarket] = useCommerceMarket();
  const setActiveNotification = useNotificationStore(
    (state) => state.setActiveNotification
  );
  const setMenuOverlayActive = useHeaderStore(
    (store) => store.setMenuOverlayActive
  ); 

  const addProductToBasket = useBasketStore((state) => state.addProduct);

  const [dropped, setDropped] = useState(false);
  const [orderAllowed, setOrderAllowed] = useState(null);
  const [productData, setProductData] = useState(null);
  const [waitingForBasketAdd, setWaitingForBasketAdd] = useState(false);


  useEffect(() => {
    async function loadProduct() {
      const response = await fetchProduct(data.productId, sc_lang);
      const item = response.data[0];

      let product;
      if (item) {
        product = {
          configurationId_s: item.itemType_s === "SKU" ? item.id : item.configurationId_s,
          fullFriendlyItem: item.fullFriendlyItem_s,
          friendlyName: item.friendlyItem_s,
          seriesName_t: item.ModelTypeProductSeriesName_s,
          plpDescription_t: item.ModelTypeModelDefinitionNamePLP_s,
          urlSlug_s: item.urlSlug_s,
          imageId_s: item.friendlyItem_s,
          imageUrl_s: item.imageUrl_s,
          itemType_s: item.itemType_s,
          onlyAvailableInMarkets_ss: item.onlyAvailableInMarkets_ss,
        };
      }
      setProductData(product);
    }
    async function checkAllowOrder() {
      const response = await fetchAllowOrder(data.modelTypeId);
      let allowed = response?.data[data.modelTypeId];

      setOrderAllowed(allowed);
    }

    checkAllowOrder();
    if (data.productId) loadProduct();
  }, []);

  const actionList = () => {
    let list = [
      {
        icon: "MapMarkerSvg",
        label: texts.findInStore,
        href: storesHref,
      },
      {
        icon: "RemoveSvg",
        label: texts.removeFromWishlist,
        callback: () => {
          onRemove(data);
        },
      },
    ];
    if (canAddToBasket()) {
      list.push({
        icon: "BasketSvg",
        label: waitingForBasketAdd ? texts.addingToBasket : texts.addToBasket,
        callback: () => {
          onAddToBasket();
        },
      });
    }
    return list;
  };

  const canAddToBasket = () => {

    if (productData && priceHasResolved && !zeroPriceNoBasketAdd ) {
      let available = testForAvailability(
        commerceMarket,
        productData.onlyAvailableInMarkets_ss        
      );
      return available && commerceMarketSupportsEcom && orderAllowed;
    } else {
      return false;
    }
  };

  const onAddToBasket = () => {
    if ( waitingForBasketAdd ) return false;

    setWaitingForBasketAdd(true);
    addProductToBasket(
      { productId: productData.fullFriendlyItem },
      productAddedSuccessHandler
    );

  };

  const productAddedSuccessHandler = () => {
    setWaitingForBasketAdd(false);
    setActiveNotification({
      id: cuid(),
      title: t(
        "FritzHansen_Project_ConsumersAndProfessionals_Notifications_AddToBasketTitle"
      ),
      buttonText: t(
        "FritzHansen_Project_ConsumersAndProfessionals_Cart_OpenBasket"
      ),
      type: NOTIFICATION.ITEM_ADDED,
      clickHandler: () => {
        setMenuOverlayActive(OVERLAY.BASKET);
      },
      timer: 2000,
    });
  };




  const [priceHasResolved, setPriceHasResolved] = useState(false);
  const [zeroPriceNoBasketAdd, setZeroPriceNoBasketAdd] = useState(false);

  const updateButtonsAccordingToPrice = (resolvedPrice) => {
    setPriceHasResolved(true);
    if ( !isNaN(resolvedPrice?.unitPrice) && resolvedPrice?.unitPrice === 0 ) {
      setZeroPriceNoBasketAdd(true);
    }
  };

  return (
    <div className="portfolio-product-tile" onMouseLeave={() => setDropped(false)}>
      {canEdit && !dropped && (
        <ActionButton
          className="portfolio-product-tile__button"
          buttonType={BUTTONSTYLE.GHOST}
          backgroundColorHex="#c6c6c6"
          textColorHex="white"
          text={texts.info}
          icon="PlusSvg"
          clickHandler={() => setDropped(!dropped)}
        />
      )}
      {canEdit && <ActionMenu actionList={actionList()} isActive={dropped} />}
      {productData && <ProductTile {...productData} addToBasket={canAddToBasket()} onPriceResolve={updateButtonsAccordingToPrice}/>}
    </div>
  );
};
PortfolioProductTile.propTypes = {
  canEdit: PropTypes.bool,
};

export default PortfolioProductTile;
