import MediaFocal from "./media-focal";
import NavButton from "./nav-button";

export default {
  default: {
    fields: {
      headlineType: "h2",
      headline: "The Iconic \n Carimate Chair",
      bodyText:
        "Fritz Hansen releases a numbered edition of the Carimate chair to honour the designer Vico Magistretti 100 years after his birth. Bold personality and detailed construction make Fritz Hansen’s anniversary edition Carimate truly special.",
      leftMedia: MediaFocal.default,
      rightMedia: MediaFocal.default,
      button: NavButton.link,
      isButtonEnabled: true,
      // buttonStyle: "ghost",//Use style in NavButton.default
    },
  },
  customBg: {
    fields: {
      leftMedia: MediaFocal.default,
      rightMedia: MediaFocal.default,
      isButtonEnabled: true,

      button: NavButton.outlinedInverted,
      // buttonStyle: "ghost",//Use style in NavButton.default
      headlineType: "h2",
      headline: "A note of blue",
      bodyText:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi consequuntur quos minus asperiores error, eveniet facere. Ut ab debitis quisquam molestias ad impedit eveniet rerum, culpa commodi. Dolorem, nihil maiores.",
      backgroundColorHex: "#6677ee",
      isInverted: true,
    },
  },
  separated: {
    fields: {
      headlineType: "h2",
      headline: "A note of blue",
      bodyText:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi consequuntur quos minus asperiores error, eveniet facere. Ut ab debitis quisquam molestias ad impedit eveniet rerum, culpa commodi. Dolorem, nihil maiores.",
      leftMedia: MediaFocal.default,
      rightMedia: MediaFocal.default,
      button: NavButton.outlined,
      isButtonEnabled: true,
      // buttonStyle: "ghost",//Use style in NavButton.default
      isSeparated: true,
    },
  },
};
