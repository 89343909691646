import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";

import MediaFocal from "../MediaFocal";
import NavButton from "../NavButton";

import classnames from "classnames";
import isTrue from "@assets/js/is-true";

import "./hero-linear.scss";

const HeroLinear = ({ fields }) => {
  const classList = classnames({
    "hero-linear": true,
    "hero-linear--inverted": isTrue(fields?.isInverted),
    "hero-linear--center-image": fields?.centeredMedia?.value,
  });

  return (
    <>
      {fields && (
        <div
          className={classList}
          style={
            fields.backgroundColorHex && {
              "--hero-bgc": fields.backgroundColorHex.value,
            }
          }
        >
          <div className="hero-linear__content">
            {fields?.headline?.value && (
              <div className="hero-linear__headline">
                <Text
                  tag={fields?.headlineType?.value ? fields?.headlineType?.value : "h1"}
                  field={fields?.headline}
                />
              </div>
            )}

            <div className="hero-linear__text">
              {fields.bodyText?.value && fields.bodyText.value.length > 0 && (
                <Text tag="p" field={fields.bodyText} />
              )}

              {fields.button && <NavButton fields={fields.button.fields} />}
            </div>
          </div>

          {fields.media && (
            <div className="hero-linear__image">
              <MediaFocal fields={fields.media.fields} />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default HeroLinear;
