import MediaFocal from "./media-focal";

export default {
  default: {
    fields: {
      data: {
        noParse: true,
        item: {
          headline: { field: { value: "Headline" } },
          loadMoreButtonText: { field: { value: "Load more" } },
          tiles: {
            values: [
              {
                href: "/en/Consumers-and-Professionals/By-function",
                media: {
                  fields: {
                    id: "112fc1e8-a8d0-4ffb-b709-d0cb8df4edd3",
                    url:
                      "/Consumers-and-Professionals/By-function/Page-Components/Media",
                    fields: {
                      image: {
                        value: {
                          src:
                            "http://fritz.alpha-solutions.dk/-/media/002.ashx?h=900&iar=0&w=800",
                          alt: "Media002",
                          width: "800",
                          height: "900",
                          focalpoint: "8, 23",
                        },
                      },
                    },
                  },
                },

                title: "By function",
              },
              {
                href: "/en/Consumers-and-Professionals/Products",
                media: {
                  fields: {
                    id: "c5344e25-2e8c-4df2-8b18-b0b105259a41",
                    url:
                      "/Consumers-and-Professionals/Products/Page-Components/Media",
                    fields: {
                      image: {
                        value: {
                          src:
                            "http://fritz.alpha-solutions.dk/-/media/002.ashx?h=900&iar=0&w=800",
                          alt: "Media002",
                          width: "800",
                          height: "900",
                          focalpoint: "8, 23",
                        },
                      },
                    },
                  },
                },
                title: "Products",
              },
              {
                href: "/en/Consumers-and-Professionals/Products/Furniture",
                media: {
                  fields: {
                    id: "404b6533-ed25-41cc-9253-6f7a079ebf35",
                    url:
                      "/Consumers-and-Professionals/Products/Furniture/Page-Components/Media",
                    fields: {
                      image: {
                        value: {
                          src:
                            "http://fritz.alpha-solutions.dk/-/media/001.ashx?h=900&iar=0&w=800",
                          alt: "Media001",
                          width: "800",
                          height: "900",
                          focalpoint: "21, 63",
                        },
                      },
                    },
                  },
                },
                title: "Furniture",
              },
              {
                href: "/en/Consumers-and-Professionals/Products/Lightning",
                media: {
                  fields: {
                    id: "855b230e-3d79-4890-8fee-48d4e6771021",
                    url:
                      "/Consumers-and-Professionals/Products/Lightning/Page-Components/Media",
                    fields: {
                      image: {
                        value: {
                          src:
                            "http://fritz.alpha-solutions.dk/-/media/002.ashx?h=900&iar=0&w=800",
                          alt: "Media002",
                          width: "800",
                          height: "900",
                          focalpoint: "8, 23",
                        },
                      },
                    },
                  },
                },
                title: "Lightning",
              },
              {
                href: "/en/Consumers-and-Professionals/Products/Accessories",
                media: {
                  fields: {
                    id: "967de0a7-ec52-4558-a82f-7116d2847401",
                    url:
                      "/Consumers-and-Professionals/Products/Accessories/Page-Components/Media",
                    fields: {
                      image: {
                        value: {
                          src:
                            "http://fritz.alpha-solutions.dk/-/media/001.ashx?h=900&iar=0&w=800",
                          alt: "Media001",
                          width: "800",
                          height: "900",
                          focalpoint: "21, 63",
                        },
                      },
                    },
                  },
                },
                title: "Accessories",
              },
              {
                href: "/en/Consumers-and-Professionals/Products/Furniture",
                media: {
                  fields: {
                    id: "404b6533-ed25-41cc-9253-6f7a079ebf35",
                    url:
                      "/Consumers-and-Professionals/Products/Furniture/Page-Components/Media",
                    fields: {
                      image: {
                        value: {
                          src:
                            "http://fritz.alpha-solutions.dk/-/media/001.ashx?h=900&iar=0&w=800",
                          alt: "Media001",
                          width: "800",
                          height: "900",
                          focalpoint: "21, 63",
                        },
                      },
                    },
                  },
                },
                title: "Furniture",
              },
            ],
          },
        },
      },
    },
  },
};
