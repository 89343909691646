import React, { useEffect, useState } from "react";

import { AnimatePresence, m, motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import produce from "immer";
import { loader } from "graphql.macro";
import { useQuery } from "react-apollo";
import cuid from "cuid";
import shallow from "zustand/shallow";
import { HeartSmallSvg, ArrowSmallSvg, PlusSvg } from "@assets/js/icons";

import { log } from "@assets/js/utils";
import { dataLayerCommercePush, productSignature } from "@assets/js/gtm";

import classnames from "classnames";

import PDPConfiguratorOptions from "../PDPConfiguratorOptions";
import PDPModelSelector from "../PDPModelSelector";
import PDPMaterialSelector from "../PDPMaterialSelector";
import PDPMaterialSetSelector from "../PDPMaterialSetSelector";

import { renderedImagePath } from "../../ProductImage";
import ActionButton from "../../ActionButton";
import ProductPrice from "../../ProductPrice";

import usePDPStore, {
  useProductSpecificationsOpen,
  useBasicConfiguration,
  useConfiguratorMode,
  useCustomConfiguration,
  useActiveProductData,
  useActiveModelData,
  usePendingProductData,
  useModelTypes,
  useActiveModelTypeIndex,
  useMaterialSets,
  useActiveMaterialIndex,
  useActiveMaterialSetIndex,
  useActiveMaterialSetMaterialIndex,
  useActiveMaterialIsUnallowed,
  useActiveSelector,
} from "@store/pdp";

import useGlobalStore, {
  useMarketInfo,
  useCommerceMarket,
  useCommerceMarketSupportsEcom,
  useCurrentSiteMode,
} from "@store/global";

import useBasketStore from "@store/basket";
import { useSubmissionMode, useSubmissionContent } from "@store/portfolio";
import useHeaderStore from "@store/header";
import useNotificationStore from "@store/notifications";
import useStockStore from "@store/stock";

import {
  OVERLAY,
  PORTFOLIO_TYPE,
  SITE_MODE,
  BUTTONSTYLE,
  CONFIGURATOR_MODE,
  CONFIGURATOR_SELECTOR,
  DEFAULT_TRANSITION,
  NOTIFICATION,
} from "@constants";

import onMediaChanged from "@assets/js/on-media-changed";
import getViewportSize from "@assets/js/get-viewport-size";
import ViewportSizes from "@assets/js/viewport-sizes";

import "./pdp-configurator.scss";

const storeLocatorQuery = loader("./storeLocatorQuery.graphql");

const PDPConfigurator = ({ allowOrder, currency }) => {
  const { t } = useTranslation();

  const texts = {
    loading: t("FritzHansen_Project_ConsumersAndProfessionals_General_Loading"),
    moreInfo: t("FritzHansen_Project_ConsumersAndProfessionals_PDP_MoreInfo"),
    apply: t("FritzHansen_Project_ConsumersAndProfessionals_PDP_Apply"),
    continue: t("FritzHansen_Project_ConsumersAndProfessionals_PDP_Continue"),
    cancel: t("FritzHansen_Project_ConsumersAndProfessionals_PDP_Cancel"),
    addToBasket: t(
      "FritzHansen_Project_ConsumersAndProfessionals_PDP_AddToBasket"
    ),
    addingToBasket: t(
      "FritzHansen_Project_ConsumersAndProfessionals_PDP_AddingToBasket"
    ),
    notifyMe: t("FritzHansen_Project_ConsumersAndProfessionals_PDP_NotifyMe"),
    notifyMeMessage: t(
      "FritzHansen_Project_ConsumersAndProfessionals_PDP_NotifyMeMessage"
    ),
    saveToProjects: t(
      "FritzHansen_Project_ConsumersAndProfessionals_PDP_SaveToProjects"
    ),
    inclVat: t("FritzHansen_Project_ConsumersAndProfessionals_PDP_IncludeVat"),
    findInStore: t(
      "FritzHansen_Project_ConsumersAndProfessionals_PDP_FindInStore"
    ),
    errorTitle: t(
      "FritzHansen_Project_ConsumersAndProfessionals_PDP_NoConfigErrorTitle"
    ),
    errorText: t(
      "FritzHansen_Project_ConsumersAndProfessionals_PDP_NoConfigErrorText"
    ),
    addToWishList: t(
      "FritzHansen_Project_ConsumersAndProfessionals_PDP_AddToWishlist"
    ),
    configuratorResetWarning: t(
      "FritzHansen_Project_ConsumersAndProfessionals_PDP_ConfiguratorResetWarning"
    ),
  };
  const [activeConfigurationSKU, fullFriendlySKU] = usePDPStore(
    (state) => [state.activeConfigurationSKU, state.fullFriendlySKU],
    shallow
  );
  const setActiveNotification = useNotificationStore(
    (state) => state.setActiveNotification
  );
  const [currentSiteMode] = useCurrentSiteMode();

  const [, ,] = useBasicConfiguration();

  const [, , fetchCustomConfiguration] = useCustomConfiguration();

  const [configuratorMode] = useConfiguratorMode();

  const [activeModelData] = useActiveModelData();
  const [activeProductData, setActiveProductData] = useActiveProductData();
  const [pendingProductData, setPendingProductData] = usePendingProductData();

  const [modelTypes, setModelTypes, fetchModelTypes] = useModelTypes();
  const [
    activeModelTypeIndex,
    setActiveModelTypeIndex,
  ] = useActiveModelTypeIndex();

  const fetchProductImageData = usePDPStore(
    (state) => state.fetchProductImageData
  );

  const addProductToBasket = useBasketStore((state) => state.addProduct);

  const [materialSets, setMaterialSets, fetchMaterialSets] = useMaterialSets();
  const [activeSelector, setActiveSelector] = useActiveSelector();
  const [
    activeMaterialIndex,
    setActiveMaterialIndex,
  ] = useActiveMaterialIndex();

  //position of chosen material set in active material bracket's list of material set
  const [
    activeMaterialSetIndex,
    setActiveMaterialSetIndex,
  ] = useActiveMaterialSetIndex();

  //position of chosen material in chosen material set's list of materials
  const [
    activeMaterialSetMaterialIndex,
    setActiveMaterialSetMaterialIndex,
  ] = useActiveMaterialSetMaterialIndex();

  //position of chosen material in chosen material set's list of materials
  const [
    activeMaterialIsUnallowed,
    setActiveMaterialIsUnallowed,
  ] = useActiveMaterialIsUnallowed();

  const [
    productSpecificationsOpen,
    setProductSpecificationsOpen,
  ] = useProductSpecificationsOpen();

  const [, setSubmissionMode] = useSubmissionMode();
  const [, setSubmissionContent] = useSubmissionContent();
  const setMenuOverlayActive = useHeaderStore(
    (store) => store.setMenuOverlayActive
  );

  const [waitingForBasketAdd, setWaitingForBasketAdd] = useState(false);

  const [debugShown, setDebugShown] = useState(false);

  const [mode, sc_lang] = useGlobalStore(
    (state) => [state.currentSiteMode, state.sc_lang],
    shallow
  );

  const [commerceMarket] = useCommerceMarket();
  const [marketInfo] = useMarketInfo();
  const [commerceMarketSupportsEcom] = useCommerceMarketSupportsEcom();

  const stockList = useStockStore((state) => state.stockList);
  const fetchStockData = useStockStore((state) => state.fetchStockData);

  useEffect(() => {
    if (
      !stockList[activeConfigurationSKU] &&
      activeConfigurationSKU &&
      commerceMarket
    ) {
      fetchStockData(commerceMarket, activeConfigurationSKU);
    }
  }, [commerceMarket, activeConfigurationSKU, stockList]);

  useEffect(() => {
    //reset price logic on new configuration
    setPriceHasResolved(false);
    setZeroPriceForceNotifyMe(false);
  }, [activeConfigurationSKU]);

  const menuId =
    mode === SITE_MODE.RESIDENTIAL
      ? process.env.REACT_APP_RESIDENTIAL_DESKTOP_ID
      : process.env.REACT_APP_PROFESSIONAL_DESKTOP_ID;

  const { data, loading } = useQuery(storeLocatorQuery, {
    variables: { menuId },
    errorPolicy: "all",
  });

  const removeDebugHandler = () => {
    setDebugShown(false);
  };

  // If just 1 materialset, show materials selector
  useEffect(() => {
    if (materialSets.length === 1) {
      setActiveSelector(CONFIGURATOR_SELECTOR.MATERIALS);
      setActiveMaterialSetIndex(0);
    }
  }, [materialSets]);

  const [currentViewport, setCurrentViewport] = useState(getViewportSize());

  onMediaChanged((newViewport) => {
    setCurrentViewport(newViewport);
  });

  useEffect(() => {
    const mainHeaderEl = document.querySelector(".main-header");
    const htmlEl = document.querySelector("html");

    if (activeSelector != CONFIGURATOR_SELECTOR.NONE) {
      if (
        currentViewport.name === ViewportSizes.SMALL.name ||
        currentViewport.name === ViewportSizes.MEDIUM.name
      ) {
        if (mainHeaderEl) mainHeaderEl.classList.add("is-configuring");
        if (htmlEl) htmlEl.classList.add("is-configuring");

        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    } else {
      if (
        currentViewport.name === ViewportSizes.SMALL.name ||
        currentViewport.name === ViewportSizes.MEDIUM.name
      ) {
        if (mainHeaderEl) mainHeaderEl.classList.remove("is-configuring");
        if (htmlEl) htmlEl.classList.remove("is-configuring");
      }
    }
  }, [activeSelector]);

  //animation presets
  const selectorAnimationVariants = {
    initial: {
      x: "30px",
      opacity: 0,
    },
    animate: {
      x: "0",
      opacity: 1,
      transition: DEFAULT_TRANSITION,
    },
    exit: {
      x: "-30px",
      opacity: 0,
    },
  };

  const [marketRemark, setMarketRemark] = useState("");

  useEffect(() => {
    if (marketInfo && commerceMarket) {
      setMarketRemark(getCommerceMarketRemark());
    }
  }, [marketInfo, commerceMarket]);

  const getCommerceMarketRemark = () => {
    let currentMarket = marketInfo.markets.values.find(
      (item) => item.commerceMarket.value.toLowerCase() == commerceMarket
    );
    return currentMarket?.remark?.value;
  };

  // ================================================================================================
  // ==   C O N F I G   O P T I O N S   A C T I O N S   =============================================
  // ================================================================================================

  const activateModelOptionHandler = (model) => {
    setPendingProductData(activeProductData);
    fetchModelTypes(model.groupId);

    setActiveSelector(CONFIGURATOR_SELECTOR.MODEL_TYPES);
  };

  // ================================================================================================

  const activateMaterialOptionHandler = (materialIndex) => {
    setActiveMaterialIndex(materialIndex);
    setPendingProductData(activeProductData);

    fetchMaterialSets(
      materialIndex,
      activeProductData.model.typeId,
      activeProductData.info.materialSetGroupingEnabled,
      activeProductData.materials,
      sc_lang,
      commerceMarket
    );

    setActiveSelector(CONFIGURATOR_SELECTOR.MATERIAL_SETS);
  };

  // ================================================================================================
  // ==   S E L E C T O R    A C T I O N S   ========================================================
  // ================================================================================================

  // ACTIVATE MODEL, MATERIAL SET OR MATERIAL
  const modelTypeChosenHandler = (item) => {
    const locatedModelIndex = modelTypes.indexOf(
      modelTypes.find((type) => item.typeId === type.typeId)
    );
    setActiveModelTypeIndex(locatedModelIndex);

    const newModelTypes = produce(modelTypes, (tmpTypes) => {
      tmpTypes.forEach((type) => {
        if (tmpTypes.indexOf(type) != locatedModelIndex) {
          type.isActive = false;
        }
      });
      if (locatedModelIndex > -1) {
        tmpTypes[locatedModelIndex].isActive = true;
      }
    });
    setModelTypes(newModelTypes);

    const newPendingProductData = produce(pendingProductData, (tmpModel) => {
      tmpModel["model"] = {
        typeId: item.typeId,
        groupId: item.groupId,
        label: item.label,
        imageUrl: item.imageUrl,
      };
      tmpModel["materials"] = item.materials;
    });

    fetchProductImageData(item.typeId);
    setPendingProductData(newPendingProductData);
  };

  // ================================================================================================

  const materialSetChosenHandler = (item) => {
    const locatedSetIndex = materialSets.indexOf(
      materialSets.find((set) => item.materialSetId === set.materialSetId)
    );

    const newMaterialSets = produce(materialSets, (tmpSets) => {
      tmpSets.forEach((set) => {
        //necessary for null check!
        if (set) {
          if (tmpSets.indexOf(set) != locatedSetIndex) {
            set.isActive = false;
          }
        }
      });
      if (locatedSetIndex > -1) {
        tmpSets[locatedSetIndex].isActive = true;
      }
    });

    setMaterialSets(newMaterialSets);
    setActiveMaterialSetIndex(locatedSetIndex);
    setActiveSelector(CONFIGURATOR_SELECTOR.MATERIALS);
  };

  // ================================================================================================

  const materialChosenHandler = (item, isAllowed) => {
    //log("MAT CHOSEN isAllowed?", isAllowed);
    // 1. get and save material position in active materialset's materials array
    const materialSetMaterialIndex = materialSets[
      activeMaterialSetIndex
    ].materials.indexOf(
      materialSets[activeMaterialSetIndex].materials.find(
        (material) => item.materialId === material.materialId
      )
    );
    setActiveMaterialSetMaterialIndex(materialSetMaterialIndex);

    // 2. prepare materials sets for state change
    const newMaterialSets = produce(materialSets, (tmpSets) => {
      //save materials list for later
      const currentMaterialSetMaterials =
        tmpSets[activeMaterialSetIndex].materials;

      //deactivate all materials but the chosen one
      currentMaterialSetMaterials.forEach((material) => {
        if (material.materialId !== item.materialId) material.isActive = false;
        else material.isActive = true;
      });
    });
    setMaterialSets(newMaterialSets);

    const newPendingProductData = produce(pendingProductData, (tmpModel) => {
      tmpModel.materials[activeMaterialIndex].activeMaterial = {
        materialId: item.materialId,
        materialLabel: item.materialLabel,
        materialSetId: item.materialSetId,
        materialSetLabel: item.materialSetLabel,
        imageUrl: item.imageUrl,
      };
    });
    setPendingProductData(newPendingProductData);

    //log("isAllowed", isAllowed);
    setActiveMaterialIsUnallowed(isAllowed);
  };

  // ================================================================================================
  // ==   F O O T E R    A C T I O N S   ============================================================
  // ================================================================================================

  const saveToProjectsHandler = () => {
    openPortfolioOverlay();
  };

  const wishListHandler = () => {
    openPortfolioOverlay();
  };

  const productAddedErrorHandler = () => {
    setWaitingForBasketAdd(false);
  };

  const productAddedSuccessHandler = () => {
    setWaitingForBasketAdd(false);
    setActiveNotification({
      id: cuid(),
      title: t(
        "FritzHansen_Project_ConsumersAndProfessionals_Notifications_AddToBasketTitle"
      ),
      buttonText: t(
        "FritzHansen_Project_ConsumersAndProfessionals_Cart_OpenBasket"
      ),
      type: NOTIFICATION.ITEM_ADDED,
      clickHandler: () => {
        setMenuOverlayActive(OVERLAY.BASKET);
      },
      timer: 2000,
    });
  };

  const [priceHasResolved, setPriceHasResolved] = useState(false);
  const [zeroPriceForceNotifyMe, setZeroPriceForceNotifyMe] = useState(false);

  const updateButtonsAccordingToPrice = (resolvedPrice) => {
    setPriceHasResolved(true);
    if (!isNaN(resolvedPrice?.unitPrice) && resolvedPrice?.unitPrice === 0) {
      setZeroPriceForceNotifyMe(true);
    }
  };

  const showBasketButton = () => {
    return (
      activeModelData &&
      commerceMarket &&
      commerceMarketSupportsEcom &&
      allowOrder &&
      !zeroPriceForceNotifyMe
    );
  };

  const showNotifyMeButton = () => {
    let isSkuProduct = configuratorMode === CONFIGURATOR_MODE.NONE;
    let skuProductHasStock = false;

    if (stockList && stockList[activeConfigurationSKU] && isSkuProduct) {
      skuProductHasStock = stockList[activeConfigurationSKU]?.Stock > 0;
    }
    // log("isSkuProduct",isSkuProduct)
    // log("skuProductHasStock",skuProductHasStock)
    // log("activeModelData",activeModelData)
    // log("skuProductHasStock",skuProductHasStock)
    // log("commerceMarket",commerceMarket)
    // log("allowOrder",allowOrder)

    let caseSkuProductNoStock =
      activeModelData &&
      commerceMarket &&
      commerceMarketSupportsEcom &&
      isSkuProduct &&
      !skuProductHasStock &&
      allowOrder;

    let caseSkuProductHasStock =
      activeModelData &&
      commerceMarket &&
      commerceMarketSupportsEcom &&
      isSkuProduct &&
      skuProductHasStock &&
      !allowOrder;

    let caseConfigProductNoOrder =
      activeModelData &&
      commerceMarket &&
      commerceMarketSupportsEcom &&
      !isSkuProduct &&
      !allowOrder;

    let caseZeroPriceAndEcomSupport =
      activeModelData &&
      commerceMarket &&
      commerceMarketSupportsEcom &&
      zeroPriceForceNotifyMe;

    log(
      "PDP | showNotifyMeButton | caseSkuProductNoStock=" +
        caseSkuProductNoStock
    );
    log(
      "PDP | showNotifyMeButton | caseSkuProductHasStock=" +
        caseSkuProductHasStock
    );
    log(
      "PDP | showNotifyMeButton | caseConfigProductNoOrder=" +
        caseConfigProductNoOrder
    );
    log(
      "PDP | showNotifyMeButton | caseZeroPriceAndEcomSupport=" +
        caseConfigProductNoOrder
    );

    if (isSkuProduct) {
      return (
        caseSkuProductNoStock ||
        caseSkuProductHasStock ||
        caseZeroPriceAndEcomSupport
      );
    } else {
      return caseConfigProductNoOrder || caseZeroPriceAndEcomSupport;
    }
  };

  const onAddToBasket = () => {
    if (waitingForBasketAdd) return false;

    setWaitingForBasketAdd(true);

    addProductToBasket(
      {
        productId: fullFriendlySKU,
      },
      productAddedSuccessHandler,
      productAddedErrorHandler
    );

    // GTM Tracking
    dataLayerCommercePush("addToCart", {
      currencyCode: currency,
      add: productSignature(activeProductData),
    });
  };

  const onNotifyMe = () => {
    setMenuOverlayActive(OVERLAY.NOTIFY_ME);
  };

  const openPortfolioOverlay = () => {
    let content = {
      imageSrcHigh:
        configuratorMode === CONFIGURATOR_MODE.NONE
          ? activeProductData.imageUrl
          : renderedImagePath({
              imageId: activeConfigurationSKU,
              sw: 300,
            }),
      imageSrcLow:
        configuratorMode === CONFIGURATOR_MODE.NONE
          ? activeProductData.imageUrl
          : renderedImagePath({
              imageId: activeConfigurationSKU,
              sw: 50,
            }),
      imageWidth: 300,
      imageHeight: 300,

      portfolioItem: {
        productId: activeConfigurationSKU,
        modelTypeId: activeProductData.model.typeId,
        quantity: 1,
      },
    };

    setSubmissionContent(content);
    setSubmissionMode(PORTFOLIO_TYPE.PRODUCT);
    setMenuOverlayActive(OVERLAY.PORTFOLIO_SUBMISSION);
  };

  const moreInfoHandler = () => {
    document.querySelector("#productSpecifications").scrollIntoView({
      behavior: "smooth",
    });
    setProductSpecificationsOpen(true);
  };

  const cancelConfigurationHandler = () => {
    setPendingProductData(activeProductData);
    fetchProductImageData(activeProductData.model.typeId);
    resetConfigurator();
  };

  const submitModelTypeHandler = () => {
    setActiveProductData(pendingProductData);

    resetConfigurator();
    fetchCustomConfiguration(
      {
        modelTypeId: pendingProductData.model.typeId,
        market: commerceMarket,
      },
      sc_lang,
      true
    );
  };

  const submitMaterialHandler = () => {
    setActiveProductData(pendingProductData);
    resetConfigurator();

    let params = {
      modelTypeId: pendingProductData?.model?.typeId,
      market: commerceMarket,
    };

    if (activeMaterialIsUnallowed) {
      params[`material${activeMaterialIndex + 1}Id`] =
        pendingProductData.materials[
          activeMaterialIndex
        ].activeMaterial.materialId;
    } else {
      pendingProductData.materials.map((item, index) => {
        params[`material${index + 1}Id`] =
          pendingProductData.materials[index].activeMaterial.materialId;
      });
    }
    //log({ params });
    fetchCustomConfiguration(params, sc_lang);
  };

  const getParamCount = () => {
    let p = activeProductData?.materials?.length;
    if (configuratorMode === CONFIGURATOR_MODE.ADVANCED) p++;

    return p;
  };

  const backToMaterialSetsHandler = () => {
    setActiveMaterialSetMaterialIndex(-1);
    setActiveMaterialIsUnallowed(false);
    setActiveSelector(CONFIGURATOR_SELECTOR.MATERIAL_SETS);
  };

  const resetConfigurator = () => {
    setModelTypes([]);
    setMaterialSets([]);
    setActiveModelTypeIndex(-1);
    setActiveMaterialIndex(-1);
    setActiveMaterialSetIndex(-1);
    setActiveMaterialSetMaterialIndex(-1);
    setActiveMaterialIsUnallowed(false);
    setActiveSelector(CONFIGURATOR_SELECTOR.NONE);
  };

  // ================================================================================================
  // ==   R E N D E R   F U N C T I O N   ===========================================================
  // ================================================================================================

  const classList = classnames({
    "pdp-configurator": true,
    "pdp-configurator--1-params": getParamCount() === 1,
    "pdp-configurator--2-params": getParamCount() === 2,
    "pdp-configurator--3-params": getParamCount() === 3,
    "pdp-configurator--4-params": getParamCount() === 4,
    "pdp-configurator--5-params": getParamCount() === 5,
    "pdp-configurator--6-params": getParamCount() === 6,
    "pdp-configurator--7-params": getParamCount() === 7,
    "is-configuring": activeSelector !== CONFIGURATOR_SELECTOR.NONE,
  });

  const priceClassList = classnames({
    "pdp-price": true,
    "has-resolved": priceHasResolved,
  });

  return (
    <div className={classList}>
      <div className="pdp-configurator__content">
        <div className="pdp-configurator__slides">
          {activeSelector == CONFIGURATOR_SELECTOR.NONE && (
            <AnimatePresence>
              <motion.div
                key="pdpConfigSlide0"
                initial={selectorAnimationVariants.initial}
                animate={selectorAnimationVariants.animate}
                exit={selectorAnimationVariants.exit}
                className="pdp-configurator__slide pdp-configurator__header"
              >
                <header className="pdp-configurator__header__text">
                  <h2>
                    {!activeProductData.title && texts.loading}
                    {activeProductData.title === "NONE"
                      ? texts.errorTitle
                      : activeProductData.title}
                  </h2>

                  <p>
                    {activeProductData.description === "NONE"
                      ? texts.errorText
                      : activeProductData.description}
                  </p>
                  {activeProductData.description && (
                    <button className="basic-link" onClick={moreInfoHandler}>
                      <span className="text">{texts.moreInfo}</span>
                      <span className="icon">
                        <ArrowSmallSvg />
                      </span>
                    </button>
                  )}
                </header>

                <PDPConfiguratorOptions
                  mode={configuratorMode}
                  activateModelHandler={activateModelOptionHandler}
                  activateMaterialHandler={activateMaterialOptionHandler}
                />

                <div className="pdp-configurator__header__price">
                  {currentSiteMode === SITE_MODE.PROFESSIONAL && (
                    <>
                      {showNotifyMeButton() ? (
                        <button className="basic-link" onClick={onNotifyMe}>
                          <span className="icon">
                            <PlusSvg />
                          </span>
                          <span className="text">{texts.notifyMe}</span>
                        </button>
                      ) : (
                        showBasketButton() && (
                          <button
                            className={`basic-link${
                              waitingForBasketAdd ? " is-disabled" : ""
                            }`}
                            onClick={onAddToBasket}
                          >
                            <span className="icon">
                              <PlusSvg />
                            </span>
                            <span className="text">
                              {waitingForBasketAdd
                                ? texts.addingToBasket
                                : texts.addToBasket}
                            </span>
                          </button>
                        )
                      )}
                    </>
                  )}
                  {currentSiteMode === SITE_MODE.RESIDENTIAL &&
                    (currentViewport?.name === ViewportSizes.SMALL.name ||
                      currentViewport?.name === ViewportSizes.MEDIUM.name) && (
                      <button className="basic-link" onClick={wishListHandler}>
                        <span className="icon">
                          <HeartSmallSvg
                            fillHex="transparent"
                            strokeHex="#000"
                          />
                        </span>
                        <span className="text">{texts.addToWishList}</span>
                      </button>
                    )}
                  <h4 className={priceClassList}>
                    <small>{marketRemark}</small>{" "}
                    <ProductPrice
                      configurationId={activeConfigurationSKU}
                      onResolved={updateButtonsAccordingToPrice}
                    />
                  </h4>
                </div>
                <div className="pdp-configurator__header__cta">
                  {data?.menu?.storeLocatorLink?.jss?.value?.href && (
                    <ActionButton
                      icon="MapMarkerSvg"
                      text={texts.findInStore.toUpperCase()}
                      buttonType={BUTTONSTYLE.GHOST}
                      customClass="action-button--secondary"
                      href={data.menu.storeLocatorLink.jss.value.href}
                    />
                  )}
                  {currentSiteMode === SITE_MODE.PROFESSIONAL && (
                    <ActionButton
                      text={texts.saveToProjects.toUpperCase()}
                      buttonType={BUTTONSTYLE.SOLID}
                      clickHandler={saveToProjectsHandler}
                      customClass="action-button--primary"
                    />
                  )}
                  {currentSiteMode === SITE_MODE.RESIDENTIAL && (
                    <>
                      {showNotifyMeButton() ? (
                        <ActionButton
                          text={texts.notifyMe.toUpperCase()}
                          buttonType={BUTTONSTYLE.SOLID}
                          customClass="action-button--primary"
                          clickHandler={onNotifyMe}
                        />
                      ) : (
                        showBasketButton() && (
                          <ActionButton
                            text={
                              waitingForBasketAdd
                                ? texts.addingToBasket.toUpperCase()
                                : texts.addToBasket.toUpperCase()
                            }
                            icon="BasketSvg"
                            buttonType={BUTTONSTYLE.SOLID}
                            customClass="action-button--primary"
                            isDisabled={waitingForBasketAdd}
                            clickHandler={onAddToBasket}
                          />
                        )
                      )}
                    </>
                  )}
                </div>
              </motion.div>
              {showNotifyMeButton() ? (
                <p className="pdp-configurator__notify-me-message">
                  {texts.notifyMeMessage}
                </p>
              ) : (
                <></>
              )}
            </AnimatePresence>
          )}
          {modelTypes &&
            modelTypes.length > 0 &&
            activeSelector == CONFIGURATOR_SELECTOR.MODEL_TYPES && (
              <AnimatePresence>
                <motion.div
                  key="pdpConfigSlide1"
                  initial={selectorAnimationVariants.initial}
                  animate={selectorAnimationVariants.animate}
                  exit={selectorAnimationVariants.exit}
                  className="pdp-configurator__slide"
                >
                  <PDPModelSelector
                    tiles={modelTypes}
                    itemClickHandler={modelTypeChosenHandler}
                    currentViewport={currentViewport}
                  />
                </motion.div>
              </AnimatePresence>
            )}
          {materialSets &&
            materialSets.length > 1 &&
            activeSelector == CONFIGURATOR_SELECTOR.MATERIAL_SETS && (
              <AnimatePresence>
                <motion.div
                  key="pdpConfigSlide2"
                  initial={selectorAnimationVariants.initial}
                  animate={selectorAnimationVariants.animate}
                  exit={selectorAnimationVariants.exit}
                  className="pdp-configurator__slide"
                >
                  <PDPMaterialSetSelector
                    materialSets={materialSets}
                    itemClickHandler={materialSetChosenHandler}
                    currentViewport={currentViewport}
                  />
                </motion.div>
              </AnimatePresence>
            )}
          {materialSets &&
            materialSets.length > 0 &&
            activeSelector == CONFIGURATOR_SELECTOR.MATERIALS && (
              <AnimatePresence>
                <motion.div
                  key="pdpConfigSlide3"
                  initial={selectorAnimationVariants.initial}
                  animate={selectorAnimationVariants.animate}
                  exit={selectorAnimationVariants.exit}
                  className="pdp-configurator__slide"
                >
                  <PDPMaterialSelector
                    materialSet={
                      activeMaterialSetIndex > -1 && materialSets
                        ? materialSets[activeMaterialSetIndex]
                        : {}
                    }
                    materials={
                      activeMaterialSetIndex > -1 && materialSets
                        ? materialSets[activeMaterialSetIndex].materials
                        : []
                    }
                    itemClickHandler={materialChosenHandler}
                    currentViewport={currentViewport}
                  />
                </motion.div>
              </AnimatePresence>
            )}
        </div>
      </div>

      {activeSelector == CONFIGURATOR_SELECTOR.MODEL_TYPES && (
        <div className="pdp-configurator__footer">
          <div className="pdp-configurator__footer__cta">
            <button className="basic-link" onClick={cancelConfigurationHandler}>
              <span className="text">{texts.cancel}</span>
            </button>

            <ActionButton
              text={texts.apply}
              buttonType={BUTTONSTYLE.SOLID}
              customClass="action-button--primary"
              isDisabled={activeModelTypeIndex < 0}
              clickHandler={submitModelTypeHandler}
            />
          </div>
        </div>
      )}

      {(activeSelector == CONFIGURATOR_SELECTOR.MATERIAL_SETS ||
        activeSelector == CONFIGURATOR_SELECTOR.MATERIALS) && (
        <div className="pdp-configurator__footer">
          {activeMaterialIsUnallowed && <p>{texts.configuratorResetWarning}</p>}
          {activeSelector == CONFIGURATOR_SELECTOR.MATERIALS &&
            materialSets.length > 1 && (
              <ActionButton
                hasArrow={true}
                buttonType={BUTTONSTYLE.ICON_LINK}
                clickHandler={backToMaterialSetsHandler}
                customClass="pdp-configurator__back"
              />
            )}
          <div className="pdp-configurator__footer__cta">
            <button className="basic-link" onClick={cancelConfigurationHandler}>
              <span className="text">{texts.cancel}</span>
            </button>
            <ActionButton
              text={texts.apply}
              buttonType={BUTTONSTYLE.SOLID}
              customClass="action-button--primary"
              isDisabled={activeMaterialSetMaterialIndex < 0}
              clickHandler={submitMaterialHandler}
            />
          </div>
        </div>
      )}
    </div>
  );
};
PDPConfigurator.propTypes = {};

export default PDPConfigurator;
