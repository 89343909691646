import NavTile from "./nav-tile";

export default {
  default: {
    fields: {
      tiles: [NavTile.default, NavTile.noContact, NavTile.onlyText],
    },
  },
  customBg: {
    fields: {
      tiles: [NavTile.default, NavTile.noContactInverted, NavTile.onlyText],
      isInverted: true,
      backgroundColorHex: "#5599DD",
    },
  },
  twoTiles: {
    fields: {
      tiles: [NavTile.default, NavTile.onlyText],
    },
  },
};
