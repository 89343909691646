import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import StatusBar from "../../StatusBar";
import InfoPanel from "../../InfoPanel";
import ProductLine from "../../ProductLine";
import OrderCalculation from "../../OrderCalculation";
import { dataLayerCommercePush } from "@assets/js/gtm";
import { getURLParams } from "@assets/js/url-params";

import "./order-receipt.scss";
/**
 *
 * @param {*} order reciept data
 * @returns
 */
const OrderReceipt = ({ data }) => {
  const [trackedOrderId, setTrackedOrderId] = useState(null);
  useEffect(() => {
    // GTM Tracking
    const params = getURLParams();
    const id = params.get("id");
    if (trackedOrderId !== id) {
      const orderId = params.get("orderid");
      dataLayerCommercePush("purchase", {
        currencyCode: data.calculation.Currency,
        purchase: {
          actionField: {
            id,
            orderId,
            revenue: data.calculation.OrderTotal,
            tax: data.calculation.TaxTotal,
            shipping: data.calculation.ShippingTotal,
            coupon: data.calculation.totalWithoutDiscount,
          },
          products: data.productLines.map((p) =>
            Object.assign(
              {},
              {
                name: p.ModelGroupName,
                id: p.FriendlyName,
                price: p.Price,
                brand: "",
                category: "",
                variant: p.ModelName,
                quantity: p.Quantity,
              },
              ...(p.Materials?.length
                ? p.Materials.map((mat, index) => ({
                    [`dimension${index + 1}`]: mat.Id,
                  }))
                : [{ dimension1: "not set" }])
            )
          ),
        },
      });
      setTrackedOrderId(id);
    }
  }, [data, trackedOrderId]);
  return (
    <div className="order-receipt">
      {data.statusBar && <StatusBar />}
      {data.infoPanels &&
        data.infoPanels.map((item, index) => {
          return (
            <InfoPanel key={`orderReceiptInfoPanel${index}`} fields={item} />
          );
        })}
      {data.productLines &&
        data.productLines.map((item, index) => {
          return (
            <ProductLine
              key={`orderReceiptProductLine${index}`}
              {...item}
              currency={data.calculation.Currency}
            />
          );
        })}
      {data.calculation && (
        <OrderCalculation postalCode={data.postalCode} {...data.calculation} />
      )}
    </div>
  );
};
OrderReceipt.propTypes = {};

export default OrderReceipt;
