import React, { Fragment, useState } from "react";
import { SwiperSlide } from "swiper/react";

import Carousel from "../Carousel";
import { PlusSvg } from "@assets/js/icons";
import { motion } from "framer-motion";
import ProductSeriesTile from "../ProductSeriesTile";

import "./product-series-slider.scss";

const ProductSeriesSlider = ({ fields, rendering }) => {
  const [expanded, setExpanded] = useState(false);
  const { uid } = rendering;
  const showAllHandler = () => {
    setExpanded(!expanded);
  };

  let isExpanded;
  if (expanded) {
    isExpanded = "product-series-slider is-expanded";
  } else {
    isExpanded = "product-series-slider";
  }
  const tiles = fields?.data?.item?.tiles?.values;
  const swiperArgs = {
    fields: {
      headline: fields?.data?.item?.headline?.field,
      headlineType: "h3",
      isInverted: false,
    },
    className: isExpanded,
    children: (
      <>
        {tiles?.map((tile, index) => {
          return (
            <Fragment key={`slideFrag${index}`}>
              <SwiperSlide
                key={`slide${index}`}
                className="product-series-slider__slide"
              >
                <ProductSeriesTile fields={tile} />
              </SwiperSlide>
            </Fragment>
          );
        })}
      </>
    ),
    showMoreBtn: (
      <>
        {!expanded && tiles?.length > 4 && (
          <motion.div className="product-series-slider__show-more">
            <div className="product-series-slider__link">
              <button
                className="product-series-slider__expand"
                onClick={showAllHandler}
              >
                <PlusSvg />
                <p>{fields?.data?.item?.loadMoreButtonText?.field?.value}</p>
              </button>
            </div>
          </motion.div>
        )}
      </>
    ),
  };

  return <Carousel {...swiperArgs} key={uid} />;
};

export default ProductSeriesSlider;
