import React, { Fragment, useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import PropTypes from "prop-types";
import getUniqueId from "@assets/js/get-unique-id";
import { IMAGE } from "@constants";
import PDPSwatchTile from "../PDPSwatchTile";

import ViewportSizes from "@assets/js/viewport-sizes";

import {
  useAvailableMaterialIds,
  useAllowedMaterialIds,
  useActiveMaterialIndex,
} from "@store/pdp";

import "./pdp-material-selector.scss";

const PDPMaterialSelector = ({
  materialSet,
  materials,
  itemClickHandler,
  currentViewport,
}) => {
  const { t } = useTranslation();

  const texts = {
    materials: t("FritzHansen_Project_ConsumersAndProfessionals_PDP_Materials"),
    selectMaterial: t(
      "FritzHansen_Project_ConsumersAndProfessionals_PDP_SelectMaterial"
    ),
    selectedMaterial: t(
      "FritzHansen_Project_ConsumersAndProfessionals_PDP_SelectedMaterial"
    ),
    moreInfo: t("FritzHansen_Project_ConsumersAndProfessionals_PDP_MoreInfo"),
  };
  const rootRef = useRef();
  const [selectedMaterialItem, setSelectedMaterialItem] = useState(null);
  const [
    availableMaterialIds,
    setAvailableMaterialIds,
  ] = useAvailableMaterialIds();

  const [allowedMaterialIds, setAllowedMaterialIds] = useAllowedMaterialIds();

  const [
    activeMaterialIndex,
    setActiveMaterialIndex,
  ] = useActiveMaterialIndex();

  const isAvailableMaterial = (item) => {
    const foundIndex = availableMaterialIds[activeMaterialIndex].indexOf(
      item.materialId
    );
    const isAvailable = foundIndex > -1;

    // if (!isAvailable) console.log(`materialId "${item.materialId}" is not available`);

    return isAvailable;
  };

  const isAllowedMaterial = (item) => {
    const foundIndex = allowedMaterialIds[activeMaterialIndex].indexOf(
      item.materialId
    );
    const isAllowed = foundIndex > -1;
    // if (!isAllowed) console.log(`materialId "${item.materialId}" is not allowed`);

    return isAllowed;
  };

  const clickHandler = (item, isAllowed) => {
    setSelectedMaterialItem(item);
    itemClickHandler(item, isAllowed);
  };
  useEffect(() => {
    if (
      currentViewport.name === ViewportSizes.SMALL.name ||
      currentViewport.name === ViewportSizes.MEDIUM.name
    ) {
      disableBodyScroll(rootRef.current);
    }
    return () => {
      enableBodyScroll(rootRef.current);
    };
  }, []);

  return (
    <div className="pdp-material-selector">
      <h3>{texts.selectMaterial}</h3>
      <div className="pdp-material-selector__info">
        {materialSet.isPrimary && <h5>
          {materialSet.label} – {materials.length} {texts.materials}
        </h5>}
        {materialSet.urlSlug && (
          <a className="basic-link" href={materialSet.urlSlug} target="_blank">
            <span className="text">{texts.moreInfo}</span>
          </a>
        )}
      </div>

      {/* {selectedMaterialItem ? <p>{texts.selectedMaterial}: {selectedMaterialItem.materialLabel} ({selectedMaterialItem.materialId})</p> : <p></p>} */}

      <div className="pdp-material-selector__content" ref={rootRef}>
        {materials && (
          <div className="pdp-material-selector__tiles">
            {materials.map((item) => {
              return (
                <Fragment key={`materialTile${item.materialId}`}>
                  {isAvailableMaterial(item) && (
                    <PDPSwatchTile
                      {...item}
                      title={item.materialLabel.split("(")[0]}
                      tag={item.materialLabel.split("(")[1]?.split(")")[0]}
                      item={item}
                      imageType={IMAGE.SWATCH}
                      isUnallowed={!isAllowedMaterial(item)}
                      itemClickHandler={clickHandler}
                    />
                  )}
                </Fragment>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

PDPMaterialSelector.propTypes = {
  materials: PropTypes.array.isRequired,
  materialSet: PropTypes.object.isRequired,
  filters: PropTypes.object,
};

export default PDPMaterialSelector;
