import React, { useState, useEffect } from "react";
import classnames from "classnames";
import { NavLink } from "react-router-dom";
import { ArrowSmallSvg } from "@assets/js/icons";

import useHeaderStore, {
  useMenuOverlayMediaQueue,
} from "@store/header";
/**
 * @prop item { Object } - root object containing field and fields
 * @prop clickHandler { fn } - clickhandler function
 */
export default ({ item, jss, clickHandler, active = false, className }) => {
  const closeMainMenu = useHeaderStore((store) => store.closeMainMenu);
  const setMobileNavigationActive = useHeaderStore((store) => store.setMobileNavigationActive);
  
  const [ , , clearMenuOverlayMediaQueue] = useMenuOverlayMediaQueue();

  const linkItem = jss ? jss : item.fields.find((x) => x.name === "Link");
  const link = linkItem?.jss?.value?.href;
  const linkType = linkItem?.jss?.value?.linktype;

  const name =
    linkItem?.jss?.value?.title ||
    linkItem?.jss?.value?.text ||
    item?.fields[item.fields.findIndex((x) => x.name === "Title")]?.value ||
    item?.name;

  const onClick = () => {
    if (clickHandler) {
      clickHandler(item.id);
    }
  };
  const [css, setCss] = useState({ [className]: true, "is-active": false });

  useEffect(() => {
    setCss({ ...css, "is-active": link === window.location.pathname });
  }, [0]);

  return (
    <>
      {linkType?.toUpperCase() === "INTERNAL" && (
        <NavLink
          to={link}
          className={classnames(css)}
          onClick={() => {
            setMobileNavigationActive(false);
            closeMainMenu();
            clearMenuOverlayMediaQueue();
          }}
        >
          <span>{name}</span>
          <ArrowSmallSvg />
        </NavLink>
      )}
      {linkType?.toUpperCase() === "EXTERNAL" && (
        <a className={classnames(css)} href={link}>
          <span>{name}</span>
          <ArrowSmallSvg />
        </a>
      )}
      {!linkType && (
        <button className={classnames(css)} onClick={onClick}>
          <span>{name}</span>
          <ArrowSmallSvg />
        </button>
      )}
    </>
  );
};
