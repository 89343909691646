import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import classnames from "classnames";
import NavButton from "../NavButton";
import ContactLinksGroup from "../ContactLinksGroup";
import MediaFocal from "../MediaFocal";

import "./nav-highlight-block.scss";

const NavHighlightBlock = ({ fields }) => {
  const link1 = fields?.button1?.fields?.link?.value?.href;
  const contactInfoAvailable1 = fields?.phone1?.value || fields?.email1?.value;

  //TODO: make interactive checks, don't use anchor tags if false

  const interactive1 = !contactInfoAvailable1 && link1;
  const link2 = fields?.button2?.fields?.link?.value?.href;
  const contactInfoAvailable2 = fields?.phone2?.value || fields?.email2?.value;
  const interactive2 = !contactInfoAvailable2 && link2;

  const classList = classnames({
    "nav-highlight-block": true,
    "nav-highlight-block--inverted": fields?.isInverted?.value,
  });
  const textHoverLeft = classnames({
    "nav-highlight-block--left-hover-text": interactive1,
  });
  const imgHoverLeft = classnames({
    "nav-highlight-block--left-hover-img": interactive1,
  });
  const hoverRight = classnames({
    "nav-highlight-block--right-hover": interactive2,
  });

  // If text inverted, btn gets inverted too
  if (fields?.isInverted?.value === true && fields?.button1) {
    fields.button1.fields.isInverted = true;
  }
  if (fields?.isInverted?.value === true && fields?.button2) {
    fields.button2.fields.isInverted = true;
  }
  return (
    <>
      {fields && (
        <div
          className={classList}
          style={
            fields.backgroundColorHex && {
              "--highlight-bgc": fields.backgroundColorHex.value,
            }
          }
        >
          <div
            className={`nav-highlight-block__text-wrapper nav-highlight-block__text-wrapper--left ${textHoverLeft}`}
          >
            {interactive1 ? (
              <a href={link1}>
                <Text className="nav-highlight-block__headline" tag="h2" field={fields.headline1} />
              </a>
            ) : (
              <Text className="nav-highlight-block__headline" tag="h2" field={fields.headline1} />
            )}

            {fields?.bodyText1 && (
              <>
                {interactive1 ? (
                  <a href={link1}>
                    <Text
                      className="nav-highlight-block__bodytext"
                      tag="p"
                      field={fields.bodyText1}
                    />
                  </a>
                ) : (
                  <Text
                    className="nav-highlight-block__bodytext"
                    tag="p"
                    field={fields.bodyText1}
                  />
                )}
              </>
            )}

            {contactInfoAvailable1 && (
              <div className="nav-highlight-block__contact">
                <ContactLinksGroup phone={fields.phone1} email={fields.email1} />
              </div>
            )}

            {fields.button1?.fields && !contactInfoAvailable1 && (
              <div className="nav-highlight-block__link">
                <NavButton fields={fields.button1.fields} />
              </div>
            )}
          </div>
          <div
            className={`nav-highlight-block__img nav-highlight-block__img--left ${imgHoverLeft}`}
          >
            {fields.media1?.fields &&
              (interactive1 ? (
                <a href={link1}>
                  <MediaFocal fields={fields.media1.fields} />
                </a>
              ) : (
                <MediaFocal fields={fields.media1.fields} />
              ))}
          </div>

          {/* TILE TWO */}

          <div className={`nav-highlight-block__wrapper ${hoverRight}`}>
            <div className="nav-highlight-block__img nav-highlight-block__img--right">
              {fields.media2?.fields &&
                (interactive2 ? (
                  <a href={link2}>
                    <MediaFocal fields={fields.media2.fields} />
                  </a>
                ) : (
                  <MediaFocal fields={fields.media2.fields} />
                ))}
            </div>

            <div className="nav-highlight-block__text-wrapper">
              {interactive2 ? (
                <a href={link2}>
                  <Text
                    className="nav-highlight-block__headline"
                    tag="h2"
                    field={fields.headline2}
                  />
                </a>
              ) : (
                <Text className="nav-highlight-block__headline" tag="h2" field={fields.headline2} />
              )}

              {fields?.bodyText2 && (
                <>
                  {interactive2 ? (
                    <a href={link2}>
                      <Text
                        className="nav-highlight-block__bodytext"
                        tag="p"
                        field={fields.bodyText2}
                      />
                    </a>
                  ) : (
                    <Text
                      className="nav-highlight-block__bodytext"
                      tag="p"
                      field={fields.bodyText2}
                    />
                  )}
                </>
              )}

              {!fields.button2?.fields && contactInfoAvailable2 && (
                <div className="nav-highlight-block__contact">
                  <ContactLinksGroup phone={fields.phone2} email={fields.email2} />
                </div>
              )}

              {fields.button2?.fields && (
                <div className="nav-highlight-block__link">
                  <NavButton fields={fields.button2.fields} />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default NavHighlightBlock;
