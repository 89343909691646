import React, { useState } from "react";
import cuid from "cuid";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LazyLoad from "react-lazyload";
import { motion } from "framer-motion";
import classnames from "classnames";
import PropTypes from "prop-types";
import ProductPrice from "../ProductPrice";
import ProductImage from "../ProductImage";
import { dataLayerCommercePush } from "@assets/js/gtm";

import useBasketStore from "@store/basket";
import usePDPStore from "@store/pdp";
import useHeaderStore from "@store/header";
import useNotificationStore from "@store/notifications";

import {
  PRICE,
  BUTTONSTYLE,
  NOTIFICATION,
  OVERLAY,
} from "@assets/js/constants";
import { BasketSvg } from "@assets/js/icons";

import "./product-tile.scss";

const imgFallback =
  "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCA0MDAgNDAwIj48cGF0aCBmaWxsPSIjOTk5IiBkPSJNMTg1LjczIDE4OS43NWgyNy43N3YyMi43NWgtMjcuNzd2LTIyLjc1em0xIDF2MjAuNzVoMjUuNzd2LTIwLjc1aC0yNS43N3oiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZmlsbC1ydWxlPSJldmVub2RkIi8+PHBhdGggZmlsbD0iIzk5OSIgZD0iTTE5NC42MTQgMTk3LjkyN2w3LjYwMSA3LjYgMTAuNTQ4LTUuNjc5LjQ3NC44OC0xMS4yMDMgNi4wMzMtNy40NTYtNy40NTYtOC4wMTMgNy4yMTEtLjY2OS0uNzQzIDguNzE4LTcuODQ2eiIgY2xpcC1ydWxlPSJldmVub2RkIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48cGF0aCBmaWxsPSIjOTk5IiBkPSJNMjAyLjk2MSAxOTkuMjg5YTIuMzQ2IDIuMzQ2IDAgMTAwLTQuNjkyIDIuMzQ2IDIuMzQ2IDAgMDAwIDQuNjkyem0wIDFhMy4zNDYgMy4zNDYgMCAxMDAtNi42OTIgMy4zNDYgMy4zNDYgMCAwMDAgNi42OTJ6IiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==";

const ProductTile = ({
  modelTypeId_s = null,
  modelId_s = null,
  configurationId_s = null,
  fullFriendlyItem = null,

  imageId_s,

  imageUrl_s,
  itemType_s,

  urlSlug_s,
  displayName_t,
  fullName_t,
  plpDescription_t,
  seriesName_t,
  visualSize_s,
  doc_type_s,
  variants,
  onPriceResolve = null,
  addToBasket = false,
  currency = null,
  taxonomy = null,
  index,
}) => {
  // console.log("ProductTile", id, displayName_t);

  const [imageLoadError, setImageLoadError] = useState(false);

  const [waitingForBasketAdd, setWaitingForBasketAdd] = useState(false);
  const { t } = useTranslation();
  const texts = {
    moreVariants: t(
      "FritzHansen_Project_ConsumersAndProfessionals_PLP_MoreVariants"
    ),
    addToBasket: t(
      "FritzHansen_Project_ConsumersAndProfessionals_PLP_AddToBasket"
    ),
    addingToBasket: t(
      "FritzHansen_Project_ConsumersAndProfessionals_PDP_AddingToBasket"
    ),
  };
  const setActiveNotification = useNotificationStore(
    (state) => state.setActiveNotification
  );
  const setMenuOverlayActive = useHeaderStore(
    (store) => store.setMenuOverlayActive
  );
  const resetPDPStore = usePDPStore((state) => state.resetPDPStore);
  const addProductToBasket = useBasketStore((state) => state.addProduct);

  const addToBasketHandler = () => {
    if (waitingForBasketAdd) return false;

    setWaitingForBasketAdd(true);
    addProductToBasket(
      { productId: fullFriendlyItem },
      productAddedSuccessHandler
    );
  };

  const productAddedSuccessHandler = () => {
    setWaitingForBasketAdd(false);
    setActiveNotification({
      id: cuid(),
      title: t(
        "FritzHansen_Project_ConsumersAndProfessionals_Notifications_AddToBasketTitle"
      ),
      buttonText: t(
        "FritzHansen_Project_ConsumersAndProfessionals_Cart_OpenBasket"
      ),
      type: NOTIFICATION.ITEM_ADDED,
      clickHandler: () => {
        setMenuOverlayActive(OVERLAY.BASKET);
      },
      timer: 2000,
    });
  };

  const onImageLoadError = () => {
    setImageLoadError(true);
  };

  // visualSize:
  // full-horizontal, full-vertical, scale-small, scale-medium, scale-large

  const imageClassList = classnames({
    image: true,
    [`image--${visualSize_s?.toLowerCase()}`]: visualSize_s,
    "no-image": imageLoadError,
  });

  // GTM Tracking
  const clickHandler = () => {
    //nuke pdp store
    resetPDPStore();

    let materials = configurationId_s?.split("-") || ["not set"];
    if (materials && materials.length > 1) {
      materials.shift();
    } else {
      materials = ["not set"];
    }
    dataLayerCommercePush("productClick", {
      currencyCode: currency,
      click: {
        actionField: { list: taxonomy },
        products: [
          Object.assign(
            {},
            {
              name: fullName_t,
              id: configurationId_s,
              price: "",
              brand: "",
              category: "",
              variant: plpDescription_t,
              position: index + 1,
            },
            ...materials.map((mat, index) => ({
              [`dimension${index + 1}`]: mat,
            }))
          ),
        ],
      },
    });
  };

  return (
    <motion.article className="product-tile" variants={variants}>
      <NavLink
        to={urlSlug_s}
        className="product-tile__image"
        onClick={clickHandler}
      >
        <div className={imageClassList}>
          <div className="image__inner">
            {itemType_s === "SKU" ? (
              <>
                {imageUrl_s ? (
                  <img src={imageUrl_s} />
                ) : (
                  <img src={imgFallback} />
                )}
              </>
            ) : (
              <ProductImage
                imageId={imageId_s || "null"}
                alt={displayName_t}
                onErrorHandler={onImageLoadError}
              />
            )}
          </div>
        </div>
      </NavLink>

      <div className="product-tile__text">
        <NavLink to={urlSlug_s} onClick={clickHandler}>
          {seriesName_t && <h3>{seriesName_t}</h3>}
          {plpDescription_t && <p>{plpDescription_t}</p>}

          {modelTypeId_s ? (
            <ProductPrice
              modelTypeId={modelTypeId_s}
              onResolved={
                onPriceResolve ? (price) => onPriceResolve(price) : null
              }
              // format={itemType_s === "SKU" ? PRICE.EXACT : PRICE.FROM}
              // https://alphasolutionsdk.atlassian.net/browse/FHBC-1090
              format={PRICE.FROM}
            />
          ) : (
            <ProductPrice
              configurationId={configurationId_s}
              onResolved={
                onPriceResolve ? (price) => onPriceResolve(price) : null
              }
              format={PRICE.FROM}
            />
          )}

          {/* <h5>{fields.value.priceAdjusted}</h5> */}
        </NavLink>
      </div>

      {addToBasket && fullFriendlyItem ? (
        <button
          onClick={addToBasketHandler}
          className={`product-tile__link${
            waitingForBasketAdd ? " is-disabled" : ""
          }`}
        >
          <span className="icon">
            <BasketSvg />
          </span>
          <span className="text">
            {waitingForBasketAdd ? texts.addingToBasket : texts.addToBasket}
          </span>
        </button>
      ) : (
        <NavLink
          to={urlSlug_s}
          className="product-tile__link"
          onClick={clickHandler}
        >
          <span className="text">{texts.moreVariants}</span>
        </NavLink>
      )}
      {/* {fields.value.tag && (
        <span className="product-tile__tag">{fields.value.tag}</span>
      )} */}
    </motion.article>
  );
};
ProductTile.propTypes = {
  imageId_s: PropTypes.string,
  urlSlug_s: PropTypes.string,
  displayName_t: PropTypes.string,
  fullName_t: PropTypes.string,
  doc_type_s: PropTypes.string,
  variants: PropTypes.object,
};
export default ProductTile;
