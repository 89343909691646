import React from "react";
import RichTextModule from "../RichTextModule";
import { Text, RichText } from "@sitecore-jss/sitecore-jss-react";
import NavButton from "../NavButton";
import MediaFocal from '../MediaFocal';
import classnames from "classnames";
import isTrue from "@assets/js/is-true";

import './article-module.scss';

const ArticleModule = ({ fields }) => {

  const classList = classnames({
    "article-module": true,
    "article-module--no-headline": !fields?.quote?.value,
    "article-module--inverted": isTrue(fields?.isInverted),
    "article-module--has-bgcolor": fields?.backgroundColorHex?.value,
  });


  return (
    <>
      {fields && (
        <div className={classList}
          style={
            fields.backgroundColorHex && {
              "--article-module-bgc": fields?.backgroundColorHex.value,
            }
          }>

          {fields?.quote && (
            <Text
              tag="h2"
              className="article-module__headline"
              field={fields.quote}
            />
          )}

          {fields?.asideBodyText || fields?.media ? (
            <aside className="article-module__aside">

              <MediaFocal fields={fields?.media?.fields} />

              <div className="article-module__data">
                <RichText field={fields.asideBodyText} />
              </div>

            </aside>
          ) : (
              null
            )}

          {fields?.manchet && (
            <Text
              tag="h4"
              className="article-module__manchet"
              field={fields.manchet}
            />
          )}

          <RichTextModule fields={fields} />

          {fields.button && (
            <div className="article-module__button">
              <NavButton fields={fields.button.fields} />
            </div>
          )}

        </div>
      )}
    </>
  );
}


export default ArticleModule;
