import React, { useEffect } from "react";
import NavButton from "../NavButton";
import { Text } from "@sitecore-jss/sitecore-jss-react";

import useGlobalStore, {useCommerceMarket} from "@store/global";

import "./flowbox-slider.scss";

const loadFlowbox = () =>
  new Promise((resolve) => {
    (function (d, id) {
      if (!window.flowbox) {
        var f = function () {
          f.q.push(arguments);
        };
        f.q = [];
        window.flowbox = f;
      }

      if (d.getElementById(id)) {
        return resolve();
      }
      var s = d.createElement("script"),
        fjs = d.scripts[d.scripts.length - 1];
      s.id = id;
      s.async = true;
      s.src = "https://connect.getflowbox.com/flowbox.js";

      fjs.parentNode.insertBefore(s, fjs);
      resolve();
    })(document, "flowbox-js-embed");
  });
  
const FlowBoxSlider = ({ flowKey: key, fields }) => {
  
  const container = `${containerName}-${key}`;
  const containerName = "js-flowbox-flow";
  
  const sc_lang = useGlobalStore((state) => state.sc_lang);
  const [commerceMarket] = useCommerceMarket();
    
  
  useEffect(() => {
    if ( commerceMarket && sc_lang ) {
      const locale = `${sc_lang}-${commerceMarket.toUpperCase()}`;

      loadFlowbox().then(() => {
        window.flowbox("init", {
          container: `#${container}`,
          key: fields?.flowboxKey?.value,
          locale,
        });
      });
    }
  }, [commerceMarket, sc_lang, container, key]);

  return (
    <div className="flowbox-slider">
      {fields?.headline && (
        <div className="flowbox-slider__headline">
          <Text tag="h2" field={fields?.headline} />
        </div>
      )}

      <div id={container} />

      {fields?.Button?.fields && (
        <div className="flowbox-slider__button">
          <NavButton fields={fields?.Button?.fields} />
        </div>
      )}
    </div>
  );
};

export default FlowBoxSlider;
