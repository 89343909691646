import create from "zustand";
import { devtools } from "zustand/middleware";
import axios from "axios";
import cuid from "cuid";
import globalErrorHandler from "@assets/js/global-error-handler";
import Cookies from "universal-cookie";
import produce from "immer";

import { STATUS, COOKIES } from "@constants";

const getPortfoliosUrl = `${process.env.REACT_APP_PORTFOLIOAPI}list/`; //with get

const createPortfolioUrl = `${process.env.REACT_APP_PORTFOLIOAPI}create/`; //with post
const deletePortfolioUrl = `${process.env.REACT_APP_PORTFOLIOAPI}delete/`; //with delete

const getPortfolioPrivateUrl = `${process.env.REACT_APP_PORTFOLIOAPI}edit/`;
const getPortfolioPublicUrl = `${process.env.REACT_APP_PORTFOLIOAPI}show/`;

const updatePortfolioUrl = `${process.env.REACT_APP_PORTFOLIOAPI}edit/`;

const addItemUrl = `${process.env.REACT_APP_PORTFOLIOAPI}addItem/`;
const deleteItemUrl = `${process.env.REACT_APP_PORTFOLIOAPI}deleteItem/`;

const PORTFOLIO_LOGS = false;

const cookies = new Cookies();

const store = create(
  devtools(
    (set, get) => ({
      loadStatus: STATUS.LOADING_IDLE,
      submissionMode: null,
      setSubmissionMode: (mode) => set({ submissionMode: mode }),

      submissionContent: null,
      setSubmissionContent: (mode) => set({ submissionContent: mode }),

      portfoliosList: null,
      setPortfoliosList: (data) => set({ portfoliosList: data }),

      activePortfolioData: {},
      setActivePortfolioData: (data) => set({ activePortfolioData: data }),

      // ####################################################################################

      fetchPortfolios: async (userKey, userAuthenticated, loadedCallback) => {
        let headers = {};

        if (userKey || userAuthenticated) {
          set({ loadStatus: STATUS.LOADING });
          if (PORTFOLIO_LOGS) console.log("portfolios | fetchPortfolios", userKey, loadedCallback);

          let fetchUrl = `${getPortfoliosUrl}${!userAuthenticated ? userKey : ""}`;
          if (userAuthenticated) {
            headers["headers"] = {
              Authorization: `Bearer ${cookies.get(COOKIES.jwt.name)}`,
            };
          }

          const response = await axios({
            method: "GET",
            url: fetchUrl,
            headers: {
              Authorization: userAuthenticated ? `Bearer ${cookies.get(COOKIES.jwt.name)}` : "",
            },
          }).catch((error) => globalErrorHandler(error, "PORTFOLIOS LIST"));

          if (response) {
            if (PORTFOLIO_LOGS) console.log("portfolios | fetchPortfolios | response:", response);

            let list = response.data;
            list = list.sort((a, b) => {
              return a.isWishlist && !b.isWishlist ? -1 : 1;
            });

            set({
              loadStatus: STATUS.READY,
              portfoliosList: list,
            });

            if (loadedCallback) {
              loadedCallback();
            }
          }
        } else {
          globalErrorHandler("No user key found and user is unauthenticated", "PORTFOLIOS LIST");
        }
      },

      // ####################################################################################

      createPortfolio: async (
        userKey,
        userAuthenticated,
        name,
        isWishlist = false,
        saveCompleteHandler
      ) => {
        set({ loadStatus: STATUS.LOADING });

        let fetchUrl = `${createPortfolioUrl}`;
        const config = {
          method: "post",
          url: fetchUrl,
          data: {
            userKey: !userAuthenticated ? userKey : "",
            name,
            isWishlist,
          },
        };
        if (userAuthenticated) {
          config["headers"] = {
            Authorization: `Bearer ${cookies.get(COOKIES.jwt.name)}`,
          };
        }

        const response = await axios(config).catch((error) =>
          globalErrorHandler(error, "PORTFOLIO CREATE")
        );

        if (response) {
          if (PORTFOLIO_LOGS) console.log("portfolios | createPortfolio | response:", response);

          set((state) => ({
            loadStatus: STATUS.READY,
            portfoliosList: produce(state.portfoliosList, (draft) => {
              draft.push(response.data);
            }),
          }));

          if (saveCompleteHandler && response.status === 200) {
            saveCompleteHandler(response.status);
          }
        }
      },

      // ####################################################################################

      fetchPortfolio: async (readOnly, portfolioKey, userAuthenticated) => {
        set({ loadStatus: STATUS.LOADING });
        let fetchUrl;
        if (readOnly) {
          fetchUrl = `${getPortfolioPublicUrl}${portfolioKey}`;
        } else {
          fetchUrl = `${getPortfolioPrivateUrl}${portfolioKey}`;
        }
        const config = {
          url: fetchUrl,
          method: "get",
        };
        if (userAuthenticated) {
          config["headers"] = {
            Authorization: `Bearer ${cookies.get(COOKIES.jwt.name)}`,
          };
        }

        const response = await axios(config).catch((error) =>
          globalErrorHandler(error, "PORTFOLIO GET")
        );

        if (response) {
          if (PORTFOLIO_LOGS) console.log("portfolios | fetchPortfolio | response:", response);

          set({
            loadStatus: STATUS.READY,
            activePortfolioData: response.data,
          });
        }
      },

      // ####################################################################################

      updatePortfolio: async (
        portfolioEditKey,
        newName = "",
        newDescription = "",
        userAuthenticated = false,
        updateCompleteHandler
      ) => {
        set({ loadStatus: STATUS.LOADING });

        let fetchUrl = `${updatePortfolioUrl}${portfolioEditKey}`;
        const postData = { name: newName, description: newDescription };
        const config = {
          url: fetchUrl,
          method: "put",
          data: postData,
        };
        if (userAuthenticated) {
          config["headers"] = {
            Authorization: `Bearer ${cookies.get(COOKIES.jwt.name)}`,
          };
        }

        const response = await axios(config).catch((error) =>
          globalErrorHandler(error, "PORTFOLIO UPDATE")
        );

        if (response) {
          if (PORTFOLIO_LOGS) console.log("portfolios | updatePortfolio | response:", response);

          if (updateCompleteHandler && response.status === 200) {
            updateCompleteHandler(response.status);
          }

          set({
            activePortfolioData: response.data,
            loadStatus: STATUS.READY,
          });
        }
      },

      // ####################################################################################

      deletePortfolio: async (portfolioEditKey, userAuthenticated) => {
        set({ loadStatus: STATUS.LOADING });
        let fetchUrl = `${deletePortfolioUrl}${portfolioEditKey}`;
        const config = {
          url: fetchUrl,
          method: "delete",
        };
        if (userAuthenticated) {
          config["headers"] = {
            Authorization: `Bearer ${cookies.get(COOKIES.jwt.name)}`,
          };
        }

        const response = await axios(config).catch((error) =>
          globalErrorHandler(error, "PORTFOLIO DELETE")
        );

        if (response) {
          if (PORTFOLIO_LOGS) console.log("portfolios | deletePortfolio | response:", response);

          set((state) => ({
            loadStatus: STATUS.READY,
            portfoliosList: produce(state.portfoliosList, (draft) => {
              const index = draft.findIndex((p) => p.portfolioEditKey === portfolioEditKey);
              draft.splice(index, 1);
            }),
          }));
        }
      },

      // ####################################################################################

      addItem: async (portfolioEditKey, addCompleteHandler, userAuthenticated) => {
        set({ loadStatus: STATUS.LOADING });

        let fetchUrl = `${addItemUrl}${portfolioEditKey}`;
        let postData = [
          {
            id: cuid(),
            type: get().submissionMode,
            addedDate: new Date().toISOString(),
            ...get().submissionContent.portfolioItem, //add all relevant parameters
          },
        ];

        if (PORTFOLIO_LOGS) console.log("portfolios | addItem | postData:", postData);

        const config = {
          url: fetchUrl,
          method: "post",
          data: postData,
        };
        if (userAuthenticated) {
          config["headers"] = {
            Authorization: `Bearer ${cookies.get(COOKIES.jwt.name)}`,
          };
        }

        const response = await axios(config).catch((error) =>
          globalErrorHandler(error, "PORTFOLIO ADD ITEM")
        );
        if (response) {
          if (PORTFOLIO_LOGS) console.log("portfolios | addItem | response:", response);

          // TODO add nofication
          set((state) => ({
            loadStatus: STATUS.READY,
            portfoliosList: produce(state.portfoliosList, (draft) => {
              const index = draft.findIndex((p) => p.portfolioEditKey === portfolioEditKey);
              if (index > -1) {
                draft[index] = response.data;
              }
            }),
            activePortfolioData: response.data,
          }));

          if (addCompleteHandler && response.status === 200) {
            addCompleteHandler({
              status: response.status,
              data: response.data,
            });
          }
        }
      },

      deleteItem: async (portfolioEditKey, itemKey, userAuthenticated) => {
        set({ loadStatus: STATUS.LOADING });

        if (PORTFOLIO_LOGS) console.log("portfolios | deleteItem |", portfolioEditKey, itemKey);

        const fetchUrl = `${deleteItemUrl}${portfolioEditKey}/${itemKey}`;

        const config = {
          url: fetchUrl,
          method: "DELETE",
        };
        if (userAuthenticated) {
          config["headers"] = {
            Authorization: `Bearer ${cookies.get(COOKIES.jwt.name)}`,
          };
        }

        const response = await axios(config).catch((error) =>
          globalErrorHandler(error, "PORTFOLIO DELETE ITEM")
        );
        if (response) {
          set({
            loadStatus: STATUS.READY,
            activePortfolioData: response.data,
          });
        }
      },
    }),
    "Portfolio"
  )
);

// ####################################################################################
// ####################################################################################
// ####################################################################################

export const useSubmissionMode = () => [
  store((store) => store.submissionMode),
  store((store) => store.setSubmissionMode),
];
export const useSubmissionContent = () => [
  store((store) => store.submissionContent),
  store((store) => store.setSubmissionContent),
];
export const usePortfoliosList = () => [
  store((store) => store.portfoliosList),
  store((store) => store.setPortfoliosList),
  store((store) => store.fetchPortfolios),
];
export const useActivePortfolioData = () => [
  store((store) => store.activePortfolioData),
  store((store) => store.setActivePortfolioData),
  store((store) => store.fetchPortfolio),
];

export default store;

// ####################################################################################
// ####################################################################################
// ####################################################################################
