import React from "react";
// import classnames from "classnames";

import "./main-footer.scss";

const MainFooter = (props) => {
  return (
    <>
      {!props.onlyMobile && (
        <footer className="main-footer"> DESKTOP FOOTER </footer>
      )}
      <footer className="main-footer main-footer--mobile d-n-vp5">
        <ul className="main-footer__links">
          <li>
            <a className="link" href="#">
              Account &amp; wishlist
            </a>
          </li>
          <li>
            <a className="link" href="#">
              About us
            </a>
          </li>
          <li>
            <a className="link" href="#">
              Customer service
            </a>
          </li>
        </ul>

        <ul className="main-footer__social">
          <li>
            <a className="link link--small" href="#">
              Facebook
            </a>
          </li>
          <li>
            <a className="link link--small" href="#">
              Instagram
            </a>
          </li>
          <li>
            <a className="link link--small" href="#">
              Pinterest
            </a>
          </li>
          <li>
            <a className="link link--small" href="#">
              YouTube
            </a>
          </li>
          <li>
            <a className="link link--small" href="#">
              LinkedIn
            </a>
          </li>
        </ul>
      </footer>
    </>
  );
};

export default MainFooter;
