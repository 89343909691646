import MediaFocal from "./media-focal";
import NavButton from "./nav-button";

export default {
  default: {
    fields: {
      tag: null,
      href: "",
      headline: "Neque et temporibus sunt",
      bodyText:
        "Sit explicabo eum labore voluptatem, neque et temporibus sunt, culpa tempora ratione quasi nostrum.",
      button: NavButton.link,
      isButtonEnabled: true,
      // contact: ContactLinksGroup.default,
      //   phone: "+45 22 33 44 55",
      //   email: "sdfsdfdf@dsfsdfds.com",
      media: MediaFocal.default,
      bodyRight: false,
      isInverted: false,
      isInteractive: false,
      backgroundColorHex: "#c3eecc",
    },
    isInteractive: false,
  },
  bodyRight: {
    fields: {
      tag: null,
      href: "",
      headline: "Neque et temporibus sunt",
      bodyText:
        "Sit explicabo eum labore voluptatem, neque et temporibus sunt, culpa tempora ratione quasi nostrum.",
      button: NavButton.outlined,
      isButtonEnabled: true,
      // contact: ContactLinksGroup.default,
      //   phone: "+45 22 33 44 55",
      //   email: "sdfsdfdf@dsfsdfds.com",
      media: MediaFocal.default,
      bodyRight: true,
      isInverted: false,
      isInteractive: false,
      backgroundColorHex: "#45ecc4",
    },
    isInteractive: true,
  },
  customInverted: {
    fields: {
      tag: null,
      href: "",
      headline: "Neque et temporibus sunt",
      bodyText:
        "Sit explicabo eum labore voluptatem, neque et temporibus sunt, culpa tempora ratione quasi nostrum.",
      button: NavButton.link,
      isButtonEnabled: true,
      // contact: ContactLinksGroup.default,
      //   phone: "+45 22 33 44 55",
      //   email: "sdfsdfdf@dsfsdfds.com",
      media: MediaFocal.default,
      bodyRight: true,
      isInverted: true,
      backgroundColorHex: "#45ecc4",
    },
    isInteractive: true,
  },
};
