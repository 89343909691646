import React, { useState } from "react";
import classnames from "classnames";
import getUniqueId from "@assets/js/get-unique-id.js";
import "./select.scss";

const Select = ({
  sortLabel,
  id,
  label,
  defaultLabel,
  name,
  options,
  isSortSelector,
  changeHandler,
  error,
  register,
  required,
  disabled,
  value,
  hasValue,
  externalValidation,
}) => {
  const [val, setVal] = useState(
    value && value.optionValue
      ? value.optionValue
      : options && options[0]
      ? options[0].optionValue
      : ""
  );

  const onChangeHandler = (target) => {
    setVal(target.value);
    if (changeHandler) changeHandler(target.value);
  };

  const classList = classnames({
    select: true,
    "select--sort": isSortSelector,
    "is-invalid": error,
    "is-valid": hasValue || (val && !error),
    "is-disabled": disabled,
  });

  let localProps = {
    name: name,
    disabled: disabled,
    required: required,
    id: id,
    ref: register,
    onChange: ({ target }) => onChangeHandler(target),
  };
  if (value) {
    localProps.value = val;
  }

  return (
    <span className={classList}>
      {isSortSelector && <span className="select__sort-text">{sortLabel}</span>}
      {label && (
        <label className="select__label" htmlFor={id}>
          {label}
        </label>
      )}
      <select className="select__selector" {...localProps}>
        {defaultLabel && (
          <option className="select__option" value="0">
            {defaultLabel}
          </option>
        )}
        {options.map((option) => {
          return (
            <option
              key={option.id || getUniqueId()}
              className="select__option"
              value={option.optionValue}
            >
              {option.optionLabel}
            </option>
          );
        })}
      </select>
      {error && <p className="input-field__message">{error.message}</p>}
    </span>
  );
};

export default Select;
