import React, { Fragment, useEffect } from "react";
import shallow from "zustand/shallow";
import { SwiperSlide } from "swiper/react";
import classnames from "classnames";
import Carousel from "../Carousel";
import getUniqueId from "@assets/js/get-unique-id.js";
import usePLPStore, { useGetProducts } from "@store/plp";
import urlParams from "@assets/js/url-params";

import { useTranslation } from "react-i18next";

import "./product-filters-visual.scss";

/**
 * Filter model
 * "id": "model_3177 Children's chair",
 * "doc_type_s": "model",
 * "displayName_t": "Children's chair, 3177",
 * "fullName_t": "Children's chair, 3177",
 * "urlSlug_s": "/#tbd",
 * "imageUrl_s": "https://fritzhansenimagecdn.azureedge.net/~/media/C68765C6816C403CA23709FB094C03B0.ashx"
 */

const ProductFiltersVisual = ({ fields }) => {
  //const [filterActive, setFilterActive] = useState(false);
  const [models, activeModels, setActiveModel] = usePLPStore(
    (state) => [state.models, state.activeModels, state.setActiveModel],
    shallow
  );
  const { refreshProductList } = useGetProducts();
  const { t } = useTranslation();

  const setFilterActive = (id) => {
    setActiveModel(id);
    refreshProductList();
  };
  // set models in URL for reload and sharing
  useEffect(() => {
    urlParams.set({
      models: activeModels.length
        ? activeModels.map((m) => m.replace("model_", "")).join(",")
        : "",
    });
  }, [activeModels]);

  const args = {
    settings: { noNavigation: true },
    fields: fields,
    children: (
      <>
        {models && models.length
          ? models.map((filter) => {
              const isActive = activeModels.includes(filter.id);
              const buttonCss = classnames({
                "product-filters-visual__filter": true,
                "product-filters-visual__filter--active": isActive,
                "product-filters-visual__filter--disabled": filter.disabled,
              });
              return (
                <SwiperSlide
                  key={filter.id}
                  className={"product-filters-visual__slide"}
                >
                  <div className="product-filters-visual__filter-wrapper">
                    <button
                      onClick={() => {
                        setFilterActive(filter.id);
                      }}
                      className={buttonCss}
                    >
                      <div className="product-filters-visual__label">
                        {filter.displayName_t}
                      </div>
                      {filter.imageUrl_s && (
                        <img
                          className="product-filters-visual__image"
                          src={filter.imageUrl_s}
                          alt={filter.fullName_t}
                        />
                      )}
                    </button>
                  </div>
                </SwiperSlide>
              );
            })
          : null}
      </>
    ),
  };

  return (
    <>
      {models && models.length > 0 && (
        <section className="product-filters-visual">
          <h2 className="product-filters-visual__title">
            {t(
              "FritzHansen_Project_ConsumersAndProfessionals_PLP_VisualFiltersTitle"
            )}
          </h2>

          <Carousel {...args} />
        </section>
      )}
    </>
  );
};

export default ProductFiltersVisual;
