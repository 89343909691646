import NavButton from "./nav-button";
export default {
  fields: {
    newsletterHeader: "Get inspired",
    newsletterText:
      "Keep up to date on news from Fritz Hansen and be the first to receive inspiration from around the world.",
    phone: {
      value: "28282828",
    },
    email: {
      value: "sorthvid@sorthvid.dk",
    },
    bottomLeftLink1: {
      fields: NavButton.link.fields,
    },
    bottomLeftLink2: {
      fields: NavButton.link.fields,
    },
    findStoreLink: {
      value: {
        text: "",
        href: "",
      },
    },
    menuRoot: {
      fields: {
        Items: [
          {
            fields: {
              Title: {
                value: "Headline2",
              },
              Link: "#",
              SubItems: [
                {
                  fields: {
                    Title: {
                      value: "Headline1",
                    },
                    Link: "#",
                    SubItems: [
                      {
                        fields: {
                          Title: {
                            value: "Subitem",
                          },
                          Link: "#",
                        },
                      },
                      {
                        fields: {
                          Title: {
                            value: "Subitem2",
                          },
                          Link: "#",
                        },
                      },
                    ],
                  },
                },
                {
                  fields: {
                    Title: {
                      value: "Hej",
                    },
                    Link: "#",
                  },
                },
              ],
            },
          },
        ],
      },
    },
  },
};
