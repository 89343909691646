export default {
  default: {
    fields: {
      data: {
        texts: {
          filterText: { value: "Filter by test" },
          loadMoreText: { value: "Load more test" },
        },
        tags: {
          list: {
            values: [
              { id: { value: "tag1" }, title: { field: { value: "MyTag" } } },
              { id: { value: "tag2" }, title: { field: { value: "MyTag2" } } },
            ],
          },
        },
        stories: {
          contextItem: "FAD82832FA074CD7B6AF8BB9F3F82C58",
          values: [
            {
              id: "20CBA38F40DF4F5BA308EE326390A02F",
              tags: {
                values: [
                  {
                    id: { value: "tag1" },
                    title: { field: { value: "MyTag" } },
                  },
                  {
                    id: { value: "tag2" },
                    title: { field: { value: "MyTag2" } },
                  },
                ],
              },
              image: {
                value: {
                  id: "566de416-1bb5-424e-83b5-3967a67a8cad",
                  url: "/components/125/ArticlePage1/Page-Components/Media",
                  fields: {
                    image: {
                      value: {
                        src:
                          "http://fritz.alpha-solutions.dk/-/media/002.ashx?h=900&iar=0&w=800",
                        alt: "Media002",
                        width: "800",
                        height: "900",
                        focalpoint: "8, 23",
                      },
                    },
                  },
                },
              },
              headline: { value: "ArticlePage1 title" },
              bodyText: { value: "ArticlePage1 description" },
              link: "/en/components/125/ArticlePage1",
            },
            {
              id: "4855044D5CBF4CEE96556B4C7BA0282F",
              tags: {
                values: [
                  {
                    id: { value: "tag2" },
                    title: { field: { value: "MyTag2" } },
                  },
                ],
              },
              image: {
                value: {
                  id: "06b5bbcc-68b3-48cb-bb1d-bd4c395a7df7",
                  url: "/components/125/ArticlePage10/Page-Components/Media",
                  fields: {
                    image: {
                      value: {
                        src:
                          "http://fritz.alpha-solutions.dk/-/media/002.ashx?h=900&iar=0&w=800",
                        alt: "Media002",
                        width: "800",
                        height: "900",
                        focalpoint: "8, 23",
                      },
                    },
                  },
                },
              },
              headline: { value: "ArticlePage10 title " },
              bodyText: { value: "ArticlePage10 description" },
              link: "/en/components/125/ArticlePage10",
            },
            {
              id: "2B83A7DA89AD47B2B30FC6783DB40F60",
              tags: {
                values: [
                  {
                    id: { value: "tag2" },
                    title: { field: { value: "MyTag2" } },
                  },
                ],
              },
              image: {
                value: {
                  id: "c03c9699-53fe-4681-823d-5422326c0ee8",
                  url: "/components/125/ArticlePage11/Page-Components/Media",
                  fields: {
                    image: {
                      value: {
                        src:
                          "http://fritz.alpha-solutions.dk/-/media/002.ashx?h=900&iar=0&w=800",
                        alt: "Media002",
                        width: "800",
                        height: "900",
                        focalpoint: "8, 23",
                      },
                    },
                  },
                },
              },
              headline: { value: "ArticlePage11 title " },
              bodyText: { value: "ArticlePage11 description" },
              link: "/en/components/125/ArticlePage11",
            },
            {
              id: "5E5BD11A8476403E96B0C493CF428051",
              tags: {
                values: [
                  {
                    id: { value: "tag1" },
                    title: { field: { value: "MyTag" } },
                  },
                ],
              },
              image: {
                value: {
                  id: "9aa51ccf-597a-4220-8a26-0a99cba1afe2",
                  url: "/components/125/ArticlePage2/Page-Components/Media",
                  fields: {
                    image: {
                      value: {
                        src:
                          "http://fritz.alpha-solutions.dk/-/media/002.ashx?h=900&iar=0&w=800",
                        alt: "Media002",
                        width: "800",
                        height: "900",
                        focalpoint: "8, 23",
                      },
                    },
                  },
                },
              },
              headline: { value: "ArticlePage2 title" },
              bodyText: { value: "ArticlePage2 description" },
              link: "/en/components/125/ArticlePage2",
            },
            {
              id: "FC3FC176816942F29F192B389674EC17",
              tags: {
                values: [
                  {
                    id: { value: "tag2" },
                    title: { field: { value: "MyTag2" } },
                  },
                ],
              },
              image: {
                value: {
                  id: "27226c3c-3ab2-46a6-96cf-e294fac38ab1",
                  url: "/components/125/ArticlePage3/Page-Components/Media",
                  fields: {
                    image: {
                      value: {
                        src:
                          "http://fritz.alpha-solutions.dk/-/media/002.ashx?h=900&iar=0&w=800",
                        alt: "Media002",
                        width: "800",
                        height: "900",
                        focalpoint: "8, 23",
                      },
                    },
                  },
                },
              },
              headline: { value: "ArticlePage3 title " },
              bodyText: { value: "ArticlePage3 description" },
              link: "/en/components/125/ArticlePage3",
            },
            {
              id: "7EE80A6FF1B14DDFB810FF29AAC4BA87",
              tags: {
                values: [
                  {
                    id: { value: "tag2" },
                    title: { field: { value: "MyTag2" } },
                  },
                ],
              },
              image: {
                value: {
                  id: "3a13af3b-2718-4ef7-be4f-84042efb7fe6",
                  url: "/components/125/ArticlePage4/Page-Components/Media",
                  fields: {
                    image: {
                      value: {
                        src:
                          "http://fritz.alpha-solutions.dk/-/media/002.ashx?h=900&iar=0&w=800",
                        alt: "Media002",
                        width: "800",
                        height: "900",
                        focalpoint: "8, 23",
                      },
                    },
                  },
                },
              },
              headline: { value: "ArticlePage4 title " },
              bodyText: { value: "ArticlePage4 description" },
              link: "/en/components/125/ArticlePage4",
            },
            {
              id: "CE77465013B24A08B9E4BBF57427E48A",
              tags: {
                values: [
                  {
                    id: { value: "tag2" },
                    title: { field: { value: "MyTag2" } },
                  },
                ],
              },
              image: {
                value: {
                  id: "a41900ac-bbe2-4456-8423-85531fc6792a",
                  url: "/components/125/ArticlePage5/Page-Components/Media",
                  fields: {
                    image: {
                      value: {
                        src:
                          "http://fritz.alpha-solutions.dk/-/media/002.ashx?h=900&iar=0&w=800",
                        alt: "Media002",
                        width: "800",
                        height: "900",
                        focalpoint: "8, 23",
                      },
                    },
                  },
                },
              },
              headline: { value: "ArticlePage5 title " },
              bodyText: { value: "ArticlePage5 description" },
              link: "/en/components/125/ArticlePage5",
            },
            {
              id: "4A93DFFF7BFA4C16865966C8C0A026A6",
              tags: {
                values: [
                  {
                    id: { value: "tag2" },
                    title: { field: { value: "MyTag2" } },
                  },
                ],
              },
              image: {
                value: {
                  id: "191f1416-de00-4c10-883b-2a15857e8161",
                  url: "/components/125/ArticlePage6/Page-Components/Media",
                  fields: {
                    image: {
                      value: {
                        src:
                          "http://fritz.alpha-solutions.dk/-/media/002.ashx?h=900&iar=0&w=800",
                        alt: "Media002",
                        width: "800",
                        height: "900",
                        focalpoint: "8, 23",
                      },
                    },
                  },
                },
              },
              headline: { value: "ArticlePage6 title " },
              bodyText: { value: " ArticlePage6 description" },
              link: "/en/components/125/ArticlePage6",
            },
            {
              id: "D4F6B73B9EBA4E298F30BC01C7E8821B",
              tags: {
                values: [
                  {
                    id: { value: "tag2" },
                    title: { field: { value: "MyTag2" } },
                  },
                ],
              },
              image: {
                value: {
                  id: "d33bdfb3-0cc4-4658-b086-49985f251e7e",
                  url: "/components/125/ArticlePage7/Page-Components/Media",
                  fields: {
                    image: {
                      value: {
                        src:
                          "http://fritz.alpha-solutions.dk/-/media/002.ashx?h=900&iar=0&w=800",
                        alt: "Media002",
                        width: "800",
                        height: "900",
                        focalpoint: "8, 23",
                      },
                    },
                  },
                },
              },
              headline: { value: "ArticlePage7 title " },
              bodyText: { value: "ArticlePage7 description" },
              link: "/en/components/125/ArticlePage7",
            },
            {
              id: "52021D620DFB4EA2A8FE3D03761B6A15",
              tags: {
                values: [
                  {
                    id: { value: "tag2" },
                    title: { field: { value: "MyTag2" } },
                  },
                ],
              },
              image: {
                value: {
                  id: "22bd1b88-0322-4892-bc34-1a8f31ae0826",
                  url: "/components/125/ArticlePage8/Page-Components/Media",
                  fields: {
                    image: {
                      value: {
                        src:
                          "http://fritz.alpha-solutions.dk/-/media/002.ashx?h=900&iar=0&w=800",
                        alt: "Media002",
                        width: "800",
                        height: "900",
                        focalpoint: "8, 23",
                      },
                    },
                  },
                },
              },
              headline: { value: "ArticlePage8 title " },
              bodyText: { value: "ArticlePage8 description" },
              link: "/en/components/125/ArticlePage8",
            },
          ],
        },
      },
    },
  },
};
