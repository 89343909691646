import React, { useState, useEffect, useRef } from "react";
import { motion, useAnimation } from "framer-motion";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import MediaFocal from "../MediaFocal";
import NavButton from "../NavButton";
import classnames from "classnames";
import isTrue from "@assets/js/is-true";
import { defaultReveal, defaultOpacity } from "@assets/js/animation-variations";
import { log } from "@assets/js/utils";

import "./hero-cover.scss";

const animationVariants = {
  initial: { opacity: 0, x: -50 },
  exit: { opacity: 0, x: 50 },
  enter: { opacity: 1, x: 0 },
};

const HeroCover = ({ fields, rendering, itemId }) => {
  const [imgComplete, setImageComplete] = useState(false);
  const [renderKey, setRenderKey] = useState(0);
  const { uid } = rendering;
  const classList = classnames({
    "hero-cover": true,
    "hero-cover--ready": true,
    "hero-cover--inverted": isTrue(fields?.isInverted),
    "hero-cover--text-right": fields?.textAlignedRight,
  });

  const imageLoaded = () => {
    if (!imgComplete) {
      setImageComplete(true);
    }
  };
  const imageLoadedRef = useRef(imgComplete);
  imageLoadedRef.current = imgComplete;
  useEffect(() => {
    // in some cases the animation isn't triggered on re-render -sorry adding this hack
    const tOId = window.setTimeout(function () {
      if (!imageLoadedRef.current) {
        log("image force animation start");
        setImageComplete(true);
      }
    }, 2500);
    setRenderKey((state) => state++);
    return () => {
      window.clearTimeout(tOId);
    };
  }, []);

  return (
    <>
      {fields && (
        <div
          key={uid}
          className={classList}
          style={
            fields.backgroundColorHex && {
              "--hero-bgc": fields.backgroundColorHex.value,
            }
          }
        >
          {fields?.backgroundMedia && (
            <motion.div
              initial="initial"
              exit="exit"
              animate={imgComplete ? "enter" : "intial"}
              variants={defaultOpacity}
              transition={{
                ...defaultOpacity.transition,
                delay: 0,
              }}
            >
              <MediaFocal
                className={"hero-cover__image"}
                fields={fields.backgroundMedia.fields}
                hasVideoControls={false}
                noSave
                onImageLoaded={imageLoaded}
                key={`${uid}-${renderKey}`}
              />
            </motion.div>
          )}

          <div className="hero-cover__content">
            {fields.headline?.value && fields.headlineType?.value && (
              <motion.div
                className="hero-cover__headline"
                initial="initial"
                exit="exit"
                animate={imgComplete ? "enter" : "intial"}
                variants={defaultReveal}
                transition={{
                  ...defaultReveal.transition,
                  delay: 0.4,
                }}
              >
                <Text tag={fields.headlineType.value} field={fields.headline} />
              </motion.div>
            )}

            <motion.div
              className="hero-cover__text"
              initial="initial"
              exit="exit"
              animate={imgComplete ? "enter" : "intial"}
              variants={animationVariants}
              transition={{ delay: 1.5, duration: 0.7 }}
            >
              {fields.bodyText?.value && fields.bodyText.value.length > 0 && (
                <Text tag="p" field={fields.bodyText} />
              )}

              {fields.button && <NavButton fields={fields.button.fields} />}
            </motion.div>
          </div>
        </div>
      )}
    </>
  );
};

export default HeroCover;
