import React, { useState } from "react";

import { DEFAULT_TRANSITION, NOTIFICATION } from "@constants";
import { NavLink } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";

import onMediaChanged from "@assets/js/on-media-changed";
import getViewportSize from "@assets/js/get-viewport-size";
import ViewportSizes from "@assets/js/viewport-sizes";
import MapMarkerSvg from "@assets/js/icons/map-marker";

import ContactLinksGroup from "../ContactLinksGroup";
import NewsletterSignUp from "../NewsletterSignUp";
import FooterLogo from "../FooterLogo";
import InputFieldwButton from "../forms/InputFieldwButton";
import CountrySelectButton from "../CountrySelectButton";
import ModeSwitch from "../ModeSwitch";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import useGlobalStore from "@store/global";
import shallow from "zustand/shallow";
import NavButton from "../NavButton";

import "./site-footer.scss";

const SiteFooter = ({ fields = {}, rendering }) => {
  let currentViewport = getViewportSize();
  const { uid } = rendering;
  const initMobile =
    currentViewport?.name == ViewportSizes.SMALL.name ||
    currentViewport?.name == ViewportSizes.MEDIUM.name;

  const [setMobile] = useState(initMobile);
  const [expanded, setExpanded] = useState(false);

  onMediaChanged((newViewport) => {
    switch (newViewport.name) {
      case ViewportSizes.SMALL.name:
      case ViewportSizes.MEDIUM.name:
        setMobile(true);
        setExpanded(false);

        break;
      case ViewportSizes.LARGE.name:
      case ViewportSizes.XLARGE.name:
      default:
        setMobile(false);
        setExpanded(true);
        break;
    }
  });
  onMediaChanged(currentViewport);

  const [setShowNotificationGeolocation] = useGlobalStore(
    (state) => [state.setShowNotificationGeolocation],
    shallow
  );

  let children = (
    <>
      <InputFieldwButton
        id="id"
        name="input"
        type="email"
        placeholder="Sign up"
        ariaDescribedBy="info success"
        iconType="arrow"
        errorMessage="error"
        successMessage="success"
        isInverted={true}
      />
    </>
  );

  const newsletter = {
    headline: fields.newsletterHeader,
    bodyText: fields.newsletterText,
    isInverted: true,
  };

  const menuItems = fields?.menuRoot?.fields?.Items[0]?.fields?.SubItems;
  // console.log("menuItems", menuItems);

  return (
    <>
      {fields && (
        <footer className="site-footer" key={uid}>
          <div className="site-footer__upper-part">
            <div className="site-footer__signup">
              <NewsletterSignUp fields={newsletter} children={children} />
              <div className="site-footer__portal-links">
                {fields.bottomLeftLink1?.fields && (
                  <div>
                    <NavButton fields={fields.bottomLeftLink1.fields} />
                  </div>
                )}
                {fields.bottomLeftLink2?.fields && (
                  <div>
                    <NavButton fields={fields.bottomLeftLink2.fields} />
                  </div>
                )}
              </div>
            </div>
            {/* =================== NAVIGATION =================== */}
            <div className="site-footer__nav-list">
              {menuItems?.map((topic, index) => {
                return (
                  <FooterNavList
                    key={"menuitem23423" + index}
                    headline={topic?.fields?.Title}
                    topics={topic?.fields?.SubItems}
                    preexpanded={expanded}
                  />
                );
              })}
            </div>
            {/* =================== NAVIGATION END =================== */}
            <div>
              {fields?.phone && (
                <div className="site-footer__contact">
                  <ContactLinksGroup
                    phone={fields?.phone}
                    email={fields?.email}
                  />
                </div>
              )}
              {fields?.findStoreLink?.value?.text && !fields?.phone?.value && (
                <div className="site-footer__store-locator">
                  <MapMarkerSvg
                    className="site-footer__map-marker-icon"
                    isInverted={true}
                  />
                  <NavLink to={fields.findStoreLink.value.href}>
                    <span>{fields?.findStoreLink?.value?.text}</span>
                  </NavLink>
                </div>
              )}
            </div>
          </div>
          <div className="site-footer__lower-part">
            <div className="site-footer__lower-main">
              <div className="site-footer__footer-logo">
                <FooterLogo />
              </div>
              <div className="site-footer__mode-links">
                <ModeSwitch show={true} />
              </div>
            </div>

            <div className="site-footer__country-selector">
              <CountrySelectButton
                clickHandler={() =>
                  setShowNotificationGeolocation(NOTIFICATION.GEO_BOTTOM)
                }
              />
            </div>
          </div>
        </footer>
      )}
    </>
  );
};
export default SiteFooter;

const FooterNavList = ({ headline, topics, preexpanded }) => {
  const [expanded, setExpanded] = useState(preexpanded);
  const toggleHandler = () => {
    setExpanded(!expanded);
  };

  return (
    <nav className="site-footer__link-item-group">
      {headline && (
        <button className="site-footer__headline" onClick={toggleHandler}>
          <Text field={headline} />
        </button>
      )}
      {topics && (
        <ul>
          {topics.map((child, index) => {
            return (
              <li key={`footerLi${index}`}>
                <AnimatePresence>
                  {expanded && (
                    <motion.div
                      style={{ overflow: "hidden" }}
                      initial={{ height: 0 }}
                      animate={{
                        height: "auto",
                        transition: DEFAULT_TRANSITION,
                      }}
                      exit={{
                        height: 0,
                        transition: DEFAULT_TRANSITION,
                      }}
                    >
                      <p className="site-footer__collapsible-item">
                        {/* <Link field={child?.fields?.Title} /> */}
                        <a href={child?.fields?.Link?.value?.href}>
                          {child?.fields?.Title?.value}
                        </a>
                      </p>
                    </motion.div>
                  )}
                </AnimatePresence>
              </li>
            );
          })}
        </ul>
      )}
    </nav>
  );
};
