import React, { Fragment } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import NavButton from "../NavButton";
import MediaFocal from "../MediaFocal";
import classnames from "classnames";
import isTrue from "@assets/js/is-true";
import ContactLinksGroup from "../ContactLinksGroup";

import "./feature-block.scss";

const FeatureBlock = ({ fields }) => {
  const hasTwoImages = fields?.media1?.fields && fields?.media2?.fields ? true : false;

  const classList = classnames({
    "feature-block": true,
    "feature-block--left-body": isTrue(fields?.isBodyLeft),
    "feature-block--right-body": !isTrue(fields?.isBodyLeft),
    "feature-block--inverted": isTrue(fields?.isInverted),
    "feature-block--2-imgs": hasTwoImages,
    "feature-block--2-imgs--left-body": hasTwoImages && isTrue(fields?.isBodyLeft),
    "feature-block--2-imgs--right-body": hasTwoImages && !isTrue(fields?.isBodyLeft),
  });

  return (
    <>
      {fields && (
        <div
          className={classList}
          style={
            fields.backgroundColorHex && {
              "--feature-block-bgc": fields.backgroundColorHex.value,
            }
          }
        >
          {fields.media1 && <MediaFocal className={"feature-block__img--1"} fields={fields.media1.fields} />}

          <div className="feature-block__text-wrapper">
            {fields.tagList && (
              <>
                {fields.tagList.map((tag) => {
                  return (
                    <Fragment key={tag.id}>
                      {tag?.fields && (
                        <div className="feature-block__tag">
                          <Text tag="h6" field={tag?.fields?.title} />
                        </div>
                      )}
                    </Fragment>
                  );
                })}
              </>
            )}

            {fields?.headline?.value && (
              <div className="feature-block__headline">
                <Text tag="h2" field={fields.headline} />
              </div>
            )}

            {fields?.bodyText?.value.length > 0 && (
              <div className="feature-block__bodytext">
                <Text tag="p" field={fields.bodyText} />
              </div>
            )}
            {fields.phone && !fields.button && (
              <div className="feature-block__contact">
                <ContactLinksGroup phone={fields.phone} email={fields.email} />
              </div>
            )}
            {fields.button && <NavButton fields={fields.button.fields} />}
          </div>
          {hasTwoImages && <MediaFocal className={"feature-block__img--2"} fields={fields.media2.fields} />}
        </div>
      )}
    </>
  );
};

export default FeatureBlock;
