import React, { useEffect, useState, Fragment } from "react";
import Collapsible from "../Collapsible";
import ActionButton from "../ActionButton";
import { PlusSvg } from "@assets/js/icons";
import { Text, RichText } from "@sitecore-jss/sitecore-jss-react";
import { ChevronSvg } from "@assets/js/icons";

import "./accordion-module.scss";
const AccordionModule = ({ fields }) => {
  let newArray = [];
  useEffect(() => {
    newArray = fields.items.map((item) => {
      item.expanded = false;
      return item;
    });
    setAllItems(newArray);
  }, []);
  const [allItems, setAllItems] = useState(newArray);

  const [allShown, setAllShown] = useState(false);
  // const [allItems, setAllItems] = useState(fields?.items);

  const loadMoreItems = () => {
    setAllShown(true);
  };

  const toggleHandler = (id) => {
    let updatedList = allItems.map((item) => {
      if (item.id === id) {
        item.expanded = true;
      } else {
        item.expanded = false;
      }
      return item;
    });
    setAllItems(updatedList);
  };

  // WE ARE PROBABLY GOING TO USE THIS FUNCTION
  // const handleAllItems = () => {
  //   const updatedList = allItems.map((item) => {
  //     item.expanded = true;
  //     return item;
  //   });
  //   setAllItems(updatedList);
  // };

  return (
    <div className="accordion-module">
      {fields?.headline && (
        <div className="accordion-module__headline">
          <Text tag="h2" field={fields.headline} />
          {/* {fields.handleAllButton.value && (
            <button
              className="accordion-module__handle-all-button"
              onClick={handleAllItems}
            >
              +
            </button>
          )} */}
        </div>
      )}
      {fields?.bodyText && (
        <div className="accordion-module__bodytext">
          <Text tag="p" field={fields.bodyText} />
        </div>
      )}

      <div className="accordion-module__items">
        {allItems.map((item, index) => {
          return (
            <Fragment key={`accordion${index}`}>
              {(allShown || index < fields?.itemsShown?.value) && (
                <>
                  <Collapsible
                    toggleHandler={() => toggleHandler(item.id)}
                    id={item.id}
                    preExpanded={item.expanded}
                    triggerComponent={
                      <>
                        <Text tag="h2" field={item.fields.headline} />
                        <ChevronSvg />
                      </>
                    }
                    contentComponent={
                      <>
                        <RichText className="rich-text" field={item.fields.bodyText} />
                      </>
                    }
                  />
                </>
              )}
            </Fragment>
          );
        })}
      </div>

      {fields?.loadMoreButtonText?.value && !allShown && (
        <div className="accordion-module__load-more">
          <ActionButton
            clickHandler={loadMoreItems}
            buttonType="link"
            text={fields.loadMoreButtonText.value}
            hasArrow={false}
            icon="PlusSvg"
          />
        </div>
      )}
    </div>
  );
};
AccordionModule.propTypes = {};

export default AccordionModule;
