import React from "react";
import MediaFocal from "../MediaFocal";
import TextTile from "../TextTile";
import classnames from "classnames";
import LinkWrapper from "../LinkWrapper";
import "./nav-tile.scss";

const NavTile = ({ fields = {}, isButtonEnabled, isInverted }) => {
  const buttonFields = fields.button?.fields;
  const contactInfoAvailable = fields.phone?.value || fields.email?.value;

  // const interactive = !contactInfoAvailable && link ? true : false;

  const classList = classnames({
    "nav-tile": true,
    "nav-tile--interactive": isButtonEnabled,
  });

  return (
    <div className={classList}>
      {fields.media?.fields &&
        (isButtonEnabled && buttonFields ? (
          <LinkWrapper field={buttonFields}>
            <MediaFocal fields={fields.media.fields} className="nav-tile__img" />
          </LinkWrapper>
        ) : (
          <MediaFocal fields={fields.media.fields} className="nav-tile__img" />
        ))}

      <TextTile
        fields={fields}
        contactInfoAvailable={contactInfoAvailable}
        interactive={isButtonEnabled?.value || isButtonEnabled ? true : false}
        isButtonEnabled={isButtonEnabled?.value || isButtonEnabled ? true : false}
        isInverted={isInverted?.value}
      />
    </div>
  );
};

export default NavTile;
