import React, { useEffect, useState, lazy, Suspense } from "react";
import { useTranslation } from "react-i18next";

import PDPConfigurator from "../pdp/PDPConfigurator";
import PDPProductViewer from "../pdp/PDPProductViewer";
import PDPProductSpecification from "../pdp/PDPProductSpecification";
import PDPProductThumbs from "../pdp/PDPProductThumbs";
import useGlobalStore from "@store/global";

import { useActiveProductData } from "@store/pdp";

import { CONFIGURATOR_SKU } from "@constants";
import { log } from "@assets/js/utils";
import { dataLayerCommercePush, productSignature } from "@assets/js/gtm";

import "./pdp.scss";

const PDP = ({ allowOrder, careAndMaintenance }) => {
  const [activeProductData] = useActiveProductData();
  const currentMarket = useGlobalStore((state) => state.currentMarket);
  const { t } = useTranslation();

  const texts = {
    productError: t(
      "FritzHansen_Project_ConsumersAndProfessionals_PDP_ProductError"
    ),
  };
  // GTM Tracking
  useEffect(() => {
    // log("PDP ACTIVE Productdata", activeProductData);
    if (activeProductData?.model && currentMarket?.currency) {
      dataLayerCommercePush("productDetail", {
        currencyCode: currentMarket?.currency?.value,
        detail: productSignature(activeProductData),
      });
    }
  }, [activeProductData, currentMarket]);
  return (
    <>
      {activeProductData && (
        <>
          {activeProductData === CONFIGURATOR_SKU.NONE ? (
            <div className="error">
              <h2>{texts.productError}</h2>
            </div>
          ) : (
            <>
              <PDPProductViewer />
              <PDPConfigurator
                allowOrder={allowOrder}
                currency={currentMarket?.currency?.value}
              />
              <PDPProductThumbs />
              <PDPProductSpecification
                careAndMaintenance={careAndMaintenance}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default PDP;
