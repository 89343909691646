export const SITE_MODE = {
  RESIDENTIAL: "RESIDENTIAL",
  PROFESSIONAL: "PROFESSIONAL",
};

// export const LANGUAGE = {
//   ENGLISH: "ENGLISH",
//   GERMAN: "GERMAN",
// };
// export const COUNTRY = {
//   DENMARK: "DENMARK",
//   GERMANY: "GERMANY",
// };

export const BUTTONSTYLE = {
  SOLID: "SOLID",
  GHOST: "GHOST",
  LINK: "LINK",
  ICON_LINK: "ICON_LINK",
  SMALL: "SMALL",
  NONE: "NONE",
};

export const COOKIES = {
  basket: {
    name: "fh-basket-id",
    expire: "",
  },
  market: {
    name: "fh-user-market",
    expire: "",
  },
  user: {
    name: "fh-user-id",
    expire: "",
  },
  jwt: {
    name: "fh_jwt_token",
    expire: "",
  },
};

export const LINKTYPE = {
  INTERNAL: "INTERNAL",
  JAVASCRIPT: "JAVASCRIPT",
  EXTERNAL: "EXTERNAL",
  MAILTO: "MAILTO",
  ANCHOR: "ANCHOR",
  MEDIA: "MEDIA",
};

export const OVERLAY = {
  NONE: "NONE",
  MENU: "MENU",
  MYFRITZ: "MYFRITZ",
  BASKET: "BASKET",
  FILTERS: "FILTERS",
  PORTFOLIO_SUBMISSION: "PORTFOLIO_SUBMISSION",
  PORTFOLIO_ADMIN: "PORTFOLIO_ADMIN",
  MEDIA: "MEDIA",
  MATERIAL: "MATERIAL",
  SEARCH: "SEARCH",
  NOTIFY_ME: "NOTIFY_ME",
  LOGIN: "WINDOW:LOGIN",
  LOGOUT: "IDENTITY:LOGOUT",
};

export const NOTIFICATION = {
  ITEM_ADDED: "ITEM_ADDED",
  SITE_MODE: "SITE_MODE",
  SERVICE_MESSAGE: "SERVICE_MESSAGE",
  TOP_MESSAGE: "TOP_MESSAGE",
  GEO_BOTTOM: "GEO_BOTTOM",
  GEO_MENUBOTTOM: "GEO_MENUBOTTOM",
  GEO_TOP: "GEO_TOP",
};

export const CONFIGURATOR_SKU = {
  NONE: "NOSKU",
};

export const CONFIGURATOR_TYPE = {
  CUSTOM: "Custom",
  MODEL_TYPE: "ModelType",
  VARIATION_GROUP: "VariationGroup",
  HERO_CONFIGURATION: "HeroConfiguration",
  SKU: "SKU",
};

export const CONFIGURATOR_MODE = {
  ADVANCED: "ADVANCED",
  BASIC: "BASIC",
  NONE: "NONE",
};

export const CONFIGURATOR_SELECTOR = {
  NONE: "NONE",
  MODEL_TYPES: "MODEL_TYPES",
  MATERIALS: "MATERIALS",
  MATERIAL_SETS: "MATERIAL_SETS",
};

export const CHECKOUT_STATUS = {
  IDLE: "idle",
  ACTIVE: "active",
  COMPLETE: "complete",
};

export const DEFAULT_TRANSITION = {
  duration: 0.3,
  ease: [0.25, 0.01, 0.25, 1],
};

export const STATUS = {
  LOADING_IDLE: "loadingidle",
  LOADING: "loading",
  SUCCESS: "success",
  READY: "ready",
  INITIALIZING: "initializing",
  ERROR: "error",
};

export const IMAGE = {
  PRODUCT: "PRODUCT",
  SWATCH: "SWATCH",
  THUMB: "THUMB",
};

export const RESPONSIVE_IMAGE_WIDTHS = [500, 768, 1024, 1280, 1680, 1920, 2560];

export const LAYOUT = {
  ID: "default",
};

export const PORTFOLIO_WISHLIST_NAME = "_DEFAULT_";
export const FILTER_NO_TYPE = "_NOTYPE_";

export const PORTFOLIO_TYPE = {
  MEDIA: "Image",
  PRODUCT: "Product",
  MATERIAL: "Material",
  URL_REFERENCE: "UrlReference",
};
export const ASPECT = {
  TALL: "TALL",
  WIDE: "WIDE",
  SQUARE: "SQUARE",
};
export const SITECORE_TYPE = {
  MENU_LABEL: "MenuLabel",
  MENU_ITEM: "MenuItem",
};
export const PRICE = {
  EXACT: "price:exact",
  FROM: "price:from",
  RANGE: "price:range",
};

export const QUERY_PARAMETER = {
  MODE: "mode",
  MARKET: "market",
  SKU: "sku",
  ORDER_ID: "orderid",
  ACTIVE_TAG: "activeTag",
};

export const CHECKOUT_STEPS = {
  DETAILS: 0,
  DELIVERY: 1,
  PAYMENT: 2,
  RECIEPT: 3,
};

export const INTERNATIONAL = "int";

export default {
  SITE_MODE,
  LINKTYPE,
  OVERLAY,
  BUTTONSTYLE,
  DEFAULT_TRANSITION,
  CONFIGURATOR_MODE,
  CONFIGURATOR_TYPE,
  CONFIGURATOR_SKU,
  CHECKOUT_STATUS,
  LAYOUT,
  IMAGE,
  QUERY_PARAMETER,
  INTERNATIONAL,
};
