import NavButton from "./nav-button";
import { LINKTYPE, BUTTONSTYLE } from "@constants";

export default {
  // default: {
  //   fields: {
  //     tag: null,
  //     href: "",
  //     headline: "Neque et temporibus sunt",
  //     bodyText:
  //       "Sit explicabo eum labore voluptatem, neque et temporibus sunt, culpa tempora ratione quasi nostrum.",
  //     button: NavButton.link,
  //     isButtonEnabled: true,
  //   },
  //   interactive: true,
  //   link: "#loremipsum",
  //   headline: "Can’t find what you are looking for?",
  // },
  default: {
    fields: {
      data: {
        item: {
          headline: {
            field: "Head",
          },
          tiles: {
            values: [
              {
                headline: {
                  field: "Headline",
                },
                bodyText: {
                  field: "bodytext",
                },
                noParse: true,
                href: "#loremipsum",
                button: NavButton.link,
              },
              {
                headline: {
                  field: "Headline",
                },
                bodyText: {
                  field: "bodytext",
                },
                noParse: true,
                href: "#loremipsum",
                button: NavButton.link,
              },
            ],
          },
        },
      },
    },
  },
};
