import React, { useEffect, useState } from "react";
import axios from "axios";

import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import ActionMenu from "../../ActionMenu";
import ActionButton from "../../ActionButton";

import path from "@assets/js/browser-path";
import getRandomColor from "@assets/js/get-random-color";
import { BUTTONSTYLE } from "@constants";

import useGlobalStore from "@store/global";

import "./portfolio-material-tile.scss";

// ex https://test-products.fritzhansen.com/Material/en/CHRHAVN
const fetchMaterialSet = (materialSetId, lang) =>
  axios.get(path.join(process.env.REACT_APP_PRODUCTSAPI, "Material", lang, materialSetId));

const PortfolioMaterialTile = ({ data, canEdit, onRemove }) => {
  const { t } = useTranslation();
  const texts = {
    info: t("FritzHansen_Project_ConsumersAndProfessionals_Portfolio_Info"),
    removeFromWishlist: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Portfolio_RemoveFromWishlist"
    ),
  };
  const sc_lang = useGlobalStore((state) => state.sc_lang);
  const [dropped, setDropped] = useState(false);
  const [materialSetData, setMaterialSetData] = useState(null);

  useEffect(() => {
    async function loadMaterialSet() {
      const response = await fetchMaterialSet(data.materialSetId, sc_lang);
      const item = response.data;
      setMaterialSetData(item);
    }
    if (data.materialSetId) loadMaterialSet();
  }, []);

  let actionList = [
    {
      icon: "RemoveSvg",
      label: texts.removeFromWishlist,
      callback: () => {
        onRemove(data);
      },
    },
  ];
  const color = getRandomColor(150);

  const getImageUrl = () => {
    let material = materialSetData.materials.filter((f) => f.id === data.materialId)[0];
    let res = material
      ? material.resources.filter((f) => f.subType.toUpperCase() === "SWATCH")[0]
      : null;
    return res
      ? res.url
      : path.join(process.env.REACT_APP_PRODUCT_IMAGE_API, "r", `SWATCH-${data.materialId}.jpg`);
  };

  const getFieldValue = (prop) => {
    return materialSetData.fields.filter((f) => f.id === prop)[0]
      ? materialSetData.fields.filter((f) => f.id === prop)[0].value
      : "NONE";
  };

  return (
    <div className="portfolio-material-tile" onMouseLeave={() => setDropped(false)}>
      {materialSetData && (
        <>
          {canEdit && !dropped && (
            <ActionButton
              className="portfolio-material-tile__button"
              buttonType={BUTTONSTYLE.GHOST}
              backgroundColorHex="#c6c6c6"
              textColorHex="white"
              text={texts.info}
              icon="PlusSvg"
              clickHandler={() => setDropped(!dropped)}
            />
          )}
          {canEdit && <ActionMenu actionList={actionList} isActive={dropped} />}

          <div className="portfolio-material-tile__image">
            {/* `https://via.placeholder.com/1000/${color}/000000?text=${data.materialId}` */}
            <img src={getImageUrl()} />
          </div>

          <div className="portfolio-material-tile__text">
            <h2 className="portfolio-material-tile__headline">{`${materialSetData.materialSetName} / ${data.materialName}`}</h2>
            <p className="portfolio-material-tile__bodytext">
              {getFieldValue("MaterialSetTypeNew")}
            </p>
          </div>
        </>
      )}
    </div>
  );
};
PortfolioMaterialTile.propTypes = {
  canEdit: PropTypes.bool,
};

export default PortfolioMaterialTile;
