import React, { useState, useRef, useEffect } from "react";
import classnames from "classnames";
import { getPoint, getRelativePoint } from "../pdp-helpers";

import {log} from "@assets/js/utils";
import usePDPStore, { usePendingProductData, useActiveProductData } from "@store/pdp";

import "./pdp-product-image-spinner.scss";

const PDPProductImageSpinner = ({ images, onUpdate, onZoom }) => {
  const [firstImageLoaded, setFirstImageLoaded] = useState(false);
  const [imagesLoaded, setImagesLoaded] = useState(0);
  const [swipeStart, setSwipeStart] = useState(null);
  const [startFrame, setStartFrame] = useState(0);
  const [currentFrame, setCurrentFrame] = useState(0);
  const [pixelWidth, setPixelWidth] = useState(0);

  const [hasDragged, setHasDragged] = useState(false);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [ready, setReady] = useState(false);

  const imagesRef = useRef();
  const imagesLength = images.length;

  const [pendingProductData, setPendingProductData] = usePendingProductData();
  const [activeProductData, setActiveProductData] = useActiveProductData();

  useEffect(() => {
    setReady(false);
  }, [pendingProductData]);

  useEffect(() => {
    setReady(true);
  }, [activeProductData]);

  useEffect(() => {
    window.addEventListener("resize", updatePixelWidth);
    document.querySelector("body").addEventListener("mouseup", handleBodyMouseUp);

    return () => {
      document.querySelector("body").removeEventListener("mouseup", handleBodyMouseUp);
      window.removeEventListener("resize", updatePixelWidth); // Remove event listener on cleanup
    };
  }, []);

  useEffect(() => {
    updatePixelWidth();
  }, [firstImageLoaded]);

  const handleFirstImageLoaded = () => {
    setFirstImageLoaded(true);
  };

  const updatePixelWidth = () => {
    if (imagesRef.current) setPixelWidth(imagesRef.current.getClientRects()[0].width);
  };

  const handleBodyMouseUp = (e) => {
    setCurrentFrame((currentFrame) => {
      setStartFrame(currentFrame);
      return currentFrame;
    });
    setIsMouseDown(false);
    setSwipeStart(null);
  };

  const handleImageLoaded = () => {
    // console.log("imagesLoaded: " + (imagesLoaded + 1) + " out of " + (imagesLength - 1));
    const percentage = imagesLoaded / (imagesLength - 2); // it's -2 beacuse the first image is loaded by itself before we load the rest in the array
    setImagesLoaded((imagesLoaded) => ++imagesLoaded);

    if (percentage === 1) {
      setImagesLoaded(0);
      setReady(true);
    }

    if (onUpdate) onUpdate(percentage);
  };

  const handleTouchStart = (e) => {
    e.preventDefault();
    if (!ready) return;

    setIsMouseDown(true);
    setSwipeStart(getPoint(e).eventX);
  };

  const handleTouchMove = (e) => {
    if (!ready) return;
    e.preventDefault();

    if (isMouseDown) spin(getPoint(e).eventX);
  };

  const handleTouchEnd = (e) => {
    e.preventDefault();
    if (!ready) return;
    if (!hasDragged) {
      //this must be a click
      var relPos = getRelativePoint(imagesRef.current, e);
      onZoom(relPos, currentFrame, imagesRef.current.children[currentFrame]);
    }
    setIsMouseDown(false);
    setHasDragged(false);

    // spin(getPoint(e).eventX)
    setSwipeStart(null);
    setStartFrame(currentFrame);
  };

  const spin = (eventX) => {
    if (swipeStart != null) {
      var delta = eventX - swipeStart;
      delta = Math.round(((2 * -delta) / pixelWidth) * imagesLength);
      delta = -delta;
      showFrame((imagesLength * 10 + startFrame + delta) % imagesLength);
    }
  };

  const showFrame = (frame) => {
    if( frame !== currentFrame ) {
     setHasDragged(true); 
    }
    setCurrentFrame(frame);
  };

  const imageClasses = (index) => {
    return classnames({
      "pdp-product-images-spinner__frame": true,
      show: currentFrame === index + 1,
    });
  };

  return (
    <div className="pdp-product-images-spinner">
      <div
        className="pdp-product-images-spinner__frames"
        onTouchStart={handleTouchStart}
        onMouseDown={handleTouchStart}
        onTouchMove={handleTouchMove}
        onMouseMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        onMouseUp={handleTouchEnd}
        ref={imagesRef}
      >
        <img
          src={images[0]}
          alt=""
          onLoad={!firstImageLoaded ? handleFirstImageLoaded : null}
          className="pdp-product-images-spinner__frame pdp-product-images-spinner__firstImage"
        />
        {firstImageLoaded &&
          images
            .slice(1)
            .map((src, index) => (
              <img
                onMouseMove={(e) => e.preventDefault()}
                className={imageClasses(index)}
                key={index}
                alt=""
                src={src}
                onLoad={handleImageLoaded}
              />
            ))}
      </div>
    </div>
  );
};

export default PDPProductImageSpinner;
