export default {
  default: {
    currency: "EUR",
    noParse: true,
    OrderLineId: 1132,
    Quantity: 5,
    FullFriendlyName: "3101-(M199)BEE001_FY",
    FriendlyName: "3101-BEE001",
    Price: 348.0,
    Total: 2001.0,
    Tags: null,
    BundleId: "",
    ModelGroupName: "FakeModelGroupName",
    Materials: [
      {
        Name: "FakeName",
        MaterialSetName: "FakeSetName",
        Id: "FakeId",
        MaterialSetId: "FakeSetId",
        ConfigurationTypeName: "FakeConfig",
      },
      {
        Name: "FakeName2",
        MaterialSetName: "FakeSetName",
        Id: "FakeId",
        MaterialSetId: "FakeSetId",
        ConfigurationTypeName: "FakeConfig",
      },
    ],
    Options: [
      {
        Name: "FakeOptionName",
        Value: "FakeOptionValue",
        Id: "FakeOptionId",
      },
    ],
    Measurements: "FakeMeasurements",
  },
};
