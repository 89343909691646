import debounce from "lodash-es/debounce";
import cuid from "cuid";
import Cookies from "universal-cookie";
import { testWebP, rafInterval, log } from "@assets/js/utils";
import { getURLParams, setURLParams } from "./url-params";

import { COOKIES, STATUS, OVERLAY, CONFIGURATOR_SELECTOR } from "@constants";

import globalStore from "@store/global";
import headerStore from "@store/header";
import basketStore from "@store/basket";
import pdpStore from "@store/pdp";

export const initBasket = async (commerceMarket) => {
  const cookies = new Cookies();
  const { getBasket, createBasket } = basketStore.getState();
  const params = getURLParams();
  let basketId = cookies.get(COOKIES.basket.name);

  // we can be sure that this is the orderconfonfirmation page
  // if orderid is pressent as queryparams.
  if (params.get("orderid") && params.get("id")) {
    basketId = null;
  }
  if (basketId) {
    log("initBasket | basketId found");
    basketStore.setState({
      basketId: basketId,
      hasProducts: false,
    });

    getBasket(commerceMarket, basketId);
  } else {
    log("initBasket | no basketId found");
    createBasket(commerceMarket);
  }
};

export default () => {
  if (process.browser && typeof window !== "undefined") {
    init();
  }
  // onScroll handler
  globalStore.subscribe(
    (state) => {
      document.body.classList.toggle("is-scrolled", state);
    },
    (state) => state.isScrolled
  );

  async function init() {
    globalStore.setState({
      loadStatus: STATUS.READY,
    });

    let skipLogs = process.env.NODE_ENV != "development";
    
    let bodyEl = document.querySelector("body");
    let scrollPos = 0;
    let bodyScrollStartY;
    let scrollPositionPageTop = true;
    let debounceTime = 100;
    let viewportHeight =
      window.innerWidth < 1024 ? window.innerHeight / 2 : window.innerHeight;

    
    const setVHProp = () => {
      let vh = window.innerHeight * 0.01;
      try {
        document.documentElement.style.setProperty("--vh", `${vh}px`);
      } catch (err) {
        //no doc
      }
    };
    setVHProp();

    rafInterval(() => {
      if (window.scrollY > 5) {
        globalStore.setState({
          logoMustContract: true,
        });
      } else {
        globalStore.setState({
          logoMustContract: false,
        });
      }
    }, 100);

    window.addEventListener(
      "scroll",
      debounce(
        (e) => {
          
            scrollPos = window.scrollY;

            //- 41: about 50% header height
            if (scrollPos > viewportHeight - 41) {
              log("SCROLL TEST | SCROLL EVENT | DOWN");
              globalStore.setState({
                isScrolled: true,
              });
            }
            if (scrollPos <= viewportHeight - 41) {
              log("SCROLL TEST | SCROLL EVENT | PAGE TOP");
              globalStore.setState({
                isScrolled: false,
              });
            }
          
        },
        debounceTime,
        { leading: false, trailing: true }
      )
    );

    // Pollyfill for mobiles falsy implementation of vh
    window.addEventListener(
      "resize",
      debounce(() => {
        viewportHeight = window.innerHeight;

        if (window.innerWidth < 1024) {
          viewportHeight = window.innerHeight / 2;
        }
        setVHProp();
      })
    );

    // Cookieinformation set in state 
    window.addEventListener(
      "CookieInformationConsentGiven",
      function (event) {
          globalStore.setState({
            cookieMarketingEnabled: window?.CookieInformation?.getConsentGivenFor("cookie_cat_marketing"),
            cookieStatisticEnabled: window?.CookieInformation?.getConsentGivenFor("cookie_cat_statistic"),
            cookieFunctionalEnabled: window?.CookieInformation?.getConsentGivenFor("cookie_cat_functional"),
          });
        }
    );

    const cookies = new Cookies();
    const foundUserKey = cookies.get(COOKIES.user.name);
    const foundJWTToken = cookies.get(COOKIES.jwt.name);

    if (foundJWTToken) {
      globalStore.setState({
        userKey: null,
      });
      globalStore.setState({
        userAuthenticated: true,
      });
      // cookies.remove(COOKIES.user.name);
    } else if (foundUserKey) {
      globalStore.setState({
        userKey: foundUserKey,
      });
      globalStore.setState({
        userAuthenticated: false,
      });
    } else {
      const generatedUserKey = cuid();

      globalStore.setState({
        userKey: generatedUserKey,
      });
      globalStore.setState({
        userAuthenticated: false,
      });

      cookies.set(COOKIES.user.name, generatedUserKey, {
        maxAge: 24 * 3600,
        path: "/",
      });
    }
    // image webp detection
    globalStore.setState({ webPSupport: await testWebP() });
  }
};
