export default {
  default: {
    fields: {
      headline: "Sign up to our Newsletter",
      bodyText:
        "Keep up to date on news from Fritz Hansen and be the first to receive inspiration from around the world.",
      isInverted: false,
    },
  },
};
