import React, { useState } from "react";
import useHeaderStore, { useMenuOverlayMediaQueue } from "@store/header";
import MenuLink from "../MenuLink";
import cuid from "cuid";

/*
 * SUBMENU ITEM
 */
const SubMenuItem = ({ num, item, loading = false, tag = "div" }) => {
  const [, pushMenuOverlayMediaQueue] = useMenuOverlayMediaQueue();

  const setCurrentMegaMenuCategoryItem = useHeaderStore(
    (store) => store.setCurrentMegaMenuCategoryItem
  );

  const getMedia = () => {
    let media = item?.fields.filter((item) => item.name == "media")[0],
      fields = media?.jss?.fields;

    if (fields?.image) {
      return { type: "image", url: fields?.image?.value?.src, alt: "image", id: cuid(), focalPoint: fields?.image?.value?.focalpoint };
    } else if (fields?.video) {
      return { type: "video", url: fields?.video?.value?.href, alt: "video", id: cuid(), focalPoint: fields?.image?.value?.focalpoint };
    } else {
      return null;
    }
  };

  const [media] = useState(getMedia());

  const clickHandler = (id) => {
    setCurrentMegaMenuCategoryItem(item.id);
  };
  const hoverHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();

    {media && pushMenuOverlayMediaQueue(media);}
  };

  const getContent = () => {
    return (
      <>
        <MenuLink
          className="mega-menu__button"
          clickHandler={clickHandler}
          item={item}
          // hoverHandler={hoverHandler}
        />

        {media && <div className="mega-menu__list-item__preload">
          {media.type == "image" && <img src={media.url} alt={media.alt} />}
          {media.type == "video" && <video src={media.url} preload="auto" alt={media.alt} muted />}
        </div>}
      </>
    );
  };

  return (
    <>
      {!loading && item && (
        <>
          {tag == "li" ? (
            <li className="mega-menu__list-item" onMouseEnter={hoverHandler}>
              {getContent()}
            </li>
          ) : (
            <div className="mega-menu__list-item" onMouseEnter={hoverHandler}>
              {getContent()}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default SubMenuItem;
