import React, { useEffect, useState, Fragment } from "react";
import { PropTypes } from "prop-types";
import { motion, AnimatePresence } from "framer-motion";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";
import classnames from "classnames";

import onMediaChanged from "@assets/js/on-media-changed";
import getViewportSize from "@assets/js/get-viewport-size";
import ViewportSizes from "@assets/js/viewport-sizes";

import { DEFAULT_TRANSITION, OVERLAY } from "@constants";

import useHeaderStore, {
  useMenuOverlayActive,
  useMenuOverlayInstant,
  useMenuOverlayMediaQueue,
} from "@store/header";

import "./menu-overlay.scss";

const MenuOverlay = (props) => {
  const [
    menuOverlayMediaQueue,
    ,
    clearMenuOverlayMediaQueue,
  ] = useMenuOverlayMediaQueue();

  const [menuOverlayActive, setMenuOverlayActive] = useMenuOverlayActive();

  const [menuOverlayInstant, setMenuOverlayInstant] = useMenuOverlayInstant();

  const setCurrentMegaMenuCategoryItem = useHeaderStore(
    (store) => store.setCurrentMegaMenuCategoryItem
  );
  const setCurrentMainMenuItem = useHeaderStore(
    (store) => store.setCurrentMainMenuItem
  );

  const setMobileNavigationActive = useHeaderStore(
    (store) => store.setMobileNavigationActive
  );

  const [menuTransitionDelay, setMenuTransitionDelay] = useState(0.2);

  const closeHandler = () => {
    setMenuTransitionDelay(0);
    setMenuOverlayActive(OVERLAY.NONE);

    setCurrentMegaMenuCategoryItem(null);
    setCurrentMainMenuItem(null);

    setMenuOverlayInstant(false);
    setMobileNavigationActive(false);
    clearMenuOverlayMediaQueue();
  };

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      closeHandler();
    }
  };

  useEffect(() => {
    //reset instant for next overlay
    if (menuOverlayInstant === true) setMenuOverlayInstant(false);
  }, [menuOverlayInstant]);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  useEffect(() => {
    if (menuOverlayActive !== OVERLAY.NONE) {
      setMenuTransitionDelay(0);
      disableBodyScroll(document.querySelector(".menu-overlay__content"));
    } else {
      setMenuTransitionDelay(0.2);
      clearAllBodyScrollLocks();
    }
  }, [menuOverlayActive]);

  const menuOverlayClasses = classnames({
    "menu-overlay": true,
    "is-active": menuOverlayActive !== OVERLAY.NONE,
  });

  let currentViewport = getViewportSize();

  onMediaChanged((newViewport) => {
    switch (newViewport.name) {
      case ViewportSizes.SMALL.name:
      case ViewportSizes.MEDIUM.name:
        //changed to small newViewport, if menu is open and currentViewport top bound is below LARGE low bound
        if (
          menuOverlayActive !== OVERLAY.NONE &&
          currentViewport.boundLow > ViewportSizes.MEDIUM.boundHigh
        ) {
          closeHandler();
        }
        break;

      case ViewportSizes.LARGE.name:
      case ViewportSizes.XLARGE.name:
        if (
          menuOverlayActive &&
          currentViewport.boundLow < ViewportSizes.LARGE.boundLow
        ) {
          closeHandler();
        }
        break;
    }
    currentViewport = newViewport;
  });

  const menuTransition = (delayed) => {
    if (menuOverlayInstant) {
      return {
        duration: 0.1,
        delay: 0,
        ease: [0.75, 0, 0.4, 1],
      };
    } else {
      return {
        duration: 0.7,
        delay: menuTransitionDelay,
        ease: [0.75, 0, 0.4, 1],
      };
    }
  };

  const overlayTransition = () => {
    if (menuOverlayInstant) {
      return {
        duration: 0,
        ease: [0.75, 0, 0.4, 1],
      };
    } else {
      return {
        duration: 0.7,
        ease: [0.75, 0, 0.4, 1],
      };
    }
  };

  const [animationVariants] = useState(
    menuOverlayInstant
      ? {
          initial: {
            opacity: 0,
            x: "-100%",
          },
          animate: {
            x: "-100%",
            opacity: 1,
          },
          exit: {
            opacity: 0,
            x: 0,
          },
        }
      : {
          initial: {
            opacity: 0,
            x: 0,
          },
          animate: {
            opacity: 1,
            x: "-100%",
          },
          exit: {
            opacity: 0,
            x: 0,
          },
        }
  );

  return (
    <>
      <AnimatePresence>
        {menuOverlayActive === props.id && (
          <div className={menuOverlayClasses}>
            <motion.div
              className="menu-overlay__backdrop"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={overlayTransition()}
              onClick={closeHandler}
            >
              <AnimatePresence>
                {menuOverlayMediaQueue &&
                  menuOverlayMediaQueue.length &&
                  menuOverlayMediaQueue.map((item, index) => {
                    return (
                      <Fragment key={"overlayItem" + index}>
                        {item.type == "image" && (
                          <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{
                              duration: 0.6,
                              ease: [0.75, 0, 0.4, 1],
                            }}                          
                          >
                            <img
                              src={item.url}
                              alt={item.alt}
                              style={
                                item.focalPoint && {
                                  objectPosition: `${item.focalPoint?.split(", ")[0]}% ${
                                    item.focalPoint?.split(",")[1]
                                  }%`,
                                }
                              }
                            />
                          </motion.div>
                        )}

                        {item.type == "video" && (
                          <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{
                              duration: 0.6,
                              ease: [0.75, 0, 0.4, 1],
                            }}                          
                          >
                            <video
                              src={item.url}
                              preload="auto"
                              muted
                              autoPlay
                            />
                          </motion.div>
                        )}
                      </Fragment>
                    );
                  })}
              </AnimatePresence>
            </motion.div>

            <motion.div
              variants={animationVariants}
              initial="initial"
              animate="animate"
              exit="exit"
              transition={menuTransition(menuOverlayActive !== OVERLAY.NONE)}
              className="menu-overlay__content"
            >
              {props.children}
            </motion.div>
          </div>
        )}
      </AnimatePresence>
    </>
  );
};

MenuOverlay.propTypes = {
  id: PropTypes.string,
};

export default MenuOverlay;
