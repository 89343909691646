import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";
import { withTranslation } from "react-i18next";
import classnames from "classnames";

import {
  MagnifierSvg,
  MapMarkerSvg,
  UserSvg,
  ArrowSmallSvg,
  BurgerSvg,
  BoxSvg,
} from "@assets/js/icons";

import BasketIcon from "../../checkout/BasketIcon";
import LogoAnimation from "../../LogoAnimation";

import useHeaderStore from "@store/header";
import useGlobalStore, { useCommerceMarketSupportsEcom, sc_lang } from "@store/global";
import { useMenuOverlayActive } from "@store/header";

import isTrue from "@assets/js/is-true";
import { OVERLAY, SITE_MODE } from "@constants";

import "./main-header.scss";

let MainHeader = ({ menu, fields }) => {
  // get current active mainmenu item
  const currentMainMenuItem = useHeaderStore((store) => store.currentMainMenuItem);
  // set curremt active mainmenu item
  const setCurrentMainMenuItem = useHeaderStore((store) => store.setCurrentMainMenuItem);

  const currentMegaMenuCategoryItem = useHeaderStore((store) => store.currentMegaMenuCategoryItem);
  const setCurrentMegaMenuCategoryItem = useHeaderStore(
    (store) => store.setCurrentMegaMenuCategoryItem
  );

  // get mobile navigation status
  const mobileNavigationActive = useHeaderStore((store) => store.mobileNavigationActive);

  // set mobile navigation to active
  const setMobileNavigationActive = useHeaderStore((store) => store.setMobileNavigationActive);


  const [menuOverlayActive, setMenuOverlayActive] = useMenuOverlayActive();
  const [commerceMarketSupportsEcom] = useCommerceMarketSupportsEcom();
  const closeMainMenu = useHeaderStore((store) => store.closeMainMenu);

  const currentSiteMode = useGlobalStore((store) => store.currentSiteMode);
  const sc_lang = useGlobalStore((store) => store.sc_lang);

  const menuItemClickHandler = (id) => {
    if (currentMainMenuItem === id) {
      closeHandler();
    } else {
      setCurrentMegaMenuCategoryItem(null);
      setCurrentMainMenuItem(id);
      setMenuOverlayActive(OVERLAY.MENU);
    }
  };

  const searchClickHandler = () => {
    setMenuOverlayActive(OVERLAY.SEARCH);
  };

  const closeHandler = () => {
    if (currentMegaMenuCategoryItem) {
      setCurrentMegaMenuCategoryItem(null);
    } else {
      setCurrentMainMenuItem(null);
      setMenuOverlayActive(OVERLAY.NONE);
    }
  };

  const mobileNavigationHandler = () => {
    if (mobileNavigationActive) {
      setMenuOverlayActive(OVERLAY.NONE);
      setMobileNavigationActive(false);
    } else {
      setMenuOverlayActive(OVERLAY.MENU);
      setMobileNavigationActive(true);
    }
  };

  const containerMotion = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        staggerDirection: -1,
      },
    },
  };

  const [isInverted, setIsInverted] = useState(false);

  useEffect(() => {

  const headerIsInvertedResidential = isTrue(fields?.headerIsInvertedResidential);
  const headerIsInvertedProfessional = isTrue(fields?.headerIsInvertedProfessional);

    if (currentSiteMode === SITE_MODE.RESIDENTIAL) {
      setIsInverted(headerIsInvertedResidential && menuOverlayActive == OVERLAY.NONE);
    } else if (currentSiteMode === SITE_MODE.PROFESSIONAL) {
      setIsInverted(headerIsInvertedProfessional && menuOverlayActive == OVERLAY.NONE);
    }
  }, [fields, menuOverlayActive, currentSiteMode]);
  

  const itemMotion = {
    hidden: { y: 20, opacity: 0 },
    show: { y: 0, opacity: 1 },
  };
  const mainHeaderClasses = classnames({
    "main-header": true,
    "is-active": menuOverlayActive !== OVERLAY.NONE,
    "is-inverted": isInverted,
  });

  return (
    <>
      {!menu.loading && (
        <div className={mainHeaderClasses}>
          <nav className="main-header__nav d-n-vp5">
            <ul className="main-header__list">
              {commerceMarketSupportsEcom && (
                <>
                  {currentSiteMode === SITE_MODE.PROFESSIONAL ? (
                    <li>
                      <button
                        className="main-header__list-button"
                        onClick={() => setMenuOverlayActive(OVERLAY.PORTFOLIO_ADMIN)}
                      >
                        <BoxSvg />
                      </button>
                    </li>
                  ) : (
                    <li>
                      <BasketIcon className="main-header__list-button" />
                    </li>
                  )}
                </>
              )}
            </ul>
          </nav>

          <NavLink to={"/"+sc_lang} className="main-header__logo t-title-1">
            <LogoAnimation />
          </NavLink>

          <nav className="main-header__nav d-n-vp5">
            <ul className="main-header__list">
              <li>
                <button onClick={mobileNavigationHandler} className="main-header__list-button">
                  <span className="icon">
                    <BurgerSvg />
                  </span>
                </button>
              </li>
            </ul>
          </nav>

          <nav className="main-header__nav d-n d-f-vp5">
            {currentMainMenuItem && currentMegaMenuCategoryItem && (
              <button className="main-header__back" onClick={closeHandler}>
                <span className="icon">
                  <ArrowSmallSvg />
                </span>
              </button>
            )}

            <motion.ul
              className="main-header__list"
              variants={containerMotion}
              initial="hidden"
              animate="show"
              key={`mainHeader-${currentSiteMode}`}
            >
              {menu?.desktop &&
                menu?.desktop?.field?.targetItems?.map((item, index) => {
                  return (
                    <motion.li
                      variants={itemMotion}
                      key={`mainHeader-${currentSiteMode}-item-${index}`}
                    >
                      <MenuItem
                        item={item}
                        clickHandler={menuItemClickHandler}
                        active={currentMainMenuItem && item.id === currentMainMenuItem}
                      />
                    </motion.li>
                  );
                })}
            </motion.ul>

            <ul className="main-header__list">
              {menu.desktop?.showStorelocatorIcon && menu.desktop?.storelocatorIconLink && (
                <li>
                  <NavLink
                    to={menu.desktop.storelocatorIconLink.jss.value.href}
                    className="main-header__list-button"
                    onClick={() => closeMainMenu()}
                  >
                    <span className="icon">
                      <MapMarkerSvg />
                    </span>
                  </NavLink>
                </li>
              )}
              {menu.desktop?.showMyFritzHansenIcon && (
                <li>
                  <button
                    className="main-header__list-button"
                    onClick={() => setMenuOverlayActive(OVERLAY.MYFRITZ)}
                  >
                    <span className="icon">
                      <UserSvg />
                    </span>
                  </button>
                </li>
              )}
              <li>
                <button className="main-header__list-button" onClick={searchClickHandler}>
                  <span className="icon">
                    <MagnifierSvg />
                  </span>
                </button>
              </li>
              {commerceMarketSupportsEcom && (
                <>
                  {currentSiteMode === SITE_MODE.PROFESSIONAL ? (
                    <li>
                      <button
                        className="main-header__list-button"
                        onClick={() => setMenuOverlayActive(OVERLAY.PORTFOLIO_ADMIN)}
                      >
                        <BoxSvg />
                      </button>
                    </li>
                  ) : (
                    <li>
                      <BasketIcon className="main-header__list-button" />
                    </li>
                  )}
                </>
              )}
            </ul>
          </nav>
        </div>
      )}
    </>
  );
};

// inject dictionary props (`t`) into navigation so we can translate it
// NOTE: using this is needed instead of using i18next directly to keep
// the component state updated when i18n state (e.g. current language) changes
MainHeader = withTranslation()(MainHeader);

export default MainHeader;

const MenuItem = ({ item, clickHandler, active }) => {
  const closeMainMenu = useHeaderStore((store) => store.closeMainMenu);
  const linkItem = item.fields.find((x) => x.name === "Link");
  const link = linkItem?.jss?.value?.href;
  const linkType = linkItem?.jss?.value?.linktype;
  const name =
    item.fields[item.fields.findIndex((x) => x.name === "Title")]?.value ||
    linkItem?.jss?.value?.title ||
    linkItem?.jss?.value?.text ||
    item.name;

  const onClick = () => {
    if (clickHandler) {
      clickHandler(item.id);
    }
  };

  const css = classnames({
    "main-header__item": true,
    "is-active": (process.browser && link === window.location.pathname) || active,
  });

  return (
    <>
      {linkType?.toUpperCase() === "INTERNAL" && (
        <NavLink to={link} className={css} onClick={() => closeMainMenu()}>
          <span>{name}</span>
        </NavLink>
      )}
      {linkType?.toUpperCase() === "EXTERNAL" && (
        <a className={css} href={link}>
          <span>{name}</span>
        </a>
      )}
      {!linkType && (
        <button className={css} onClick={onClick}>
          <span>{name}</span>
        </button>
      )}
    </>
  );
};
