import React from "react";

import "./footer-logo.scss";
const FooterLogo = () => {
  return (
    <svg
      width="147"
      height="26"
      viewBox="0 0 147 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.45 4.177V.745H.531V25h3.795V14.407h12.903v-3.3H4.326v-6.93H18.45zM18.364 25h4.323v-4.323h-4.323V25zM46.744.745v10.032h-14.09V.745h-3.796V25h3.795V14.275h14.091V25h3.795V.745h-3.795zM56.746 25h4.322v-4.323h-4.322V25zM68.632.415c-.363 3.036-2.475 4.323-6.831 4.323v3.564c3.168-.033 4.983-.561 6.17-1.848V25h3.796V.415h-3.135zm25.513 11.781c3.234-.99 4.917-2.904 4.917-5.412 0-3.564-3.267-6.699-10.131-6.699-6.732 0-10.197 3.201-10.197 6.765 0 2.541 1.683 4.455 4.917 5.379-3.597 1.023-5.577 3.201-5.577 6.039 0 3.927 3.333 7.392 10.791 7.392 7.392 0 10.857-3.531 10.857-7.458 0-2.871-1.947-5.016-5.577-6.006zM82.529 7.048c0-2.211 2.277-3.696 6.336-3.696 4.092 0 6.402 1.485 6.402 3.696 0 2.178-2.277 3.696-6.369 3.696-4.059 0-6.369-1.518-6.369-3.696zm6.402 15.345c-4.752 0-7.062-1.848-7.062-4.29s2.277-4.323 7.029-4.323c4.719 0 7.029 1.881 7.029 4.323s-2.277 4.29-6.996 4.29zM123.265.745h-19.833v8.712h3.63V4.111h11.715c-4.521 5.016-7.623 11.649-9.306 20.889h4.059c1.749-9.702 4.851-16.5 9.735-21.615V.745zm10.192 20.79l6.204-4.785c5.412-4.125 6.369-6.303 6.369-9.042 0-4.455-3.333-7.623-9.174-7.623-6.204 0-9.537 3.663-10.131 7.953l3.696 1.386c.363-3.465 2.178-5.841 6.369-5.841 3.3 0 5.214 1.782 5.214 4.29 0 1.815-.825 3.399-5.412 6.996l-9.636 7.425V25h19.8v-3.465h-13.299z"
        fill="#000"
      />
    </svg>
  );
};
FooterLogo.propTypes = {};

export default FooterLogo;
