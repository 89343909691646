import React from "react";
import { useTranslation } from "react-i18next";
import getFormattedPrice from "@assets/js/get-formatted-price";
import shallow from "zustand/shallow";
import useGlobalStore, { useCommerceMarket } from "@store/global";

import "./order-calculation.scss";
const OrderCalculation = ({
  TotalWithoutDiscount,
  DiscountTotal,
  SubTotal,
  TaxTotal,
  OrderTotal,
  ShippingTotal,
  Vat,
  Currency,
  postalCode,

  campaign = null,
}) => {
  const [commerceMarket] = useCommerceMarket();
  const [language] = useGlobalStore((state) => [state.language], shallow);

  const { t } = useTranslation();
  const texts = {
    totalWithoutDiscount: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Order_TotalWithoutDiscount"
    ),
    subTotal: t("FritzHansen_Project_ConsumersAndProfessionals_Order_SubTotal"),
    shippingTo: t("FritzHansen_Project_ConsumersAndProfessionals_Order_ShippingTo"),
    taxesFor: t("FritzHansen_Project_ConsumersAndProfessionals_Order_TaxesFor"),

    orderTotal: t("FritzHansen_Project_ConsumersAndProfessionals_Order_OrderTotal"),
    inclVat: t("FritzHansen_Project_ConsumersAndProfessionals_Order_InclVat"),
    discountTotal: t("FritzHansen_Project_ConsumersAndProfessionals_Order_DiscountTotal"),
  };

  return (
    <div className="order-calculation">
      <div className="order-calculation__section">
        <div>
          <p>{texts.totalWithoutDiscount}</p>
          <p>{getFormattedPrice(language, commerceMarket, Currency, TotalWithoutDiscount)}</p>
        </div>
        {DiscountTotal > 0 && (
          <>
            <div>
              <p>{texts.discountTotal}</p>
              <p>{getFormattedPrice(language, commerceMarket, Currency, DiscountTotal)}</p>
            </div>
            <div>
              <p>{texts.subTotal}</p>
              <p>{getFormattedPrice(language, commerceMarket, Currency, SubTotal)}</p>
            </div>
          </>
        )}
        <div>
          <p>
            {texts.shippingTo} {postalCode}
          </p>
          <p>{getFormattedPrice(language, commerceMarket, Currency, ShippingTotal)}</p>
        </div>
      </div>

      <div className="order-calculation__section">
        <div>
          <h5>{texts.orderTotal}</h5>
          <h5>{getFormattedPrice(language, commerceMarket, Currency, OrderTotal)}</h5>
        </div>

        <div>
          <p>{texts.inclVat}</p>
          <p>{getFormattedPrice(language, commerceMarket, Currency, TaxTotal)}</p>
        </div>
      </div>
    </div>
  );
};
OrderCalculation.propTypes = {};

export default OrderCalculation;
