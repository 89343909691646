import Select from "./select";

export default {
  default: {
    fields: {
      moreFiltersText: "More filters",
      filtersText: "Filters",
      sortText: "Sort",
      sorting: { ...Select.sorting, noParse: true },
    },
  },
};
