import React, { Fragment, useEffect, useState } from "react";
import shallow from "zustand/shallow";
import classnames from "classnames";
import { motion, AnimatePresence } from "framer-motion";
import { useTranslation } from "react-i18next";

import ProductTile from "../ProductTile";
import SpotTile from "../SpotTile";
import Select from "../forms/Select";
import ActionButton from "../ActionButton";
import ProductFiltersVisual from "../ProductFiltersVisual";
import ProductFiltersBlock from "../ProductFiltersBlock";

import globalStore, { useCommerceMarket } from "@store/global";

import usePLPStore, {
  useEnabledFacetList,
  useProductList,
  useGetProducts,
  usePaging,
} from "@store/plp";

import { BUTTONSTYLE, QUERY_PARAMETER } from "@constants";
import { dataLayerCommercePush } from "@assets/js/gtm";
import { log } from "@assets/js/utils";
import urlParams from "@assets/js/url-params";

import "./product-list.scss";

const containerMotion = {
  hidden: {},
  show: {
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.5,
      staggerDirection: -1,
    },
  },
};

const itemMotion = {
  hidden: { y: 20, opacity: 0 },
  show: (idx) => ({
    y: 0,
    opacity: 1,
    transition: {
      delay: idx * 0.1,
      ease: [0.25, 0.01, 0.25, 1],
    },
  }),
};

const ProductListController = ({ fields, rendering }) => {
  let {
    facets,
    taxonomy,
    numberOfProducts = 15,
    sortingOptions = [],
  } = fields?.data?.item;
  const { uid } = rendering;
  // setting components variables and func
  const { t } = useTranslation();
  const [, setEnabledFacetList] = useEnabledFacetList();
  const [productList] = useProductList();
  const [commerceMarket] = useCommerceMarket();
  const { getProductList, refreshProductList } = useGetProducts();
  const [paging, setPaging] = usePaging();

  const sc_lang = globalStore.getState().sc_lang;
  const [setSortingCriteria, savePriceInfo, count] = usePLPStore(
    (state) => [state.setSortingCriteria, state.savePriceInfo, state.count],
    shallow
  );
  const currentMarket = globalStore((state) => state.currentMarket);
  const [gtmDone, setGtmDone] = useState(false);
  const [uniqueCount, setUniqueCount] = useState(0);
  // component mounted
  useEffect(() => {
    if (!sc_lang) return;
    if (!commerceMarket) return;

    // initial paging data
    // log("ProductList | load");

    let newVal =
      numberOfProducts?.value && !isNaN(numberOfProducts.value)
        ? numberOfProducts?.value
        : numberOfProducts && !isNaN(numberOfProducts)
        ? numberOfProducts
        : 16;

    let newPaging = {
      numberOfProducts: newVal,
      start: 0,
      count: newVal,
    };

    setPaging(newPaging);

    setEnabledFacetList(facets ? facets.values : []);

    let appliedFilters = {};
    let models = [];

    // URLSearchParams
    const params = urlParams.get();

    params.forEach((facetFieldId, facetId) => {
      if (paramIsRelevant(facetId)) {
        if (facetId.indexOf("models") > -1) {
          models = facetFieldId.split(";");
        }
        appliedFilters[facetId] = facetFieldId.split(";");
      }
    });
    function paramIsRelevant(param) {
      // check if param is a system parameter and should not be included in filter list.
      // ONLY non-system params are relevant

      return Object.values(QUERY_PARAMETER).indexOf(param) === -1;
    }
    // get first product-data
    getProductList({
      taxonomies: [taxonomy ? taxonomy.value : ""],
      appliedFilters,
      models,
      paging,
    });
  }, [taxonomy, commerceMarket, sc_lang]);

  // Trigger animation on browser back
  useEffect(() => {
    setUniqueCount(uniqueCount + 1);
    setGtmDone(false);
  }, [productList]);

  const updatePaging = () => {
    setPaging({
      start: paging.start + paging.numberOfProducts,
      count: paging.count + paging.numberOfProducts,
    });
    refreshProductList();
  };

  // GTM tracking
  useEffect(() => {
    if (currentMarket && productList?.length && !gtmDone) {
      dataLayerCommercePush("impressionView", {
        currencyCode: currentMarket.currency?.value,
        impressions: productList.map((productItem, index) => {
          let materials = productItem.configurationId_s?.split("-") || [
            "not set",
          ];
          if (materials && materials.length > 1) {
            materials.shift();
          } else {
            materials = ["not set"];
          }
          return Object.assign(
            {},
            {
              name: productItem?.fullName_t,
              id: productItem?.configurationId_s,
              price: "",
              brand: "",
              category: "",
              variant: productItem?.plpDescription_t,
              list: taxonomy?.value,
              position: index + 1,
            },
            ...materials.map((mat, index) => ({
              [`dimension${index + 1}`]: mat,
            }))
          );
        }),
      });
      setGtmDone(true);
    }
  }, [currentMarket, productList, gtmDone]);

  return (
    <div className="product-list" key={uid}>
      {fields?.data?.item?.showVisualFilters?.field?.value && (
        <ProductFiltersVisual />
      )}

      <div className="product-list__tools">
        <div className="product-list__filters">
          <ProductFiltersBlock />
        </div>
        {sortingOptions?.values?.length > 1 && (
          <div className="product-list__sort">
            <Select
              changeHandler={setSortingCriteria}
              isSortSelector={true}
              options={sortingOptions.values.map((option, i) => ({
                id: `${option.value}-${i}`,
                optionLabel: option.key?.value,
                optionValue: option.value?.value,
              }))}
              sortLabel={t(
                "FritzHansen_Project_ConsumersAndProfessionals_PLP_SortBy"
              )}
            />
          </div>
        )}
      </div>
      <div className={classnames({ "empty-list": productList.length === 0 })}>
        {productList && (
          <div
            className={classnames({
              "product-list__tiles": true,
              "product-list--empty": productList.length === 0,
            })}
          >
            {productList.map((item, index) => {
              return (
                <Fragment key={"fragment" + item.modelTypeId_s + index}>
                  <motion.div
                    className="product-list__tile"
                    variants={itemMotion}
                    custom={index}
                    id={"motion" + item.modelTypeId_s + index}
                  >
                    <ProductTile
                      key={"productTile" + item.modelTypeId_s + index}
                      {...item}
                      currency={currentMarket?.currency?.value}
                      taxonomy={taxonomy?.value}
                      index={index}
                    />
                  </motion.div>
                  {index === 6 && fields.data?.item?.inspirationSpot?.field && (
                    <motion.div
                      className="product-list__tile product-list__tile-inspiration"
                      key={`${item.id}-spot`}
                      variants={itemMotion}
                      custom={index}
                    >
                      <SpotTile
                        fields={fields.data.item.inspirationSpot.field}
                      />
                    </motion.div>
                  )}
                </Fragment>
              );
            })}
          </div>
        )}
      </div>

      {count > paging.count && (
        <div className="product-list__paging">
          <ActionButton
            text="Load more"
            buttonType={BUTTONSTYLE.GHOST}
            clickHandler={updatePaging}
          />
        </div>
      )}
    </div>
  );
};
ProductListController.propTypes = {
  // filters: PropTypes.array.isRequired,
};
export default ProductListController;
