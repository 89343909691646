import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { loader } from "graphql.macro";
import { useQuery } from "react-apollo";
import dummyData from "@assets/storybook/all-dummy-data.js";

import FeatureSmall from "../FeatureSmall";
import MessageBlock from "../MessageBlock";
import PortfolioMaterialTile from "../portfolio/PortfolioMaterialTile";
import PortfolioMediaTile from "../portfolio/PortfolioMediaTile";
import PortfolioProductTile from "../portfolio/PortfolioProductTile";
import ActionButton from "../ActionButton";

import {
  OVERLAY,
  BUTTONSTYLE,
  PORTFOLIO_TYPE,
  PORTFOLIO_WISHLIST_NAME,
  SITE_MODE,
} from "@constants";

import urlParams from "@assets/js/url-params.js";

import useGlobalStore from "@store/global";
import usePortfolioStore, { useActivePortfolioData } from "@store/portfolio";
import useHeaderStore from "@store/header";

import "./portfolio-layout.scss";

const storeLocatorQuery = loader("./storeLocatorQuery.graphql");

const PortfolioLayoutController = ({ fields }) => {
  const { t } = useTranslation();
  const texts = {
    error: t("FritzHansen_Project_ConsumersAndProfessionals_General_Error"),
    noPortfolioFound: t("FritzHansen_Project_ConsumersAndProfessionals_Portfolio_NoPortfolioFound"),
    weAreSorry: t("FritzHansen_Project_ConsumersAndProfessionals_Portfolio_WeAreSorry"),
  };

  const [, , fetchPortfolio] = useActivePortfolioData();
  const userAuthenticated = useGlobalStore((store) => store.userAuthenticated);
  const [state, setState] = useState({
    keyFound: false,
    readOnly: false,
    activePortfolioKey: null,
  });
  useEffect(() => {
    const params = urlParams.get();
    const portfolioReadOnlyKey = params.get("prkey");
    const portfolioEditKey = params.get("pekey");

    const keyFound = portfolioEditKey || portfolioReadOnlyKey;
    const readOnly = portfolioReadOnlyKey ? true : false;

    const activePortfolioKey = keyFound
      ? portfolioEditKey
        ? portfolioEditKey
        : portfolioReadOnlyKey
      : "_NO_PORTFOLIO_KEY_";

    setState({
      keyFound,
      readOnly,
      activePortfolioKey,
    });
    if (keyFound) {
      fetchPortfolio(readOnly, activePortfolioKey, userAuthenticated);
    }
  }, []);

  return (
    <>
      {state.keyFound ? (
        <PortfolioLayout
          fields={fields}
          canEdit={!state.readOnly}
          portfolioKey={state.activePortfolioKey}
        />
      ) : (
        <div className="portfolio-layout">
          <div className="portfolio-layout__header">
            <MessageBlock
              trumpet={texts.error}
              headline={texts.noPortfolioFound}
              bodyText={texts.weAreSorry}
            />
          </div>
        </div>
      )}
    </>
  );
};

PortfolioLayoutController.propTypes = {};
export default PortfolioLayoutController;

const PortfolioLayout = ({ fields, canEdit, portfolioKey }) => {
  const { t } = useTranslation();
  const texts = {
    share: t("FritzHansen_Project_ConsumersAndProfessionals_Portfolio_Share"),
    allProjects: t("FritzHansen_Project_ConsumersAndProfessionals_Portfolio_AllProjects"),
    wishList: t("FritzHansen_Project_ConsumersAndProfessionals_Portfolio_Wishlist"),
    emptyPortfolio: t("FritzHansen_Project_ConsumersAndProfessionals_Portfolio_Empty"),
  };

  const setMenuOverlayActive = useHeaderStore((store) => store.setMenuOverlayActive);
  const [activePortfolioData, ,] = useActivePortfolioData();
  const deleteItem = usePortfolioStore((store) => store.deleteItem);

  const mode = useGlobalStore((state) => state.currentSiteMode);
  const menuId =
    mode === SITE_MODE.RESIDENTIAL
      ? process.env.REACT_APP_RESIDENTIAL_DESKTOP_ID
      : process.env.REACT_APP_PROFESSIONAL_DESKTOP_ID;

  const { data, loading } = useQuery(storeLocatorQuery, {
    variables: { menuId },
    errorPolicy: "all",
  });

  const shareHandler = () => {
    window.open(
      `${window.location.origin}${window.location.pathname}?prkey=${activePortfolioData.portfolioReadOnlyKey}`
    );
  };
  const removeHandler = (data) => {
    deleteItem(portfolioKey, data.id);
  };
  const allProjectsHandler = () => {
    setMenuOverlayActive(OVERLAY.PORTFOLIO_ADMIN);
  };

  const getStoresHref = () => {
    if (data && data?.menu?.storeLocatorLink?.jss?.value?.href) {
      return data.menu.storeLocatorLink.jss.value.href;
    } else {
      return "/";
    }
  };

  return (
    <div className="portfolio-layout">
      <div className="portfolio-layout__header">
        <div className="portfolio-layout__header__inner">
          {activePortfolioData.name && (
            <h1>
              {activePortfolioData.name === PORTFOLIO_WISHLIST_NAME
                ? texts.wishList
                : activePortfolioData.name}
            </h1>
          )}
          {activePortfolioData.description && <p>{activePortfolioData.description}</p>}

          {canEdit && (
            <ActionButton
              className="portfolio-layout__button"
              buttonType={BUTTONSTYLE.GHOST}
              text={texts.share}
              icon="ArrowSmallSvg"
              clickHandler={shareHandler}
            />
          )}

          {canEdit && (
            <button className="basic-link" onClick={allProjectsHandler}>
              <span className="text">{texts.allProjects}</span>
            </button>
          )}
        </div>
      </div>

      {activePortfolioData?.portfolioItems?.length ? (
        <div className="portfolio-layout__wrapper">
          {activePortfolioData.portfolioItems.map((item, index) => {
            return (
              <Fragment key={`portfolioItemTop${index}`}>
                {index < 6 && (
                  <>
                    {item.type === PORTFOLIO_TYPE.MATERIAL && (
                      <PortfolioMaterialTile
                        data={item}
                        canEdit={canEdit}
                        onRemove={removeHandler}
                      />
                    )}
                    {item.type === PORTFOLIO_TYPE.MEDIA && (
                      <PortfolioMediaTile data={item} canEdit={canEdit} onRemove={removeHandler} />
                    )}
                    {item.type === PORTFOLIO_TYPE.PRODUCT && (
                      <PortfolioProductTile
                        data={item}
                        canEdit={canEdit}
                        onRemove={removeHandler}
                        storesHref={getStoresHref()}
                      />
                    )}
                  </>
                )}
              </Fragment>
            );
          })}

          {fields?.feature && <FeatureSmall fields={fields.feature.fields} />}

          {activePortfolioData.portfolioItems.map((item, index) => {
            return (
              <Fragment key={`portfolioItemBottom${index}`}>
                {index >= 6 && (
                  <>
                    {item.type === PORTFOLIO_TYPE.MATERIAL && (
                      <PortfolioMaterialTile
                        data={item}
                        canEdit={canEdit}
                        onRemove={removeHandler}
                      />
                    )}
                    {item.type === PORTFOLIO_TYPE.MEDIA && (
                      <PortfolioMediaTile data={item} canEdit={canEdit} onRemove={removeHandler} />
                    )}
                    {item.type === PORTFOLIO_TYPE.PRODUCT && (
                      <PortfolioProductTile
                        data={item}
                        canEdit={canEdit}
                        onRemove={removeHandler}
                      />
                    )}
                  </>
                )}
              </Fragment>
            );
          })}
        </div>
      ) : (
        <div>{texts.emptyPortfolio}</div>
      )}
    </div>
  );
};

PortfolioLayout.propTypes = {
  headline: PropTypes.string,
  fields: PropTypes.object,
  canEdit: PropTypes.bool,
};
