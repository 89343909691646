import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import classnames from "classnames";
// import { ChevronSvg } from "@assets/js/icons";
import "./collapsible.scss";

const Collapsible = ({
  preExpanded = false,
  triggerClassName,
  triggerComponent,
  contentComponent,
  toggleHandler,
  id,
}) => {
  const [expanded, setExpanded] = useState(preExpanded);

  const toggleClickHandler = () => {
    if (toggleHandler) {
      toggleHandler();
    }
    setExpanded(!expanded);
  };

  useEffect(() => {
    setExpanded(preExpanded);
  }, [preExpanded]);

  const collapsibleClasses = classnames({
    collapsible: true,
    "is-active": expanded,
  });

  const triggerClasses = classnames({
    [triggerClassName]: !!triggerClassName,
    collapsible__trigger: true,
    "is-active": expanded,
  });

  const contentVariants = {
    open: {
      opacity: 1,
      height: "auto",
    },
    collapsed: {
      opacity: 0,
      height: 0,
    },
  };

  return (
    <div className={collapsibleClasses}>
      <button className={triggerClasses} onClick={toggleClickHandler}>
        {triggerComponent}
        {/* <ChevronSvg /> */}
      </button>
      <AnimatePresence initial={false}>
        {expanded && (
          <motion.div
            className="collapsible__content"
            initial="collapsed"
            variants={contentVariants}
            animate="open"
            exit="collapsed"
            key="content"
            transition={{ duration: 0.6, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            {contentComponent}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Collapsible;
