import MediaFocal from "./media-focal";
import NavButton from "./nav-button";
import ContactLinksGroup from "./contact-links-group";

export default {
  default: {
    fields: {
      headline: "Our Story",
      bodyText:
        "Fritz Hansen is an exclusive, international design brand whose timeless collection unites world-famous classic and contemporary furniture, lighting and accessories.",
      tag: "Hospitality",
      media1: MediaFocal.default,
      button: NavButton.link,
      contact: ContactLinksGroup.default,
      isButtonEnabled: true,
      isBodyLeft: false,
      isInverted: false,
      backgroundColorHex: "",
    },
  },
  customBg: {
    fields: {
      headline: "Our Story",
      bodyText:
        "Fritz Hansen is an exclusive, international design brand whose timeless collection unites world-famous classic and contemporary furniture, lighting and accessories.",
      tag: "Hospitality",
      media1: MediaFocal.default,
      button: NavButton.linkInverted,
      contact: ContactLinksGroup.default,
      isButtonEnabled: true,
      isBodyLeft: false,
      isInverted: true,
      backgroundColorHex: "#335599",
    },
  },
  twoImages: {
    fields: {
      headline: "Our Story",
      bodyText:
        "Fritz Hansen is an exclusive, international design brand whose timeless collection unites world-famous classic and contemporary furniture, lighting and accessories.",
      tag: "Hospitality",
      media1: MediaFocal.default,
      media2: MediaFocal.default,
      button: NavButton.link,
      contact: ContactLinksGroup.default,
      isButtonEnabled: true,
      isBodyLeft: false,
      isInverted: false,
      backgroundColorHex: null,
    },
  },
  leftBody: {
    fields: {
      headline: "Our Story",
      bodyText:
        "Fritz Hansen is an exclusive, international design brand whose timeless collection unites world-famous classic and contemporary furniture, lighting and accessories.",
      tag: "Hospitality",
      media1: MediaFocal.default,
      button: NavButton.link,
      contact: ContactLinksGroup.default,
      isButtonEnabled: true,
      isBodyLeft: true,
      isInverted: false,
      backgroundColorHex: null,
    },
  },
  leftBodyTwoImages: {
    fields: {
      headline: "Our Story",
      bodyText:
        "Fritz Hansen is an exclusive, international design brand whose timeless collection unites world-famous classic and contemporary furniture, lighting and accessories.",
      tag: "Hospitality",
      media1: MediaFocal.default,
      media2: MediaFocal.default,
      button: NavButton.link,
      contact: ContactLinksGroup.default,
      isButtonEnabled: true,
      isBodyLeft: true,
      isInverted: false,
      backgroundColorHex: null,
    },
  },
  findNearest: {
    fields: {
      headline: "Find your nearest retail store",
      bodyText:
        "Fritz Hansen is an exclusive, international design brand whose timeless collection unites world-famous classic and contemporary furniture, lighting and accessories.",
      tag: {},
      media1: MediaFocal.default,
      button: NavButton.outlinedArrow,
      isButtonEnabled: true,
      isBodyLeft: false,
      backgroundColorHex: "#D6E1B9",
    },
  },

  reference1: {
    fields: {
      interactive: true,
      href: "#destination",
      headline: "Our Story",
      bodyText:
        "Fritz Hansen is an exclusive, international design brand whose timeless collection unites world-famous classic and contemporary furniture, lighting and accessories.",
      tag: "Office",
      media1: MediaFocal.default,
      media2: MediaFocal.default,
      button: NavButton.linkInverted,
      contact: ContactLinksGroup.default,
      isButtonEnabled: true,
      isBodyLeft: false,
      isInverted: true,
      backgroundColorHex: "#335599",
      tags: [
        {
          id: "234234234",
          label: "Office",
        },
      ],
    },
  },

  reference2: {
    fields: {
      interactive: true,
      href: "#destination",
      headline: "Our Story",
      bodyText:
        "Fritz Hansen is an exclusive, international design brand whose timeless collection unites world-famous classic and contemporary furniture, lighting and accessories.",
      tag: "Hospitality, Living",
      media1: MediaFocal.default,
      media2: MediaFocal.default,
      button: NavButton.link,
      contact: ContactLinksGroup.default,
      isButtonEnabled: true,
      isBodyLeft: true,
      isInverted: false,
      backgroundColorHex: "#ffaa99",
    },
  },

  reference3: {
    fields: {
      interactive: true,
      href: "#destination",
      headline: "Our Story",
      bodyText:
        "Fritz Hansen is an exclusive, international design brand whose timeless collection unites world-famous classic and contemporary furniture, lighting and accessories.",
      tag: "Living",
      media1: MediaFocal.default,
      media2: MediaFocal.default,
      button: NavButton.linkInverted,
      contact: ContactLinksGroup.default,
      isButtonEnabled: true,
      isBodyLeft: true,
      isInverted: true,
      backgroundColorHex: "#AA7722",
    },
  },

  reference4: {
    fields: {
      interactive: true,
      href: "#destination",
      headline: "Our Story",
      bodyText:
        "Fritz Hansen is an exclusive, international design brand whose timeless collection unites world-famous classic and contemporary furniture, lighting and accessories.",
      tag: "Hospitality",
      media1: MediaFocal.default,
      media2: MediaFocal.default,
      button: NavButton.link,
      contact: ContactLinksGroup.default,
      isButtonEnabled: true,
      isBodyLeft: true,
      isInverted: false,
      backgroundColorHex: "#55EEDD",
    },
  },
};
