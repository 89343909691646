import React, { useState } from "react";
import classnames from "classnames";
import { ArrowSmallSvg, MagnifierSvg } from "@assets/js/icons";

import "./input-field-w-button.scss";

const InputFieldwButton = ({
  id,
  name,
  type,
  value,
  autoComplete,
  label,
  placeholder,
  required,
  disabled,
  ariaDescribedBy,
  iconType,
  isInverted,
  register,
  error,
  changeHandler,
}) => {
  const [val, setVal] = useState(value);
  const classList = classnames({
    "input-field-w-button": true,
    "input-field-w-button--inverted": isInverted,
  });

  const onChangeHandler = (target) => {
    setVal(target.value);
    if (changeHandler) changeHandler(target);
  };

  let icon;
  if (iconType === "arrow") {
    icon = <ArrowSmallSvg />;
  } else if (iconType === "magnifier") {
    icon = <MagnifierSvg />;
  } else {
    icon = null;
  }
  return (
    <div className={classList}>
      {label && (
        <label className="input-field-w-button__label" htmlFor={id}>
          {label}
        </label>
      )}
      <div className="input-field-w-button__wrapper">
        <input
          id={id}
          name={name}
          aria-describedby={ariaDescribedBy}
          type={type}
          autoComplete={autoComplete}
          placeholder={placeholder}
          disabled={disabled}
          required={required}
          ref={register}
          value={val}
          onChange={({ target }) => onChangeHandler(target)}
        />
        <button>{icon}</button>
      </div>
      {error && <p>{error.message}</p>}
    </div>
  );
};

export default InputFieldwButton;
