import React, { useEffect } from "react";
import { AnimatePresence } from "framer-motion";
import { useCookies } from "react-cookie";
import { Switch, Route, useLocation, useHistory } from "react-router-dom";
import RouteHandler from "./RouteHandler";
import useGlobalStore from "@store/global";
import { SITE_MODE, QUERY_PARAMETER } from "@constants";
import dataLayerPush from "@assets/js/gtm";

// support languages in the URL prefix
// e.g. /da-DK/path, or /en/path, or /path

export const routePatterns = [
  "/:lang([a-z]{2}-[A-Z]{2})/:sitecoreRoute*",
  "/:lang([a-z]{2})/:sitecoreRoute*",
  "/:sitecoreRoute*",
];

const Container = ({ ssrRenderComplete, setSsrRenderComplete }) => {
  const currentSiteMode = useGlobalStore((state) => state.currentSiteMode);
  const location = useLocation();
  const history = useHistory();
  const [cookies] = useCookies([
    process.env.REACT_APP_QUERY_VARIABLES_MODE_COOKIE,
  ]);
  useEffect(() => {
    history.listen((location) => {
      if (
        useGlobalStore.getState().currentSiteMode === SITE_MODE.PROFESSIONAL
      ) {
        const qp = new URLSearchParams(location.search);
        if (!qp.has(QUERY_PARAMETER.MODE)) {
          qp.set(QUERY_PARAMETER.MODE, SITE_MODE.PROFESSIONAL);
          window.history.replaceState(
            null,
            "Professional persitance",
            `${location.pathname}${
              qp.toString().length ? "?" + qp.toString() : ""
            }`
          );
        }
      }
      // Tracking
      dataLayerPush({ event: "virtualPageview" });
      window && window.fbq && window.fbq("track", "PageView");
      window && window.pixie && window.pixie('event', 'PageView');
    });
  }, [history]);

  const routeRenderFunction = (props) => (
    <RouteHandler
      route={props}
      ssrRenderComplete={ssrRenderComplete}
      setSsrRenderComplete={setSsrRenderComplete}
    />
  );

  return (
    <Route
      render={({ location }) => (
        <AnimatePresence
          exitBeforeEnter={true}
          onExitComplete={() => console.log("Container | PAGE EXIT")}
        >
          <Switch location={location} key={location.pathname.split("/")[0]}>
            {routePatterns.map((routePattern) => (
              <Route
                key={routePattern}
                path={routePattern}
                render={routeRenderFunction}
                exact
              />
            ))}
          </Switch>
        </AnimatePresence>
      )}
    />
  );
};
export default Container;
