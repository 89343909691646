import React, { useState, useEffect } from "react";
import usePDPStore, {
  usePendingProductData,
  useActiveProductData,
} from "@store/pdp";
import useGlobalStore from "@store/global";
import { useTranslation } from "react-i18next";

import PDPProductImageSpinner from "../PDPProductImageSpinner";
import PDPProductImageZoom from "../PDPProductImageZoom";
import {
  ArrowSquareSvg,
  MagnifierSvg,
  HeartSmallSvg,
  WebArSvg,
} from "@assets/js/icons";

import { useActiveSelector } from "@store/pdp";
import { placeholderReplace, log } from "@assets/js/utils";

import "./pdp-product-images.scss";

const modelImageUrl = `${process.env.REACT_APP_PRODUCT_IMAGE_API}r/`;

const PDPProductImages = () => {
  const webPSupport = useGlobalStore((state) => state.webPSupport);
  const imageData = usePDPStore((state) => state.productImage);
  const [pendingProductData] = usePendingProductData();
  const [activeProductData] = useActiveProductData();
  //const [activeModelData, setActiveModelData] = useActiveModelData(null);

  const [zoom, setZoom] = useState({ zooming: false, event: null });
  const [images, setImages] = useState([]);
  const [imageProgress, setImageProgress] = useState(0);
  const [activeSelector] = useActiveSelector();

  const { t } = useTranslation();
  const texts = {
    addToWishList: t(
      "FritzHansen_Project_ConsumersAndProfessionals_PDP_AddToWishlist"
    ),
    clickToZoom: t(
      "FritzHansen_Project_ConsumersAndProfessionals_PDP_ClickToZoom"
    ),
    dragToRotate: t(
      "FritzHansen_Project_ConsumersAndProfessionals_PDP_DragToRotate"
    ),
    webVr: t("FritzHansen_Project_ConsumersAndProfessionals_PDP_WebVr"),
  };
  const [isRelAR, setIsRelAr] = useState(false);
  const [webArUrl, setWebarUrl] = useState(false);
  useEffect(() => {
    setZoom({ zooming: false });
  }, [activeSelector, pendingProductData]);

  useEffect(() => {
    if (
      pendingProductData?.materials &&
      pendingProductData?.model?.typeId === imageData?.model
    ) {
      //if (!pendingProductData?.materials) return;

      log(
        "PDPProductImages | pendingProductData change???",
        pendingProductData.model.typeId
      );
      // 3107FU-{{FullUpholstered}}-{{Base}}-r{{angle}}.{{format}}
      // Render?model=3107FU&angle=0&FullUpholstered=HAL840&Base=DRBL&Format=jpeg&bgcolor=ffffff&size=1024

      let materialMap = new Map([["format", webPSupport ? "webp" : "jpg"]]);

      // find all material angles and push to an array
      const activeAngles = pendingProductData.materials.reduce(
        (acc, val, index) => {
          if (imageData?.layers[index]?.materials?.length) {
            const layer = imageData.layers[index];
            const mObj = layer.materials.filter(
              (it) => it.id === val.activeMaterial.materialId
            );
            if (mObj.length === 1 && mObj[0].angles) {
              // create material mapping
              materialMap.set(layer.name, val.activeMaterial.materialId);
              // active angles
              acc.push(mObj[0].angles);
            }
          }
          return acc;
        },
        []
      );
      if (!activeAngles || activeAngles.length === 0) return;
      // find common values in angles array
      const commonImageAngles = activeAngles?.reduce((a, b) => {
        return a.filter((c) => b.includes(c)).sort((a, b) => a - b);
      });
      const qs = placeholderReplace(
        imageData.querytemplate,
        materialMap,
        "{{$}}"
      );

      if (!commonImageAngles || commonImageAngles.length === 0) return;

      const _images = commonImageAngles.map((angle) => {
        const angleObj = { angle };
        return `${modelImageUrl}${placeholderReplace(
          qs,
          angleObj,
          "{{$}}"
        )}?bgcolor=efeeeb`;
      });

      setImages(_images);
    }
  }, [pendingProductData, activeProductData, imageData]);

  useEffect(() => {
    const a = document.createElement("a");
    if (a.relList.supports("ar")) {
      setIsRelAr(true);
    }
  }, []);
  useEffect(() => {
    if (activeProductData && activeProductData.model) {
      let newUrl = getWebarImgUrl(activeProductData, isRelAR);
      log('set new web AR url:', newUrl);
      setWebarUrl(newUrl);
    }
  }, [activeProductData, isRelAR]);

  const getWebarImgUrl = (prodData, isRel) => {
    if (!prodData || !prodData.model) return false;

    return `${process.env.REACT_APP_PRODUCT_IMAGE_API}ar/${
      prodData.model.configurationId
    }.${isRel ? "usdz" : "html"}`;
  };
  const handleZoom = (relPos, currentFrame, frameNode) => {
    setZoom({ zooming: true, relPos, currentFrame, frameNode });
  };
  const handleStopZoom = () => {
    setZoom({ zooming: false });
  };

  const handleImageLoadUpdate = (percent) => {
    setImageProgress(percent);
  };

  return (
    <div className="pdp-product-images">
      {webArUrl && imageData && imageData.webar && (
        <div className="pdp-product-images__webar">
          <a
            rel={isRelAR ? "ar" : undefined}
            target={isRelAR ? undefined : "_blank"}
            href={webArUrl}
          >
            <WebArSvg aria-labelledby="webar" />
            <span id="webar" className="sr-only">
              {texts.webVr}
            </span>
          </a>
        </div>
      )}

      <PDPProductImageSpinner
        images={images}
        onZoom={handleZoom}
        onUpdate={handleImageLoadUpdate}
      />

      <PDPProductImageZoom
        {...zoom}
        images={images}
        onStopZoom={handleStopZoom}
      />

      <div className="pdp-product-viewer__guide">
        {imageProgress < 1 && imageProgress > 0 && (
          <progress
            className="pdp-product-images__progress"
            max="1"
            value={imageProgress}
          />
        )}
        {imageProgress === 1 && (
          <React.Fragment>
            <div>
              <span>
                <MagnifierSvg />
              </span>
              <span>{texts.clickToZoom}</span>
            </div>
            <div>
              <span>
                <ArrowSquareSvg />
              </span>
              <span>{texts.dragToRotate}</span>
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default PDPProductImages;
