import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import MediaFocal from "../MediaFocal";
import NavButton from "../NavButton";
import classnames from "classnames";
import isTrue from "@assets/js/is-true";
import { motion } from "framer-motion";
import * as variants from "@assets/js/animation-variations";

import "./hero-staggered.scss";

const HeroStaggered = ({ fields, rendering }) => {
  const classList = classnames({
    "hero-staggered": true,
    "hero-staggered--inverted": isTrue(fields?.isInverted),
    "hero-staggered--separated": isTrue(fields?.isSeparated),
    "hero-staggered--left-img-infront": fields?.leftMediaInFront,
  });
  const { uid } = rendering;

  return (
    <>
      {fields && (
        <div
          key={uid}
          className={classList}
          style={
            fields?.backgroundColorHex && {
              "--hero-bgc": fields?.backgroundColorHex.value,
            }
          }
        >
          <div className="hero-staggered__content">
            {fields?.headline?.value && fields?.headlineType?.value && (
              <motion.div
                className="hero-staggered__headline"
                initial="initial"
                exit="exit"
                animate="enter"
                variants={variants.revealFromTop}
                transition={{
                  ...variants.revealFromTop.transition,
                  delay: 1.1,
                }}
              >
                <Text
                  tag={fields?.headlineType.value}
                  field={fields?.headline}
                />
              </motion.div>
            )}

            <motion.div
              className="hero-staggered__text"
              initial="initial"
              exit="exit"
              animate="enter"
              variants={variants.revealFromTop}
              transition={{
                ...variants.revealFromTop.transition,
                delay: 1.3,
              }}
            >
              {fields?.bodyText?.value && fields?.bodyText.value.length > 0 && (
                <Text tag="p" field={fields?.bodyText} />
              )}

              {fields?.button && <NavButton fields={fields?.button.fields} />}
            </motion.div>
          </div>
          {fields?.leftMedia && (
            <motion.div
              className={"hero-staggered__image hero-staggered__image--left"}
              initial="initial"
              exit="exit"
              animate="enter"
              variants={variants.defaultReveal}
              transition={{
                ...variants.defaultReveal.transition,
                delay: 0,
              }}
            >
              <MediaFocal fields={fields?.leftMedia.fields} />
            </motion.div>
          )}
          {fields?.rightMedia && (
            <motion.div
              className={"hero-staggered__image hero-staggered__image--right"}
              initial="initial"
              exit="exit"
              animate="enter"
              variants={variants.revealFromRight}
              transition={{
                ...variants.revealFromRight.transition,
                delay: 0.3,
              }}
            >
              <MediaFocal fields={fields?.rightMedia.fields} />
            </motion.div>
          )}
        </div>
      )}
    </>
  );
};

export default HeroStaggered;
