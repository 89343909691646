import React, { Fragment } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import TextTile from "../TextTile";
import { LINKTYPE, BUTTONSTYLE } from "@constants";

import cuid from "cuid";

import "./nav-module.scss";
const NavModule = ({ fields }) => {
  console.log("nav", fields);
  const tiles =
    fields.tiles &&
    fields.tiles.map((item) => {
      console.log(item);
      console.log(item.fields);

      return {
        headline: item.fields?.headline,
        bodyText: item.fields?.bodyText,
        isButtonEnabled: {
          value: true,
        },
        button: {
          fields: {
            hasArrow: {
              value: true,
            },
            command: item.fields?.command,
            title: item.fields?.title,
            link: item.fields?.link,
            style: {
              value: BUTTONSTYLE.LINK,
            },
          },
        },
      };
    });

  return (
    <>
      {fields && tiles.length && (
        <div className="nav-module">
          <div className="nav-module__wrapper">
            {tiles.map((item) => (
              <TextTile
                key={item.id}
                fields={item}
                link={item?.button?.fields?.link?.value?.href}
                interactive={true}
                isButtonEnabled={item.isButtonEnabled.value}
              />
            ))}
          </div>
          {fields.headline?.value && (
            <div className="nav-module__headline">
              <Text tag="h2" field={fields.headline?.value} />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default NavModule;
