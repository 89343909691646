import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import cuid from "cuid";

import { PlusSvg } from "@assets/js/icons";

import { OVERLAY } from "@constants";

import { useActiveFacetId, useFacetList } from "@store/plp";
import useHeaderStore from "@store/header";


import "./product-filters-block.scss";


const ProductFiltersBlock = ({ fields }) => {
  const { t } = useTranslation();

  const [facetList] = useFacetList();

  const setMenuOverlayActive = useHeaderStore(
    (store) => store.setMenuOverlayActive
  );
  const [activeFacetId, setActiveFacetId] = useActiveFacetId();

  const moreFiltersHandler = (id) => {
    if (id) {
      setActiveFacetId(id);
    } else {
      setActiveFacetId(null);
    }
    setMenuOverlayActive(OVERLAY.FILTERS);
  };

  return (
    <div className="product-filters-block">
      {/* MOBILE */}
      {facetList && facetList.length > 0 && (
        <button
          className="product-filters-block__button d-n-vp4"
          onClick={() => moreFiltersHandler(null)}
        >
          <span className="text">
            {t("FritzHansen_Project_ConsumersAndProfessionals_PLP_ShowFilters")}
          </span>
      </button>
      )}

      {/* DESKTOP */}
      {facetList && facetList.length > 0 && (        
        <ul className="d-n d-f-vp4">
          <li key={cuid()}>
            <p>
              {t("FritzHansen_Project_ConsumersAndProfessionals_PLP_FilterBy")}
            </p>
          </li>
        
          {facetList?.map((item, index) => {
            return (
              <Fragment key={`filter${index}`}>
                {index < 3 && (
                  <li key={item.facetId}>
                    <button
                      className={`product-filters-block__button ${
                        item.facetId === activeFacetId ? "is-active-fake" : ""
                      }`}
                      onClick={() => moreFiltersHandler(item.facetId)}
                    >
                      <span className="text">{item.name}</span>
                    </button>
                  </li>
                )}
              </Fragment>
            );
          })}
          {facetList?.length > 3 && (
            <li>
              <button
                className="product-filters-block__button d-n d-if-vp4"
                onClick={() => moreFiltersHandler(null)}
              >
                <span>
                  <PlusSvg />
                </span>
                <span>
                  {t(
                    "FritzHansen_Project_ConsumersAndProfessionals_PLP_MoreFilters"
                  )}
                </span>
              </button>
            </li>
          )}        
      </ul>)}
      <span className="product-filters-block__select d-n d-if-vp4">
        {/* <Select {...fields.sorting} changeHandler={sortingChangeHandler} /> */}
      </span>
    </div>
  );
};

export default ProductFiltersBlock;
