import React from "react";
import "./icons.scss";
export default ({ className, isInverted }) => (
  <svg
    className={`svg-icon svg-icon--chevron ${className || ""} ${
      isInverted ? "is-inverted" : ""
    }`}
    width="22"
    height="19"
    viewBox="0 0 22 11"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.947 10.186L20.54.593l.707.707-10.3 10.3L.647 1.3l.707-.707 9.593 9.593z"
    />
  </svg>
);
