import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { loader } from "graphql.macro";
import { useQuery } from "react-apollo";

import MiniBasketProduct from "../MiniBasketProduct";
import MiniBasketCalculation from "../MiniBasketCalculation";
import ActionButton from "@components/ActionButton";

import { PlusSvg, CheckmarkLargeSvg } from "@assets/js/icons";
import useBasketStore from "@store/basket";
import useGlobalStore from "@store/global";
import useHeaderStore from "@store/header";
import { SITE_MODE, OVERLAY } from "@constants";
// import InputField from "../../forms/InputField";

import "./mini-basket.scss";
// import { set } from "immer/dist/internal";

const checkoutStartQuery = loader("./checkoutStartQuery.graphql");

const MiniBasketController = ({ noFooter }) => {
  // const loading = useBasketStore((store) => store.loading);
  const lineItems = useBasketStore((state) => state.lineItems);
  const priceView = useBasketStore((state) => state.priceView);
  const hasProducts = useBasketStore((state) => state.hasProducts);
  const basketId = useBasketStore((state) => state.basketId);
  const setMenuOverlayActive = useHeaderStore(
    (state) => state.setMenuOverlayActive
  );

  const submitVoucher = useBasketStore((store) => store.submitVoucher);

  //const basketData = useBasketStore((store) => store.basketData);
  const { t } = useTranslation();

  const texts = {
    goToCheckout: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Cart_GoToCheckout"
    ),
    cartHeadline: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Cart_Headline"
    ),
    cartIsEmpty: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Cart_CartIsEmpty"
    ),
    noProdsAddedText: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Cart_NoProdsAdded"
    ),
    addVoucherCode: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Cart_AddVoucher"
    ),
    assembly: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Cart_AssemblyUponDelivery"
    ),
    warranty: t("FritzHansen_Project_ConsumersAndProfessionals_Cart_Warranty"),
    easyReturns: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Cart_EasyReturns"
    ),
    apply: t("FritzHansen_Project_ConsumersAndProfessionals_Cart_Apply"),
    voucherConfirmed: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Cart_VoucherConfirmed"
    ),
    voucherError: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Cart_VoucherError"
    ),
  };
  const VOUCHER_STEP = {
    AVAILABLE: "AVAILABLE",
    INPUT: "INPUT",
    ERROR: "ERROR",
    SUCCESS: "SUCCESS",
  };

  const [voucherHandlerStep, setVoucherHandlerStep] = useState(
    priceView?.discountTotal > 0 ? VOUCHER_STEP.SUCCESS : VOUCHER_STEP.AVAILABLE
  );

  const [voucherText, setVoucherText] = useState("");

  const mode = useGlobalStore((state) => state.currentSiteMode);
  const menuId =
    mode === SITE_MODE.RESIDENTIAL
      ? process.env.REACT_APP_RESIDENTIAL_DESKTOP_ID
      : process.env.REACT_APP_PROFESSIONAL_DESKTOP_ID;

  const { data } = useQuery(checkoutStartQuery, {
    variables: { menuId },
    errorPolicy: "all",
  });

  const showVoucherInput = () => {
    setVoucherHandlerStep(VOUCHER_STEP.INPUT);
  };
  const voucherInputHandler = (val) => {
    setVoucherText(val);
    setVoucherHandlerStep(VOUCHER_STEP.INPUT);
  };
  const voucherSubmitHandler = (event) => {
    event.stopPropagation();
    event.preventDefault();

    submitVoucher(
      { basketId: basketId, voucherCode: voucherText },
      voucherErrorHandler,
      voucherSuccessHandler
    );
  };

  const voucherErrorHandler = (event) => {
    setVoucherHandlerStep(VOUCHER_STEP.ERROR);
  };
  const voucherSuccessHandler = (event) => {
    setVoucherHandlerStep(VOUCHER_STEP.SUCCESS);
  };
  const voucherClickHandler = (event) => {
    event.stopPropagation();
    event.preventDefault();
  };
  const voucherKeyDownHandler = (event) => {
    if (event.key === "Enter") {
      voucherSubmitHandler(event);
    }
  };

  const basketClickHandler = (event) => {
    if (
      voucherHandlerStep === VOUCHER_STEP.INPUT ||
      voucherHandlerStep === VOUCHER_STEP.ERROR
    )
      setVoucherHandlerStep(VOUCHER_STEP.AVAILABLE);
  };

  const checkoutStartHandler = () => {
    if (data && data?.menu?.checkoutStartLink?.jss?.value?.href) {
      // window.location = data.menu.checkoutStartLink.jss.value.href;
      setMenuOverlayActive(OVERLAY.NONE);
    }
  };

  useEffect(() => {
    if (
      priceView?.discountTotal === 0 &&
      voucherHandlerStep === VOUCHER_STEP.SUCCESS
    )
      setVoucherHandlerStep(VOUCHER_STEP.AVAILABLE);
  }, [priceView, VOUCHER_STEP]);

  ////////////////////////////////////////////////////////////////////////////////

  return (
    <>
      <div className="mini-basket" onClick={basketClickHandler}>
        <div
          className={`mini-basket__headline ${
            !hasProducts ? "mini-basket__headline--no-products" : ""
          }`}
        >
          {hasProducts && <h2>{texts.cartHeadline}</h2>}
          {!hasProducts && (
            <>
              <h2>{texts.cartIsEmpty}</h2>
              <p>{texts.noProdsAddedText}</p>
            </>
          )}
        </div>
        {hasProducts && (
          <div className="mini-basket__products">
            {lineItems &&
              lineItems.map((item, index) => {
                return (
                  <MiniBasketProduct
                    key={`product${index}`}
                    {...item}
                    currency={priceView.currency}
                    basketId={basketId}
                  />
                );
              })}
          </div>
        )}
        {/* Waiting for data for the addon part
        <div className="mini-basket__addon">
          <MiniBasketAddon fields={fields.addon.fields} />
      </div> */}
        {hasProducts && (
          <div className="mini-basket__calculation">
            <MiniBasketCalculation {...priceView} />
          </div>
        )}
        {hasProducts && (
          <div className="mini-basket__cta-row">
            {voucherHandlerStep === VOUCHER_STEP.AVAILABLE && (
              <button className="icon-link" onClick={showVoucherInput}>
                <PlusSvg />
                {texts.addVoucherCode}
              </button>
            )}

            {(voucherHandlerStep === VOUCHER_STEP.INPUT ||
              voucherHandlerStep === VOUCHER_STEP.ERROR) && (
              <div className="voucher">
                <div className="voucher__input" onClick={voucherClickHandler}>
                  <input
                    type="text"
                    autoFocus
                    onChange={(event) => {
                      voucherInputHandler(event.target.value);
                    }}
                    onKeyDown={(e) => voucherKeyDownHandler(e)}
                  />
                  <button type="submit" onClick={voucherSubmitHandler}>
                    {texts.apply}
                  </button>
                </div>
                {voucherHandlerStep === VOUCHER_STEP.ERROR && (
                  <p>{texts.voucherError}</p>
                )}
              </div>
            )}

            {voucherHandlerStep === VOUCHER_STEP.SUCCESS && (
              <button className="icon-link">
                <CheckmarkLargeSvg />
                {texts.voucherConfirmed}
              </button>
            )}

            {data?.menu?.checkoutStartLink?.jss?.value?.href && (
              <ActionButton
                href={data.menu.checkoutStartLink.jss.value.href}
                clickHandler={checkoutStartHandler}
                isInverted
                buttonType="solid"
                text={texts.goToCheckout}
                hasArrow={true}
              />
            )}
          </div>
        )}
        {!noFooter && (
          <div className="mini-basket__footer">
            <nav className="mini-basket__usp">
              <span className="mini-basket__usp-item">{texts.assembly}</span>
              <span className="mini-basket__usp-item-separator">|</span>
              <span className="mini-basket__usp-item">{texts.warranty}</span>
              <span className="mini-basket__usp-item-separator">|</span>
              <span className="mini-basket__usp-item">{texts.easyReturns}</span>
            </nav>
          </div>
        )}
      </div>
    </>
  );
};

export default MiniBasketController;
