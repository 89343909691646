import MediaFocal from "./media-focal";

export default {
  default: {
    fields: {
      images: [MediaFocal.default, MediaFocal.default, MediaFocal.default],
      noParse: true,
      media: MediaFocal.default,
    },
  },
};
