import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import classnames from "classnames";
import NavButton from "../NavButton";
import { useTranslation } from "react-i18next";

import "./newsletter-signup.scss";

const NewsletterSignUp = ({ fields }) => {
  const { t } = useTranslation();
  const classList = classnames({
    "newsletter-signup": true,
    "newsletter-signup--inverted": fields?.isInverted?.value,
  });

  const button = {
    fields: {
      hasArrow: {
        value: true,
      },
      isInverted: {
        value: true,
      },
      style: {
        value: "link",
      },
      link: {
        value: {
          href: "http://eepurl.com/hnr0bX",
          text: t(
            "FritzHansen_Project_ConsumersAndProfessionals_NewsletterButton"
          ),
          linktype: "external",
          anchor: "anchor",
          querystring: "querystring",
          id: "signup",
        },
      },
    },
  };

  return (
    <div className={classList}>
      {fields?.headline?.value && (
        <Text
          tag="h2"
          className="newsletter-signup__headline"
          field={fields?.headline}
        />
      )}
      {fields?.bodyText?.value && (
        <Text
          tag="p"
          className="newsletter-signup__bodytext"
          field={fields?.bodyText}
        />
      )}
      <div className="newsletter-signup__button">
        <NavButton fields={button.fields} />
      </div>
    </div>
  );
};

export default NewsletterSignUp;
