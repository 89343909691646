export default {
  default: {
    fields: {
      noParse: true,
      headline: "FAQ",
      bodyText: "This is optional bodytext",
      // handleAllButton: false, // we may want to use this later
      items: [
        {
          noParse: true,
          fields: {
            noParse: true,
            headline: "Headline",
            bodyText:
              "The Series 7™ chair is an icon in modern furniture history, designed by Arne Jacobsen in 1955. Its unique shape is timeless and incredibly versatile, displaying character without overpowering the eye. ",
          },
          noParse: true,
          id: "1",
          expanded: false,
        },
        {
          noParse: true,
          fields: {
            noParse: true,
            headline: "Product Details",

            bodyText:
              "The Series 7™ chair is an icon in modern furniture history, designed by Arne Jacobsen in 1955. Its unique shape is timeless and incredibly versatile, displaying character without overpowering the eye. ",
          },
          noParse: true,
          id: "2",
          expanded: false,
        },
      ],
    },
    itemsShown: {
      value: 5,
    },
    loadMoreButtonText: {
      value: "load more",
    },
  },
};
