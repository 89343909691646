import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import cuid from "cuid";
import axios from "axios";

import { ArrowSquareSvg, MagnifierSvg, HeartSmallSvg } from "@assets/js/icons";
import PDPProductImages from "../PDPProductImages";
import { renderedImagePath } from "../../ProductImage";

import {
  PORTFOLIO_TYPE,
  OVERLAY,
  SITE_MODE,
  DEFAULT_TRANSITION,
  CONFIGURATOR_MODE,
} from "@constants";

import {
  useActiveConfigurationSKU,
  useConfiguratorMode,
  useActiveProductData,
  usePendingProductData,
  useActiveModelData,
} from "@store/pdp";
import { useSubmissionMode, useSubmissionContent } from "@store/portfolio";

import useHeaderStore from "@store/header";
import { useCurrentSiteMode } from "@store/global";

import "./pdp-product-viewer.scss";

const PDPProductViewer = (props) => {
  const { t } = useTranslation();
  const texts = {
    addToWishList: t("FritzHansen_Project_ConsumersAndProfessionals_PDP_AddToWishlist"),
    clickToZoom: t("FritzHansen_Project_ConsumersAndProfessionals_PDP_ClickToZoom"),
    dragToRotate: t("FritzHansen_Project_ConsumersAndProfessionals_PDP_DragToRotate"),
  };

  const [configuratorMode] = useConfiguratorMode();
  const [currentSiteMode] = useCurrentSiteMode();

  const [activeConfigurationSKU] = useActiveConfigurationSKU();
  const [activeProductData] = useActiveProductData();
  // const [pendingProductData, setPendingProductData] = usePendingProductData();

  const [activeModelData] = useActiveModelData();

  const [, setSubmissionMode] = useSubmissionMode();
  const [, setSubmissionContent] = useSubmissionContent();
  const setMenuOverlayActive = useHeaderStore((store) => store.setMenuOverlayActive);

  const wishListClickHandler = () => {
    let content = {
      id: cuid(),
      imageSrcHigh:
        configuratorMode === CONFIGURATOR_MODE.NONE
          ? activeProductData.imageUrl
          : renderedImagePath({
              imageId: activeConfigurationSKU,
              sw: 300,
            }),
      imageSrcLow:
        configuratorMode === CONFIGURATOR_MODE.NONE
          ? activeProductData.imageUrl
          : renderedImagePath({
              imageId: activeConfigurationSKU,
              sw: 50,
            }),
      imageWidth: 300,
      imageHeight: 300,

      portfolioItem: {
        productId: activeConfigurationSKU,
        modelTypeId: activeProductData.model.typeId,
        quantity: 1,
      },
    };

    setSubmissionContent(content);
    setSubmissionMode(PORTFOLIO_TYPE.PRODUCT);
    setMenuOverlayActive(OVERLAY.PORTFOLIO_SUBMISSION);
  };

  //animation presets
  const viewerAnimationVariants = {
    initial: {
      opacity: 0,
      //   height: '100%'
    },
    animate: {
      opacity: 1,
      transition: DEFAULT_TRANSITION,
    },
  };

  useEffect(() => {}, [activeConfigurationSKU]);

  return (
    <div className="pdp-product-viewer" data-active-sku={activeConfigurationSKU}>
      <div className="pdp-product-viewer__inner">
        {configuratorMode === CONFIGURATOR_MODE.NONE ? (
          <div className="pdp-product-viewer__simple">
            <div className="pdp-product-images">
              {activeProductData && activeProductData.imageUrl && (
                <img src={activeProductData.imageUrl} />
              )}
            </div>
          </div>
        ) : (
          <PDPProductImages />
        )}
      </div>

      {currentSiteMode === SITE_MODE.RESIDENTIAL && (
        <button className="basic-link" onClick={wishListClickHandler}>
          <span className="icon">
            <HeartSmallSvg fillHex="transparent" strokeHex="#000" />
          </span>
          <span className="text">{texts.addToWishList}</span>
        </button>
      )}
    </div>
  );
};
PDPProductViewer.propTypes = {};

export default PDPProductViewer;
