import React, { useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import Select from "../forms/Select";

import cuid from "cuid";

import "./contact-list.scss";

const ContactList = ({ fields }) => {
  const { t } = useTranslation();

  const [chosenCountry, setChosenCountry] = useState("0");

  let countries = [];
  if (fields.countries) {
    fields.countries.map((countrySelector) => {
      if (countrySelector.fields.name) {
        countries.push({
          optionLabel: countrySelector.fields.name.value,
          optionValue: countrySelector.fields.name.value,
        });
      }
    });
  }

  return (
    <>
      {fields && (
        <div className="contact-list">
          {fields?.headline && (
            <div className="contact-list__headline">
              <Text tag="h2" field={fields?.headline} />
            </div>
          )}

          <div className="contact-list__select">
            <Select
              id="contacts"
              defaultLabel="All countries"
              name="contact"
              options={fields?.options ? fields?.options : countries}
              changeHandler={(event) => {
                setChosenCountry(event);
              }}
            />
          </div>

          <div className="contact-list__list-wrapper">
            {fields?.countries?.map((country) => {
              return (
                <Fragment key={cuid()}>
                  {chosenCountry == "0" && (
                    <>
                      <div className="contact-list__list-headline">
                        <h2>{country.fields?.name?.value}</h2>
                      </div>

                      {country?.fields?.persons?.map((person) => {
                        return (
                          <Fragment key={cuid()}>
                            <ul className="contact-list__ul">
                              <li>{person?.fields?.name?.value}</li>
                              <li>{person?.fields?.area?.value}</li>
                              <li>
                                <a href={`mailto:${person?.fields?.email?.value}`}>
                                  {person.fields?.email?.value}
                                </a>
                              </li>
                            </ul>
                          </Fragment>
                        );
                      })}
                    </>
                  )}
                  {chosenCountry == country?.fields?.name?.value && (
                    <>
                      <div className="contact-list__list-headline">
                        <h2>{country.fields?.name?.value}</h2>
                      </div>

                      {country?.fields?.persons?.map((person) => {
                        return (
                          <Fragment key={cuid()}>
                            <ul className="contact-list__ul">
                              <li>{person.fields.name.value}</li>
                              <li>{person.fields.area.value}</li>
                              <li>{person.fields.email.value}</li>
                            </ul>
                          </Fragment>
                        );
                      })}
                    </>
                  )}
                </Fragment>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default ContactList;
