import create from "zustand";
import { devtools } from "zustand/middleware";

import axios from "axios";
import basketStore from "@store/basket";
import globalStore from "@store/global";

import globalErrorHandler from "@assets/js/global-error-handler";
import { log } from "@assets/js/utils";
import { STATUS } from "@constants";

const getShippingMethodsUrl = `${process.env.REACT_APP_ROOTAPI}checkout/getshippingmethods`;
const getCheckoutInfoDataUrl = `${process.env.REACT_APP_ROOTAPI}checkout/info/`;
const submitAddressesUrl = `${process.env.REACT_APP_ROOTAPI}checkout/addresses/`;
const submitShippingUrl = `${process.env.REACT_APP_ROOTAPI}checkout/shipping/`;
const initiatePaymentUrl = `${process.env.REACT_APP_ROOTAPI}checkout/initiatepayment/`;
const getOrderDetailsUrl = `${process.env.REACT_APP_ROOTAPI}order/details/`;

const store = create(
  devtools(
    (set, get) => ({
      loadStatus: STATUS.LOADING_IDLE,

      countriesList: [],
      setCountriesList: (data) => set({ countriesList: data }),

      checkoutInfoData: {},
      setCheckoutInfoData: (data) => set({ checkoutInfoData: data }),

      shippingMethods: null,
      setShippingMethods: (data) => set({ shippingMethods: data }),

      basketData: {},
      setBasketData: (data) => set({ basketData: data }),

      orderDetailsData: {},
      setOrderDetailsData: (data) => set({ orderDetailsData: data }),

      currentStepNumber: null,
      setCurrentStepNumber: (currentStepNumber) => set({ currentStepNumber }),

      // ####################################################################################

      fetchCheckoutInfoData: async (basketId) => {
        set({ loadStatus: STATUS.LOADING });

        let fetchUrl = `${getCheckoutInfoDataUrl}${basketId}`;
        const response = await axios
          .get(fetchUrl)
          .catch((error) => globalErrorHandler(error, "CHECKOUT INFO"));

        if (response) {
          set({
            checkoutInfoData: response.data,
            loadStatus: STATUS.READY,
          });
        }
      },

      submitAddresses: async (
        postData,
        basketId,
        nextStepCallBack,
        errorHandler
      ) => {
        set({ loadStatus: STATUS.LOADING });

        let fetchUrl = `${submitAddressesUrl}${basketId}`;
        const response = await axios
          .post(fetchUrl, postData)
          .catch((error) => globalErrorHandler(error, "CHECKOUT SUBMIT"));

        if (response) {
          if (
            response?.data?.ValidationErrors?.length > 0 ||
            response.status !== 200
          ) {
            if (errorHandler) errorHandler(response.data.ValidationErrors);
          } else {
            nextStepCallBack();
          }
        }
      },

      fetchShipmentMethods: async (basketId) => {
        set({ loadStatus: STATUS.LOADING });

        const { commerceMarket } = globalStore.getState();

        let fetchUrl = `${getShippingMethodsUrl}?marketCountry=${commerceMarket}&basketId=${basketId}`;
        const response = await axios
          .post(fetchUrl, {})
          .catch((error) => globalErrorHandler(error, "CHECKOUT GET SHIPPING"));

        if (response) {
          set({
            loadStatus: STATUS.READY,
            shippingMethods: response.data,
          });
        }
      },

      submitDeliveryMethod: async (methodId, basketId) => {
        set({ loadStatus: STATUS.LOADING });
        log("methodId", methodId);

        //revideres?
        let fetchUrl = `${submitShippingUrl}${basketId}/?shippingMethodId=${methodId}`;

        const response = await axios
          .post(fetchUrl, {})
          .catch((error) => globalErrorHandler(error, "CHECKOUT SET SHIPPING"));

        if (response) {
          const { commerceMarket } = globalStore.getState();
          const { getBasket } = basketStore.getState();

          //to get updated prices
          getBasket(commerceMarket, basketId);

          // log({ response });

          //HANDLE SUCCESSFUL RESPONSE
          set({
            checkoutInfoData: response.data,
            loadStatus: STATUS.READY,
          });
        }
      },

      checkForInitiatePayment: async (
        newsletterAccepted,
        termsAccepted,
        basketId
      ) => {
        set({ loadStatus: STATUS.LOADING });

        log(
          "newsletterAccepted",
          newsletterAccepted,
          "termsAccepted",
          termsAccepted
        );
        let fetchUrl = `${initiatePaymentUrl}${basketId}/?newsletter=${newsletterAccepted}&acceptTerms=${termsAccepted}`;
        const response = await axios
          .post(fetchUrl, {})
          .catch((error) =>
            globalErrorHandler(error, "CHECKOUT INITIATE PAYMENT")
          );

        if (response) {
          //HANDLE SUCCESSFUL RESPONSE
          const nextStepUrl = response?.data?.PaymentUrl;
          // log("nextStepUrl", nextStepUrl);
          // alert(nextStepUrl);

          if (nextStepUrl) {
            log("GO TO " + nextStepUrl);
            window.location = nextStepUrl;
          }

          set({
            loadStatus: STATUS.READY,
          });
        }
      },

      /**
       *
       * @param {string} orderId
       * @param {object} marketInfo
       */
      fetchOrderDetailsData: async (orderId, marketInfo) => {
        set({ loadStatus: STATUS.LOADING });

        let fetchUrl = `${getOrderDetailsUrl}${orderId}`;
        const response = await axios
          .get(fetchUrl)
          .catch((error) =>
            globalErrorHandler(error, "CHECKOUT ORDER DETAILS")
          );

        if (response) {
          // const response = { data: detailsJSON };
          set(orderDetailsDataProxy(response, marketInfo));
        }
      },

      checkPostalCode: async (postalCode, callback) => {
        set({ loadStatus: STATUS.LOADING });

        let fetchUrl = `https://dawa.aws.dk/postnumre/autocomplete?nr=${postalCode}`;
        const postalResponse = await axios
          .get(fetchUrl)
          .catch((error) =>
            globalErrorHandler(error, "POSTNUMMER IKKE FUNDET")
          );
        if (postalResponse) {
          const city =
            postalResponse?.data?.length > 0
              ? postalResponse.data[0].postnummer.navn
              : null;

          callback && callback(city);

          set({
            loadStatus: STATUS.READY,
          });
        }
      },
    }),
    "Checkout"
  )
);

// ####################################################################################
// ####################################################################################
// ####################################################################################

export const useCountriesList = () => [
  store((store) => store.countriesList),
  store((store) => store.setCountriesList),
];

export const useCheckoutInfoData = () => [
  store((store) => store.checkoutInfoData),
  store((store) => store.setCheckoutInfoData),
  store((store) => store.fetchCheckoutInfoData),
];

export const useOrderDetailsData = () => [
  store((store) => store.orderDetailsData),
  store((store) => store.setOrderDetailsData),
  store((store) => store.fetchOrderDetailsData),
];

export const useShippingMethods = () => [
  store((store) => store.shippingMethods),
  store((store) => store.setShippingMethods),
  store((store) => store.fetchShipmentMethods),
];

export default store;

// ####################################################################################
// ####################################################################################
// ####################################################################################

const orderDetailsDataProxy = (response, marketInfo) => {
  const data = response.data;
  const billingAddress = data.Info.BillingAddress;
  const shipmentAddress = data.Info.ShipmentAddress;

  const billingCountryId = billingAddress.CountryId;
  const shipmentCountryId = shipmentAddress.CountryId;

  const markets = marketInfo?.markets?.values?.filter(
    (item) => item.marketSupportsEcom.value === true
  );
  const countriesList = markets.map((item) => {
    return {
      id: item?.commerceMarketId?.value,
      optionLabel: item?.countryName?.value,
      optionValue: item?.commerceMarket?.value,
    };
  });

  const billingCountry = countriesList.filter(
    (c) => Number(c.id) === billingCountryId
  )[0].optionLabel;
  const shipmentCountry = countriesList.filter(
    (c) => Number(c.id) === shipmentCountryId
  )[0].optionLabel;

  let details = {};
  details.statusBar = {};
  details.infoPanels = [
    {
      texts: [
        {
          title: {
            translationKey:
              "FritzHansen_Project_ConsumersAndProfessionals_Order_OrderNumber",
          },
          content: { value: data.Basket.OrderId },
        },
        {
          title: {
            translationKey:
              "FritzHansen_Project_ConsumersAndProfessionals_Order_ShippingMethod",
          },
          content: {
            value: data.Info.ShippingMethodName || data.Info.ShippingMethodId,
          },
        },
        // { title: { value: "Estimated delivery date" }, content: { value: "_MISSING_VALUE_" } },
        // { title: { value: "Order date" }, content: { value: "_MISSING_VALUE_" } },
        // { title: { value: "Payment method" }, content: { value: "_MISSING_VALUE_" } },
      ],
    },
    {
      texts: [
        {
          title: {
            translationKey:
              "FritzHansen_Project_ConsumersAndProfessionals_Order_ContactInformation",
          },
          content: {
            value: `${billingAddress.FirstName} ${billingAddress.LastName}\n${billingAddress.EmailAddress}\n${billingAddress.MobilePhoneNumber}`,
          },
        },
        {
          title: {
            translationKey:
              "FritzHansen_Project_ConsumersAndProfessionals_Order_ShippingAddress",
          },
          content: {
            value: `${shipmentAddress.FirstName} ${shipmentAddress.LastName}\n${shipmentAddress.Line1}\n${shipmentAddress.PostalCode} ${shipmentAddress.City}\n${shipmentCountry}`,
          },
        },
        {
          title: {
            translationKey:
              "FritzHansen_Project_ConsumersAndProfessionals_Order_BillingAddress",
          },
          content: {
            value: `${billingAddress.Line1}\n${billingAddress.PostalCode} ${billingAddress.City}\n${billingCountry}`,
          },
        },
      ],
    },
  ];

  details.productLines = data.Basket.LineItems;

  details.calculation = {
    TotalWithoutDiscount: data.Basket.TotalWithoutDiscount,
    DiscountTotal: data.Basket.DiscountTotal,
    SubTotal: data.Basket.SubTotal,
    TaxTotal: data.Basket.TaxTotal,
    OrderTotal: data.Basket.OrderTotal,
    ShippingTotal: data.Basket.ShippingTotal,
    Vat: data.Basket.Vat,
    Currency: data.Basket.Currency,
  };

  details.postalCode = shipmentAddress.PostalCode;

  return {
    orderDetailsData: details,
    loadStatus: STATUS.READY,
  };
};
