import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import "./loading-dots.scss";
const LoadingDots = ({ isInverted }) => {
  const css = classnames({
    "loading-dots": true,
    "loading-dots--inverted": isInverted,
  });
  return (
    <div className={css}>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
};
LoadingDots.propTypes = {
  isInverted: PropTypes.bool,
};

export default LoadingDots;
