import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { LINKTYPE, OVERLAY } from "@constants";
import useHeaderStore from "@store/header";

const LinkWrapper = ({ field, children, clickHandler }) => {
  const setMenuOverlayActive = useHeaderStore(
    (state) => state.setMenuOverlayActive
  );

  const link = field?.link;
  const linkType = link?.value?.linktype?.toUpperCase() || LINKTYPE.INTERNAL;
  const actionLink = field?.command;
  const onActionLinkClick = () => {
    if (linkType === LINKTYPE.JAVASCRIPT && link.value?.href) {
      link.value.href();
    }
    if (
      actionLink &&
      Object.values(OVERLAY).includes(actionLink.value.toUpperCase())
    ) {
      setMenuOverlayActive(actionLink.value.toUpperCase());
    }
  };
  return (
    <>
      {link && linkType === LINKTYPE.INTERNAL && (
        <NavLink to={link.value.href}>{children}</NavLink>
      )}
      {link && (linkType === LINKTYPE.EXTERNAL || linkType === LINKTYPE.MAILTO) && (
        <a href={link.value.href}>{children}</a>
      )}
      {actionLink && <button onClick={onActionLinkClick}>{children}</button>}
      {!link && !actionLink && <>{children}</>}
    </>
  );
};

LinkWrapper.propTypes = {
  field: PropTypes.object.isRequired,
  clickHandler: PropTypes.func,
};

export default LinkWrapper;
