import React from "react";
import { Text, Image } from "@sitecore-jss/sitecore-jss-react";
import FooterLogo from "../FooterLogo";

import "./checkout-footer.scss";
import TrustPilotScript from "../TrustPilotScript";
import { NavLink } from "react-router-dom";
const CheckoutFooter = ({ fields }) => {
  return (
    <>
      {fields && (
        <footer className="checkout-footer">
          <div className="checkout-footer__container">
            <TrustPilotScript />
            <div className="checkout-footer__image">
              {fields?.data?.fields?.media && (
                <Image
                  field={fields?.data?.fields?.media?.value?.fields?.image}
                />
              )}
            </div>
            <div className="checkout-footer__main">
              <div className="checkout-footer__headline">
                <Text tag="h2" field={fields?.data?.fields?.bodyText} />
              </div>

              <ul className="checkout-footer__links">
                {fields.data?.fields?.links?.values.map((item, i) => {
                  return (
                    <li
                      className="checkout-footer__link-items"
                      key={`${item.url}-${i}`}
                    >
                      <NavLink to={item.url}>{item.name}</NavLink>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="checkout-footer__footer-logo">
              <FooterLogo />
            </div>
          </div>
        </footer>
      )}
    </>
  );
};
CheckoutFooter.propTypes = {};

export default CheckoutFooter;
