import TagManager from "react-gtm-module";

export default (obj) => {
  TagManager.dataLayer({ dataLayer: obj });
};
/**
 *
 * @param {*} event
 * @param {*} ecommerce
 */
export const dataLayerCommercePush = (event = null, ecommerce) => {
  TagManager.dataLayer({ dataLayer: { ecommerce: null } });
  TagManager.dataLayer({
    dataLayer: {
      event,
      ecommerce,
    },
  });
};

/**
 *
 * @param {Obj} params currencyCode|stepNum|basketLineItems
 * @returns GTM tracking event signature
 */
export const checkoutSignature = ({
  currencyCode,
  stepNum,
  basketLineItems,
}) => ({
  currencyCode,
  checkout: {
    actionField: {
      step: stepNum + 1,
    },
    products: basketLineItems.map((p) =>
      Object.assign(
        {},
        {
          name: p.ModelGroupName,
          id: p.FriendlyName,
          price: p.Price,
          brand: "",
          category: "",
          variant: p.ModelName,
          quantity: p.Quantity,
        },
        ...(p.Materials?.length
          ? p.Materials.map((mat, index) => ({
              [`dimension${index + 1}`]: mat.Id,
            }))
          : [{ dimension1: "not set" }])
      )
    ),
  },
});
/**
 *
 * @param {*} productData
 * @returns GTM product signature
 */
export const productSignature = (productData) =>
  Object.assign(
    {},
    {
      name: productData?.model?.groupId,
      id: productData?.model?.configurationId,
      price: "",
      brand: "",
      category: "",
      variant: productData?.model?.label,
    },
    ...(productData?.materials
      ? productData?.materials.map((mat, index) => ({
          [`dimension${index + 1}`]: mat.activeMaterial.materialId,
        }))
      : [{ dimension1: "not set" }])
  );
