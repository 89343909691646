import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import "./alta-pay-form.scss";
const AltaPayForm = (props) => {
  const { t } = useTranslation();
  return (
    <div className="pensio_payment_form_table">
      <form
        action="/eCommerce/API/reservationOfFixedAmount?pid=6653907a-3a4b-4a4f-a85c-ed56d4c34e87"
        method="post"
        id="CreditCard"
      >
        <div id="CreditCardError" style={{ display: "none" }}>
          asdfasdf
        </div>
        <input type="hidden" name="pid" value="6653907a-3a4b-4a4f-a85c-ed56d4c34e87" />
        <input type="hidden" name="type" value="payment" />
        <input type="hidden" name="browserIP" id="threeDS2browserIP" value="10.6.127.52" />
        <input
          type="hidden"
          name="browserJavaEnabled"
          id="threeDS2browserJavaEnabled"
          value="false"
        />
        <input type="hidden" name="browserLanguage" id="threeDS2browserLanguage" value="en-US" />
        <input type="hidden" name="browserColorDepth" id="threeDS2browserColorDepth" value="24" />
        <input
          type="hidden"
          name="browserScreenHeight"
          id="threeDS2browserScreenHeight"
          value="800"
        />
        <input
          type="hidden"
          name="browserScreenWidth"
          id="threeDS2browserScreenWidth"
          value="1280"
        />
        <input type="hidden" name="browserTZ" id="threeDS2browserTZ" value="-60" />
        <input
          type="hidden"
          name="browserUserAgent"
          id="threeDS2browserUserAgent"
          value="Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/88.0.4324.192 Safari/537.36"
        />
        <input
          type="hidden"
          name="browserJavascriptEnabled"
          id="threeDS2browserJavascriptEnabled"
          value="true"
        />
        <table className="pensio_payment_form_table">
          <tbody>
            <tr>
              <td className="pensio_payment_form_label_cell" for="creditCardNumberInput">
                Card Number
              </td>
              <td class="pensio_payment_form_input_cell" valign="middle">
                <input
                  type="tel"
                  maxlength="23"
                  name="cardnum"
                  autocomplete="off"
                  id="creditCardNumberInput"
                  tabindex="1"
                  class="pensioCreditCardInput"
                  value=""
                  autofocus=""
                />{" "}
                <img src="/images/blank.gif" id="creditCardTypeIcon" width="24" height="15" />
              </td>
            </tr>
            <tr>
              <td class="pensio_payment_form_label_cell">Expiry Date</td>
              <td class="pensio_payment_form_input_cell">
                <label
                  class="pensio_payment_form_label_cell"
                  for="emonth"
                  style={{
                    border: 0,
                    clip: "rect(0 0 0 0)",
                    height: "1px",
                    margin: "-1px",
                    overflow: "hidden",
                    padding: 0,
                    position: "absolute",
                    width: "1px",
                  }}
                >
                  Month
                </label>
                <select name="emonth" tabindex="2" id="emonth" class="pensioCreditCardInput">
                  <option value="01">01</option>
                  <option value="02" selected="selected">
                    02
                  </option>
                  <option value="03">03</option>
                  <option value="04">04</option>
                  <option value="05">05</option>
                  <option value="06">06</option>
                  <option value="07">07</option>
                  <option value="08">08</option>
                  <option value="09">09</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                </select>
                &nbsp;/&nbsp;
                <label
                  class="pensio_payment_form_label_cell"
                  for="eyear"
                  style={{
                    border: 0,
                    clip: "rect(0 0 0 0)",
                    height: "1px",
                    margin: "-1px",
                    overflow: "hidden",
                    padding: 0,
                    position: "absolute",
                    width: "1px",
                  }}
                >
                  Year
                </label>
                <select name="eyear" tabindex="3" id="eyear" class="pensioCreditCardInput">
                  <option value="2021" selected="selected">
                    2021
                  </option>
                  <option value="2022">2022</option>
                  <option value="2023">2023</option>
                  <option value="2024">2024</option>
                  <option value="2025">2025</option>
                  <option value="2026">2026</option>
                  <option value="2027">2027</option>
                  <option value="2028">2028</option>
                  <option value="2029">2029</option>
                  <option value="2030">2030</option>
                  <option value="2031">2031</option>
                  <option value="2032">2032</option>
                  <option value="2033">2033</option>
                  <option value="2034">2034</option>
                  <option value="2035">2035</option>
                  <option value="2036">2036</option>
                  <option value="2037">2037</option>
                  <option value="2038">2038</option>
                  <option value="2039">2039</option>
                  <option value="2040">2040</option>
                  <option value="2041">2041</option>
                  <option value="2042">2042</option>
                  <option value="2043">2043</option>
                  <option value="2044">2044</option>
                  <option value="2045">2045</option>
                  <option value="2046">2046</option>
                </select>
              </td>
            </tr>
            <tr>
              <td class="pensio_payment_form_label_cell">CVC / CVV2</td>
              <td class="pensio_payment_form_input_cell">
                <input
                  type="tel"
                  maxlength="4"
                  size="4"
                  name="cvc"
                  autocomplete="off"
                  id="cvcInput"
                  tabindex="4"
                  class="pensioCreditCardInput"
                  aria-describedby="invalid_cvc, invalid_amex_cvc"
                />
                <small>
                  <a
                    href="#"
                    onclick="window.open('/eCommerce/API/cvcPopup?pid=6653907a-3a4b-4a4f-a85c-ed56d4c34e87', 'CVC2Window', 'width=400, height=300, toolbar=no, location=no'); return false;"
                    title="What is the CVC / CVV2"
                    tabindex="5"
                  >
                    ?
                  </a>
                </small>
              </td>
            </tr>
            <tr>
              <td class="pensio_payment_form_cvc_error" colspan="2">
                <div id="invalid_amex_cvc" style={{ display: "none" }}>
                  Invalid Amex CVC/CVV2; Must be 4 digits. The CVC/CVV2 can be found on the front of
                  your Amex card
                </div>
                <div id="invalid_cvc" style={{ display: "none" }}>
                  Invalid CVC/CVV2; Must be 3 digits
                </div>
              </td>
            </tr>
            <tr>
              <td class="pensio_payment_form_label_cell" for="cardholderNameInput">
                Cardholder's name
              </td>
              <td class="pensio_payment_form_input_cell" valign="middle">
                <input
                  type="text"
                  maxlength="45"
                  name="cardholdername"
                  autocomplete="off"
                  id="cardholderNameInput"
                  tabindex="6"
                  class="pensioCreditCardInput"
                  value=""
                  td=""
                />
              </td>
            </tr>
            <tr>
              <td align="center" colspan="2" class="pensio_payment_form_submit_cell">
                <input
                  name="submitButton"
                  class="AltaPaySubmitButton"
                  type="submit"
                  value="Submit"
                  id="pensioCreditCardPaymentSubmitButton"
                  tabindex="7"
                  disabled="disabled"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>
  );
};
AltaPayForm.propTypes = {};

export default AltaPayForm;
