import MediaFocal from "./media-focal";
import NavButton from "./nav-button";
import ContactLinksGroup from "./contact-links-group";

export default {
  default: {
    fields: {
      headline: "Neque et temporibus sunt",
      bodyText: "Sit explicabo eum labore voluptatem, neque et temporibus sunt, culpa tempora ratione quasi nostrum.",
      href: "#panel",
      contact: ContactLinksGroup.default,
      button: {},
      isButtonEnabled: false,
      media: MediaFocal.default,
    },
  },
  noContact: {
    fields: {
      href: "#panel",
      headline: "Culpa tempora ratione quasi",
      bodyText: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit explicabo eum labore voluptatem, neque et temporibus sunt?",
      button: NavButton.default,
      isButtonEnabled: true,
      contact: {},
      media: MediaFocal.default,
    },
  },
  onlyText: {
    fields: {
      href: "#panel",
      headline: "Adipisicing elit",
      bodyText: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit explicabo eum labore voluptatem, neque et temporibus.",
      contact: {},
      button: {},
      isButtonEnabled: false,
      media: MediaFocal.default,
    },
  },
  notInteractive: {
    fields: {
      headline: "Neque et temporibus sunt",
      bodyText:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit explicabo eum labore voluptatem, neque et temporibus sunt, culpa tempora ratione quasi nostrum, nesciunt veniam veritatis minima laborum expedita reprehenderit autem?",
      contact: ContactLinksGroup.default,
      button: {},
      isButtonEnabled: false,
      media: MediaFocal.default,
    },
  },
};
