import create from "zustand";
import { devtools } from "zustand/middleware";
import axios from "axios";
import { STATUS, FILTER_NO_TYPE } from "@constants";
import path from "@assets/js/browser-path";
import globalStore from "@store/global";

export default create(
  devtools(
    (set) => ({
      loadStatus: STATUS.LOADING_IDLE,

      materialsList: [],
      setMaterialsList: (list) => set({ materialsList: list }),

      getMaterialSets: async (lang, taxonomy) => {
        set({ loadStatus: STATUS.LOADING });
        const sc_lang = globalStore.getState().sc_lang;

        let materialsPostData = {
          pageSize: 1000,
          onlyPrimaryMaterialsets: true,
          taxonomy: taxonomy
        };

        const response = await axios.post(
          path.join(process.env.REACT_APP_PRODUCTSAPI, "Material", sc_lang, "search"),
          materialsPostData
        );
        set(materialSetDataProxy(response));
      },
    }),
    "Materials"
  )
);

const materialSetDataProxy = (response) => {
  const items = response?.data?.response?.docs;

  const materialsList = items.map((item) => {
    let body = "";
    if ( item.MaterialSetShortDisplayDescription1_s ) body += item.MaterialSetShortDisplayDescription1_s;
    if ( item.MaterialSetShortDisplayDescription2_s ) body += "\n"+item.MaterialSetShortDisplayDescription2_s;
    return {
      materialSetTile: {
        fields: {
          href: item.urlSlug_s,
          firstMaterialId:
            item.materialIds_ss && item.materialIds_ss.length > 0 ? item.materialIds_ss[0] : "",
          headline: {
            value: item.MaterialSetDisplayName_s || item.MaterialSetId_s || null,
          },
          bodyText: {
            value:  body || null,
          },
          count: {
            value: item.materialIds_ss ? item.materialIds_ss.length + " variants" : "",
          },
          tag: { value: item.MaterialSetTypeNew_s || FILTER_NO_TYPE },
        },
      },
      tags: {
        values: [
          {
            title: { value: item.MaterialSetTypeNew_s || FILTER_NO_TYPE },
            id: { value: item.MaterialSetTypeNew_s || FILTER_NO_TYPE },
          },
        ],
      },
    };
  });

  return { materialsList, loadStatus: STATUS.READY };
};
