import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import classnames from "classnames";
import { SITE_MODE } from "@constants";
import useGlobalStore from "@store/global";
import useHeaderStore from "@store/header";
import { loader } from "graphql.macro";
import { useQuery } from "react-apollo";
import { getURLParams } from "@assets/js/url-params";
import { log } from "@assets/js/utils";

import "./mode-switch.scss";

const gqlQuery = loader("./modeSwitchQuery.graphql");

const ModeSwitch = (props) => {
  const menuOverlayActive = useHeaderStore((store) => store.menuOverlayActive);

  const currentSiteMode = useGlobalStore((store) => store.currentSiteMode);

  const setCurrentSiteMode = useGlobalStore((store) => store.setCurrentSiteMode);
  const location = useLocation();
  const { loading, error, data } = useQuery(gqlQuery, {
    variables: {
      contextItem: process.env.REACT_APP_QUERY_VARIABLES_MODE_SWITCH,
    },
    errorPolicy: "all",
  });

  const residentialButtonClasses = classnames({
    button: true,
    "is-inactive": currentSiteMode === SITE_MODE.RESIDENTIAL,
  });
  const professionalButtonClasses = classnames({
    button: true,
    "is-inactive": currentSiteMode === SITE_MODE.PROFESSIONAL,
  });
  const [cookies, setCookie] = useCookies([process.env.REACT_APP_QUERY_VARIABLES_MODE_COOKIE]);


  const dataMap = (key) =>
    `${key}${currentSiteMode === SITE_MODE.PROFESSIONAL ? "Professional" : "Residential"}`;

  const style = {
    "--c-s-mode-switch-bc": !loading && data.item ? data.item[dataMap("color")].value : false,
  };

  const switchMode = (mode) => {
    const qpMode = getURLParams();
    if (qpMode.has("mode") && mode === SITE_MODE.RESIDENTIAL) {
      qpMode.delete("mode");
      window.history.replaceState(
        null,
        "proff state",
        `${location.pathname}${qpMode.toString().length ? "?" + qpMode.toString() : ""}`
      );
    }
    if (!qpMode.has("mode") && mode === SITE_MODE.PROFESSIONAL) {
      qpMode.set("mode", SITE_MODE.PROFESSIONAL);
      window.history.replaceState(
        null,
        "proff state",
        `${location.pathname}${qpMode.toString().length ? "?" + qpMode.toString() : ""}`
      );
    }

    setCookie(process.env.REACT_APP_QUERY_VARIABLES_MODE_COOKIE, mode, {
      path: "/",
    });

    setCurrentSiteMode(mode);
  };

  useEffect(() => {
    const qP = getURLParams();

    const qPHasMode = qP.has(process.env.REACT_APP_QUERY_VARIABLES_MODE_QUERYPARAMETER);
    const qPMode = qPHasMode
      ? qP.get(process.env.REACT_APP_QUERY_VARIABLES_MODE_QUERYPARAMETER).toUpperCase()
      : null;
    const qPModeValid = Object.values(SITE_MODE).indexOf(qPMode) > -1;

    const cookieMode = cookies[process.env.REACT_APP_QUERY_VARIABLES_MODE_COOKIE];

    if (process.env.NODE_ENV === "development") {
      log("ModeSwitch | qPHasMode", qPHasMode);
      log("ModeSwitch | qPMode", qPMode);
      log("ModeSwitch | qPModeValid", qPModeValid);
      log("ModeSwitch | cookieMode", qPHasMode);
    }

    if (cookieMode && !qPHasMode) {
      setCurrentSiteMode(cookieMode.toUpperCase());
    }

    if (qP.has(process.env.REACT_APP_QUERY_VARIABLES_MODE_QUERYPARAMETER) && qPModeValid) {
      setCurrentSiteMode(qPMode);
      setCookie(process.env.REACT_APP_QUERY_VARIABLES_MODE_COOKIE, qPMode, {
        path: "/",
      });
    }
  }, []);

  return (
    <>
      {!loading && data.item && (
        <div className="mode-switch" style={style}>
          <div className="mode-switch__container">
            <p className="mode-switch__bodytext">
              <span className="animation--fade-in">{data.item[dataMap("text")].value}</span>
            </p>
            <div className="mode-switch__buttons">
              <button
                className={residentialButtonClasses}
                onClick={() => switchMode(SITE_MODE.RESIDENTIAL)}
              >
                {loading ? (
                  ""
                ) : (
                  <span className="animate--fade-in">{data.item.modeSwitchResidential.value}</span>
                )}
              </button>
              <button
                className={professionalButtonClasses}
                onClick={() => switchMode(SITE_MODE.PROFESSIONAL)}
              >
                {loading ? (
                  ""
                ) : (
                  <span className="animate--fade-in">{data.item.modeSwitchProfessional.value}</span>
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ModeSwitch;
