import React from "react";
import "./icons.scss";

export default ({ className, isInverted }) => (
  <svg
    className={`svg-icon svg-icon--basket ${className || ""} ${
      isInverted ? "is-inverted" : ""
    }`}
    width="15"
    height="19"
    viewBox="0 0 15 19"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M11 5V0H4V5H0V19H15V5H11ZM5 1H10V5H5V1ZM14 18H1V6H4V10H5V6H10V10H11V6H14V18Z" />
  </svg>
);
