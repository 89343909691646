import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import QuantitySelector from "../QuantitySelector";
import ProductImage from "../../ProductImage";

import getFormattedPrice from "@assets/js/get-formatted-price";
import shallow from "zustand/shallow";
import useBasketStore from "@store/basket";
import useStockStore from "@store/stock";

import useGlobalStore, { useCommerceMarket } from "@store/global";

import "./mini-basket-product.scss";

const MiniBasketProduct = ({
  basketId,
  Price,
  PriceExVat,
  Total,
  TotalExVat,
  TotalDiscount,
  ModelName,
  OrderLineId,
  FullFriendlyName,
  FriendlyName,
  ProductImageUrl,
  ModelGroupName,
  Materials,
  Quantity,
  ItemType,
  SkuVariant,
  Vat,

  currency = "DKK",
  editHandler = () => {},
  removeHandler = () => {},
}) => {
  const { t } = useTranslation();
  const setQuantity = useBasketStore((state) => state.setQuantity);
  const setRemoveLine = useBasketStore((state) => state.removeLine);

  const [commerceMarket] = useCommerceMarket();
  const [language, currentMarket] = useGlobalStore(
    (state) => [state.language, state.currentMarket],
    shallow
  );

  const stockList = useStockStore((state) => state.stockList);
  const fetchStockData = useStockStore((state) => state.fetchStockData);

  useEffect(() => {
    if (!stockList[FriendlyName]) {
      fetchStockData(commerceMarket, FriendlyName);
    }
  }, [stockList]);

  const texts = {
    model: t("FritzHansen_Project_ConsumersAndProfessionals_Cart_Model"),
    remove: t("FritzHansen_Project_ConsumersAndProfessionals_Cart_Remove"),
    edit: t("FritzHansen_Project_ConsumersAndProfessionals_Cart_Edit"),
    variants: t("FritzHansen_Project_ConsumersAndProfessionals_Cart_Variants"),
    estDelivery: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Cart_EstimatedDelivery"
    ),
    noDelivery: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Cart_NoDelivery"
    ),
    lineDiscount: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Cart_LineDiscount"
    ),
  };

  const removeProduct = (payload) => {
    setRemoveLine({
      ...payload,
      currency: currency,
      gtm: Object.assign(
        {},
        {
          name: ModelGroupName,
          id: FriendlyName,
          price: Price,
          brand: "",
          category: "",
          variant: ModelName,
        },
        ...Materials?.map((mat, index) => ({
          [`dimension${index + 1}`]: mat.Id,
        }))
      ),
    });
  };

  const changeQuantity = (value) => {
    if (value >= 1) {
      setQuantity({
        OrderLineId,
        basketId,
        Quantity: value,
      });
    } else {
      removeProduct({
        OrderLineId,
        basketId,
      });
    }
  };

  const getFormattedDeliveryTime = () => {
    if (stockList && stockList[FriendlyName]?.BestDeliveryDate) {
      return `${getFormattedDate(
        stockList[FriendlyName].BestDeliveryDate
      )} – ${getFormattedDate(stockList[FriendlyName].LatestDeliveryDate)}`;
    } else {
      return texts.noDelivery;
    }
  };

  const getFormattedDate = (isoDate) => {
    let date = new Date(isoDate),
      options = { year: "numeric", month: "numeric", day: "numeric" };
    return date.toLocaleDateString(
      `${language}-${commerceMarket.toUpperCase()}`,
      options
    );
  };

  return (
    <div className="mini-basket-product">
      <span className="mini-basket-product__image">
        <div className="product-image">
          <img src={ProductImageUrl} alt={ModelName} />
        </div>
      </span>

      <div className="mini-basket-product__links">
        <button
          className="mini-basket-product__link"
          onClick={() => removeProduct({ OrderLineId, basketId })}
        >
          <span>{texts.remove}</span>
        </button>
      </div>

      <div className="mini-basket-product__text">
        <h3>{ModelGroupName}</h3>
        <p>
          <strong>{texts.model}:</strong> {ModelName}
        </p>
        <p>
          <strong>{texts.variants}</strong>:{" "}
          {ItemType === 1 ? (
            <>
              <span>{SkuVariant?.Name}</span>
            </>
          ) : (
            <>
              {Materials.map((item, index) => (
                <span key={`prodMat${index}`}>
                  {item.Name}
                  {index <= Materials.length - 2 ? ", " : ""}
                </span>
              ))}
            </>
          )}
        </p>
        <p>
          <strong>{texts.estDelivery}</strong>:<br />
          {getFormattedDeliveryTime()}
        </p>
      </div>

      <div className="mini-basket-product__quantity">
        <QuantitySelector
          defaultValue={Quantity}
          min={0}
          max={40}
          changeHandler={changeQuantity}
        />
      </div>

      <div className="mini-basket-product__prices">
        {/*fields.value.priceOriginal && <h5>{fields.value.priceOriginal}</h5>*/}
        {Quantity === 1 ? (
          ""
        ) : (
          <p>
            {Quantity}&nbsp;&times;&nbsp;
            {getFormattedPrice(language, commerceMarket, currency, Price)}
          </p>
        )}
        {TotalDiscount === 0 ? (
          <h4>
            {getFormattedPrice(language, commerceMarket, currency, Total)}
          </h4>
        ) : (
          <>
            <h5>
              {getFormattedPrice(
                language,
                commerceMarket,
                currency,
                Total + TotalDiscount
              )}
            </h5>
            <p>
              {texts.lineDiscount} -{" "}
              {getFormattedPrice(
                language,
                commerceMarket,
                currency,
                TotalDiscount
              )}
            </p>
            <h4>
              {getFormattedPrice(language, commerceMarket, currency, Total)}
            </h4>
          </>
        )}
      </div>
    </div>
  );
};

MiniBasketProduct.propTypes = {
  basketId: PropTypes.string.isRequired,
  OrderLineId: PropTypes.number.isRequired,
  FullFriendlyName: PropTypes.string.isRequired,
  Price: PropTypes.number,
  ModelGroupName: PropTypes.string,
  Materials: PropTypes.array,
  Quantity: PropTypes.number,
  currency: PropTypes.string,
};

export default MiniBasketProduct;
