import React, { Fragment } from "react";
import uuid from "uuid";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { motion } from "framer-motion";
import TagList from "../TagList";

import classnames from "classnames";
import isTrue from "@assets/js/is-true";
import { defaultReveal } from "@assets/js/animation-variations";
import "./list-title.scss";

const ListTitle = ({ fields, tagList, tagUpdateHandler, rendering }) => {
  // const { uid } = rendering;
  const classList = classnames({
    "list-title": true,
    "list-title--inverted": isTrue(fields?.isInverted),
  });

  return (
    <>
      {fields && (
        <div className={classList} key={rendering?.uid || uuid()}>
          <div className="list-title__content">
            {fields.headline?.value && fields.headlineType?.value && (
              <motion.div
                className="list-title__headline"
                initial="initial"
                exit="exit"
                animate="enter"
                variants={defaultReveal}
                transition={{ ...defaultReveal.transition }}
              >
                <Text tag={fields.headlineType.value} field={fields.headline} />
              </motion.div>
            )}
            {fields.bodyText?.value && fields.bodyText.value.length > 0 && (
              <motion.div
                className="list-title__text"
                initial="initial"
                exit="exit"
                animate="enter"
                variants={defaultReveal}
                transition={{ ...defaultReveal.transition, delay: 0.3 }}
              >
                <Text tag="p" field={fields.bodyText} />
              </motion.div>
            )}
          </div>

          {tagList && (
            <TagList
              fields={tagList.fields}
              tagUpdateHandler={tagUpdateHandler && tagUpdateHandler}
            />
          )}
        </div>
      )}
    </>
  );
};

export default ListTitle;
