import React from "react";
import { useTranslation } from "react-i18next";
import shallow from "zustand/shallow";

import Collapsible from "../Collapsible";
import Checkbox from "../forms/Checkbox";
import ActionButton from "../ActionButton";

import urlParams from "@assets/js/url-params";
import { BUTTONSTYLE } from "@constants";
import { ChevronSvg } from "@assets/js/icons";

import usePLPStore, { useFacetList, useSortingCriteria, useGetProducts } from "@store/plp";
import useHeaderStore from "@store/header";
import { OVERLAY } from "@constants";

import "./product-filters-list.scss";

const ProductFiltersList = (props) => {

  const { t } = useTranslation();
  const texts = {
    clear: t("FritzHansen_Project_ConsumersAndProfessionals_PLP_ClearFilter"),
    see: t("FritzHansen_Project_ConsumersAndProfessionals_PLP_SeeFilter"),
  };

  const setMenuOverlayActive = useHeaderStore((store) => store.setMenuOverlayActive);
  const activeOverlay = useHeaderStore((store) => store.menuOverlayActive);
  const [facetList] = useFacetList();
  const [,] = useSortingCriteria();

  const [count, getAppliedFilters] = usePLPStore(
    (state) => [state.count, state.getAppliedFilters],
    shallow
  );

  const [
    activeFacetId,
    currentFilters,
    setCurrentFilters,
    setActiveFacetId,
    clearSearch,
  ] = usePLPStore(
    (state) => [
      state.activeFacetId,
      state.currentFilters,
      state.setCurrentFilters,
      state.setActiveFacetId,
      state.clearSearch,
    ],
    shallow
  );
  const { refreshProductList } = useGetProducts();

  const submitHandler = () => { 
    refreshProductList(true);
    setMenuOverlayActive(OVERLAY.NONE);
    setActiveFacetId(null);
    let paramObj = {};
    for (let [key, value] of Object.entries(currentFilters)) {
      paramObj[key] = value.join(";");
    }
    urlParams.set(Object.keys(paramObj).length ? paramObj : null);
  };

  const clearHandler = () => {
    // let newFacets = filters;
    //setFacetList(newFacets);
    // TODO: clean url
    clearSearch();
    setActiveFacetId(null);
    setMenuOverlayActive(OVERLAY.NONE);
    urlParams.set(null);
  };

  const onFacetFieldClick = ({ checked, facetFieldId, facetId }) => {
    setCurrentFilters({ facetFieldId, facetId }, checked);
    getAppliedFilters();
  };

  return (
    <div className="product-filters-list">
      <div className="product-filters-list__inner">
        <div className="product-filters-list__facets">
          {activeOverlay === OVERLAY.FILTERS &&
            facetList && facetList?.length && facetList?.map((facet, index) => {
              return (
                <Collapsible
                  key={facet.facetId}
                  preExpanded={facet.facetId === activeFacetId || (!index && !activeFacetId) }
                  triggerComponent={
                    <>
                      <h3>{facet.name}</h3>
                      <ChevronSvg />
                    </>
                  }
                  contentComponent={
                    <div>
                      {facet?.facetFields?.map((facetField) => {
                        return (
                          <Checkbox
                            key={facetField.id}
                            id={facetField.id}
                            value={facetField.id}
                            name={facetField.name}
                            label={`${facetField.name}`}
                            checked={facetField.active}
                            labelLeft={true}
                            disabled={facetField.count === 0}
                            changeHandler={(value) =>
                              onFacetFieldClick({
                                checked: value,
                                facetFieldId: facetField.id,
                                facetId: facet.facetId,
                              })
                            }
                          />
                        );
                      })}
                    </div>
                  }
                />
              );
            })}
        </div>
        <div className="product-filters-list__footer">
          <ActionButton
            text={texts.clear}
            clickHandler={clearHandler}
            buttonType={BUTTONSTYLE.GHOST}
          />
          <ActionButton
            text={`${texts.see} (${count})`}
            clickHandler={submitHandler}
            hasArrow={true}
            buttonType={BUTTONSTYLE.SOLID}
            isInverted={true}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductFiltersList;
