import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { RichText } from "@sitecore-jss/sitecore-jss-react";

import MaterialTile from "../MaterialTile";
import ListTitle from "../ListTitle";

import path from "@assets/js/browser-path";
import parseSpecifications from "@assets/js/parse-specifications";

import AnimationWrapper from "@assets/js/AnimationWrapper";

import useGlobalStore from "@store/global";

import "./material-list.scss";

const fetchMaterialSet = (urlSlug) =>
  axios.get(path.join(process.env.REACT_APP_ASSETSAPI, urlSlug));

const MaterialListController = ({ fields, rendering }) => {
  const urlSlug = fields?.data?.item?.url?.value
    ? fields.data.item.url.value
    : "/materialsets/3107VELCOL/en.json";

  const [materialSetData, setMaterialSetData] = useState(null);

  useEffect(() => {
    fetchMaterialSet(urlSlug);

    async function loadMaterialSet() {
      const response = await fetchMaterialSet(urlSlug);

      setMaterialSetData(response.data);
    }
    if (urlSlug) loadMaterialSet();
  }, []);

  const getFieldValue = (prop) => {
    return materialSetData.Fields.find((f) => f.Id === prop)
      ? materialSetData.Fields.find((f) => f.Id === prop).Value
      : "NONE";
  };

  const getMaterialTitleData = () => {
    if (materialSetData) {
      return {
        bodyText: { value: getFieldValue("MaterialSetDisplayDescription") },
        headlineType: { value: "h1" },
        headline: { value: getFieldValue("MaterialSetDisplayName") },
      };
    }
    return null;
  };

  return (
    <div className="material-list-controller">
      {fields && urlSlug && materialSetData && (
        <>
          {getMaterialTitleData() && (
            <ListTitle fields={getMaterialTitleData()} rendering={rendering} />
          )}
          <MaterialList fields={fields} data={materialSetData} />
        </>
      )}
    </div>
  );
};

const MaterialList = ({ data, fields }) => {
  const sc_lang = useGlobalStore((state) => state.sc_lang);

  const { t } = useTranslation();
  const texts = {
    colorOptions: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Materials_ColorOptions"
    ),
    techSpecs: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Materials_TechSpecs"
    ),
    downloadProductSheet: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Materials_DownloadProductSheet"
    ),
  };

  const downloadUrl = fields?.downloadUrl?.value;

  return (
    <AnimationWrapper type="defaultOpacity">
      {fields && data && (
        <>
          <div className="material-list">
            <div className="material-list__tiles">
              <h3>{texts.colorOptions}</h3>
              {data.Materials && (
                <ul>
                  {data.Materials.map((item, index) => {
                    return (
                      <MaterialTile
                        key={`materialTile${index}`}
                        item={item}
                        materialSetId={data.MaterialSetId}
                      />
                    );
                  })}
                </ul>
              )}
            </div>

            {fields.description && (
              <div className="material-list__description">
                <RichText field={fields.description} />
              </div>
            )}

            {data.GroupedSpecifications && (
              <div className="material-list__specs">
                <h3>{texts.techSpecs}</h3>

                <table>
                  <tbody>
                    {parseSpecifications(
                      data.GroupedSpecifications,
                      sc_lang
                    ).map((item, index) => {
                      return (
                        <tr key={`specLine${index}`}>
                          <th>{item.key}</th>
                          <td>{item.value}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>

                {downloadUrl && (
                  <a className="nav-button is-outlined" href={downloadUrl}>
                    <span className="text">{texts.downloadProductSheet}</span>
                  </a>
                )}
              </div>
            )}
          </div>
        </>
      )}
    </AnimationWrapper>
  );
};

MaterialListController.propTypes = {};

export default MaterialListController;
