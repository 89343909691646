import React from "react";
import "./icons.scss";

export default ({ className, isInverted, fillHex, strokeHex }) => (
  <svg
    className={`svg-icon svg-icon--heart-small ${className || ""} ${
      isInverted ? "is-inverted" : ""
    }`}
    width="21"
    height="18"
    viewBox="0 0 21 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.129 3.177l.371.411.371-.411C12.12 1.793 13.654.5 15.366.5 18.564.5 20.5 3.121 20.5 5.21c0 2.342-1.426 4.668-3.483 6.83-1.955 2.055-4.41 3.886-6.517 5.352-2.107-1.465-4.561-3.297-6.517-5.352C1.926 9.879.5 7.552.5 5.21.5 3.122 2.437.5 5.634.5 7.346.5 8.88 1.793 10.13 3.177z"
      stroke={strokeHex}
      fill={fillHex}
    />
  </svg>
);
