import React, { useState, Fragment } from "react";

import { Text, Link } from "@sitecore-jss/sitecore-jss-react";
import NavButton from "../NavButton";
import NavListButton from "../NavListButton";
import { useTranslation } from "react-i18next";

import { motion, AnimatePresence } from "framer-motion";
import classnames from "classnames";

import { DEFAULT_TRANSITION } from "@constants";

import getUniqueId from "@assets/js/get-unique-id.js";
import isTrue from "@assets/js/is-true";
import "./nav-list.scss";

const NavList = ({ fields }) => {
  const { t } = useTranslation();
  const [hoverMedia, setHoverMedia] = useState(false);
  const [itemIndex, setItemIndex] = useState(-1);

  const texts = {
    listHeadline: t(
      "FritzHansen_Project_ConsumersAndProfessionals_NavList_ListHeadline"
    ),
  };

  const mouseEnterHandler = (item, index) => {
    if (item.fields?.image) {
      if (item.fields?.image?.value) {
        setHoverMedia(item.fields.image);
      } else {
        setHoverMedia(null);
      }
    }
    setItemIndex(index);
  };
  const mouseLeaveHandler = () => {
    setHoverMedia(null);
    setItemIndex(-1);
  };

  const listClasses = classnames({
    "nav-list": true,
    "nav-list--inverted": isTrue(fields?.isInverted),
    "is-active": itemIndex > -1,
    "has-custom-headline": fields?.headline?.value,
  });

  return (
    <>
      {fields && (
        <div
          className={listClasses}
          style={
            fields.backgroundColorHex && {
              "--nav-list-bgc": fields.backgroundColorHex.value,
            }
          }
        >
          <div className="nav-list__inner">
            {/* <div className="nav-list__headline nav-list__headline--default">
              <h2>{texts.listHeadline} </h2>
            </div> */}

            {fields?.headline && (
              <div className="nav-list__headline">
                <Text tag="h2" field={fields?.headline} />
              </div>
            )}

            {fields.primaryList && (
              <div
                className="nav-list__primary"
                onMouseLeave={(event) => {
                  mouseLeaveHandler();
                }}
              >
                <ul>
                  {fields.primaryList &&
                    fields.primaryList.map((item, index) => {
                      return (
                        <li
                          key={`listItem${index}`}
                          className={`nav-list__item ${
                            itemIndex === index ? "is-active" : ""
                          }`}
                          onMouseEnter={(event) => {
                            mouseEnterHandler(item, index);
                          }}
                        >
                          <NavListButton fields={item.fields} />
                        </li>
                      );
                    })}
                </ul>
              </div>
            )}

            {fields.secondaryList && fields.secondaryList.length > 0 && (
              <div className="nav-list__secondary">
                <ul>
                  <li>
                    <Text tag="p" field={fields.seeMoreText} />
                  </li>

                  {fields.secondaryList.map((item, index) => {
                    return (
                      <Fragment key={`listItem${index}`}>
                        {item.fields?.link?.value && (
                          <li>
                            <Link field={item.fields.link} />
                          </li>
                        )}
                      </Fragment>
                    );
                  })}
                </ul>

                {fields.seeAllLink?.fields && (
                  <NavButton fields={fields.seeAllLink.fields} />
                )}
              </div>
            )}

            {!fields.secondaryList && fields.seeAllLink?.fields && (
              <div className="nav-list__footer">
                <NavButton fields={fields.seeAllLink.fields} />
              </div>
            )}

            <AnimatePresence>
              <motion.div
                key={getUniqueId()}
                className="nav-list__image"
                style={{ top: `${itemIndex * 61 + 67 - 10}px` }}
                transition={DEFAULT_TRANSITION}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, top: `${itemIndex * 61 + 67}px` }}
                exit={{ opacity: 0 }}
              >
                {hoverMedia?.value && (
                  <>
                    <img
                      src={hoverMedia.value.src}
                      alt={hoverMedia.value.alt}
                    />
                  </>
                )}
              </motion.div>
            </AnimatePresence>
          </div>
        </div>
      )}
    </>
  );
};

export default NavList;
