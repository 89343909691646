export default {
  fields: {
    list: [
      {
        id: "62617d5a-d299-4fd0-b52d-b73a2c70cea1",
        fields: {
          link: {
            value: {
              href: "/en/hello",
              text: "The Journal",
              anchor: "",
              linktype: "internal",
              class: "",
              title: "",
              querystring: "",
              id: "{A0CD8AEB-6A9E-4896-A536-8D186D5E3855}",
            },
          },
        },
      },
      {
        id: "523d2e0c-4f98-4f8e-85d7-e7bb915d1944",
        fields: {
          link: {
            value: {
              href: "/en/components",
              text: "#Fritzhansen",
              anchor: "",
              linktype: "internal",
              class: "",
              title: "",
              querystring: "",
              id: "{3D09AC78-880B-462B-BFD5-531E2FC436B5}",
            },
          },
        },
      },
      {
        id: "523d2e0c-4f98-4f8e-85d7-e7bb915d1944",
        fields: {
          link: {
            value: {
              href: "/en/components",
              text: "Collaboration",
              anchor: "",
              linktype: "internal",
              class: "",
              title: "",
              querystring: "",
              id: "{3D09AC78-880B-462B-BFD5-531E2FC436B5}",
            },
          },
        },
      },
      {
        id: "523d2e0c-4f98-4f8e-85d7-e7bb915d1944",
        fields: {
          link: {
            value: {
              href: "/en/components",
              text: "Inspiration",
              anchor: "",
              linktype: "internal",
              class: "",
              title: "",
              querystring: "",
              id: "{3D09AC78-880B-462B-BFD5-531E2FC436B5}",
            },
          },
        },
      },
      {
        id: "523d2e0c-4f98-4f8e-85d7-e7bb915d1944",
        fields: {
          link: {
            value: {
              href: "/en/components",
              text: "Magazine",
              anchor: "",
              linktype: "internal",
              class: "",
              title: "",
              querystring: "",
              id: "{3D09AC78-880B-462B-BFD5-531E2FC436B5}",
            },
          },
        },
      },
      {
        id: "523d2e0c-4f98-4f8e-85d7-e7bb915d1944",
        fields: {
          link: {
            value: {
              href: "/en/components",
              text: "Magazine",
              anchor: "",
              linktype: "internal",
              class: "",
              title: "",
              querystring: "",
              id: "{3D09AC78-880B-462B-BFD5-531E2FC436B5}",
            },
          },
        },
      },
      {
        id: "523d2e0c-4f98-4f8e-85d7-e7bb915d1944",
        fields: {
          link: {
            value: {
              href: "/en/components",
              text: "News",
              anchor: "",
              linktype: "internal",
              class: "",
              title: "",
              querystring: "",
              id: "{3D09AC78-880B-462B-BFD5-531E2FC436B5}",
            },
          },
        },
      },
    ],
  },
};
