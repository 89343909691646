import React, { useEffect, useState, Fragment } from "react";
import shallow from "zustand/shallow";
import { loader } from "graphql.macro";
import { useQuery } from "react-apollo";
import classnames from "classnames";
import cuid from "cuid";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import ActionMenu from "../../ActionMenu";

import { BoxSvg, PlusSvg, HeartSmallSvg, CheckmarkLargeSvg } from "@assets/js/icons";
import { PORTFOLIO_WISHLIST_NAME, SITE_MODE } from "@constants";
import usePortfolioStore, { usePortfoliosList } from "@store/portfolio";
import useGlobalStore from "@store/global";

import "./portfolio-admin.scss";

const PortfolioAdminController = (props) => {
  const { t } = useTranslation();
  const [portfoliosList, , fetchPortfolios] = usePortfoliosList();
  const createPortfolio = usePortfolioStore((state) => state.createPortfolio);
  const createWishlist = usePortfolioStore((state) => state.createWishlist);
  const userKey = useGlobalStore((store) => store.userKey);
  const userAuthenticated = useGlobalStore((store) => store.userAuthenticated);

  useEffect(() => {
    fetchPortfolios(userKey, userAuthenticated);
  }, [userKey, userAuthenticated]);

  useEffect(() => {
    if (portfoliosList && portfoliosList.length === 0) {
      createPortfolio(userKey, userAuthenticated, PORTFOLIO_WISHLIST_NAME, true);
    }
  }, [portfoliosList]);

  return <PortfolioAdmin />;
};

const portfolioLinkQuery = loader("../../../assets/js/gql/portfolioLinkQuery.graphql");

const PortfolioAdmin = (props) => {
  const [userKey, userAuthenticated, mode] = useGlobalStore(
    (store) => [store.userKey, store.userAuthenticated, store.currentSiteMode],
    shallow
  );

  const [portfoliosList, ,] = usePortfoliosList();
  const [isCreating, setIsCreating] = useState(false);
  const [operationComplete, setOperationComplete] = useState(false);

  const [createPortfolio, deletePortfolio, updatePortfolio] = usePortfolioStore(
    (store) => [store.createPortfolio, store.deletePortfolio, store.updatePortfolio],
    shallow
  );

  const menuId =
    mode === SITE_MODE.RESIDENTIAL
      ? process.env.REACT_APP_RESIDENTIAL_DESKTOP_ID
      : process.env.REACT_APP_PROFESSIONAL_DESKTOP_ID;

  const { data: linkData, loading } = useQuery(portfolioLinkQuery, {
    variables: { menuId },
    errorPolicy: "all",
  });

  const { t } = useTranslation();
  const texts = {
    saveToProject: t("FritzHansen_Project_ConsumersAndProfessionals_Portfolio_SaveToProject"),
    createNewProject: t("FritzHansen_Project_ConsumersAndProfessionals_Portfolio_CreateNewProject"),
    noPortfolios: t("FritzHansen_Project_ConsumersAndProfessionals_Portfolio_NoPortfolios"),
    projects: t("FritzHansen_Project_ConsumersAndProfessionals_Portfolio_Projects"),
    operationComplete: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Portfolio_OperationComplete"
    ),
  };

  const updateCompleteHandler = (status) => {
    setOperationComplete(true);
    console.log("OPE COM 1");
    setTimeout(() => {
      setOperationComplete(false);
    }, 2000);
  };

  const saveCompleteHandler = (status) => {
    setIsCreating(false);
    setOperationComplete(true);
    console.log("OPE COM 2");

    setTimeout(() => {
      setOperationComplete(false);
    }, 2000);
  };

  const deleteHandler = (data) => {
    deletePortfolio(data.portfolioEditKey, userAuthenticated);
  };
  const renameHandler = (data, newName) => {
    updatePortfolio(data.portfolioEditKey, newName, "", false, updateCompleteHandler);
  };

  const createClickHandler = () => {
    setIsCreating(!isCreating);
  };

  const createSaveHandler = (name) => {
    createPortfolio(userKey, userAuthenticated, name, false, saveCompleteHandler);
  };

  const classList = classnames({
    "portfolio-admin": true,
  });

  return (
    <div className={classList}>
      <div className="portfolio-admin__header">
        <h1>{texts.projects}</h1>

        {operationComplete && (
          <span className="success">
            <CheckmarkLargeSvg /> {texts.operationComplete}
          </span>
        )}
      </div>

      <ul className="portfolio-admin-list">
        {isCreating && (
          <li>
            <PortfolioAdminCreateItem saveHandler={createSaveHandler} />
          </li>
        )}
        {!loading && linkData && portfoliosList?.length > 0 ? (
          <>
            {portfoliosList.map((item, index) => {
              return (
                <li key={cuid()}>
                  <PortfolioAdminListItem
                    data={{
                      ...item,
                      link: `${linkData.menu?.portfolioLink?.jss?.value?.href}?pekey=${item.portfolioEditKey}`,
                    }}
                    onDelete={deleteHandler}
                    onRename={renameHandler}
                  />
                </li>
              );
            })}
          </>
        ) : (
          <li>
            <div className="portfolio-admin-list-item">
              <div className="portfolio-admin-list-item__inner">
                <span className="label">{texts.noPortfolios}</span>
              </div>
            </div>
          </li>
        )}
        <li>
          <button className="portfolio-admin__create" onClick={createClickHandler}>
            <span className="icon">
              <PlusSvg />
            </span>
            <span className="label">{texts.createNewProject}</span>
          </button>
        </li>
      </ul>
    </div>
  );
};

const PortfolioAdminListItem = ({ data, onDelete, onRename }) => {
  const { t } = useTranslation();
  const texts = {
    wishList: t("FritzHansen_Project_ConsumersAndProfessionals_Portfolio_Wishlist"),
    items: t("FritzHansen_Project_ConsumersAndProfessionals_Portfolio_Items"),
    save: t("FritzHansen_Project_ConsumersAndProfessionals_Portfolio_Save"),
    delete: t("FritzHansen_Project_ConsumersAndProfessionals_Portfolio_Delete"),
    rename: t("FritzHansen_Project_ConsumersAndProfessionals_Portfolio_Rename"),
    tooShortError: t("FritzHansen_Project_ConsumersAndProfessionals_Portfolio_TooShort"),
  };

  const iconWishlist = <HeartSmallSvg />;
  const iconProject = <BoxSvg />;

  const icon = data.isWishlist ? iconWishlist : iconProject;
  const itemCount = data.portfolioItems.length;

  const [dropped, setDropped] = useState(false);
  const [isRenaming, setIsRenaming] = useState(false);

  const [name, setName] = useState(data.name);
  const [isValidating, setIsValidating] = useState(false);

  const portfolioNameMinLength = 3;

  const saveClickHandler = () => {
    setIsValidating(true);
    if (name.length >= portfolioNameMinLength && onRename) {
      onRename(data, name);
      setIsRenaming(false);
    }
  };
  const nameInputHandler = (e) => {
    setIsValidating(false);
    setName(e.target.value);
  };

  let actionList = [
    {
      icon: "BoxSvg",
      label: texts.rename,
      callback: () => {
        setIsRenaming(true);
        setDropped(false);
      },
    },
    {
      icon: "RemoveSvg",
      label: texts.delete,
      callback: () => {
        onDelete(data);
        setDropped(false);
      },
      prompt: {
        labelConfirm: "Really??",
        labelCancel: "Nah...",
      },
    },
  ];

  const classList = classnames({
    "portfolio-admin-list-item": true,
  });
  return (
    <div className={classList} onMouseLeave={() => setDropped(false)}>
      {!isRenaming && (
        <div className="portfolio-admin-list-item__inner">
          <a href={data.link}>
            <span className="icon">{icon}</span>
            <span className="name">{name === PORTFOLIO_WISHLIST_NAME ? texts.wishList : name}</span>
            <span className="count">
              {itemCount} {texts.items}
            </span>
          </a>

          {!data.isWishlist && (
            <>
              <button className="menu-button" onClick={() => setDropped(!dropped)}>
                <span>&bull;&nbsp;&bull;&nbsp;&bull;</span>
              </button>

              <ActionMenu actionList={actionList} isActive={dropped} />
            </>
          )}
        </div>
      )}

      {isRenaming && (
        <div className="portfolio-admin-list-item__inner">
          <span className="icon">{icon}</span>

          <input
            className="input"
            autoFocus
            type="text"
            defaultValue={name}
            onChange={nameInputHandler}
          />
          {isValidating && name.length < portfolioNameMinLength && (
            <span className="error">{texts.tooShortError} </span>
          )}

          <button className="save" onClick={saveClickHandler}>
            {texts.save}
          </button>
        </div>
      )}
    </div>
  );
};

const PortfolioAdminCreateItem = ({ saveHandler }) => {
  const { t } = useTranslation();
  const texts = {
    projectName: t("FritzHansen_Project_ConsumersAndProfessionals_Portfolio_ProjectName"),
    save: t("FritzHansen_Project_ConsumersAndProfessionals_Portfolio_Save"),
    tooShortError: t("FritzHansen_Project_ConsumersAndProfessionals_Portfolio_TooShort"),
  };

  const classList = classnames({
    "portfolio-admin-list-item": true,
    "portfolio-admin-list-item--create": true,
  });

  const iconProject = <BoxSvg />;

  const icon = iconProject;
  const [name, setName] = useState("");
  const [isValidating, setIsValidating] = useState(false);

  const saveClickHandler = () => {
    setIsValidating(true);
    if (name.length >= portfolioNameMinLength && saveHandler) saveHandler(name);
  };
  const nameInputHandler = (e) => {
    setIsValidating(false);
    setName(e.target.value);
  };

  const portfolioNameMinLength = 3;

  return (
    <div className={classList}>
      <div className="portfolio-admin-list-item__inner">
        <span className="icon">{icon}</span>

        <input
          className="input"
          autoFocus
          type="text"
          placeholder={texts.projectName}
          onChange={nameInputHandler}
        />
        {isValidating && name.length < portfolioNameMinLength && (
          <span className="error">{texts.tooShortError}</span>
        )}

        <button className="save" onClick={saveClickHandler}>
          {texts.save}
        </button>
      </div>
    </div>
  );
};

PortfolioAdminController.propTypes = {};

export default PortfolioAdminController;
