import React from "react";

import { Text } from "@sitecore-jss/sitecore-jss-react";

import MediaFocal from "../MediaFocal";
import classnames from "classnames";
import isTrue from "@assets/js/is-true";

import "./spot-tile.scss";

const SpotTile = ({ fields }) => {
  const classList = classnames({
    "spot-tile": true,
    "spot-tile--linear": isTrue(fields.isLinear),
    "spot-tile--inverted": isTrue(fields.isInverted),
  });

  return (
    <>
      {fields && (
        <div className={classList}>
          {fields.media && (
            <div className="spot-tile__image">
              <MediaFocal fields={fields.media.fields} />
            </div>
          )}

          <div className="spot-tile__content">
            {fields.headline?.field?.value && (
              <div className="spot-tile__headline">
                <Text tag="h2" field={fields.headline.field} />
              </div>
            )}

            {fields.bodyText?.field?.value &&
              fields.bodyText.field.value.length > 0 && (
                <div className="spot-tile__bodytext">
                  <Text tag="p" field={fields.bodyText?.field} />
                </div>
              )}

            {(fields.phone?.field || fields.email?.field) && (
              <div className="spot-tile__contact">
                {fields.phone?.field && (
                  <Text tag="p" field={fields.phone.field} />
                )}
                {fields.email?.field && (
                  <Text tag="p" field={fields.email.field} />
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default SpotTile;
