import React, { useEffect, useState } from "react";
import ActionButton from "../../ActionButton";
import { BUTTONSTYLE } from "@constants";
import PropTypes from "prop-types";
import useNotificationStore from "@store/notifications";
import { motion, AnimatePresence } from "framer-motion";
import { collapseVariant } from "@assets/js/animation-variations";

import "../notification.scss";

const NotificationServiceMessage = ({
  timer,
  pinAlert,
  backgroundColorHex,
  bodyText,
  textColor,
  id,
}) => {
  const removeNotification = useNotificationStore((store) => store.removeNotification);

  const [isRunning, setIsRunning] = useState(true);

  let timeout = null;

  useEffect(() => {
    if (timer) {
      timeout = setTimeout(() => {
        setIsRunning(false);
      }, timer);
      return () => clearTimeout(timeout);
    }
  }, []);

  const notificationAnimationDone = () => {
    if (!isRunning) {
      removeNotification(id);
    }
  };
  return (
    <AnimatePresence>
      <motion.div
        className="notification notification--service-message"
        style={{
          "--notification-bgc": backgroundColorHex,
          "--notification-c": textColor,
        }}
        variants={collapseVariant}
        animate={isRunning ? "visible" : "hidden"}
        onAnimationComplete={notificationAnimationDone}
      >
        <ActionButton
          buttonType={BUTTONSTYLE.NONE}
          icon="TimesSvg"
          clickHandler={() => setIsRunning(false)}
        />

        <div className="notification__bodytext">
          {bodyText}
          {/* <span>
                <a
                  href={link.href}
                  className="notifications-service-message__link"
                >
                  {link.text}
                </a>
              </span> */}
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

NotificationServiceMessage.propTypes = {
  // link: PropTypes.object,
  timer: PropTypes.number,
  pinAlert: PropTypes.bool,
  backgroundColorHex: PropTypes.string,
};

export default NotificationServiceMessage;
