export default {
  default: {
    fields: {
      headline: "Regional Contracts & Retail Contacts",
      options: [
        {
          noParse: true,
          id: "denmark",
          optionLabel: "Denmark",
          optionValue: "denmark",
        },
        {
          noParse: true,
          id: "sweden",
          optionLabel: "Sweden",
          optionValue: "sweden",
        },
        {
          noParse: true,
          id: "norway",
          optionLabel: "Norway",
          optionValue: "norway",
        },
      ],
      countries: [
        {
          noParse: true,
          name: "Denmark",
          id: "denmark",
          persons: [
            {
              noParse: true,
              name: "Johnny",
              area: "Sales Manager Retail",
              email: "johnny@johnny.dk",
              emailHref: "mailto:johnny@johnny.dk",
            },
            {
              noParse: true,
              name: "Lars",
              area: "Communication Manager",
              email: "lars@lars.dk",
              emailHref: "mailto:lars@lars.dk",
            },
            {
              noParse: true,
              name: "Signe",
              area: "Receptionist",
              email: "signe@signe.dk",
              emailHref: "mailto:signe@signe.dk",
            },
          ],
        },
        {
          noParse: true,
          name: "Sweden",
          id: "sweden",
          persons: [
            {
              noParse: true,
              name: "Johnny",
              area: "Sales Manager Retail",
              email: "johnny@johnny.dk",
              emailHref: "mailto:johnny@johnny.dk",
            },
            {
              noParse: true,
              name: "Lars",
              area: "Communication Manager",
              email: "lars@lars.dk",
              emailHref: "mailto:lars@lars.dk",
            },
            {
              noParse: true,
              name: "Signe",
              area: "Receptionist",
              email: "signe@signe.dk",
              emailHref: "mailto:signe@signe.dk",
            },
          ],
        },
        {
          noParse: true,
          name: "Norway",
          id: "norway",
          persons: [
            {
              noParse: true,
              name: "Johnny",
              area: "Sales Manager Retail",
              email: "johnny@johnny.dk",
              emailHref: "mailto:johnny@johnny.dk",
            },
            {
              noParse: true,
              name: "Lars",
              area: "Communication Manager",
              email: "lars@lars.dk",
              emailHref: "mailto:lars@lars.dk",
            },
            {
              noParse: true,
              name: "Signe",
              area: "Receptionist",
              email: "signe@signe.dk",
              emailHref: "mailto:signe@signe.dk",
            },
          ],
        },
      ],
    },
  },
};
