import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { BoxSvg } from "@assets/js/icons";

import { Text } from "@sitecore-jss/sitecore-jss-react";
import { OVERLAY } from "@constants";

import { useSubmissionContent } from "@store/portfolio";
import useHeaderStore from "@store/header";

import "./material-info-overlay.scss";
const MaterialInfoOverlay = (props) => {
  const { t } = useTranslation();

  const texts = {
    saveImage: t("FritzHansen_Project_ConsumersAndProfessionals_Portfolio_SaveMaterialToProject"),
  };

  const setMenuOverlayActive = useHeaderStore((store) => store.setMenuOverlayActive);
  const [submissionContent] = useSubmissionContent();

  const iconProject = <BoxSvg />;

  const saveToProjectHandler = () => {
    setMenuOverlayActive(OVERLAY.PORTFOLIO_SUBMISSION);
  };

  return (
    <div className="material-info-overlay">
      {submissionContent && (
        <Fragment key={submissionContent.id}>
          <div className="material-info-overlay__headline">
            {submissionContent.headline?.value && <Text tag="h4" field={submissionContent.headline} />}
            {submissionContent.bodyText?.value && <Text tag="p" field={submissionContent.bodyText} />}
          </div>

          <div className="material-info-overlay__cta">
            <button onClick={saveToProjectHandler} className="material-info-overlay__button">
              <span className="icon">{iconProject}</span>
              <span className="label">{texts.saveImage}</span>
            </button>
          </div>

          <div className="material-info-overlay__image">
            <img src={submissionContent.imageSrcHigh} />
          </div>
        </Fragment>
      )}
    </div>
  );
};
MaterialInfoOverlay.propTypes = {};

export default MaterialInfoOverlay;
