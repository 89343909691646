import React from "react";
import { useTranslation } from "react-i18next";
import LogoAnimation from "../LogoAnimation";
import { NavLink } from "react-router-dom";

import "./checkout-header.scss";

const SvgLogo = () => (
  <svg viewBox="0 0 1281 121" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="a" maskUnits="userSpaceOnUse" x="0" y="0">
      <path d="M.06.821h1280.5V120.94H.06V.821z" fill="#fff" />
    </mask>
    <g mask="url(#a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1280.56 4.218v113.385h-27.92l-56.62-96.878h-1.08l.31 96.878h-17.28V4.218h27.93l56.61 96.879h1.08l-.31-96.879h17.28zm-129.15 97.649v15.736h-84.23V4.218h83.61v15.736h-65.87v31.778h60.16V66.85h-60.16v35.017h66.49zM938.796 86.29l17.739-6.633c3.085 14.656 12.495 25.917 36.716 25.917 21.129 0 30.699-7.712 30.699-17.74 0-31.007-80.837-4.327-80.837-53.074 0-20.519 19.439-33.939 46.899-33.939 30.548 0 46.588 16.044 50.138 34.402l-17.74 6.631c-3.09-14.5-11.42-25.607-32.709-25.607-18.973 0-28.538 7.714-28.538 17.74 0 31.008 80.837 4.327 80.837 53.076 0 20.517-19.44 33.937-49.06 33.937-34.553 0-50.597-16.042-54.144-34.71zM914.482 4.218v113.385h-27.923l-56.614-96.878h-1.08l.308 96.878h-17.278V4.218h27.923l56.614 96.879h1.081l-.31-96.879h17.279zM753.796 64.999l-17.895-47.206-18.05 47.206h35.945zm6.015 15.889h-47.976l-13.885 36.715h-18.048L724.484 4.218h23.448l44.584 113.385h-18.821l-13.884-36.715zm-99.226-76.67v113.385h-17.741V67.468h-65.871v50.135h-17.741V4.218h17.741v46.896h65.871V4.218h17.741zM470.67 101.405v16.198h-89.937v-12.341l65.287-84.846h-63.745V4.218h86.852V16.56l-65.287 84.845h66.83zM367.616 20.417h-42.422v97.186h-17.741V20.417h-41.798V4.218h101.961v16.199zm-137.134 97.186h17.74V4.218h-17.74v113.385zm-70.658-62.014c15.272 0 23.139-5.863 23.139-17.895 0-12.187-7.867-18.05-23.139-18.05h-37.796v35.945h37.796zm43.039 62.014h-18.82l-3.703-25.299c-2.159-14.963-8.33-22.059-22.214-22.059h-36.098v47.358h-17.741V4.218h56.616c26.224 0 40.571 12.187 40.571 32.088 0 14.5-7.867 23.603-19.591 27.305 8.793 3.547 14.038 10.798 16.198 24.066l4.782 29.926zM17.74 20.262v32.395h60.164v15.426H17.74v49.52H0V4.218h83.612v16.044H17.74z"
        fill="#000"
      />
    </g>
  </svg>
);

const CheckoutHeader = ({ continueShoppingLink }) => {
  const { t } = useTranslation();

  const texts = {
    continueShopping: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Cart_ContinueShopping"
    ),
  };

  return (
    <div className="checkout-header">
      {continueShoppingLink ? (
        <>
          <NavLink className="checkout-header__logo" to={continueShoppingLink}>
            {/* <LogoAnimation /> */}
            <SvgLogo />
          </NavLink>

          <NavLink className="checkout-header__link" to={continueShoppingLink}>
            {texts.continueShopping}
          </NavLink>
        </>
      ) : (
        <div className="checkout-header__logo">
          <SvgLogo />
        </div>
      )}
    </div>
  );
};
CheckoutHeader.propTypes = {};

export default CheckoutHeader;
