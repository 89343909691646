import React from "react";
import "./icons.scss";
export default ({ className, isInverted }) => (
  <svg
    className={`svg-icon svg-icon--plus ${className || ""} ${isInverted ? "is-inverted" : ""}`}
    width="9"
    height="9"
    viewBox="0 0 9 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3.857 0h1v9h-1z" />
    <path d="M9 3.857v1H0v-1z" />
  </svg>
);
