import MediaFocal from "./media-focal";
import NavButton from "./nav-button";

export default {
  default: {
    fields: {
      headlineType: "h2",
      headline: "A note of blue",
      bodyText:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi consequuntur quos minus asperiores error, eveniet facere. Ut ab debitis quisquam molestias ad impedit eveniet rerum, culpa commodi. Dolorem, nihil maiores.",
      backgroundMedia: MediaFocal.landscape,
      button: NavButton.default,
      isButtonEnabled: true,
      isInverted: true,
    },
  },
  customBg: {
    fields: {
      backgroundMedia: MediaFocal.landscapeWithOverlay,
      button: NavButton.default,
      headlineType: "h2",
      headline: "A note of blue",
      bodyText:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi consequuntur quos minus asperiores error, eveniet facere. Ut ab debitis quisquam molestias ad impedit eveniet rerum, culpa commodi. Dolorem, nihil maiores.",
      backgroundColorHex: "#6677ee",
      isButtonEnabled: true,
      isInverted: true,
    },
  },
};
