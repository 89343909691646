export default {
  default: {
    fields: {
      bodyText:
        "<h1>Medium Headline</h1><p>Paragraph Lorem ipsum dolor sit <i>amet</i>, consectetur <em>adipiscing elit</em>, sed do<a href='#'>eiusmod tempor</a> incididunt ut labore et dolore magna aliqua.</p><p>Paragraph Lorem ipsum dolor sit <i>amet</i>, consectetur <em>adipiscing elit</em>, sed do<a href='#'>eiusmod tempor</a> incididunt ut labore et dolore magna aliqua.</p><p>Paragraph Lorem ipsum dolor sit <strong>amet</strong>, consectetur <b>adipiscing elit</b>, sed do<a href='#'>eiusmod tempor</a> incididunt ut labore et dolore magna aliqua.</p> <a href='#'>This is a link</a> <ul><li> <span>Lorem ipsum dolor</span> </li> <li> <span>Lorem ipsum dolor</span> </li> <li> <span>Lorem ipsum dolor</span> </li></ul> <ol> <li> <span>Lorem ipsum dolor</span> </li> <li> <span>Lorem ipsum dolor</span> </li> <li> <span>Lorem ipsum dolor</span> </li></ol> <table> <tbody> <tr> <td>&nbsp;</td> <td>Knocky</td> <td>Flor</td> <td>Ella</td> <td>Juan</td> </tr> <tr> <td>Breed</td> <td>Jack Russell</td> <td>Poodle</td> <td>Streetdog</td> <td>Cocker Spaniel</td> </tr> <tr> <td>Age</td> <td>16</td> <td>9</td> <td>10</td> <td>5</td> </tr> <tr> <td>Owner</td> <td>Mother-in-law</td> <td>Me</td> <td>Me</td> <td>Sister-in-law</td> </tr> <tr> <td>Eating Habits</td> <td>Eats everyone's leftovers</td> <td>Nibbles at food</td> <td>Hearty eater</td> <td>Will eat till he explodes</td> </tr> </tbody> </table><img src='https://picsum.photos/id/166/400/600'/>",

      readMoreBodyText: "readmorebodytext",
    },
  },
  customInverted: {
    fields: {
      bodyText:
        "<h1>Medium Headline</h1><p>Paragraph Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do<a href='#'>eiusmod tempor</a> incididunt ut labore et dolore magna aliqua.</p> <a href='#'>This is a link</a> <ul><li> <span>Lorem ipsum dolor</span> </li> <li> <span>Lorem ipsum dolor</span> </li> <li> <span>Lorem ipsum dolor</span> </li></ul> <ol> <li> <span>Lorem ipsum dolor</span> </li> <li> <span>Lorem ipsum dolor</span> </li> <li> <span>Lorem ipsum dolor</span> </li></ol> <table> <tbody> <tr> <td>&nbsp;</td> <td>Knocky</td> <td>Flor</td> <td>Ella</td> <td>Juan</td> </tr> <tr> <td>Breed</td> <td>Jack Russell</td> <td>Poodle</td> <td>Streetdog</td> <td>Cocker Spaniel</td> </tr> <tr> <td>Age</td> <td>16</td> <td>9</td> <td>10</td> <td>5</td> </tr> <tr> <td>Owner</td> <td>Mother-in-law</td> <td>Me</td> <td>Me</td> <td>Sister-in-law</td> </tr> <tr> <td>Eating Habits</td> <td>Eats everyone's leftovers</td> <td>Nibbles at food</td> <td>Hearty eater</td> <td>Will eat till he explodes</td> </tr> </tbody> </table><img src='https://picsum.photos/id/166/400/600'/>",
      readMoreBodyText: "readmorebodytext",
      isInverted: true,
      backgroundColorHex: "#888888",
    },
  },
};
