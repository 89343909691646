import Select from "./select";

export default {
  default: {
    geoComp: true,
    countrySelect: { ...Select.country, noParse: true },
    languageSelect: { ...Select.language, noParse: true },
  },
  userType: {
    modeComp: true,
  },
};
