import React, { Fragment } from "react";

import { SwiperSlide } from "swiper/react";
import NavTile from "../NavTile";
import Carousel from "../Carousel";
import { BUTTONSTYLE, LINKTYPE } from "@constants";

import "./nav-tiles-slider.scss";
import isTrue from "@assets/js/is-true";

const NavTilesSliderController = ({ fields }) => {
  const isAutoMode = fields?.data?.__type?.name === "RelatedSliderAuto" ? true : false;

  const root = isAutoMode ? fields?.data : fields;

  const tiles = isAutoMode ? fields?.data.tiles.values : fields?.tiles;

  const isCompact = isAutoMode ? isTrue(root?.item?.isCompact?.field) : isTrue(root?.isCompact);

  const itemCount = tiles?.length;
  let parsedTiles = [];

  if (!tiles || itemCount < 1) {
    return false;
  }
  tiles.map((item) => {
    let itemRoot = isAutoMode ? item : item.fields;

    parsedTiles.push({
      media: isAutoMode ? itemRoot.media?.value : { fields: itemRoot.media?.fields },
      bodyText: isAutoMode ? itemRoot.bodyText.field : itemRoot.bodyText,
      headline: isAutoMode ? itemRoot.headline.field : itemRoot.headline,
      button: isAutoMode
        ? {
            fields: {
              link: {
                value: {
                  href: itemRoot.link,
                  text: itemRoot.buttonText.field.value,
                  linktype: LINKTYPE.EXTERNAL,
                },
              },
              style: { value: BUTTONSTYLE.LINK },
              hasArrow: { value: true },
            },
          }
        : { fields: itemRoot.button?.fields },
    });
  });
  let otherContent = {
    headline: isAutoMode ? root.item.headline.field : root.headline,
    bodyText: isAutoMode ? root.item.bodyText.field : root.bodyText,
  };

  if (root?.footerButton?.fields || root?.item?.footerButton?.field?.fields) {
    otherContent.footerButton = isAutoMode
      ? root.item.footerButton.field.fields
      : root.footerButton.fields;
  }

  return <NavTilesSlider fields={otherContent} tiles={parsedTiles} isCompact={isCompact} />;
};

const NavTilesSlider = ({ fields, tiles, isCompact }) => {
  const args = {
    fields: fields,
    className: `nav-tiles-slider ${isCompact ? "nav-tiles-slider--compact" : ""}`,

    children: (
      <>
        {tiles &&
          tiles.length > 0 &&
          tiles.map((tile, index) => {
            return (
              <Fragment key={`referenceFragItem${index}`}>
                {tile?.media && (
                  <>
                    <SwiperSlide
                      key={`referenceSlideItem${index}`}
                      className="nav-tiles-slider__slide"
                    >
                      <NavTile fields={tile} isButtonEnabled={true} />
                    </SwiperSlide>
                  </>
                )}
              </Fragment>
            );
          })}
      </>
    ),
  };

  return <Carousel {...args} />;
};

export default NavTilesSliderController;
