import React, { useState, Fragment, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import produce from "immer";
import path from "@assets/js/browser-path";

import PropTypes from "prop-types";
import { IMAGE } from "@constants";

import PDPConfiguratorFilters from "../PDPConfiguratorFilters";
import PDPSwatchTile from "../PDPSwatchTile";

import ViewportSizes from "@assets/js/viewport-sizes";

import "./pdp-model-selector.scss";

const modelImageUrl = path.join(process.env.REACT_APP_PRODUCT_IMAGE_API, "r");

const PDPModelSelector = ({ tiles, itemClickHandler, currentViewport }) => {
  const { t } = useTranslation();

  const texts = {
    selectModelType: t(
      "FritzHansen_Project_ConsumersAndProfessionals_PDP_SelectModelType"
    ),
    noFilter: t("FritzHansen_Project_ConsumersAndProfessionals_PDP_NoFilter"),
  };
  const rootRef = useRef(null);
  // ====================================================================================

  //parse used material types
  let filters = tiles.map((item) => ({
    id: item.typeText,
    label: item.typeText === "_NO_TYPE_" ? texts.noFilter : item.typeText,
    isActive: false,
  }));
  //remove duplicates
  filters = Array.from(new Set(filters.map((a) => a.id))).map((id) => {
    return filters.find((a) => a.id === id);
  });

  //console.log({ filters });

  const [filterList, setFilterList] = useState(filters);
  const [activeFilter, setActiveFilter] = useState(null);

  const filterClickHandler = (item) => {
    //console.log("filterClickHandler", item);

    const newFilters = produce(filterList, (tmpList) => {
      tmpList.forEach((filter) => {
        if (item.id === filter.id) filter.isActive = !filter.isActive;
        else if (item.id !== filter.id) filter.isActive = false;
      });
    });
    setFilterList(newFilters);
    if (activeFilter && activeFilter.id === item.id) {
      setActiveFilter(null);
    } else {
      setActiveFilter(item);
    }
  };

  const isActiveFilterType = (item) => {
    return !activeFilter || item.typeText === activeFilter.id;
  };

  useEffect(() => {
    if (
      currentViewport.name === ViewportSizes.SMALL.name ||
      currentViewport.name === ViewportSizes.MEDIUM.name
    ) {
      disableBodyScroll(rootRef.current);
    }
    return () => {
      enableBodyScroll(rootRef.current);
    };
  }, []);

  // ====================================================================================

  return (
    <div className="pdp-model-selector">
      <h3>{texts.selectModelType}</h3>
      {filterList && filterList.length > 1 && (
        <PDPConfiguratorFilters
          list={filterList}
          filterClickHandler={filterClickHandler}
        />
      )}
      <div className="pdp-model-selector__content" ref={rootRef}>
        <div className="pdp-model-selector__tiles">
          {tiles.map((item) => {
            return (
              <Fragment key={`modelTile${item.typeId}`}>
                {isActiveFilterType(item) && (
                  <PDPSwatchTile
                    {...item}
                    imageUrl={`${modelImageUrl}/${item.configurationId}-r0.webp?bgcolor=ffffff`}
                    imageType={IMAGE.PRODUCT}
                    // tag={item.typeId}
                    title={item.definitionName}
                    item={item}
                    itemClickHandler={itemClickHandler}
                  />
                )}
              </Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};
PDPModelSelector.propTypes = {
  tiles: PropTypes.array.isRequired,
  filters: PropTypes.object,
};

export default PDPModelSelector;
