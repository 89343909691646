import { INTERNATIONAL } from "@constants";

export default function (language, commerceMarket, currency, amount) {
  if (!language || !commerceMarket || !currency || isNaN(amount) ) {
    return "–";
  } else {
    // console.log("GET PRICE", currency, `${language}-${commerceMarket.toUpperCase()}`);
    const nfWithCurrency = new Intl.NumberFormat(commerceMarket === INTERNATIONAL ? process.env.REACT_APP_COMMERCE_FALLBACK_LOCALE :`${language}-${commerceMarket.toUpperCase()}`, {
      style: "currency",
      currency: currency,
      currencyDisplay: "code",
    });
    let tp = nfWithCurrency.format(amount);
    return tp;
  }
}
