import React from "react";
import SwiperCore, { Navigation } from "swiper";
import { Swiper } from "swiper/react";
import NavButton from "../NavButton";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { ArrowMediumSvg } from "@assets/js/icons";
import getUniqueId from "@assets/js/get-unique-id.js";
import classnames from "classnames";

import "./carousel.scss";
import isTrue from "@assets/js/is-true";

// install Swiper components
SwiperCore.use([Navigation]);

const Carousel = ({
  className,
  settings = { noNavigation: false },
  swiperProps,
  children,
  showMoreBtn,
  fields = { isInverted: false },
}) => {
  const carouselId = getUniqueId(true);
  // const { uid } = rendering;
  let swiperExtendedProps = {
    freeMode: false,
    slidesPerView: "auto",
  };

  swiperExtendedProps = { ...swiperExtendedProps, ...swiperProps };

  if (!settings.noNavigation) {
    swiperExtendedProps.navigation = {
      nextEl: `#${carouselId}SliderNext`,
      prevEl: `#${carouselId}SliderPrev`,
      disabledClass: "is-disabled",
    };
  }

  const css = classnames({
    carousel: true,
    [className]: className && className.length,
    "carousel--inverted ": isTrue(fields.isInverted),
    "carousel--has-bgcolor": fields?.backgroundColorHex?.value,
  });

  return (
    <div
      className={css}
      style={
        fields.backgroundColorHex && {
          "--carousel-bgc": fields.backgroundColorHex.value,
        }
      }
    >
      {(fields.headline?.value ||
        fields.bodyText?.value ||
        !settings.noNavigation) && (
        <div className="carousel__header">
          {fields.headline?.value && (
            <div className="carousel__headline">
              <Text
                tag={fields.headlineType ? fields.headlineType : "h2"}
                field={fields.headline}
              />
            </div>
          )}
          {fields.bodyText?.value && (
            <div className="carousel__bodytext">
              <Text tag="p" field={fields.bodyText} />
            </div>
          )}
          {/* {!settings.noNavigation && (
            <div className="carousel__nav">
              <button
                className="carousel__prev"
                id={`${carouselId}SliderPrev`}
              ></button>
              <button
                className="carousel__next"
                id={`${carouselId}SliderNext`}
              ></button>
            </div>
          )} */}
          {!settings.noNavigation && (
            <div className="carousel__nav">
              <button className="carousel__prev" id={`${carouselId}SliderPrev`}>
                <ArrowMediumSvg />
              </button>
              <button className="carousel__next" id={`${carouselId}SliderNext`}>
                <ArrowMediumSvg />
              </button>
            </div>
          )}
        </div>
      )}

      <Swiper {...swiperExtendedProps}>
        {children} {showMoreBtn}
      </Swiper>

      {fields.footerButton && (
        <div className="carousel__footer">
          <NavButton fields={fields.footerButton} />
        </div>
      )}
    </div>
  );
};

export default Carousel;
