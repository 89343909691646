import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import uniqueId from "@assets/js/get-unique-id";

import "./checkbox.scss";

const Checkbox = ({
  name,
  register,
  error,
  id = uniqueId,
  value,
  label,
  checked,
  disabled,
  required,
  labelLeft,
  changeHandler,
  link
}) => {
  const [isChecked, setChecked] = useState(checked);

  const onChangeHandler = () => {
    let newValue = !isChecked;
    setChecked(newValue);
    if (changeHandler) changeHandler(newValue);
  };
  useEffect(() => {
    setChecked(checked);
  }, [checked]);

  const classList = classnames({
    checkbox: true,
    "checkbox--label-left": labelLeft,
    "is-invalid": error,
  });

  return (
    <div className={classList}>
      <div className="checkbox__wrapper">
        <input
          className="checkbox__box"
          name={name}
          type="checkbox"
          value={value || ""}
          id={id}
          checked={isChecked || false}
          disabled={disabled}
          required={required}
          onChange={onChangeHandler}
          ref={register}
        />
        <label className="checkbox__label" htmlFor={id}>
          {label}
          {required && <span>*</span>}
          {link}
        </label>
      </div>
      {error && <p className="checkbox__message">{error.message}</p>}
    </div>
  );
};

Checkbox.propTypes = {};

export default Checkbox;
