import NavButton from "./nav-button";
import NavListButton from "./nav-list-button";

export default {
  default: {
    fields: {
      headline: "Lorem ipsum",
      primaryList: [
        NavListButton.default,
        NavListButton.variation1,
        NavListButton.default,
        NavListButton.variation1,
        NavListButton.default,
        NavListButton.variation1,
      ],
    },
  },

  noHoverImage: {
    fields: {
      headline: "Lorem ipsum",
      primaryList: [
        NavListButton.noHoverImage,
        NavListButton.noHoverImage,
        NavListButton.noHoverImage,
        NavListButton.noHoverImage,
        NavListButton.noHoverImage,
        NavListButton.noHoverImage,
      ],
    },
  },

  extended: {
    fields: {
      headline: "Lorem ipsum",
      primaryList: [
        NavListButton.default,
        NavListButton.variation1,
        NavListButton.default,
        NavListButton.variation1,
        NavListButton.default,
        NavListButton.variation1,
      ],

      seeAllLink: NavButton.link,
      seeMoreText: "See more:",
      secondaryList: [
        NavListButton.default,
        NavListButton.variation1,
        NavListButton.default,
        NavListButton.variation1,
        NavListButton.default,
        NavListButton.variation1,
      ],
    },
  },

  forSearch: {
    fields: {
      primaryList: [NavListButton.noHoverImage, NavListButton.noHoverImage, NavListButton.noHoverImage],
    },
  },
};
