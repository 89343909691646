import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import axios from "axios";
import ProductTilesSlider from "../ProductTilesSlider";
import path from "@assets/js/browser-path";
import useGlobalStore from "@store/global";

const fetchProducts = (params, lang) =>
  axios.get(
    path.join(
      process.env.REACT_APP_PRODUCTSAPI,
      "Product",
      lang,
      "search",
      "ModelsFilter"
    ),
    {
      params,
    }
  );

const MaterialProductSliderController = ({ fields }) => {
  const { t } = useTranslation();

  const itemsShown = fields?.itemsShown?.value ? fields.itemsShown.value : 1;
  const taxonomy = fields?.taxonomy?.value ? fields.taxonomy.value : null;
  const sc_lang = useGlobalStore((state) => state.sc_lang);
  const headline = fields?.headline
    ? fields.headline
    : { value: "Test headline" };

  const [tiles, setTiles] = useState([]);

  useEffect(() => {
    async function loadProducts() {
      const response = await fetchProducts(
        {
          count: itemsShown,
          materialId: taxonomy,
        },
        sc_lang
      );

      setTiles(response.data.response.docs);
    }
    if (taxonomy) loadProducts();
  }, []);

  return (
    <>
      {taxonomy ? (
        <ProductTilesSlider tiles={tiles} headline={headline} />
      ) : (
        <></>
      )}
    </>
  );
};

MaterialProductSliderController.propTypes = {
  fields: PropTypes.object,
};

export default MaterialProductSliderController;
