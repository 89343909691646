import React, { useState, useEffect } from "react";
import produce from "immer";
import cuid from "cuid";

import FeatureBlock from "../FeatureBlock";
import NavTile from "../NavTile";
import ActionButton from "../ActionButton";
import TagList from "../TagList";

import { BUTTONSTYLE } from "@constants";

import "./references.scss";

const ReferencesController = ({ fields }) => {
  const isManualMode = fields.data?.__type?.name === "ReferencesManual";

  const tagList = fields.data?.tags?.list?.values;
  const tagCount = tagList?.length;

  const itemList = isManualMode
    ? fields.data?.references?.values?.references
    : fields.data?.references?.values;
  const itemCount = itemList?.length;
  const textFields = {
    filterText: fields.data?.texts?.filterText,
    loadMoreText: fields.data?.texts?.loadMoreText,
  };

  if (!tagList || tagCount < 1) {
    return false;
  }
  if (!itemList || itemCount < 1) {
    return false;
  }

  if (tagList && tagCount > 0) {
    tagList.map((tag) => {
      if (tag && tag.active) {
        tag.active = false;
      }
    });
  }

  const generateId = cuid();

  itemList.map((item, index) => {
    let tagsText = "";
    item.tags.values.forEach((tag, index) => {
      if (index > 0) tagsText += ", ";
      tagsText += tag.title.value;
    });
    item.navTile = {
      fields: {
        id: generateId,
        media: { fields: { image: item.image?.value?.fields?.image } },
        bodyText: item.bodyText,
        tag: { value: tagsText },
        headline: item.headline,
        button: {
          fields: {
            link: { value: { href: item.link, text: item.buttonText } },
          },
        },
      },
    };
    item.feature = {
      fields: {
        media1: item.navTile.fields.media,
        media2: {
          fields: { image: item.secondaryMedia?.value?.fields?.image },
        },
        bodyText: item.bodyText,
        tag: { value: tagsText },
        headline: item.headline,
        isBodyLeft: { value: false },
        backgroundColorHex: item.componentBackground,
        button: isManualMode
          ? {
              fields: {
                link: { value: item.link?.value?.value },
              },
            }
          : {
              fields: {
                link: { value: { href: item.link, text: item.buttonText } },
              },
            },
      },
    };

    if (index % 2 != 0) {
      item.feature.fields.isBodyLeft = { value: true };
    }
  });

  return (
    <References fields={textFields} tagList={tagList} itemList={itemList} />
  );
};

const References = ({ fields, tagList, itemList }) => {
  const loadMoreLimit = 4;

  const itemCount = itemList.length;

  //All states
  const [availableTags, setAvailableTags] = useState(tagList);

  const [filteredItems, setFilteredItems] = useState(itemList);
  const [tileItems, setTileItems] = useState(itemList.slice(3, loadMoreLimit));
  const [expanded, setExpanded] = useState(tileItems.length > loadMoreLimit);

  const showAllHandler = () => {
    setTileItems(filteredItems.slice(3));
    setExpanded(true);
  };

  const tagUpdateHandler = (tagId) => {
    //Toggle tag active
    let locatedTagIndex = availableTags.indexOf(
      availableTags.find((tag) => tag.id.value === tagId.value)
    );

    const newAvailableTags = produce(availableTags, (tmpTags) => {
      if (locatedTagIndex > -1)
        tmpTags[locatedTagIndex].active = !tmpTags[locatedTagIndex].active;

      tmpTags.forEach((tag) => {
        if (tmpTags.indexOf(tag) != locatedTagIndex) {
          tag.active = false;
        }
      });
    });

    setAvailableTags(newAvailableTags);
  };

  useEffect(() => {
    const activeTags = availableTags.filter((tag) => tag.active);
    const activeTagsCount = activeTags.length;

    if (activeTagsCount === 0) {
      setFilteredItems(itemList);
      setTileItems(itemList.slice(3, loadMoreLimit));
    } else {
      // setExpanded(true);
      let matchingReferences = [];

      //locate matching references
      for (let h = 0; h < activeTagsCount; h++) {
        const currentTag = activeTags[h];

        for (let i = 0; i < itemCount; i++) {
          const item = itemList[i];

          const itemTagCount = item.tags.values.length;
          for (let j = 0; j < itemTagCount; j++) {
            let t = item.tags.values[j];
            if (t.id.value === currentTag.id.value) {
              matchingReferences.push(JSON.parse(JSON.stringify(item)));
              break;
            }
          }
        }
      }
      let matchCount = matchingReferences.length;
      for (let k = 0; k < matchCount; k++) {
        let match = matchingReferences[k];
        //ensure alternating features
        if (k % 2 == 0) {
          match.feature.fields.isBodyLeft = { value: false };
        } else {
          match.feature.fields.isBodyLeft = { value: true };
        }
      }

      //state updates

      setFilteredItems(matchingReferences);
      setTileItems(matchingReferences.slice(3));
    }
  }, [availableTags]);

  return (
    <div className="references">
      <TagList
        fields={{
          label: fields?.filterText,
          buttonText: fields?.loadMoreText,
        }}
        tagList={availableTags}
        tagUpdateHandler={tagUpdateHandler}
      />
      {filteredItems.length > 0 && (
        <>
          <FeatureBlock fields={filteredItems[0].feature.fields} />
        </>
      )}
      {filteredItems.length > 1 && (
        <>
          <FeatureBlock fields={filteredItems[1].feature.fields} />
        </>
      )}
      {filteredItems.length > 2 && (
        <>
          <FeatureBlock fields={filteredItems[2].feature.fields} />
        </>
      )}

      {tileItems.length > 0 && (
        <div className="references__tiles">
          {tileItems?.map((item) => {
            return (
              <NavTile
                key={item?.navTile?.fields?.id}
                fields={item?.navTile?.fields}
                isButtonEnabled={item?.navTile?.fields?.button}
              ></NavTile>
            );
          })}
        </div>
      )}
      {!expanded && itemCount > loadMoreLimit && (
        <div className="references__footer">
          <ActionButton
            text={fields.loadMoreText?.value}
            buttonType={BUTTONSTYLE.GHOST}
            clickHandler={showAllHandler}
          />
        </div>
      )}
    </div>
  );
};

export default ReferencesController;
