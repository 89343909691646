import React, { useEffect, useState } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";

import TagListButton from "../TagListButton";

import { motion, AnimatePresence } from "framer-motion";
import classnames from "classnames";

import ViewportSizes from "@assets/js/viewport-sizes.js";

import onMediaChanged from "@assets/js/on-media-changed.js";
import getViewportSize from "@assets/js/get-viewport-size.js";

import { QUERY_PARAMETER } from "@constants";
import urlParams from "@assets/js/url-params";
import { log } from "@assets/js/utils";

import { itemUpMotion, containerMotion } from "@assets/js/animation-variations";

import "./tag-list.scss";

const TagList = ({ fields = {}, tagList = [], tagUpdateHandler }) => {
  let isMobile = false;
  let currentViewport = getViewportSize();

  onMediaChanged((newViewport) => {
    switch (newViewport.name) {
      case ViewportSizes.SMALL.name:
      case ViewportSizes.MEDIUM.name:
        isMobile = true;
        break;
      case ViewportSizes.LARGE.name:
      case ViewportSizes.XLARGE.name:
        isMobile = false;
        break;
    }
  });
  onMediaChanged(currentViewport);

  // const containerMotion = {
  //   animate: {
  //     transition: {
  //       delayChildren: 0.3,
  //       staggerChildren: 0.2,
  //     },
  //   },
  // };

  // const itemMotion = {
  //   initial: { opacity: 0 },
  //   animate: {
  //     opacity: 1,
  //   },
  // };

  const [expanded, setExpanded] = useState(false);

  const showAllHandler = () => {
    setExpanded(!expanded);
  };

  const listClasses = classnames({
    "tag-list": true,
    "is-expanded": expanded,
  });

  if (typeof fields.label != "object") fields.label = { value: fields.label };

  useEffect(() => {
    let activeTag = urlParams.get().get(QUERY_PARAMETER.ACTIVE_TAG);
    log("activeTag=" + activeTag);
    if (activeTag && activeTag.length > 0) {
      let locatedTag = tagList.find(
        (tag) =>
          activeTag.toLowerCase() === tag?.id?.field?.value.toLowerCase() ||
          activeTag.toLowerCase() === tag?.title?.field?.value.toLowerCase()
      );
      log("activeTag=" + activeTag + ", locatedTag?", locatedTag);
      try {
        tagUpdateHandler(locatedTag.id);
      } catch (err) {
        //no tag found, do nothing
      }
    }
  }, []);

  return (
    <>
      {fields && tagList && tagList.length > 1 && (
        <div className={listClasses}>
          <div className="tag-list__inner">
            {fields.label && fields.label?.value && (
              <div className="tag-list__label">
                <Text tag="p" field={fields?.label} />
              </div>
            )}

            <AnimatePresence>
              <motion.ul
                variants={containerMotion}
                animate="animate"
                initial="initial"
                transition={{ duration: 2, delay: 0.4 }}
              >
                {tagList.map((item, index) => {
                  return (
                    <motion.li
                      key={"tag-list__item" + index}
                      className="tag-list__item"
                      variants={itemUpMotion}
                    >
                      <TagListButton
                        fields={item}
                        tagUpdateHandler={tagUpdateHandler}
                      />
                    </motion.li>
                  );
                })}
                {!expanded && !isMobile && tagList.length > 4 && (
                  <motion.li key="tag-list__expand" variants={itemUpMotion}>
                    <button
                      className="tag-list__expand"
                      onClick={showAllHandler}
                    >
                      <Text
                        tag="p"
                        field={
                          fields?.buttonText
                            ? fields?.buttonText
                            : fields?.loadMoreText
                        }
                      />
                    </button>
                  </motion.li>
                )}
              </motion.ul>
            </AnimatePresence>
          </div>
        </div>
      )}
    </>
  );
};

export default TagList;
