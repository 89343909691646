import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

/**
 * The main layout (columns) of the Content.
 * Navigation is automatically generated based on the components added to the layout,
 * and does not need to be manually maintained.
 */
const ContentLayout = (props) => {
  return (
    <div className="row">
      <div className="col-sm-5">
        <Placeholder name="jss-layout-left" rendering={props.rendering} />
      </div>
      <div className="col-sm-6">
        <Placeholder name="jss-layout-right" rendering={props.rendering} />
      </div>
    </div>
  );
};

export default ContentLayout;
