import MediaFocal from "./media-focal";

export default {
  default: {
    fields: {
      // href: "#",
      // noParse: true,
      // title: "SVANEN",
      media: {
        fields: MediaFocal.default,
      },
    },
  },
};
