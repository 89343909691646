import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";

import ProductTilesSlider from "../ProductTilesSlider";
import path from "@assets/js/browser-path";
import useGlobalStore from "@store/global";
import dummyData from "@assets/storybook/all-dummy-data.js";

import "./material-set-product-slider-controller.scss";

const fetchProducts = (params, lang) =>
  axios.get(
    path.join(
      process.env.REACT_APP_PRODUCTSAPI,
      "Product",
      lang,
      "search",
      "ModelsFilter"
    ),
    {
      params,
    }
  );

const MaterialSetProductSliderController = ({ fields }) => {
  const { t } = useTranslation();

  const itemsShown = fields?.itemsShown?.value ? fields.itemsShown.value : 1;
  const appliedMaterialId = fields?.materialId?.value;
  const lang = useGlobalStore((state) => state.language);
  const headline = fields?.headline
    ? fields.headline
    : { value: "Test headline" };

  const [tiles, setTiles] = useState([]);

  useEffect(() => {
    async function loadProducts() {
      const response = await fetchProducts(
        {
          count: itemsShown,
          materialId: appliedMaterialId,
        },
        lang
      );

      setTiles(response.data.response.docs);
    }
    if (appliedMaterialId) loadProducts();
  }, []);

  if (tiles.length == 0) {
    setTiles([
      dummyData.ProductTile.newData,
      dummyData.ProductTile.newData2,
      dummyData.ProductTile.newData3,
    ]);
  }

  return (
    <>
      {appliedMaterialId ? (
        <ProductTilesSlider tiles={tiles} headline={headline} />
      ) : (
        <></>
      )}
    </>
  );
};

MaterialSetProductSliderController.propTypes = {};

export default MaterialSetProductSliderController;
