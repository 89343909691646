import MediaFocal from "./media-focal";

export default {
  default: {
    fields: {
      data: {
        fields: {
          media: {
            field: MediaFocal.default,
          },
          layout: {
            fields: "TextTop",
          },
          bodyText: {
            field:
              "Lorem ipsum dolor sit amet consectetur adipiscing elit quam",
          },
          headline: {
            field: "headline (Tall)",
          },
        },
      },
    },
  },
  tall: {
    fields: {
      data: {
        fields: {
          media: {
            field: MediaFocal.default,
          },
          layout: {
            fields: "Tall",
          },
          bodyText: {
            field:
              "Lorem ipsum dolor sit amet consectetur adipiscing elit quam",
          },
          headline: {
            field: "headline",
          },
        },
      },
    },
  },
  tall2: {
    fields: {
      data: {
        fields: {
          media: {
            field: MediaFocal.default,
          },
          layout: {
            fields: "Tall",
          },
          bodyText: {
            field:
              "Lorem ipsum dolor sit amet consectetur adipiscing elit quam",
          },
          headline: {
            field: "Tall",
          },
        },
      },
    },
  },
  split: {
    fields: {
      data: {
        fields: {
          media: {
            field: MediaFocal.default,
          },
          layout: {
            fields: "TextTop",
          },
          bodyText: {
            field:
              "Lorem ipsum dolor sit amet consectetur adipiscing elit quam",
          },
          headline: {
            field: "Split",
          },
        },
      },
    },
  },
};
