import React, { useEffect, useState } from "react";
import Taglist from "../TagList";
import produce from "immer";

import useGlobalStore, {useCommerceMarket} from "@store/global";

import "./flowbox-controller.scss";

const loadFlowbox = () =>
  new Promise((resolve) => {
    (function (d, id) {
      if (!window.flowbox) {
        var f = function () {
          f.q.push(arguments);
        };
        f.q = [];
        window.flowbox = f;
      }

      if (d.getElementById(id)) {
        return resolve();
      }
      var s = d.createElement("script"),
        fjs = d.scripts[d.scripts.length - 1];
      s.id = id;
      s.async = true;
      s.src = "https://connect.getflowbox.com/flowbox.js";

      fjs.parentNode.insertBefore(s, fjs);
      resolve();
    })(document, "flowbox-js-embed");
  });

  
  const FlowBoxController = ({ fields, flowKey: key }) => {
    const [availableTags, setAvailableTags] = useState(fields?.tagList);
    
    
    const containerName = "js-flowbox-flow";
  const container = `${containerName}-${key}`;


  const sc_lang = useGlobalStore((state) => state.sc_lang);
  const [commerceMarket] = useCommerceMarket();

  useEffect(() => {
    if ( commerceMarket && sc_lang ) {
      const locale = `${sc_lang}-${commerceMarket.toUpperCase()}`;

      loadFlowbox().then(() => {
        window.flowbox("init", {
          container: `#${container}`,
          key: fields?.flowboxKey?.value,
          locale,
        });
      });
    }
  }, [commerceMarket, sc_lang, container, key]);

  let activeItems = [];

  const tagUpdateHandler = (tagId) => {
    let locatedTagIndex = availableTags.indexOf(
      availableTags.find((tag) => tag.id === tagId)
    );

    const newAvailableTags = produce(availableTags, (tmpTags) => {
      if (locatedTagIndex > -1)
        tmpTags[locatedTagIndex].active = !tmpTags[locatedTagIndex].active;

      tmpTags.forEach((tag) => {
        if (tmpTags.indexOf(tag) != locatedTagIndex) {
          tag.active = false;
        }
      });
    });

    setAvailableTags(newAvailableTags);

    updateTagsArray(tagId);
  };

  const updateTagsArray = (id) => {
    availableTags.forEach((item) => {
      if (item.id === id) {
        activeItems.push(item?.fields?.id?.value);

        updateFlow(activeItems);

        if (item.active) {
          activeItems.pop();

          updateFlow(activeItems);
        }
      }
    });
  };

  function updateFlow(tag) {
    window.flowbox("update", {
      key: fields?.flowboxKey?.value,
      tags: [tag],
    });
  }

  return (
    <div className="flowbox-controller">
      {fields?.tagList && (
        <Taglist
          fields={{
            label: fields?.tagListName.value,
            buttonText: fields?.loadMoreText,
          }}
          tagUpdateHandler={(id) => tagUpdateHandler(id)}
          tagList={availableTags}
        />
      )}
      <div id={container} />
    </div>
  );
};

export default FlowBoxController;
