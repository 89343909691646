export default {
  default: {
    subTotal: 348,
    taxTotal: 52.2,
    discountTotal: 0,
    orderTotal: 400.2,
    shippingTotal: 0,
    vat: 52.2,
    currency: "EUR",
  },
};
