import React from "react";
import MediaFocal from "../MediaFocal";
import TextTile from "../TextTile";
import LinkWrapper from "../LinkWrapper";
import { BUTTONSTYLE } from "@constants";

import classnames from "classnames";

import "./nav-panel.scss";

const NavPanel = ({ fields = {}, isButtonEnabled = true, isInverted }) => {
  const link = fields.button?.fields?.link?.value?.href;
  // if (fields.button && fields.button.fields) {
  //   fields.button.fields.style.value = BUTTONSTYLE.LINK;
  //   fields.button.fields.hasArrow.value = true;
  // }

  const contactInfoAvailable = fields.phone?.value || fields.email?.value;

  const interactive = !contactInfoAvailable && link ? true : false;

  const classList = classnames({
    "nav-panel": true,
    "nav-panel--interactive": isButtonEnabled,
  });

  return (
    <div className={classList}>
      {isButtonEnabled ? (
        <div className="nav-panel__img">
          <LinkWrapper field={fields.button?.fields}>
            <MediaFocal fields={fields?.media?.fields} />
          </LinkWrapper>
        </div>
      ) : (
        <div className="nav-panel__img">
          <MediaFocal fields={fields?.media?.fields} />
        </div>
      )}

      <TextTile
        fields={fields}
        contactInfoAvailable={contactInfoAvailable}
        isButtonEnabled={isButtonEnabled?.value}
        isInverted={isInverted?.value}
      />
    </div>
  );
};

export default NavPanel;
