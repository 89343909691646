import React, {useState} from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import classnames from "classnames";
import ProductImage from "../../ProductImage";
import "./pdp-swatch-tile.scss";

const PDPSwatchTile = ({
  centerImage,
  isActive,
  isUnallowed,

  title,
  tag,

  imageType,
  materialDisplayName,
  configurationId,
  materialId,

  item,
  itemClickHandler,
}) => {
  const { t } = useTranslation();
  const texts = {
    notCombinable: t("FritzHansen_Project_ConsumersAndProfessionals_PDP_NotCombinable"),
  };
  const [imageLoadError,setImageLoadError] = useState(false);

  const classList = classnames({
    "pdp-swatch-tile": true,
    "pdp-swatch-tile--cover": !centerImage && !imageLoadError,
    "is-active": isActive,
    "is-unallowed": isUnallowed,
    "is-animated": title?.length > 18,
    "no-image": imageLoadError
  });

  const onError = () => {
    setImageLoadError(true);
  };

  return (
    <button className={classList} onClick={() => itemClickHandler(item, isUnallowed)}>
      <div className="pdp-swatch-tile__inner">
        <ProductImage type={imageType} imageId={materialId || configurationId || ""} alt={title} onErrorHandler={onError} useLargeFallback={true}/>
        {tag && <span className="pdp-swatch-tile__tag">{tag}</span>}
        {isUnallowed && <span className="pdp-swatch-tile__message">{texts.notCombinable}</span>}
      </div>
      <h6>
        <span>{title}</span>
      </h6>
    </button>
  );
};
PDPSwatchTile.propTypes = {
  centerImage: PropTypes.bool,
  isActive: PropTypes.bool,
  isUnallowed: PropTypes.bool,
  title: PropTypes.string,
  tag: PropTypes.string,
  item: PropTypes.object.isRequired,
  itemClickHandler: PropTypes.func.isRequired,
};

export default PDPSwatchTile;
