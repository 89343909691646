export default {
  default: {
    fields: {
      link: {
        value: {
          href: "http://google.com",
          text: "Lorem ipsum",
          linktype: "external",
          url: "http://google.com",
          anchor: "",
          title: "google",
          target: "",
        },
      },
      image: {
        value: {
          src: "https://picsum.photos/400/600",
          alt: "Feature002",
          width: "400",
          height: "600",
          focalpoint: "8, 23",
        },
      },
    },
  },
  variation1: {
    fields: {
      link: {
        value: {
          href: "http://google.com",
          text: "Dolor sit amet",
          linktype: "external",
          url: "http://google.com",
          anchor: "",
          title: "google",
          target: "",
        },
      },
      image: {
        value: {
          src: "https://picsum.photos/400/600",
          alt: "Feature002",
          width: "400",
          height: "600",
          focalpoint: "8, 23",
        },
      },
    },
  },
  noHoverImage: {
    fields: {
      link: {
        value: {
          href: "http://google.com",
          text: "Dolor sit amet",
          linktype: "external",
          url: "http://google.com",
          anchor: "",
          title: "google",
          target: "",
        },
      },
    },
  },
};
