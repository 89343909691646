import React, { useState, useEffect } from "react";
import classnames from "classnames";

import "./input-field.scss";

const InputField = ({
  register,
  name,
  label = "default label",
  type,
  value,
  placeHolder,
  disabled,
  required,
  autoComplete,
  error,
  id,
  isInverted,
  ariaDescription,
  changeHandler,
  blurHandler,
  hasValue
}) => {
  const [val, setVal] = useState(value);
  const [autoFilled, setAutoFilled] = useState(false);

  const onBlurHandler = (target) => {
    if (blurHandler) blurHandler(target.value);
  };
  const onAutoFillHandler = () => {
    setAutoFilled(true);
  };

  const onChangeHandler = (target) => {
    setVal(target.value);
    if (changeHandler) changeHandler(target.value);
  };
  useEffect(() => {
    setVal(value);
  }, [value]);

  const classList = classnames({
    "input-field": true,
    "is-valid": (val && !error) || autoFilled,
    "input-field--inverted": isInverted,
    "is-invalid": error,
    "is-disabled": disabled,
  });

  let localProps = {
    name: name,
    type: type,
    disabled: disabled,
    required: required,
    placeholder: placeHolder,
    "aria-describedby": ariaDescription,
    id: id,
    ref: register,
    onChange: ({ target }) => onChangeHandler(target),
    onBlur: ({ target }) => onBlurHandler(target),
    autoComplete: autoComplete,
    onAnimationStart: () => onAutoFillHandler(),
  };
  if (value) {
    localProps.value = val;
  }

  return (
    <div className={classList}>
      <input className={`input-field__field ${val || hasValue ? "" : "placeholder-shown"}`} {...localProps} />

      {label && (
        <label className="input-field__label" htmlFor={id}>
          {label}
          {required && <span>*</span>}
        </label>
      )}
      {error && <p className="input-field__message">{error.message}</p>}
    </div>
  );
};

export default InputField;
