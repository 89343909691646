import React from "react";
import { NavLink } from "react-router-dom"
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { useTranslation } from "react-i18next";
import path from "@assets/js/browser-path";
import { FILTER_NO_TYPE } from "@constants";

import "./material-set-tile.scss";

const MaterialSetTile = ({ fields }) => {
  const { t } = useTranslation();

  const texts = {
    other: t("FritzHansen_Project_ConsumersAndProfessionals_General_Other"),
  };
  const getImageUrl = (sw) => {
    return path.join(
      process.env.REACT_APP_PRODUCT_IMAGE_API,
      "r",
      `SWATCH-${fields.firstMaterialId}.jpg?sw=${sw}`
    );
  };

  return (
    <>
      {fields && (
        <NavLink className="material-set-tile" to={fields.href}>
          <div className="material-set-tile__image">
            <img
              src={getImageUrl(1000)}
              alt={fields.firstMaterialId}
              srcSet={`${getImageUrl(1000)} 1200w, ${getImageUrl(500)}`}
            />

            <span className="material-set-tile__tag">
              <Text
                tag="span"
                field={fields.tag.value === FILTER_NO_TYPE ? { value: texts.other } : fields.tag}
              />
            </span>
          </div>

          <div className="material-set-tile__content">
            {fields.headline && <Text tag="h3" field={fields.headline} />}
            {fields.bodyText && <Text tag="p" field={fields.bodyText} />}
            <h5>
              <Text tag="span" field={fields.count} />
            </h5>
          </div>
        </NavLink>
      )}
    </>
  );
};

export default MaterialSetTile;
