import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import isEmpty from "lodash-es/isEmpty";
// import PDPConfigurator from "../pdp/PDPConfigurator";
// import PDPProductViewer from "../pdp/PDPProductViewer";
// import PDPProductSpecification from "../pdp/PDPProductSpecification";
// import PDPProductThumbs from "../pdp/PDPProductThumbs";
import useGlobalStore, { useCommerceMarket } from "@store/global";
import PDP from "../pdp";
import usePDPStore, {
  useConfigurationType,
  useBasicConfiguration,
  useActiveConfigurationSKU,
  useActiveModelData,
  useActiveProductData,
} from "@store/pdp";

import testForAvailability from "@assets/js/test-for-availability";
import { log } from "@assets/js/utils";

// ReactDOMServer doesn't supprt suspense just yet
// const PDP = lazy(() => import("../pdp"));

const PDPController = ({ fields, pageFields, allowOrder, rendering }) => {
  const { t } = useTranslation();

  const texts = {
    notAvailable: t(
      "FritzHansen_Project_ConsumersAndProfessionals_PDP_ProductNotAvailableInMarket"
    ),
  };

  const [, setConfigurationType] = useConfigurationType();

  const configurationInitParams = fields?.data?.item;

  const fetchProductImageData = usePDPStore(
    (state) => state.fetchProductImageData
  );

  const sc_lang = useGlobalStore((state) => state.sc_lang);
  const currentMarket = useGlobalStore((state) => state.currentMarket);
  const [, , fetchBasicConfiguration] = useBasicConfiguration();

  const [activeConfigurationSKU, ,] = useActiveConfigurationSKU();

  const [activeProductData, ,] = useActiveProductData();
  const [activeModelData, , fetchActiveModelData] = useActiveModelData();

  const [loadError, setLoadError] = useState(null);

  useEffect(() => {
    log("1 START");
    if (configurationInitParams && sc_lang && currentMarket?.commerceMarket) {
      setConfigurationType(configurationInitParams);
      // configurationInitParams.type.value = "SKU";
      fetchBasicConfiguration(
        configurationInitParams,
        errorHandler,
        sc_lang,
        currentMarket.commerceMarket?.value
      );
    }
  }, [sc_lang, currentMarket]);

  useEffect(() => {
    log("2 SKU READY", activeConfigurationSKU);
    fetchActiveModelData(sc_lang);
  }, [activeConfigurationSKU]);

  useEffect(() => {
    log("3 READY FOR IMAGES");
    if (
      activeProductData &&
      activeConfigurationSKU &&
      !isEmpty(activeModelData)
    ) {
      fetchProductImageData();
    }
  }, [activeProductData, activeConfigurationSKU, activeModelData]);

  const [commerceMarket] = useCommerceMarket();

  const errorHandler = (error) => {
    setLoadError(error.response || error);
  };
  return (
    <div className="pdp" key={rendering.uid}>
      {loadError ? (
        <div className="error">
          <h2>PDP LOAD ERROR</h2>
          {loadError?.status && <p>HTTP {loadError?.status}</p>}
          {loadError?.data?.error && <p>{loadError?.data?.error}</p>}
          {loadError.text && <p>{loadError.text}</p>}
        </div>
      ) : (
        <>
          {activeModelData && (
            <>
              {testForAvailability(
                commerceMarket,
                activeModelData?.availableMarkets
              ) ? (
                <PDP
                  allowOrder={allowOrder}
                  careAndMaintenance={pageFields?.CareAndMaintenance}
                />
              ) : (
                <div className="error">
                  <h2>{texts.notAvailable}</h2>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default PDPController;
