import MediaFocal from "./media-focal";

export default {
  default: {
    fields: {
      media: MediaFocal.withOverlay,
      headline: "Order free",
      headlineType: "h3",
      phone: "+45 45 45 45 45",
      email: "sdfsdfd@lkjdsf.com",
      isInverted: true,
    },
  },
  large: {
    fields: {
      media: MediaFocal.withOverlay,
      headline: "148 years of craftmanship",
      headlineType: "h1",
      isInverted: true,
    },
  },
  linear: {
    fields: {
      isLinear: true,
      media: MediaFocal.default,
      headline: "The production of The Swan",
      headlineType: "h3",
      bodyText:
        "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.  ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.",
    },
  },
  onlyImage: {
    fields: {
      media: MediaFocal.default,
    },
  },
};
