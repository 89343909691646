import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import cuid from "cuid";
import { useTranslation } from "react-i18next";
import produce from "immer";
import { useForm } from "react-hook-form";

import InputField from "../../forms/InputField";
import Select from "../../forms/Select";
import Checkbox from "../../forms/Checkbox";

import ActionButton from "../../ActionButton";

import isTrue from "@assets/js/is-true";
import { BUTTONSTYLE, NOTIFICATION } from "@constants";

import useCheckoutStore, {
  useCheckoutInfoData,
  useOrderDetailsData,
} from "@store/checkout";
import useBasketStore from "@store/basket";

import useNotificationStore from "@store/notifications";
import { useCommerceMarket, useMarketInfo } from "@store/global";

import "./client-form.scss";

const ClientForm = ({ fields }) => {
  const { t } = useTranslation();

  const texts = {
    billingInfo: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Checkout_BillingInfo"
    ),
    contactInfo: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Checkout_ContactInfo"
    ),

    otherAddressDelivery: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Checkout_OtherAddressDelivery"
    ),

    continueToDelivery: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Checkout_ContinueToDelivery"
    ),

    fieldFirstName: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Checkout_FirstName"
    ),
    fieldLastName: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Checkout_LastName"
    ),
    fieldVatNo: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Checkout_VatNo"
    ),
    fieldCompanyName: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Checkout_CompanyName"
    ),
    fieldAddress: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Checkout_AddressField"
    ),
    fieldPostalCode: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Checkout_PostalCode"
    ),
    fieldCity: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Checkout_CityField"
    ),
    fieldCountryDefault: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Checkout_CountrySelectDefault"
    ),
    fieldEmail: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Checkout_EmailField"
    ),
    fieldEmailAgain: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Checkout_EmailAgainField"
    ),
    fieldMobilePhone: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Checkout_MobilePhone"
    ),

    errorNoValue: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Checkout_ErrorNoValue"
    ),
    errorTooLong: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Checkout_ErrorTooLong"
    ),
    errorNotNumber: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Checkout_ErrorNotNumber"
    ),
    errorNeedsCompanyName: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Checkout_ErrorNeedsCompanyName"
    ),
    errorNeedsVatNo: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Checkout_ErrorNeedsVatNo"
    ),
    errorEmailsWrong: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Checkout_ErrorEmailWrong"
    ),
    errorEmailsDontMatch: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Checkout_ErrorEmailsDontMatch"
    ),
    errorGeoBlock: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Checkout_ErrorGeoBlock"
    ),
    errorGeoBlock2: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Checkout_ErrorGeoBlock2"
    ),
  };
  const history = useHistory();

  const [marketInfo] = useMarketInfo();
  const [commerceMarket] = useCommerceMarket();
  const setActiveNotification = useNotificationStore(
    (state) => state.setActiveNotification
  );

  const [checkoutInfoData, setCheckoutInfoData] = useCheckoutInfoData();
  const [, ,] = useOrderDetailsData();

  const checkPostalCode = useCheckoutStore((store) => store.checkPostalCode);
  const submitAddresses = useCheckoutStore((store) => store.submitAddresses);

  const basketId = useBasketStore((store) => store.basketId);

  const [countriesList, setCountriesList] = useState([]);
  const [generalErrorMessage, setGeneralErrorMessage] = useState(null);

  const [checkoutInfoParsed, setCheckoutInfoParsed] = useState(false);

  const [defaultValues] = useState({
    vatno: "",
    email: "",
    email2: "",
    mobile: "",

    fname: "",
    lname: "",
    address: "",
    postal: "",
    city: "",
    country: "",
    companyname: "",

    fname2: "",
    lname2: "",
    address2: "",
    postal2: "",
    city2: "",
    country2: "",
    companyname2: "",
  });

  const {
    register,
    handleSubmit,
    trigger,
    errors,
    watch,
    getValues,
    setValue,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: defaultValues,
  });

  const getCountryIdByCode = (code) => {
    let ct = countriesList.find((c) => c.optionValue === code)
      ? countriesList.find((c) => c.optionValue === code).id
      : 0;
    return ct;
  };

  const deliveryCountryAllowed = (code) => {
    return commerceMarket.toLowerCase() === code.toLowerCase();
  };

  useEffect(() => {
    if (marketInfo) {
      let markets = marketInfo?.markets?.values?.filter(
        (item) => item.marketSupportsEcom.value === true
      );
      let countries = markets.map((item) => {
        return {
          id: item?.commerceMarketId?.value,
          optionLabel: item?.countryName?.value,
          optionValue: item?.commerceMarket?.value,
        };
      });
      setCountriesList(countries);
    }
  }, [marketInfo]);

  useEffect(() => {
    if (checkoutInfoData?.BillingAddress && checkoutInfoData?.ShipmentAddress) {
      setValue("vatno", checkoutInfoData.BillingAddress.VatNo, {
        shouldDirty: true,
      });
      setValue("email", checkoutInfoData.BillingAddress.EmailAddress, {
        shouldDirty: true,
      });
      setValue("email2", checkoutInfoData.BillingAddress.EmailAddress, {
        shouldDirty: true,
      });
      setValue("mobile", checkoutInfoData.BillingAddress.MobilePhoneNumber, {
        shouldDirty: true,
      });
      setValue("fname", checkoutInfoData.BillingAddress.FirstName, {
        shouldDirty: true,
      });
      setValue("lname", checkoutInfoData.BillingAddress.LastName, {
        shouldDirty: true,
      });
      setValue("address", checkoutInfoData.BillingAddress.Line1, {
        shouldDirty: true,
      });
      setValue("postal", checkoutInfoData.BillingAddress.PostalCode, {
        shouldDirty: true,
      });
      setValue("city", checkoutInfoData.BillingAddress.City, {
        shouldDirty: true,
      });
      setValue(
        "country",
        checkoutInfoData.BillingAddress.CountryCode ||
          commerceMarket.toUpperCase(),
        {
          shouldDirty: true,
        }
      );
      setValue("companyname", checkoutInfoData.BillingAddress.CompanyName, {
        shouldDirty: true,
      });

      let useBilling = isTrue(checkoutInfoData.ShipmentAddress.UseBilling);
      setSeparateShipmentAddress(!useBilling);

      setValue("fname2", checkoutInfoData.ShipmentAddress.FirstName, {
        shouldDirty: true,
      });
      setValue("lname2", checkoutInfoData.ShipmentAddress.LastName, {
        shouldDirty: true,
      });
      setValue("address2", checkoutInfoData.ShipmentAddress.Line1, {
        shouldDirty: true,
      });
      setValue("postal2", checkoutInfoData.ShipmentAddress.PostalCode, {
        shouldDirty: true,
      });
      setValue("city2", checkoutInfoData.ShipmentAddress.City, {
        shouldDirty: true,
      });
      setValue("companyname2", checkoutInfoData.ShipmentAddress.CompanyName, {
        shouldDirty: true,
      });

      //necessary fix to set value
      window.requestAnimationFrame(() => {
        let shipmentCountryOption =
          checkoutInfoData.ShipmentAddress.CountryCode ||
          commerceMarket.toUpperCase();
        setValue("country2", shipmentCountryOption, {
          shouldDirty: true,
        });
      });

      setCheckoutInfoParsed(true);
    }
  }, [checkoutInfoData, countriesList]);

  const submitFormHandler = () => {
    const values = getValues();

    setGeneralErrorMessage(null);

    const newCheckoutInfoData = produce(
      checkoutInfoData,
      (tmpCheckoutInfoData) => {
        tmpCheckoutInfoData.BillingAddress = {
          FirstName: values.fname,
          LastName: values.lname,
          VatNo: values.vatno,
          CompanyName: values.companyname,
          Line1: values.address,
          PostalCode: values.postal,
          City: values.city,
          CountryCode: values.country,
          CountryId: getCountryIdByCode(values.country),
          EmailAddress: values.email,
          MobilePhoneNumber: values.mobile,
        };

        tmpCheckoutInfoData.ShipmentAddress = {
          FirstName: separateShipmentAddress ? values.fname2 : values.fname,
          LastName: separateShipmentAddress ? values.lname2 : values.lname,
          CompanyName: separateShipmentAddress
            ? values.companyname2
            : values.companyname,
          Line1: separateShipmentAddress ? values.address2 : values.address,
          PostalCode: separateShipmentAddress ? values.postal2 : values.postal,
          City: separateShipmentAddress ? values.city2 : values.city,
          CountryCode: separateShipmentAddress
            ? values.country2
            : values.country,
          CountryId: separateShipmentAddress
            ? getCountryIdByCode(values.country2)
            : getCountryIdByCode(values.country),
          UseBilling: !separateShipmentAddress,
        };
      }
    );

    if (
      !deliveryCountryAllowed(newCheckoutInfoData.ShipmentAddress.CountryCode)
    ) {
      setGeneralErrorMessage(
        separateShipmentAddress ? texts.errorGeoBlock2 : texts.errorGeoBlock
      );

      // if (window) window.scrollTo(0, 0);
      // setActiveNotification({
      //   id: cuid(),
      //   bodyText: texts.errorGeoBlock,
      //   backgroundColorHex: "#ff8d8d",
      //   textColor: "#000000",
      //   type: NOTIFICATION.SERVICE_MESSAGE,
      //   timer: 5000,
      // });
    } else {
      setCheckoutInfoData(newCheckoutInfoData);

      const nextStepUrl = fields?.nextPage?.url?.value;
      const postData = {
        BillingAddress: newCheckoutInfoData.BillingAddress,
        ShipmentAddress: newCheckoutInfoData.ShipmentAddress,
      };
      submitAddresses(
        postData,
        basketId,
        () => history.push(nextStepUrl),
        addressesSubmitErrorHandler
      );
    }
  };

  // onSubmit button click
  const onSubmitClick = async () => {
    const result = await trigger();
    if (result) {
      submitFormHandler();
    }
  };

  const addressesSubmitErrorHandler = (errors) => {
    if (window) window.scrollTo(0, 0);

    setActiveNotification({
      id: cuid(),
      bodyText: "ERRORS: " + errors.join(","),
      backgroundColorHex: "#000000",
      textColor: "#FFFFFF",
      type: NOTIFICATION.SERVICE_MESSAGE,
      timer: 3000,
    });
  };

  const [separateShipmentAddress, setSeparateShipmentAddress] = useState(true);

  const postalCodeHandler = (value) => {
    const isDenmark = getValues("country") == "DK";
    if (isDenmark && value.length === 4) {
      checkPostalCode(value, (city) => {
        setValue("city", city, {
          shouldDirty: true,
        });
      });
    }
  };

  const postalCode2Handler = (value) => {
    const isDenmark = getValues("country") == "DK";
    if (isDenmark && value.length === 4) {
      checkPostalCode(value, (city) => {
        setValue("city2", city, {
          shouldDirty: true,
        });
      });
    }
  };

  const separateShipmentAddressHandler = (checked) => {
    setGeneralErrorMessage(null);
    setSeparateShipmentAddress(checked);
  };

  useEffect(() => {
    if (
      checkoutInfoData &&
      separateShipmentAddress &&
      checkoutInfoData.ShipmentAddress
    ) {
      let shipmentCountryOption =
        checkoutInfoData.ShipmentAddress.CountryCode || commerceMarket;
      setValue("country2", shipmentCountryOption, {
        shouldDirty: true,
      });
    }
  }, [separateShipmentAddress, checkoutInfoData]);

  let requiredEmpty = {
    value: true,
    message: texts.errorNoValue,
  };
  let validateTooLong = {
    value: 20,
    message: texts.errorTooLong,
  };
  let validateEmailInvalid = {
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
    message: texts.errorEmailsWrong,
  };
  let validateNotANumber = {
    value: /^[0-9]+$/,
    message: texts.errorNotNumber,
  };

  return (
    <div className="client-form">
      <form className="form" onSubmit={handleSubmit(submitFormHandler)}>
        <fieldset className="form__group">
          <h4>{texts.billingInfo}</h4>

          <div className="form__row">
            <InputField
              id="clientFormFirstName"
              name="fname"
              autoComplete="given-name"
              type="text"
              label={texts.fieldFirstName}
              hasValue={getValues("fname")}
              error={errors.fname}
              register={register({
                required: requiredEmpty,
              })}
            />
          </div>

          <div className="form__row">
            <InputField
              id="clientFormLastName"
              name="lname"
              autoComplete="family-name"
              type="text"
              hasValue={getValues("lname")}
              label={texts.fieldLastName}
              error={errors.lname}
              register={register({
                required: requiredEmpty,
              })}
            />
          </div>

          <div className="form__row">
            <InputField
              id="clientFormVatNo"
              name="vatno"
              hasValue={getValues("vatno")}
              type="text"
              label={texts.fieldVatNo}
              error={errors.vatno}
              register={register({
                validate: (value) => {
                  if (getValues("companyname") == "") {
                    return true;
                  } else if (value.length < 1) {
                    return texts.errorNeedsVatNo;
                  } else {
                    return true;
                  }
                },
              })}
            />
          </div>

          <div className="form__row">
            <InputField
              id="clientFormCompanyName"
              name="companyname"
              hasValue={getValues("companyname")}
              type="text"
              label={texts.fieldCompanyName}
              error={errors.companyname}
              register={register({
                validate: (value) => {
                  if (getValues("vatno") == "") {
                    return true;
                  } else if (value.length < 1) {
                    return texts.errorNeedsCompanyName;
                  } else {
                    return true;
                  }
                },
              })}
            />
          </div>

          <div className="form__row">
            <InputField
              id="clientFormAddress"
              name="address"
              hasValue={getValues("address")}
              autoComplete="street-address"
              type="text"
              label={texts.fieldAddress}
              error={errors.address}
              register={register({
                required: requiredEmpty,
              })}
            />
          </div>

          <div className="form__row form__row--dual">
            <InputField
              id="clientFormPostalCode"
              name="postal"
              hasValue={getValues("postal")}
              autoComplete="postal-code"
              type="text"
              label={texts.fieldPostalCode}
              error={errors.postal}
              register={register({
                required: requiredEmpty,
                pattern: validateNotANumber,
              })}
              blurHandler={postalCodeHandler}
            />
            <InputField
              id="clientFormCity"
              name="city"
              hasValue={getValues("city")}
              autoComplete="address-level2"
              type="text"
              label={texts.fieldCity}
              error={errors.city}
              register={register({
                required: requiredEmpty,
              })}
            />
          </div>

          <div className="form__row">
            <Select
              id="clientFormCountry"
              defaultLabel={texts.fieldCountryDefault}
              name="country"
              hasValue={getValues("country")}
              options={countriesList}
              error={errors.country}
              register={register({
                validate: (value) => value != "" || texts.errorNoValue,
              })}
            />
          </div>

          <hr />

          <h4>{texts.contactInfo}</h4>

          <div className="form__row">
            <InputField
              id="clientFormEmail"
              name="email"
              hasValue={getValues("email")}
              autocomplete="email"
              type="email"
              label={texts.fieldEmail}
              error={errors.email}
              register={register({
                required: requiredEmpty,
                pattern: validateEmailInvalid,
              })}
            />
          </div>
          <div className="form__row">
            <InputField
              id="clientFormEmailAgain"
              name="email2"
              hasValue={getValues("email2")}
              autocomplete="email"
              type="email"
              label={texts.fieldEmailAgain}
              error={errors.email2}
              register={register({
                required: requiredEmpty,
                validate: (value) =>
                  value === watch("email") || texts.errorEmailsDontMatch,
              })}
            />
          </div>
          <div className="form__row">
            <InputField
              id="clientFormMobilePhone"
              name="mobile"
              hasValue={getValues("mobile")}
              autocomplete="tel"
              type="tel"
              label={texts.fieldMobilePhone}
              error={errors.mobile}
              register={register({
                required: requiredEmpty,
                pattern: validateTooLong,
              })}
            />
          </div>

          <div className="form__row">
            <Checkbox
              name="otheraddress"
              hasValue={getValues("otheraddress")}
              id="clientFormOtherAddress"
              type="checkbox"
              checked={separateShipmentAddress}
              label={texts.otherAddressDelivery}
              changeHandler={separateShipmentAddressHandler}
            />
          </div>
        </fieldset>

        {separateShipmentAddress && (
          <fieldset className="form__group">
            <div className="form__row">
              <InputField
                id="clientFormFirstName2"
                name="fname2"
                hasValue={getValues("fname2")}
                autoComplete="given-name"
                type="text"
                label={texts.fieldFirstName}
                register={register({
                  required: requiredEmpty,
                })}
                error={errors.fname2}
              />
            </div>

            <div className="form__row">
              <InputField
                id="clientFormLastName2"
                name="lname2"
                hasValue={getValues("lname2")}
                autoComplete="family-name"
                type="text"
                label={texts.fieldLastName}
                register={register({
                  required: requiredEmpty,
                })}
                error={errors.lname2}
              />
            </div>

            <div className="form__row">
              <InputField
                id="clientFormCompanyName2"
                name="companyname2"
                hasValue={getValues("companyname2")}
                type="text"
                label={texts.fieldCompanyName}
                register={register()}
                error={errors.companyname2}
              />
            </div>

            <div className="form__row">
              <InputField
                id="clientFormAddress2"
                name="address2"
                hasValue={getValues("address2")}
                autoComplete="street-address"
                type="text"
                label={texts.fieldAddress}
                register={register({
                  required: requiredEmpty,
                })}
                error={errors.address2}
              />
            </div>

            <div className="form__row form__row--dual">
              <InputField
                id="clientFormPostalCode2"
                name="postal2"
                hasValue={getValues("postal2")}
                autoComplete="postal-code"
                type="text"
                label={texts.fieldPostalCode}
                register={register({
                  required: requiredEmpty,
                })}
                error={errors.postal2}
                blurHandler={postalCode2Handler}
              />
              <InputField
                id="clientFormCity2"
                name="city2"
                hasValue={getValues("city2")}
                autoComplete="address-level2"
                type="text"
                label={texts.fieldCity}
                register={register({
                  required: requiredEmpty,
                })}
                error={errors.city2}
              />
            </div>

            <div className="form__row">
              <Select
                id="clientFormCountry2"
                name="country2"
                hasValue={getValues("country2")}
                options={countriesList}
                defaultLabel={texts.fieldCountryDefault}
                register={register({
                  validate: (value) => value != "" || texts.errorNoValue,
                })}
                error={errors.country2}
              />
            </div>
          </fieldset>
        )}
      </form>
      <footer className="checkout-flow__footer">
        {generalErrorMessage && (
          <p className="checkout-flow__footer__message">
            {generalErrorMessage}
          </p>
        )}
        <ActionButton
          text={texts.continueToDelivery}
          buttonType={BUTTONSTYLE.SOLID}
          customClass="action-button--primary"
          clickHandler={onSubmitClick}
        />
      </footer>
    </div>
  );
};
ClientForm.propTypes = {};

export default ClientForm;
