export default {
  default: {
    fields: {
      media: {
        size: "regular",
        src: "https://picsum.photos/400/450",
        alt: "Lorem ipsum",
      },
      value: {
        href: "#product",
        productId: "98gd89g7dfg",
        title: "SWAN<sup>TM</sup>",
        material: "Christianshavn Grey",
        model: "3320 Textile",
        priceOriginal: "€ 3,215.00",
        priceAdjusted: "€ 3,213.00",
        linkText: "72 variants",
      },
    },
  },

  smallImage: {
    fields: {
      media: {
        size: "small",
        src: "https://picsum.photos/250/200",
        alt: "Lorem ipsum",
      },
      value: {
        href: "#product",
        productId: "98gd89g7dfg",
        title: "SWAN<sup>TM</sup>",
        material: "Soft black",
        model: "3320 Leather",
        priceAdjusted: "€ 7,234,999,215.95",
        linkText: "More variants",
      },
    },
  },
  largeImage: {
    fields: {
      media: {
        size: "large",
        src: "https://picsum.photos/600/400",
        alt: "Lorem ipsum",
      },
      value: {
        href: "#product",
        productId: "98gd89g7dfg",
        title: "SWAN<sup>TM</sup>",
        material: "Soft black",
        model: "3320 Leather",
        priceAdjusted: "€ 7,234,999,215.95",
        linkText: "3 variants",
        tag: "NEW",
      },
    },
  },

  newData: {
    id: "modelvariationgroup_JH12_ro_footstool_wood",
    modelTypeId_s: "JH12",
    doc_type_s: "modelvariationgroup",
    displayName_t: "Ro™, JH12",
    fullName_t: "JH12, ",
    urlSlug_s: "/products/JH12MU/JH12_ro_footstool_wood/en.json",
  },
  newData2: {
    imageUrl_s: "https://picsum.photos/400/450",
    modelTypeId_s: "3320",
    id: "modelvariationgroup_3320_swan_leather",
    doc_type_s: "modelvariationgroup",
    displayName_t: "Swan™, 3320",
    fullName_t: "3320, ",
    urlSlug_s: "/products/3320/3320_swan_leather/en.json",
  },
  newData3: {
    imageUrl_s: "https://picsum.photos/400/450",
    modelTypeId_s: "JH5",
    id: "modelvariationgroup_jh5_fri",
    doc_type_s: "modelvariationgroup",
    displayName_t: "Fri™, JH5",
    fullName_t: "JH5, ",
    shortDescription_t:
      "The Fri™ easy chair adds a striking, sophisticated expression to any interior. Designed by Spanish designer Jaime Hayon, the chair has a soft, sculptured form that is as pleasing to the body as it is to the eye.",
    urlSlug_s: "/products/JH5SF/jh5_fri/en.json",
  },
};
