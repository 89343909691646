import React from "react";
import NavTile from "../NavTile/index.js";
import classnames from "classnames";
import isTrue from "@assets/js/is-true";
import "./nav-tiles-block.scss";

const NavTilesBlock = ({ fields = {} }) => {
  const classList = classnames({
    "nav-tiles-block": true,
    "nav-tiles-block--2col": fields?.tiles && fields?.tiles.length === 2,
    "nav-tiles-block--inverted": isTrue(fields.isInverted),
  });

  return (
    <>
      {fields && (
        <div
          className={classList}
          style={
            fields.backgroundColorHex && {
              "--nav-tiles-bgc": fields.backgroundColorHex.value,
            }
          }
        >
          {fields.tiles?.map((tile, index) => {
            return (
              <NavTile
                key={index}
                fields={tile.fields}
                isButtonEnabled={fields.isButtonEnabled}
                isInverted={fields.isInverted}
              />
            );
          })}
        </div>
      )}
    </>
  );
};

export default NavTilesBlock;
