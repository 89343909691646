import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import MessageBlock from "../MessageBlock";
import { useLocation } from "react-router-dom";

import "./page-error-block.scss";

const PageErrorBlock = ({ fields }) => {
  let srvmsg = new URLSearchParams(useLocation().search)?.get("srvmsg") ?? "";
  const messageBlockProps = {
    trumpet: fields?.trumpet?.value,
    headline: fields?.headline?.value,
    bodyText: fields?.bodyText?.value?.replace("{srvmsg}", srvmsg),
    buttonText: fields?.button?.fields?.link?.value?.text,
    buttonHref: fields?.button?.fields?.link?.value?.href,
  };

  return <MessageBlock {...messageBlockProps} />;
};
PageErrorBlock.propTypes = {};

export default PageErrorBlock;

/*{"trumpet":{"value":"404"},"bodyText":{"value":"The page may have been removed, or the URL you are using is incorrect."},
"button":{"id":"bbd62d70-a925-4eba-9a7e-25525a8aa60c","url":"/404/Page-Components/404/Go-to-frontpage",
  "fields":{
    "link":{
      "value":
      {"href":"/en/","text":"Go to frontpage","anchor":"","linktype":"internal","class":"","title":"","target":"","querystring":"","id":"{A7CB067A-8857-5945-8BC8-B82014AE7BAA}"}},"hasArrow":{"value":false}}},"headline":{"value":"Ups. The page you're looking for does not exist."}}*/
