import React, { useCallback, useEffect, useRef, useState } from "react";
import GoogleMapReact, { fitBounds } from "google-map-react";
import Autocomplete from "react-google-autocomplete";
import { motion, AnimatePresence } from "framer-motion";
import classnames from "classnames";
import debounce from "lodash-es/debounce";
import { useTranslation } from "react-i18next";

import { MagnifierSvg } from "@assets/js/icons";
import ListTitle from "../ListTitle";
import Select from "../forms/Select";
import AnimationWrapper from "@assets/js/AnimationWrapper";
import mapStyle from "./mapStyle.json";

import {
  useGoogleAPIKey,
  useClustersList,
  useStoresList,
  useMapZoomFactor,
  useMapCenter,
  useActiveStore,
  useSearchLocation,
  useClientLocation,
  useFilterString,
} from "@store/stores";

import "./store-locator.scss";

const StoreLocatorController = ({ fields, rendering }) => {
  // parse fields
  const { uid } = rendering;
  const [googleAPIKey, setGoogleAPIKey] = useGoogleAPIKey();

  const title = fields?.title;

  const useCluster = fields?.useCluster?.value;
  const useFilter = fields?.useFilter?.value;

  useEffect(() => {
    //parse fields
    if (!googleAPIKey) {
      const key = fields?.googleApiKey?.value; //AIzaSyAaAFLFJBrNp18F3Au_IMT4DBQ1ydc0n6w";
      // console.log("key", key);
      setGoogleAPIKey(key);
    }
  }, [googleAPIKey]);

  return (
    <div key={uid}>
      <ListTitle
        fields={{ headline: title, headlineType: { value: "h1" } }}
        rendering={rendering}
      />
      <StoreLocator
        mapCenterDefault={{
          lat: 55.61880297442295,
          lng: 12.044327913595168,
        }}
        mapZoomDefault={8}
      />
    </div>
  );
};

const StoreLocator = ({ mapCenterDefault, mapZoomDefault }) => {
  const [googleAPIKey] = useGoogleAPIKey();
  const [clustersList, , fetchClustersList] = useClustersList();
  const [storesList] = useStoresList();
  const [activeStore, setActiveStore] = useActiveStore();
  const [searchLocation, setSearchLocation] = useSearchLocation();
  const [clientLocation, setClientLocation] = useClientLocation();
  const [, setFilterString] = useFilterString();

  const [mapZoomFactor, setMapZoomFactor] = useMapZoomFactor(mapZoomDefault);
  const [mapCenter, setMapCenter] = useMapCenter(mapCenterDefault);

  const [mapReference, setMapReference] = useState(null);

  const [googleMapsAPI, setGoogleMapsAPI] = useState(null);

  // ========================================
  // ===== API INIT =========================
  // ========================================

  const googleAPILoadedHandler = (loadedProps) => {
    setMapReference(loadedProps.map);
    setGoogleMapsAPI(loadedProps.maps);
  };

  const getMapOptions = (maps) => {
    return {
      panControl: false,
      mapTypeControl: false,
      scrollwheel: false,
      styles: mapStyle,
    };
  };

  // ========================================
  // ===== USER EVENT HANDLERS ==============
  // ========================================

  const userLocatedHandler = (position) => {
    if (!googleMapsAPI) return new Error("Google API Unavailable");
    const place = new googleMapsAPI.LatLng(
      position.coords.latitude,
      position.coords.longitude
    );

    setClientLocation(place);
    goToLocation(place.lat(), place.lng());

    setMapCenter(place);
    setMapZoomFactor(12);
  };

  const mapChangeHandler = (changeProps) => {
    setMapZoomFactor(changeProps.zoom);
    setMapCenter(changeProps.center);

    // console.log(changeProps.bounds);

    let debouncedUpdate = debounce(
      (e) => {
        fetchClustersList(changeProps.bounds);
      },
      500,
      { leading: false, trailing: true }
    );
    debouncedUpdate();
  };

  const clusterClickHandler = (data) => {
    setActiveStore(null);
    let newBounds = getBoundsFromCoords(data.stores);
    if (newBounds) {
      mapReference.fitBounds(newBounds);
    }
  };

  const markerClickHandler = (data) => {
    setActiveStore(data);
    goToLocation(data.latLng.lat, data.latLng.lon);
  };

  const listItemClickHandler = (data) => {
    setActiveStore(data);
    goToLocation(data.latLng.lat, data.latLng.lon);
    setMapZoomFactor(14);
  };

  const locationChosenHandler = (places) => {
    if (places && places[0]) {
      let place = places[0];

      if (place && place.geometry) {
        setSearchLocation(place);
        goToLocation(
          place.geometry.location.lat(),
          place.geometry.location.lng()
        );
        setMapCenter(place);
        setMapZoomFactor(14);
      }
    }
  };

  const filterChangeHandler = (val) => {
    setFilterString(val);
    let b = mapReference.getBounds();
    const bounds = {
      ne: { lat: b.getNorthEast().lat(), lng: b.getNorthEast().lng() },
      sw: { lat: b.getSouthWest().lat(), lng: b.getSouthWest().lng() },
    };

    fetchClustersList(bounds);
  };

  // ========================================
  // ===== MAPS API STUFF ===================
  // ========================================

  const goToLocation = (lat, lon) => {
    if (!googleMapsAPI) return new Error("Google API Unavailable");
    const latlng = new googleMapsAPI.LatLng(lat, lon);
    mapReference.panTo(latlng);
  };

  const getBoundsFromCoords = (list) => {
    if (!googleMapsAPI) return new Error("Google API Unavailable");

    let x0, x1, y0, y1;
    list.forEach((item) => {
      if (x0 == null) {
        x0 = x1 = item.latLng.lat;
        y0 = y1 = item.latLng.lon;
      } else {
        if (item.latLng.lat > x1) x1 = item.latLng.lat;
        if (item.latLng.lat < x0) x0 = item.latLng.lat;
        if (item.latLng.lon > y1) y1 = item.latLng.lon;
        if (item.latLng.lon < y0) y0 = item.latLng.lon;
      }
    });

    let ne = new googleMapsAPI.LatLng(x1, y1);
    let sw = new googleMapsAPI.LatLng(x0, y0);
    let bounds = new googleMapsAPI.LatLngBounds(sw, ne);

    return bounds;
  };

  // ========================================
  // ===== MISC HELPERS =====================
  // ========================================

  const itemIsActive = (data) => {
    return (
      activeStore?.latLng?.lat === data.latLng.lat &&
      activeStore?.latLng?.lon === data.latLng.lon
    );
  };

  const { t } = useTranslation();

  const texts = {
    filterAll: t(
      "FritzHansen_Project_ConsumersAndProfessionals_StoreLocator_FilterAll"
    ),
    filterFurniture: t(
      "FritzHansen_Project_ConsumersAndProfessionals_StoreLocator_FilterFurniture"
    ),
    filterPoulKjaerholm: t(
      "FritzHansen_Project_ConsumersAndProfessionals_StoreLocator_FilterPoulKjaerholm"
    ),
    filterAccessories: t(
      "FritzHansen_Project_ConsumersAndProfessionals_StoreLocator_FilterAccessories"
    ),
    filterContractDealers: t(
      "FritzHansen_Project_ConsumersAndProfessionals_StoreLocator_FilterContractDealers"
    ),
    filterLighting: t(
      "FritzHansen_Project_ConsumersAndProfessionals_StoreLocator_FilterLighting"
    ),
  };

  return (
    <AnimationWrapper type="defaultOpacity">
      <div className="store-locator">
        <div className="store-locator__inner">
          <div className="store-locator__filters">
            <Select
              changeHandler={filterChangeHandler}
              id="storeLocatorFilters"
              name="storeLocator"
              options={[
                {
                  id: "1",
                  optionLabel: texts.filterAll,
                  optionValue: "",
                },
                {
                  id: "2",
                  optionLabel: texts.filterFurniture,
                  optionValue: "furniture",
                },
                {
                  id: "3",
                  optionLabel: texts.filterPoulKjaerholm,
                  optionValue: "pk",
                },
                {
                  id: "4",
                  optionLabel: texts.filterAccessories,
                  optionValue: "objects",
                },
                {
                  id: "5",
                  optionLabel: texts.filterContractDealers,
                  optionValue: "contract",
                },
                {
                  id: "6",
                  optionLabel: texts.filterLighting,
                  optionValue: "lighting",
                },
              ]}
            />
          </div>

          {googleMapsAPI && (
            <StoreLocatorSearch
              googleMapsAPI={googleMapsAPI}
              locationChosenHandler={locationChosenHandler}
              geoLocationHandler={userLocatedHandler}
            />
          )}

          <ul className="store-locator__list">
            {googleMapsAPI &&
              storesList &&
              storesList.length > 0 &&
              storesList.map((item, index) => {
                return (
                  <StoreLocatorListItem
                    isActive={itemIsActive(item)}
                    key={`storeListItem${item.id}`}
                    data={item}
                    clickHandler={listItemClickHandler}
                  />
                );
              })}
          </ul>

          {googleAPIKey && (
            <div className="store-locator__map">
              <GoogleMapReact
                yesIWantToUseGoogleMapApiInternals
                onChange={mapChangeHandler}
                onGoogleApiLoaded={googleAPILoadedHandler}
                bootstrapURLKeys={{
                  key: googleAPIKey,
                  libraries: ["places", "geometry"],
                }}
                defaultCenter={mapCenterDefault}
                defaultZoom={mapZoomDefault}
                zoom={mapZoomFactor}
                options={getMapOptions}
              >
                {googleMapsAPI &&
                  clustersList &&
                  clustersList.map((item) => {
                    if (item.stores) {
                      if (item.stores.length > 1) {
                        return (
                          <StoreLocatorCluster
                            key={`storeCluster${item.lat + item.lon}`}
                            data={item}
                            lat={item.lat}
                            lng={item.lon}
                            clickHandler={clusterClickHandler}
                          />
                        );
                      } else if (item.stores.length == 1) {
                        return (
                          <StoreLocatorMarker
                            isActive={itemIsActive(item.stores[0])}
                            key={`storeMarker${item.stores[0].id}`}
                            data={item.stores[0]}
                            lat={item.stores[0].latLng.lat}
                            lng={item.stores[0].latLng.lon}
                            clickHandler={markerClickHandler}
                          />
                        );
                      }
                    } else {
                      return <></>;
                    }
                  })}
                {googleMapsAPI && searchLocation?.geometry?.location && (
                  <StoreLocatorSearchLocation
                    lat={searchLocation.geometry.location.lat()}
                    lng={searchLocation.geometry.location.lng()}
                    data={searchLocation}
                  />
                )}
                {googleMapsAPI && clientLocation?.lat && (
                  <StoreLocatorClientLocation
                    lat={clientLocation.lat()}
                    lng={clientLocation.lng()}
                    data={clientLocation}
                  />
                )}
              </GoogleMapReact>
            </div>
          )}
        </div>
      </div>
    </AnimationWrapper>
  );
};

const StoreLocatorSearch = ({
  googleMapsAPI,
  locationChosenHandler,
  geoLocationHandler,
}) => {
  const { t } = useTranslation();
  const texts = {
    searchHere: t(
      "FritzHansen_Project_ConsumersAndProfessionals_General_SearchHere"
    ),
  };

  const geoLocationClickHandler = () => {
    getUserCordsPermission(window.navigator)
      .then((position) => {
        geoLocationHandler(position);
      })
      .catch((e) => {
        console.warn("Geolocation failed", e);
      });
  };

  const getUserCordsPermission = (navigator) => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (pos) => resolve(pos),
          reject,
          {
            enableHighAccuracy: true,
          }
        );
      } else {
        reject(new Error("navigator.geolocation unavailable"));
      }
    });
  };

  return (
    <div className="store-locator__search">
      <div className="store-locator__search__field">
        {/* <Autocomplete
          placeholder={"Search"}
          onPlaceSelected={(place) => {
            console.log("place", place);
            if (place && place.geometry) {
              locationChosenHandler(place);
            }
          }}
          types={["establishment"]}
        /> */}

        <GoogleSearchBox
          googleMapsAPI={googleMapsAPI}
          onPlacesChanged={locationChosenHandler}
          placeholder={texts.searchHere}
        />
        <MagnifierSvg />
      </div>

      <button
        className="store-locator__search__geoloc"
        onClick={geoLocationClickHandler}
      >
        <svg
          viewBox="0 0 24 24"
          width="32"
          height="32"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fill="#000000">
            <circle cx="12" cy="12" r="5"></circle>
            <path d="M24 11h-3.06A9 9 0 0 0 13 3.06V0h-2v3.06A9 9 0 0 0 3.06 11H0v2h3.06A9 9 0 0 0 11 20.94V24h2v-3.06A9 9 0 0 0 20.94 13H24zm-12 8a7 7 0 1 1 7-7 7 7 0 0 1-7 7z"></path>
          </g>
        </svg>
      </button>
    </div>
  );
};

const GoogleSearchBox = ({ googleMapsAPI, onPlacesChanged, placeholder }) => {
  const input = useRef(null);
  const searchBox = useRef(null);

  const handleOnPlacesChanged = useCallback(() => {
    if (onPlacesChanged) {
      onPlacesChanged(searchBox.current.getPlaces());
    }
  }, [onPlacesChanged, searchBox]);

  useEffect(() => {
    if (!searchBox.current && googleMapsAPI) {
      searchBox.current = new googleMapsAPI.places.SearchBox(input.current);
      searchBox.current.addListener("places_changed", handleOnPlacesChanged);
    }

    return () => {
      if (googleMapsAPI) {
        searchBox.current = null;
        googleMapsAPI.event.clearInstanceListeners(searchBox);
      }
    };
  }, [googleMapsAPI, handleOnPlacesChanged]);

  return <input ref={input} placeholder={placeholder} type="text" />;
};

const StoreLocatorSearchLocation = ({ data }) => {
  return (
    <div className="store-locator-search-location">
      <svg
        width="40"
        height="60"
        viewBox="0 0 28 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.1583 19.5138L15.7733 41.7672C15.403 42.5609 14.6064 43.0682 13.7306 43.0682C12.8548 43.0682 12.0583 42.5609 11.6879 41.7672L1.303 19.5138C-2.94007 10.4215 3.69701 0 13.7306 0C23.7642 0 30.4013 10.4215 26.1583 19.5138ZM13.7306 6.46021C10.1628 6.46021 7.27041 9.35255 7.27041 12.9204C7.27041 16.4883 10.1628 19.3807 13.7306 19.3807C17.2985 19.3807 20.1909 16.4883 20.1909 12.9204C20.1909 9.35255 17.2985 6.46021 13.7306 6.46021Z"
          stroke="#000000"
        />
      </svg>
    </div>
  );
};

const StoreLocatorClientLocation = ({ data }) => {
  return (
    <div className="store-locator-client-location">
      <svg
        width="30"
        height="40"
        viewBox="0 0 14 19"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7 1C8.65 1 10 2.35 10 4C10 5.65 8.65 7 7 7C5.35 7 4 5.65 4 4C4 2.35 5.35 1 7 1ZM7 0C4.79 0 3 1.79 3 4C3 6.21 4.79 8 7 8C9.21 8 11 6.21 11 4C11 1.79 9.21 0 7 0Z"
          fill="#525252"
        />
        <path
          d="M14 19H13V12.25L10.16 10.3L7 13.44L3.84 10.3L1 12.25V19H0V11.72L3.95 9L7 12.03L10.05 9L14 11.72V19Z"
          fill="#525252"
        />
      </svg>
    </div>
  );
};

const StoreLocatorCluster = ({ lat, lng, data, clickHandler }) => {
  const count = data.stores.length;

  let styles = { "--cluster-size": "30px" };
  if (count > 10) styles = { "--cluster-size": "40px" };
  if (count > 20) styles = { "--cluster-size": "50px" };

  const onClickHandler = () => {
    clickHandler(data);
  };
  return (
    <button
      className="store-locator-cluster"
      style={styles}
      onClick={onClickHandler}
    >
      <span className="text">{count}</span>
    </button>
  );
};

const StoreLocatorMarker = ({ lat, lng, data, clickHandler, isActive }) => {
  const onClickHandler = () => {
    clickHandler(data);
  };

  const classList = classnames({
    "store-locator-marker": true,
    "is-active": isActive,
  });
  return (
    <motion.button
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className={classList}
      onClick={onClickHandler}
    >
      {isActive ? (
        <>
          <svg
            width="28"
            height="44"
            viewBox="0 0 28 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M26.1583 19.5138L15.7733 41.7672C15.403 42.5609 14.6064 43.0682 13.7306 43.0682C12.8548 43.0682 12.0583 42.5609 11.6879 41.7672L1.303 19.5138C-2.94007 10.4215 3.69701 0 13.7306 0C23.7642 0 30.4013 10.4215 26.1583 19.5138ZM13.7306 6.46021C10.1628 6.46021 7.27041 9.35255 7.27041 12.9204C7.27041 16.4883 10.1628 19.3807 13.7306 19.3807C17.2985 19.3807 20.1909 16.4883 20.1909 12.9204C20.1909 9.35255 17.2985 6.46021 13.7306 6.46021Z"
              fill="#f19b1f"
            />
          </svg>
        </>
      ) : (
        <>
          <svg
            width="28"
            height="44"
            viewBox="0 0 28 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M26.1583 19.5138L15.7733 41.7672C15.403 42.5609 14.6064 43.0682 13.7306 43.0682C12.8548 43.0682 12.0583 42.5609 11.6879 41.7672L1.303 19.5138C-2.94007 10.4215 3.69701 0 13.7306 0C23.7642 0 30.4013 10.4215 26.1583 19.5138ZM13.7306 6.46021C10.1628 6.46021 7.27041 9.35255 7.27041 12.9204C7.27041 16.4883 10.1628 19.3807 13.7306 19.3807C17.2985 19.3807 20.1909 16.4883 20.1909 12.9204C20.1909 9.35255 17.2985 6.46021 13.7306 6.46021Z"
              fill="#525252"
            />
          </svg>
        </>
      )}
    </motion.button>
  );
};

const StoreLocatorListItem = ({ data, clickHandler, isActive }) => {
  const onClickHandler = () => {
    clickHandler(data);
  };

  const classList = classnames({
    "store-locator__list__item": true,
    "is-active": isActive,
  });
  return (
    <li className={classList} onClick={onClickHandler}>
      <h5>{data.headerLine}</h5>
      <p>{data.addressLine1}</p>
      <p>{data.addressLine2}</p>
      {data.phone?<p><a href={`tel:${data.phone}`}>{data.phone}</a></p>:""}
      {data.website?<p><a href={`${data.website.indexOf("//")===-1?"//":""}${data.website}`} target="_blank">{data.website}</a></p>:""}
    </li>
  );
};

StoreLocatorController.propTypes = {};

export default StoreLocatorController;
