import React, { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useTranslation } from "react-i18next";
import Cookies from "universal-cookie";
import classnames from "classnames";

import ActionButton from "../../ActionButton";
import CountrySelector from "../../CountrySelector";

import { collapseVariant } from "@assets/js/animation-variations";
import { log, placeholderReplace } from "@assets/js/utils";
import { BUTTONSTYLE, OVERLAY, NOTIFICATION, COOKIES } from "@constants";

import {
  useLocationConflict,
  useMarketInfo,
  useCurrentMarket,
  useCommerceMarket,
  useShowNotificationGeolocation,
} from "@store/global";
import useHeaderStore from "@store/header";

import "../notification.scss";

const NotificationGeolocation = ({ position }) => {
  const { t } = useTranslation();

  const texts = {
    geoHeadline: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Notifications_CountrySelectorMessageHeadline"
    ),
    geoHeadlineDynamic: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Notifications_CountrySelectorMessageHeadlineDynamic"
    ),
    geoBodyText: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Notifications_CountrySelectorMessageBody"
    ),
  };

  const geoContainer = useRef();

  const [isVisible, setIsVisible] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [appropriateLanguage, setAppropriateLanguage] = useState(null);
  const [detectedLanguageName, setDetectedLanguageName] = useState(null);

  const setMenuOverlayActive = useHeaderStore(
    (store) => store.setMenuOverlayActive
  );

  const [
    showNotificationGeolocation,
    setShowNotificationGeolocation,
  ] = useShowNotificationGeolocation();
  const [locationConflict, setLocationConflict] = useLocationConflict();
  const [currentMarket] = useCurrentMarket();
  const [commerceMarket] = useCommerceMarket();
  const [marketInfo] = useMarketInfo();

  useEffect(() => {
    log("GEOLOC useEffect", currentMarket, marketInfo, locationConflict);
    if (currentMarket && marketInfo && locationConflict) {
      const aL = currentMarket?.defaultLanguage?.value.toLowerCase().substring(0,2); // only take the language not the locale

      const locatedLanguageObject = marketInfo?.languages?.values?.find(
        (item) =>
          item?.sitecoreLanguage?.value?.languageCode.toLowerCase() === aL
      );

      setIsActive(true);
      setIsVisible(true);
      setAppropriateLanguage(aL);
      setDetectedLanguageName(locatedLanguageObject?.name?.value);

      log("appropriateLanguage", aL);
      log("locatedLanguageObject", locatedLanguageObject);
    }
  }, [currentMarket, marketInfo, locationConflict]);

  useEffect(() => {
    if (showNotificationGeolocation) {
      setIsActive(true);
      setIsVisible(true);
    } else if (
      !showNotificationGeolocation &&
      position === NOTIFICATION.GEO_BOTTOM &&
      isVisible
    ) {
      setIsActive(false);
      setIsVisible(false);
    }
  }, [showNotificationGeolocation, isVisible, position]);

  function onUpdate(isActive, latest) {
    if (isActive && latest.height !== "auto") {
      geoContainer.current.scrollIntoView(latest.height);
    }

    if (isActive && !isVisible && latest.height === 0) {
      setShowNotificationGeolocation(null);
    }
  }

  const abortGeolocation = () => {
    setIsVisible(false);
    setShowNotificationGeolocation(null);
    setMenuOverlayActive(OVERLAY.NONE);
    setLocationConflict(false);

    const cookies = new Cookies();
    cookies.set(COOKIES.market.name, commerceMarket, {
      path: "/",
    });
  };

  const classNames = classnames({
    notification: true,
    "notification--geolocation": true,
    "is-fixed": locationConflict,
  });

  return (
    <>
      {position === showNotificationGeolocation && (
        <AnimatePresence>
          <motion.div
            className={classNames}
            id="geolocationid"
            ref={geoContainer}
            animate={isVisible ? "visible" : "hidden"}
            initial="hidden"
            variants={collapseVariant}
            onUpdate={
              position === NOTIFICATION.GEO_BOTTOM ||
              position === NOTIFICATION.GEO_MENUBOTTOM
                ? (latest) => onUpdate(isActive, latest)
                : null
            }
            onAnimationComplete={() => {
              setIsActive(isVisible);
            }}
          >
            <div className="notification__inner-wrapper">
              <h2 className="notification__headline">
                {locationConflict
                  ? placeholderReplace(texts.geoHeadlineDynamic, {
                      detectedLanguageName,
                    })
                  : texts.geoHeadline}
              </h2>
              {!locationConflict && (
                <p className="notification__bodytext">{texts.geoBodyText}</p>
              )}

              {isVisible && (
                <>
                  {locationConflict ? (
                    <CountrySelector
                      preselectedLanguage={appropriateLanguage}
                      preselectedCountry={commerceMarket.toUpperCase()}
                    />
                  ) : (
                    <CountrySelector />
                  )}
                </>
              )}

              <ActionButton
                customClass="notification__close-icon"
                buttonType={BUTTONSTYLE.NONE}
                icon="TimesSvg"
                clickHandler={() => {
                  abortGeolocation();
                }}
              />
            </div>
          </motion.div>
        </AnimatePresence>
      )}
    </>
  );
};

export default NotificationGeolocation;
