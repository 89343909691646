export default {
  default: {
    fields: {
      noParse: true,
      type: "material",
      imageSrc: "https://placeimg.com/500/200/any",
      text1: "text1",
      text2: "text2",
      text3: "text3",
      actionList: [
        {
          icon: "magnifier",
          label: "Add to Basket",
          callback: () => {
            console.log("MAGNIFIER CLICK");
          },
        },
        {
          icon: "arrow",
          label: "Find in store",
          callback: () => {
            console.log("ARROW CLICK");
          },
        },
        {
          icon: "basket",
          label: "Remove from wishlist",
          callback: () => {
            console.log("BASKET CLICK");
          },
        },
      ],
    },
  },
};
