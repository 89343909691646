import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { loader } from "graphql.macro";
import { useQuery } from "react-apollo";
import shallow from "zustand/shallow";
import classnames from "classnames";
import "../MegaMenu/mega-menu.scss";
import useGlobalStore, { useUserAuthenticated } from "@store/global";
import { useMenuOverlayActive } from "@store/header";
import { SITE_MODE, OVERLAY } from "@constants";
import MenuLink from "../MenuLink";
import { ArrowSmallSvg } from "@assets/js/icons";
import { itemMotion, containerMotion } from "@assets/js/animation-variations";
import LoginLink from "../LoginLink";
import { placeholderReplace } from "@assets/js/utils";

const myFritzMenuQuery = loader("./myFritzHansenMenuQuery.graphql");

// const containerMotion = {
//   initial: { opacity: 0 },
//   animate: {
//     opacity: 1,
//     transition: {
//       staggerChildren: 0.1,
//     },
//   },
// };
// const itemMotion = {
//   initial: { x: 20, opacity: 0 },
//   animate: {
//     x: 0,
//     opacity: 1,
//     transition: {
//       duration: 0.2,
//     },
//   },
//   exit: { x: 20, opacity: 0 },
// };

const MyFritzMenu = ({ type = "megamenu" }) => {
  const { data, loading } = useQuery(myFritzMenuQuery, {
    variables: { menuPath: process.env.REACT_APP_QUERY_VARIABLES_MYFRITZ_ID },
    errorPolicy: "all",
  });
  const [menuItems, setMenuItems] = useState(null);
  const [currentSiteMode, lang] = useGlobalStore(
    (state) => [state.currentSiteMode, state.language],
    shallow
  );
  const [userAuthenticated] = useUserAuthenticated();
  const [, setActiveMenu] = useMenuOverlayActive();
  useEffect(() => {
    // residential signed-in
    if (data && currentSiteMode === SITE_MODE.RESIDENTIAL && userAuthenticated) {
      setMenuItems(data.item?.signedInResidential?.targetItems);
    }
    // professional signed-in
    if (data && currentSiteMode === SITE_MODE.PROFESSIONAL && userAuthenticated) {
      setMenuItems(data.item?.signedInProfessional?.targetItems);
    }
    // residential not-signed-in
    if (data && currentSiteMode === SITE_MODE.RESIDENTIAL && !userAuthenticated) {
      setMenuItems(data.item?.notSignedInResidential?.targetItems);
    }
    // professional not-signed-in
    if (data && currentSiteMode === SITE_MODE.PROFESSIONAL && !userAuthenticated) {
      setMenuItems(data.item?.notSignedInProfessional?.targetItems);
    }
  }, [data, userAuthenticated]);

  const onMenuButtonClick = (id) => {
    if (Object.keys(OVERLAY).find((key) => OVERLAY[key] === id)) {
      setActiveMenu(id);
    }
  };

  const css = classnames({
    "mega-menu": type === "megamenu",
    "mega-menu__footer-block": type === "footer",
    mb8: type === "footer",
  });

  return (
    <>
      {!loading && menuItems && (
        <div className={css}>
          <div className="menu__container d-f-vp5">
            <nav className="mega-menu__inner">
              <motion.div
                className="mega-menu__list"
                variants={containerMotion}
                initial="initial"
                animate="animate"
                key="blabla"
              >
                {data.item?.title?.value && (
                  <motion.h2 variants={itemMotion} key="header">
                    {data.item.title.value}
                  </motion.h2>
                )}
                <ul>
                  {menuItems.map((item) => {
                    const cmd = item.command?.value;
                    return (
                      <motion.li
                        variants={itemMotion}
                        className="mega-menu__list-item"
                        key={item.id}
                      >
                        {item.command && cmd && (cmd === OVERLAY.LOGIN || cmd === OVERLAY.LOGOUT) && (
                          <LoginLink
                            linkClass="mega-menu__button"
                            action={item.command.value}
                            linkText={item.title?.value}
                            popupUrl={placeholderReplace(process.env.REACT_APP_IDENTITY_LINK, {
                              locale: lang,
                              redirectUri: process.env.REACT_APP_IDENTITY_REDIRECT,
                            })}
                          />
                        )}
                        {item.command && cmd !== OVERLAY.LOGIN && cmd !== OVERLAY.LOGOUT && (
                          <button
                            className="mega-menu__button"
                            onClick={() => {
                              onMenuButtonClick(item.command?.value);
                            }}
                          >
                            <span>{item.title?.value}</span>
                            <ArrowSmallSvg />
                          </button>
                        )}
                        {item.link && <MenuLink jss={item.link} className="mega-menu__button" />}
                      </motion.li>
                    );
                  })}
                </ul>
              </motion.div>
            </nav>
          </div>
        </div>
      )}
    </>
  );
};

export default MyFritzMenu;
