import React from "react";
import "./icons.scss";

export default ({ className, isInverted }) => (
  <svg
    className={`svg-icon svg-icon--user ${className || ""} ${
      isInverted ? "is-inverted" : ""
    }`}
    width="14"
    height="19"
    viewBox="0 0 14 19"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7 1C8.65 1 10 2.35 10 4C10 5.65 8.65 7 7 7C5.35 7 4 5.65 4 4C4 2.35 5.35 1 7 1ZM7 0C4.79 0 3 1.79 3 4C3 6.21 4.79 8 7 8C9.21 8 11 6.21 11 4C11 1.79 9.21 0 7 0Z" />
    <path d="M14 19H13V12.25L10.16 10.3L7 13.44L3.84 10.3L1 12.25V19H0V11.72L3.95 9L7 12.03L10.05 9L14 11.72V19Z" />
  </svg>
);
