import React from "react";
import TextTile from "../TextTile";
import classnames from "classnames";

import "./feature-small.scss";
import MediaFocal from "../MediaFocal";

const FeatureSmall = ({ fields }) => {
  const classList = classnames({
    "feature-small": true,
    "feature-small--body-right": fields?.bodyRight?.value,
  });

  const isButtonEnabled = fields?.button ? true : false;
  const link = fields?.button?.fields?.link?.value?.href;

  return (
    <>
      {fields && (
        <div className={classList}>
          <div
            className="feature-small__wrapper"
            style={
              fields?.backgroundColorHex && {
                "--feature-small-bgc": fields?.backgroundColorHex?.value,
              }
            }
          >
            <div className="feature-small__text-wrapper">
              <TextTile
                fields={fields}
                interactive={!link ? false : true}
                link={link}
                isButtonEnabled={isButtonEnabled}
                isInverted={fields?.isInverted?.value}
              />
            </div>

            <div className="feature-small__image">
              <a href={link}>
                <MediaFocal fields={fields?.media?.fields} />
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
// FeatureSmall.propTypes = {};

export default FeatureSmall;
