import React, { useState, Fragment } from "react";
import MediaFocal from "../MediaFocal";
import ActionButton from "../ActionButton";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import classnames from "classnames";
import isTrue from "@assets/js/is-true";
import { motion } from "framer-motion";

import "./image-gallery.scss";

const ImageGallery = ({ fields }) => {
  const [expanded, setExpanded] = useState(false);

  const classList = classnames({
    "image-gallery": true,
    "image-gallery--inverted": isTrue(fields?.isInverted),
    "image-gallery--expanded": expanded, // just for mobile's sake
    "image-gallery--3-or-less": fields?.images?.length <= 3,
    "image-gallery--has-bgcolor": fields?.backgroundColorHex?.value,
  });

  const expand = () => {
    setExpanded(true);
  };

  let expandVariant = {
    expanded: {
      height: "auto",
      transition: {
        duration: 0.8,
        when: "afterChildren",
      },
    },
  };

  return (
    <>
      {fields && fields.images && (
        <div className="image-gallery-outer">
          <motion.div
            className={classList}
            style={
              fields.backgroundColorHex && {
                "--image-gallery-bgc": fields.backgroundColorHex.value,
              }
            }
            variants={expandVariant}
            animate={fields?.images?.length > 3 && expanded ? "expanded" : ""}
          >
            <div className="image-gallery__wrapper">
              <div className="image-gallery__left-col">
                {fields.images.map((image, index) => {
                  return (
                    <Fragment key={image?.id}>
                      {index % 2 === 0 && (
                        <div className="image-gallery__image">
                          <MediaFocal fields={image.fields} />
                        </div>
                      )}
                    </Fragment>
                  );
                })}
              </div>
              <div className="image-gallery__right-col">
                {fields.images.map((image, index) => {
                  return (
                    <Fragment key={image?.id}>
                      {index % 2 === 1 && (
                        <div className="image-gallery__image">
                          <MediaFocal fields={image.fields} />
                        </div>
                      )}
                    </Fragment>
                  );
                })}
              </div>

              {fields?.headline?.value && (
                <div className="image-gallery__headline">
                  <Text tag="h2" field={fields.headline} />
                </div>
              )}
              {fields?.bodyText?.value && (
                <div className="image-gallery__bodytext">
                  <Text tag="p" field={fields.bodyText} />
                </div>
              )}
            </div>
          </motion.div>
          {fields?.images?.length > 3 && !expanded && (
            <div className="image-gallery__button">
              <ActionButton
                clickHandler={expand}
                buttonType="ghost"
                text={fields.loadMoreButtonText?.value}
                hasArrow={true}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ImageGallery;
