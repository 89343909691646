import create from "zustand";
import { devtools } from "zustand/middleware";
import axios from "axios";
import { STATUS, SITE_MODE } from "@constants";
import path from "@assets/js/browser-path";
import globalStore from "@store/global";
import dummyJSON from "@assets/json/stores.json";

const store = create(
  devtools(
    (set, get) => ({
      loadStatus: STATUS.LOADING_IDLE,

      storesList: [],
      setStoresList: (list) => set({ storesList: list }),

      activeStore: [],
      setActiveStore: (value) => set({ activeStore: value }),

      searchLocation: [],
      setSearchLocation: (value) => set({ searchLocation: value }),

      clientLocation: [],
      setClientLocation: (value) => set({ clientLocation: value }),

      filterString: "",
      setFilterString: (value) => set({ filterString: value }),

      clustersList: [],
      setClustersList: (list) => set({ clustersList: list }),

      googleAPIKey: null,
      setGoogleAPIKey: (value) => set({ googleAPIKey: value }),

      mapZoomFactor: null,
      setMapZoomFactor: (value) => set({ mapZoomFactor: value }),

      mapCenter: null,
      setMapCenter: (value) => set({ mapCenter: value }),

      fetchClusters: async (bounds) => {
        set({ loadStatus: STATUS.LOADING });

        //bounds MUST to be unpacked - not in google LatLngBounds format
        //example: {ne: {lat:1,lng:1}, sw: {lat:1,lng:1}}
        let parsedBounds = {
          ne_lat: bounds.ne.lat.toString(),
          ne_lng: bounds.ne.lng.toString(),
          sw_lat: bounds.sw.lat.toString(),
          sw_lng: bounds.sw.lng.toString(),
        };
        let filter = get().filterString;

        let cells = Math.max(10, get().mapZoomFactor).toString();
        let mode = globalStore.getState().currentSiteMode ===  SITE_MODE.RESIDENTIAL ? "residential" : "professional";
        
        const response = await axios.get(
          path.join(
            process.env.REACT_APP_ROOTAPI,
            "api",
            "storelocator",
            "cluster2",
            process.env.REACT_APP_STORES_CONTEXT_ID,
            parsedBounds.ne_lat,
            parsedBounds.ne_lng,
            parsedBounds.sw_lat,
            parsedBounds.sw_lng,
            mode,
            cells,
            filter
          )
        );

        // let response = { data: dummyJSON };

        let clusters = response.data;
        let stores = [];
        clusters.forEach((item) => {
          item.stores.forEach((item) => {
            stores.push(item);
          });
        });

        let aS = get().activeStore;
        if (aS && aS.id) {
          stores = stores.sort((a) => {
            return a.id === get().activeStore.id ? -1 : 1;
          });
        }

        set({ clustersList: clusters, storesList: stores });
      },
    }),
    "Stores"
  )
);
// test-edit.fritzhansen.com/api/storelocator/cluster/04705664-F93D-4D47-A8A3-A98E44FDAFB6/56.299414632869606/14.2568914680349/55.04621993893699/10.747858331965125/10
// test-edit.fritzhansen.com/api/storelocator/cluster/04705664-F93D-4D47-A8A3-A98E44FDAFB6/56.356230844608916/14.3734294760951/54.86723822349509/14.373429476095168/10
export const useMapZoomFactor = () => [
  store((store) => store.mapZoomFactor),
  store((store) => store.setMapZoomFactor),
];
export const useMapCenter = () => [
  store((store) => store.mapCenter),
  store((store) => store.setMapCenter),
];
export const useGoogleAPIKey = () => [
  store((store) => store.googleAPIKey),
  store((store) => store.setGoogleAPIKey),
];

export const useStoresList = () => [
  store((store) => store.storesList),
  store((store) => store.setStoresList),
];
export const useClustersList = () => [
  store((store) => store.clustersList),
  store((store) => store.setClustersList),
  store((store) => store.fetchClusters),
];
export const useActiveStore = () => [
  store((store) => store.activeStore),
  store((store) => store.setActiveStore),
];
export const useSearchLocation = () => [
  store((store) => store.searchLocation),
  store((store) => store.setSearchLocation),
];
export const useClientLocation = () => [
  store((store) => store.clientLocation),
  store((store) => store.setClientLocation),
];
export const useFilterString = () => [
  store((store) => store.filterString),
  store((store) => store.setFilterString),
];

export default store;
