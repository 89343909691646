import MediaFocal from "./media-focal";

export default {
  default: {
    fields: {
      href: "#material1",
      media: MediaFocal.default,
      headline: "Textile 1",
      bodyText: "68% new wool, 26% cotton, 6%<br/>Martindale 40.000 - 50.000",
      count: "35 colors",
    },
  },
  alternate: {
    fields: {
      href: "#material2",
      media: MediaFocal.default,
      headline: "Leather 2 ",
      bodyText: "68% new wool, 26% cotton, 6%<br/>Martindale 40.000 - 50.000",
      count: "3 colors",
    },
  },
};
