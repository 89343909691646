import React, { useEffect, useState, Fragment } from "react";

import { motion, AnimatePresence } from "framer-motion";
import { useTranslation } from "react-i18next";
import axios from "axios";
import path from "@assets/js/browser-path";
import { loader } from "graphql.macro";
import { useQuery } from "react-apollo";


import SearchForm from "../SearchForm";
import ProductPrice from "../../ProductPrice";
import ProductImage from "../../ProductImage";

import MenuLink from "../../SiteHeader/MenuLink";

import useGlobalStore, { useCommerceMarket } from "@store/global";
import { SITE_MODE } from "@constants";


import "./site-search.scss";

const navLinksQuery = loader("./navLinksQuery.graphql");

const SiteSearchController = (props) => {
  const { t } = useTranslation();
  const texts = {
    products: t("FritzHansen_Project_ConsumersAndProfessionals_General_Products"),
    pages: t("FritzHansen_Project_ConsumersAndProfessionals_General_Pages"),
    lookingFor: t("FritzHansen_Project_ConsumersAndProfessionals_Search_YouMightBeLookingFor"),
  };
  const mode = useGlobalStore((state) => state.currentSiteMode);
  const menuId =
    mode === SITE_MODE.RESIDENTIAL
      ? process.env.REACT_APP_RESIDENTIAL_DESKTOP_ID
      : process.env.REACT_APP_PROFESSIONAL_DESKTOP_ID;
  const { data, loading } = useQuery(navLinksQuery, {
    variables: { menuId },
    errorPolicy: "all",
  });

  const sc_lang = useGlobalStore((state) => state.sc_lang);
  const [, setSearchTerm] = useState("");
  const [searchResultsData, setSearchResultsData] = useState(null);

  const [commerceMarket] = useCommerceMarket();

  const fetchResults = (searchTerm, lang) =>
    axios.get(
      path.join(
        process.env.REACT_APP_PRODUCTSAPI,
        "Search",
        lang,
        "cp",
        "suggest",
        `?query=${searchTerm}&market=${commerceMarket}`
      )
    );

  const changeHandler = (target) => {
    async function loadResults(term) {
      const response = await fetchResults(term, sc_lang);

      setSearchResultsData(response.data);
    }

    let newVal = target.value;
    setSearchTerm(newVal);

    if (newVal.length > 1) {
      loadResults(newVal);
      // setSearchResultsData(dummyJSON);
    } else {
      setSearchResultsData(null);
    }
  };

  return (
    <div className="site-search">
      <div className="site-search__form">
        <SearchForm onChange={changeHandler} />
      </div>
      {searchResultsData && (
        <>
          {searchResultsData.products && (
            <div className="site-search__list">
              <h3 className="site-search__list__title">
                {searchResultsData.products.count} {texts.products}
              </h3>
              <ul>
                {searchResultsData.products.list.map((item, index) => {
                  return (
                    <SiteSearchProductTile
                      key={`siteSearchProductTile${item.imageId || index}`}
                      data={item}
                    />
                  );
                })}
              </ul>
            </div>
          )}

          {searchResultsData.pages && (
            <div className="site-search__list">
              <h3 className="site-search__list__title">
                {searchResultsData.pages.count} {texts.pages}
              </h3>
              <ul>
                {searchResultsData.pages.list.map((item, index) => {
                  return (
                    <SiteSearchPageTile
                      key={`siteSearchPageTile${index}${item.headline}`}
                      data={item}
                    />
                  );
                })}
              </ul>
            </div>
          )}
        </>
      )}

      <motion.div
        className="site-search__links"
        animate={{ opacity: 1, y: 0 }}
        initial={{ opacity: 0, y: -30 }}
      >
        {data && (
          <div className="mega-menu__list">
            <span className="site-search__links-title">{data.menu?.title?.value}</span>
            <ul>
              {data.menu.navLinks &&
                data.menu.navLinks.targetItems.map((item) => {
                  return (
                    <li key={item.id}>
                      <div className="mega-menu__list-item">
                        <MenuLink jss={item.link} className="mega-menu__button" />
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
        )}
      </motion.div>
    </div>
  );
};

const SiteSearchProductTile = ({ data }) => {
  return (
    <li className="site-search-tile site-search-tile--product">
      <a href={data.url}>
        <div className="site-search-tile__image">
          {data.imageUrl ? (
            <div className="product-image">
              <img src={data.imageUrl} />
            </div>
          ) : (
            <ProductImage imageId={data.imageId || ""} />
          )}
        </div>
        <div className="site-search-tile__text">
          <h4>{data.displayName}</h4>

          {data.description && <p>{data.description}</p>}
          {data.description1 && <p>{data.description1}</p>}
          {data.description2 && <p>{data.description2}</p>}

          {data.modelTypeId_s && <ProductPrice modelTypeId={data.modelTypeId_s} />}
        </div>
      </a>
    </li>
  );
};

const SiteSearchPageTile = ({ data }) => {
  return (
    <li className="site-search-tile site-search-tile--page">
      <a href={data.url}>
        {data.imageUrl && (
          <div className="site-search-tile__image">
            <img src={data.imageUrl} alt={data.headline} />
          </div>
        )}
        <div className="site-search-tile__text">
          <h4>{data.headline ? data.headline : data.url}</h4>
          <p>{data.description}</p>
        </div>
      </a>
    </li>
  );
};

SiteSearchController.propTypes = {};

export default SiteSearchController;
