export default {
  default: {
    name: "lorem",
    id: "id",
    value: "option1",
    label: "Armchair",
    errorMessage: "error!",
    successMessage: "success!",
    checked: false,
  },
  labelPos: {
    name: "lorem",
    id: "id",
    value: "option2",
    label: "Barstool",
    errorMessage: "error!",
    successMessage: "success!",
    checked: true,
    labelLeft: true,
  },
};
