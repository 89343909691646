import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Cookies from "universal-cookie";

import Select from "../forms/Select";
import ActionButton from "../ActionButton";

import { BUTTONSTYLE, COOKIES } from "@constants";
import { log } from "@assets/js/utils";

import useGlobalStore, {
  useLanguage,
  useMarketInfo,
  useCommerceMarket,
  useCommerceMarketSupportsEcom,
  useCommerceMarketNeedsUpdate,
  useShowNotificationGeolocation,
  useUserManuallyChosenCountry,
  useLocationConflict,
} from "@store/global";

import useBasketStore from "@store/basket";

import { usePLPPrices } from "@store/price";

import "./country-selector.scss";

const CountrySelector = ({ backgroundColorHex, onUserInteractionComplete, preselectedLanguage, preselectedCountry }) => {
  const { t } = useTranslation();

  const [marketInfo] = useMarketInfo();
  const [, setUserManuallyChosenCountry] = useUserManuallyChosenCountry();

  const [, fetchPLPPrices] = usePLPPrices();
  const sc_lang = useGlobalStore((state) => state.sc_lang);

  const [commerceMarket, setCommerceMarket] = useCommerceMarket();
  const [, setCommerceMarketSupportsEcom] = useCommerceMarketSupportsEcom();

  const basketId = useBasketStore((store) => store.basketId);
  const changeBasketMarketCountry = useBasketStore((store) => store.changeBasketMarketCountry);

  const [, setShowNotificationGeolocation] = useShowNotificationGeolocation();
  const [locationConflict, setLocationConflict] = useLocationConflict();
  const [chosenValues, setChosenValues] = useState({
    sc_lang: preselectedLanguage || "",
    commerceMarket: preselectedCountry || "",
  });

  useEffect(() => {
      if ( !preselectedLanguage && !preselectedCountry ) {
        setChosenValues((state) => ({
          ...state,
          sc_lang: sc_lang,
          commerceMarket: commerceMarket,
        }));
      }
    },
    [sc_lang, commerceMarket]
  );

  const submitHandler = () => {
    log("submit",chosenValues)
    if (chosenValues.commerceMarket) {
      setUserManuallyChosenCountry(true);

      let marketChanged = commerceMarket.toLowerCase() != chosenValues.commerceMarket.toLowerCase() || locationConflict;
      let languageChanged = sc_lang.toLowerCase() != chosenValues.sc_lang.toLowerCase() || locationConflict;
      let onlyMarketChanged = marketChanged && !languageChanged;
      let onlyLanguageChanged = !marketChanged && languageChanged;
      let bothChanged = marketChanged && languageChanged;

      let newMarketSupportsEcom = getSupportsEComm(chosenValues.commerceMarket.toLowerCase());

      const pn = window.location.pathname;
      const pathname = pn.indexOf(`/${sc_lang}`) === 0
      ? pn.replace(`/${sc_lang}`, `/${chosenValues.sc_lang}`)
      : `/${chosenValues.sc_lang}${pn}`;
      let newLangUrl = pathname;

      
        log("CountrySelector | new market=" + chosenValues.commerceMarket.toLowerCase());
        log("CountrySelector | new language=" + chosenValues.sc_lang.toLowerCase());

        log("CountrySelector | marketChanged=" + marketChanged);
        log("CountrySelector | languageChanged=" + languageChanged);

        log("CountrySelector | newMarketSupportsEcom=" + newMarketSupportsEcom);
        log("CountrySelector | newLangUrl=" + newLangUrl);
      

      const cookies = new Cookies();
      cookies.set(COOKIES.market.name, chosenValues.commerceMarket.toLowerCase(), {
        path: "/",
      });

      if (marketChanged) {
        setCommerceMarket(chosenValues.commerceMarket.toLowerCase());
        setCommerceMarketSupportsEcom(newMarketSupportsEcom);
        fetchPLPPrices(chosenValues.commerceMarket.toLowerCase());
      }
 
      if (onlyMarketChanged && newMarketSupportsEcom) {
        //update basket, get new prices, carry on
        changeBasketMarketCountry(chosenValues.commerceMarket.toLowerCase(), basketId);
        setShowNotificationGeolocation(null);
      } else if (onlyLanguageChanged) {
        //just set url, sitecore and jss loads all the rest
        window.location.pathname = newLangUrl;
      } else if (bothChanged) {
        if (newMarketSupportsEcom) {
          //need to set basket to new market BEFORE reloading
          changeBasketMarketCountry(
            chosenValues.commerceMarket.toLowerCase(),
            basketId,
            newLangUrl
          );
        } else {
          //just reload with new language
          window.location.pathname = newLangUrl;
        }
      } else {
        // assuming NOTHING CHANGED
        setShowNotificationGeolocation(null);
      }

      if (onUserInteractionComplete) onUserInteractionComplete();
    }
  };

  const getSupportsEComm = (market) => {
    let newMarket = marketInfo.markets.values.find(
      (item) => item.commerceMarket.value.toLowerCase() == market
    );

      log("CountrySelector | newMarket", newMarket);

    return newMarket && newMarket.marketSupportsEcom.value == true;
  };

  const getCurrentLanguageValue = () => {
    const searchVal = preselectedLanguage ? preselectedLanguage : sc_lang.toLowerCase();
    const foundLang = marketInfo.languages.values.find(
      (item) => item.sitecoreLanguage.value.languageCode.toLowerCase() === searchVal
    );
    if (foundLang) {
      return {
        id: marketInfo.languages.values.indexOf(foundLang),
        optionLabel: foundLang.name.value,
        optionValue: foundLang.sitecoreLanguage.value.languageCode,
      };
    }
    return null;
  };

  const getCurrentCountryValue = () => {
    const searchVal = preselectedCountry ? preselectedCountry : commerceMarket;
    const foundMarket = marketInfo.markets.values.find(
      (item) => item.commerceMarket.value.toLowerCase() === searchVal.toLowerCase()
    );
    if (foundMarket) {
      return {
        id: marketInfo.markets.values.indexOf(foundMarket),
        optionLabel: foundMarket.countryName.value,
        optionValue: foundMarket.commerceMarket.value,
      };
    }
    return null;
  };

  return (
    <div
      className="country-selector"
      style={
        backgroundColorHex && {
          "--country-selector-bgc": backgroundColorHex,
        }
      }
    >
      {marketInfo?.markets?.values && (
        <Select
          value={getCurrentCountryValue()}
          options={marketInfo.markets.values.map((market) => ({
            id: market.id,
            optionLabel: market.countryName.value,
            optionValue: market.commerceMarket.value,
          }))}
          name="country"
          changeHandler={(value) => {
            setChosenValues((state) => ({ ...state, commerceMarket: value }));
          }}
        />
      )}
      {marketInfo?.languages?.values && (
        <Select
          value={getCurrentLanguageValue()}
          options={marketInfo.languages.values.map((language) => ({
            id: language.id,
            optionLabel: language.name.value,
            optionValue: language.sitecoreLanguage.value.languageCode,
          }))}
          name="languages"
          changeHandler={(value) => setChosenValues((state) => ({ ...state, sc_lang: value }))}
        />
      )}
      <ActionButton
        buttonType={BUTTONSTYLE.SOLID}
        text={t(
          "FritzHansen_Project_ConsumersAndProfessionals_Notifications_CountrySelectorButton"
        )}
        isInverted={true}
        icon="ArrowSvg"
        clickHandler={submitHandler}
      />
    </div>
  );
};
CountrySelector.propTypes = {
  backgroundColorHex: PropTypes.string,
};

export default CountrySelector;
