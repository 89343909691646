import MediaFocal from "./media-focal";

export default {
  default: {
    fields: {
      media: MediaFocal.default,
      value: {
        productId: "98gd89g7dfg",
        title: "Leather wax",
        price: "€ 20.00",
        addLabel: "Add to basket",
      },
    },
  },
};
