import React from "react";
import PropTypes from "prop-types";
import { RichText, Text } from "@sitecore-jss/sitecore-jss-react";
import { NavLink } from "react-router-dom";
import classnames from "classnames";

import NavButton from "../NavButton";
import ContactLinksGroup from "../ContactLinksGroup";
import LinkWrapper from "../LinkWrapper";

import { LINKTYPE } from "@constants";

import "./text-tile.scss";
const TextTile = ({
  fields,
  interactive,
  contactInfoAvailable,
  link,
  isButtonEnabled,
  isInverted,
}) => {
  const classList = classnames({
    "text-tile": true,
    "text-tile--inverted": isInverted,
  });
  const buttonFields =
    fields?.button?.fields?.link?.value?.href || fields?.button?.fields?.command?.value
      ? fields.button.fields
      : fields?.button?.fields?.button?.fields?.link?.value?.href ||
        fields?.button?.fields?.button?.fields?.command?.value
      ? fields.button.fields.button.fields
      : {};

  return (
    <>
      {fields && (
        <div className={classList}>
          {fields?.tag?.value && (
            <div className="text-tile__tag">
              <LinkWrapper field={buttonFields}>
                <Text tag="h6" field={fields?.tag || fields?.tag.value} />
              </LinkWrapper>
            </div>
          )}

          {fields?.headline?.value && (
            <div className="text-tile__headline">
              <LinkWrapper field={buttonFields}>
                <Text tag="h2" field={fields?.headline} />
              </LinkWrapper>
            </div>
          )}

          {fields?.bodyText?.value && fields?.bodyText.value.length > 0 && (
            <div className="text-tile__bodytext">
              <LinkWrapper field={buttonFields}>
                <RichText className="rich-text" field={fields?.bodyText} />
              </LinkWrapper>
            </div>
          )}

          {!isButtonEnabled && contactInfoAvailable && (
            <div className="text-tile__contact">
              <ContactLinksGroup phone={fields.phone} email={fields.email} />
            </div>
          )}

          {isButtonEnabled && (
            <div className="text-tile__link">
              <NavButton fields={buttonFields} />
            </div>
          )}
        </div>
      )}
    </>
  );
};

TextTile.propTypes = {
  fields: PropTypes.object,
  interactive: PropTypes.bool,
  contactInfoAvailable: PropTypes.string,
  link: PropTypes.string,
  isInverted: PropTypes.bool,
  isButtonEnabled: PropTypes.bool,
};

export default TextTile;
