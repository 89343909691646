import { ASPECT } from "./constants";
import globalErrorHandler from "./global-error-handler";

export default function (imageData) {
  if (!imageData) {
    console.warn("NO IMAGE DATA", imageData);
  }

  let returnSizes = {};

  let width = !isNaN(imageData?.value?.width) ? Number(imageData?.value?.width) : 0;
  let height = !isNaN(imageData?.value?.height) ? Number(imageData?.value?.height) : 0;

  returnSizes.width = width;
  returnSizes.height = height;
  returnSizes.aspect = 0;
  returnSizes.modifier = 0;

  if (width == 0 || height == 0) {
    console.warn("NO VALID IMAGE SIZE FOR IMAGE", imageData);
  } else {
    const aspect = width / height;

    returnSizes.aspect = aspect;

    if (aspect <= 0.7) {
      returnSizes.aspectType = ASPECT.TALL;
      returnSizes.modifier = 0.4;
    } else if (aspect > 0.7 && aspect < 1.4) {
      returnSizes.aspectType = ASPECT.SQUARE;
      returnSizes.modifier = 0.5;
    } else {
      returnSizes.aspectType = ASPECT.WIDE;
      returnSizes.modifier = 1;
    }
  }
  return returnSizes;
}
