import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import getUniqueId from "@assets/js/get-unique-id";
import { FILTER_NO_TYPE } from "@constants";

import "./pdp-configurator-filters.scss";

const PDPConfiguratorFilters = ({ list, filterClickHandler }) => {
  const { t } = useTranslation();

  const texts = {
    other: t("FritzHansen_Project_ConsumersAndProfessionals_General_Other"),
  };
  return (
    <div className="pdp-configurator-filters">
      <ul className="pdp-configurator-filters__list">
        {list.map((item, index) => {
          return (
            <li key={getUniqueId()}>
              <button
                className={`pdp-configurator-filters__button ${item.isActive ? "is-active" : ""}`}
                onClick={() => filterClickHandler(item)}
              >
                <span className="text">
                  {item.label === FILTER_NO_TYPE ? texts.other : item.label}
                </span>
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
PDPConfiguratorFilters.propTypes = {
  filters: PropTypes.array,
  filterClickHandler: PropTypes.func,
};

export default PDPConfiguratorFilters;
