import React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { BUTTONSTYLE } from "@constants";
import classnames from "classnames";
import * as icons from "@assets/js/icons";
import "./action-button.scss";

const ActionButton = ({
  text,
  icon,
  buttonType,
  isInverted,
  isDisabled,
  hasArrow,
  clickHandler,
  backgroundColorHex,
  textColorHex,
  customClass,
  href,
}) => {
  const classList = classnames({
    "action-button": buttonType.toUpperCase() !== BUTTONSTYLE.NONE,
    "is-outlined": buttonType.toUpperCase() === BUTTONSTYLE.GHOST,
    "is-link": buttonType.toUpperCase() === BUTTONSTYLE.LINK,
    "is-icon-link": buttonType.toUpperCase() === BUTTONSTYLE.ICON_LINK,
    "is-inverted": isInverted,
    "is-disabled": isDisabled,
    [customClass]: customClass ? true : false,
  });

  const Icon = icon ? icons[icon] : null;
  const ArrowSmallSvg = icons["ArrowSmallSvg"];
  const buttonClickHandler = () => {
    if (!isDisabled && clickHandler) clickHandler();
  };
  return (
    <>
      {href ? (
        <NavLink
          className={classList}
          to={href}
          onClick={buttonClickHandler}
          style={{
            "--button-bgc": backgroundColorHex ? backgroundColorHex : null,
            "--button-c": textColorHex ? textColorHex : null,
          }}
        >
          {text && <span className="text">{text}</span>}

          {Icon && (
            <span className="icon">
              <Icon />
            </span>
          )}
          {hasArrow && (
            <span className="icon">
              <ArrowSmallSvg />
            </span>
          )}
        </NavLink>
      ) : (
        <button
          className={classList}
          onClick={buttonClickHandler}
          style={{
            "--button-bgc": backgroundColorHex ? backgroundColorHex : null,
            "--button-c": textColorHex ? textColorHex : null,
          }}
          disabled={isDisabled}
        >
          {text && <span className="text">{text}</span>}

          {Icon && (
            <span className="icon">
              <Icon />
            </span>
          )}
          {hasArrow && (
            <span className="icon">
              <ArrowSmallSvg />
            </span>
          )}
        </button>
      )}
    </>
  );
};

ActionButton.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.string,
  style: PropTypes.string,
  isInverted: PropTypes.bool,
  isDisabled: PropTypes.bool,
  hasArrow: PropTypes.bool,
  clickHandler: PropTypes.func,
  backgroundColorHex: PropTypes.string,
  textColorHex: PropTypes.string,
  customClass: PropTypes.string,
};
export default ActionButton;
