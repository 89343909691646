import NavButton from "./nav-button";
import MediaFocal from "./media-focal";

export default {
  default: {
    fields: {
      feature: {
        fields: {
          tag: null,
          href: "",

          headline: {
            value: "Neque et temporibus sunt",
          },
          bodyText: {
            value: "Sit explicabo eum labore voluptatem, neque et temporibus sunt, culpa tempora ratione quasi nostrum.",
          },
          button: NavButton.link,
          isButtonEnabled: true,
          media: MediaFocal.default,
          bodyRight: false,
          isInverted: false,
          isInteractive: false,
          backgroundColorHex: "#c3eecc",
        },
      },
    },
  },
};
