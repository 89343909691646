import React, { useEffect, useState, Fragment } from "react";
import { useHistory } from "react-router-dom";
import { SwiperSlide } from "swiper/react";
import Carousel from "../Carousel";
import { Link } from "@sitecore-jss/sitecore-jss-react";
import AnimationWrapper from "@assets/js/AnimationWrapper";

import "./sub-navigation.scss";

const SubNavigation = ({ fields }) => {
  const [activeId, setActiveId] = useState(null);
  useEffect(() => {
    const index = fields.list.findIndex(
      (item) => item.fields.link.value.href === window.location.pathname
    );
    setActiveId({
      id:
        index > -1
          ? fields.list[index].fields.link.value.id
          : fields.list[0].id,
      index,
    });
  }, []);

  return (
    <AnimationWrapper type="defaultOpacity">
      {activeId && (
        <div className="sub-navigation">
          <div className="sub-navigation__desktop">
            <SubNavigationDesktop fields={fields} activeId={activeId.id} />
          </div>
          <div className="sub-navigation__mobile-tablet">
            <SubNavigationMobileTablet
              fields={fields}
              currentIndex={activeId.index}
            />
          </div>
        </div>
      )}
    </AnimationWrapper>
  );
};
export default SubNavigation;

// // // DESKTOP SUBNAV // // //

const SubNavigationDesktop = ({ fields, activeId }) => {
  return (
    <div className="sub-navigation__desktop-inner">
      {fields.list.map((item, index) => {
        const itemProps = item.fields.link.value;
        return (
          <div className={"sub-navigation__slide"} key={itemProps.id}>
            <Link
              field={item.fields.link}
              className={`sub-navigation__item ${
                activeId === itemProps.id ? "is-active" : ""
              }`}
            >
              <p className="sub-navigation__item-text">{itemProps.text}</p>
            </Link>
          </div>
        );
      })}
    </div>
  );
};

// // // MOBILE & TABLET SUBNAV // // //

const SubNavigationMobileTablet = ({ fields, currentIndex }) => {
  const history = useHistory();
  const slideChangeHandler = ({ activeIndex }) => {
    //navigationHandler(Swiper.activeIndex);
    history.push(fields.list[activeIndex].fields.link.value.href);
  };
  let swiperArgs = {
    swiperProps: {
      freeMode: false,
      slidesPerView: "auto",
      centeredSlides: true,
      spaceBetween: 50,
      onSlideChange: slideChangeHandler,
      initialSlide: currentIndex,
      runCallbacksOnInit: false,
    },
    settings: {
      noNavigation: true,
    },
    fields: fields,
    className: "sub-navigation__mobile-inner",
    children: (
      <>
        {fields.list.map((item, index) => {
          const itemProps = item.fields.link.value;

          return (
            <Fragment key={itemProps.id}>
              <SwiperSlide
                key={itemProps.id}
                className={"sub-navigation__slide"}
              >
                <div
                  id={itemProps.id}
                  key={itemProps.id}
                  className="sub-navigation__item"
                >
                  <p className="sub-navigation__item-text">{itemProps.text}</p>
                </div>
              </SwiperSlide>
            </Fragment>
          );
        })}
      </>
    ),
  };

  return <Carousel {...swiperArgs} />;
};
