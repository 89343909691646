import getUniqueId from "../js/get-unique-id.js";

export default {
  default: {
    fields: {
      label: "Filter by:",
      noParse: true,
      buttonText: "buttontext",
    },
    tagUpdateHandler: "",
    tagList: [
      {
        noParse: true,
        label: "Dolor",
        id: getUniqueId(),
        title: "field",
      },
      {
        noParse: true,
        label: "Consectetur",
        id: getUniqueId(),
        title: "field",
      },
      {
        noParse: true,
        label: "Amet",
        id: getUniqueId(),
        title: "field",
      },
      {
        noParse: true,
        label: "Dolor",
        id: getUniqueId(),
        title: "field",
      },
      {
        noParse: true,
        label: "Ipsum",
        id: getUniqueId(),
        title: "field",
      },
      {
        noParse: true,
        label: "Consectetur",
        id: getUniqueId(),
        title: "field",
      },
      {
        noParse: true,
        label: "Amet",
        id: getUniqueId(),
        title: "field",
      },
      {
        noParse: true,
        label: "Dolor",
        id: getUniqueId(),
        title: "field",
      },
      {
        noParse: true,
        label: "Ipsum",
        id: getUniqueId(),
        title: "field",
      },
      {
        noParse: true,
        label: "Consectetur",
        id: getUniqueId(),
        title: "field",
      },
      {
        noParse: true,
        label: "Amet",
        id: getUniqueId(),
        title: "field",
      },
    ],
  },
  materialGroups: {
    fields: {
      label: "Filter by:",
      noParse: true,
      buttonText: "buttontext",
    },
    tagUpdateHandler: "",
    tagList: [
      {
        noParse: true,
        id: "234234234",
        label: "Textile",
        title: "field",
      },
      {
        noParse: true,
        id: "68565",
        label: "Leather",
        title: "field",
      },
    ],
  },
  stories: {
    id: "2",
    tagUpdateHandler: "",
    tagList: [
      {
        noParse: true,
        id: { value: "11111" },
        title: "Architecture",
      },
      {
        noParse: true,
        id: { value: "2222" },
        title: "Culture",
      },
      {
        noParse: true,
        id: { value: "3333" },
        title: "Profile",
      },
      {
        noParse: true,
        id: { value: "4444" },
        title: "Design",
      },
    ],
  },
  references: {
    fields: {
      label: "Filter by:",
      noParse: true,
      buttonText: "buttontext",
    },
    tagUpdateHandler: "",
    tagList: [
      {
        noParse: true,
        id: "234234234",
        label: "Office",
        title: "field",
      },
      {
        noParse: true,
        id: "182364",
        label: "Hospitality",
        title: "field",
      },
      {
        noParse: true,
        id: "7834628",
        label: "Living",
        title: "field",
      },
    ],
  },
};
