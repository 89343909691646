import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import useBasketStore from "@store/basket";
import getFormattedPrice from "@assets/js/get-formatted-price";
import shallow from "zustand/shallow";
import useGlobalStore, { useCommerceMarket } from "@store/global";

import "./basket-summary.scss";

const BasketSummary = ({ editBasketLink }) => {
  const { t } = useTranslation();

  const texts = {
    model: t("FritzHansen_Project_ConsumersAndProfessionals_Cart_Model"),
    remove: t("FritzHansen_Project_ConsumersAndProfessionals_Cart_Remove"),
    edit: t("FritzHansen_Project_ConsumersAndProfessionals_Cart_Edit"),
    variants: t("FritzHansen_Project_ConsumersAndProfessionals_Cart_Variants"),
    estDelivery: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Cart_EstimatedDelivery"
    ),
    noDelivery: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Cart_NoDelivery"
    ),

    yourOrder: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Checkout_YourOrder"
    ),
    price: t("FritzHansen_Project_ConsumersAndProfessionals_Checkout_Price"),

    inclVat: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Checkout_InclVat"
    ),
    editBasket: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Checkout_EditBasket"
    ),
    totalWithoutDiscount: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Checkout_TotalWithoutDiscount"
    ),

    discountTotal: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Checkout_DiscountTotal"
    ),

    totalAfterDiscount: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Checkout_TotalAfterDiscount"
    ),
    delivery: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Checkout_Delivery"
    ),
    vat: t("FritzHansen_Project_ConsumersAndProfessionals_Checkout_Vat"),

    totalPrice: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Checkout_TotalPrice"
    ),
    productId: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Checkout_ProductId"
    ),
    lineDiscount: t("FritzHansen_Project_ConsumersAndProfessionals_Checkout_LineDiscount"),
  };
  const lineItems = useBasketStore((store) => store.lineItems);
  const priceView = useBasketStore((store) => store.priceView);
  const hasProducts = useBasketStore((store) => store.hasProducts);

  const [commerceMarket] = useCommerceMarket();
  const [language] = useGlobalStore((state) => [state.language], shallow);

  const [currency, setCurrency] = useState("JPY");
  const [productList, setProductList] = useState([]);

  useEffect(() => {
    if (hasProducts) {
      const newProductList = lineItems.map((item) => {
        return {
          count: item.Quantity,
          price: item.Price,
          name: item.ModelGroupName,
          description: item.FriendlyName || item.FullFriendlyName,
          materials: item.Materials,
          skuVariant: item.SkuVariant,
          itemType: item.ItemType,
          ...item
        };
      });
      setProductList(newProductList);
    }
    if (priceView) {
      setCurrency(priceView.currency);
    }
  }, [lineItems, priceView, hasProducts]);

  return (
    <div className="basket-summary">
      <header className="basket-summary__header">
        <h4>{texts.yourOrder}</h4>
      </header>

      <ul className="basket-summary__products">
        {productList.map((item, index) => {
          return (
            <li key={"orderOverviewProduct" + index}>
              <div>
                <p>{item.count} &times;</p>
              </div>
              <div>
                <h5>{item.name}</h5>
                {item.itemType === 1 ? <>
                  <p>
                    {texts.variants}:{" "}
                    <span>{item.skuVariant?.Name}</span>
                  </p>
                </> : <>                  
                {item?.materials && (
                  <p>
                    {texts.variants}:{" "}
                    {item?.materials.map((item2, index2) => (
                      <span key={`product${index}Mat${index2}`}>
                        {item2.Name}
                        {index2 <= item.materials.length - 2 ? ", " : ""}
                      </span>
                    ))}
                  </p>
                )}
                </>}  

                <h6>{item.description}</h6>
              </div>
              <div>
                {item.Quantity===1?"":
                <h6>{item.Quantity}&nbsp;&times;&nbsp;{getFormattedPrice(language, commerceMarket, currency, item.Price)}</h6>}
                {
                // eslint-disable-next-line
                item.TotalDiscount==0? // 
                  <h5>{getFormattedPrice(language, commerceMarket, currency, item.Total)}</h5>:
                  <>
                    <h6>{getFormattedPrice(language, commerceMarket, currency, item.Total + item.TotalDiscount)}</h6>
                    <h6>{texts.lineDiscount} - {getFormattedPrice(language, commerceMarket, currency, item.TotalDiscount)}</h6>
                    <h5>{getFormattedPrice(language, commerceMarket, currency, item.Total)}</h5>
                  </>
                }
              </div>
            </li>
          );
        })}
      </ul>

      {priceView && (
        <div className="basket-summary__calculation">
          <div>
            <p>{texts.totalWithoutDiscount}</p>
            <h5>
              {getFormattedPrice(
                language,
                commerceMarket,
                currency,
                priceView.totalWithoutDiscount
              )}
            </h5>
          </div>

          {priceView.discountTotal > 0 && (
            <>
              <div>
                <p>{texts.discountTotal}</p>
                <h5>
                  -{" "}
                  {getFormattedPrice(
                    language,
                    commerceMarket,
                    currency,
                    priceView.discountTotal
                  )}
                </h5>
              </div>

              <div>
                <p>{texts.totalAfterDiscount}</p>
                <h5>
                  {getFormattedPrice(
                    language,
                    commerceMarket,
                    currency,
                    priceView.subTotal
                  )}
                </h5>
              </div>
            </>
          )}

          <div>
            <p>{texts.delivery}</p>
            <h5>
              {getFormattedPrice(
                language,
                commerceMarket,
                currency,
                priceView.shippingTotal
              )}
            </h5>
          </div>


          <footer>
            <h5>{texts.totalPrice}</h5>
            <h5>
              {getFormattedPrice(
                language,
                commerceMarket,
                currency,
                priceView.orderTotal
              )}
              <small>{texts.inclVat}</small>
            </h5>
          </footer>
          
          <div>
            <p>{texts.vat}</p>
            <h5>
              {getFormattedPrice(
                language,
                commerceMarket,
                currency,
                priceView.taxTotal
              )}
            </h5>
          </div>
        </div>
      )}
      <div className="basket-summary__cta">
        <NavLink to={editBasketLink}>{texts.editBasket}</NavLink>
      </div>
    </div>
  );
};
BasketSummary.propTypes = {};

export default BasketSummary;
