import React from "react";
import { useTranslation } from "react-i18next";

import getFormattedPrice from "@assets/js/get-formatted-price";
import shallow from "zustand/shallow";
import useGlobalStore, { useCommerceMarket } from "@store/global";

import ProductImage from "../ProductImage";
import "./product-line.scss";

const ProductLine = ({
  Price,
  PriceExVat,
  Total,
  TotalExVat,
  TotalDiscount,
  ModelName,
  OrderLineId,
  FullFriendlyName,
  FriendlyName,
  ModelGroupName,
  Materials,
  Quantity,
  ItemType,
  SkuVariant,
  Vat,
  currency,
  ProductImageUrl = null,
}) => {
  const { t } = useTranslation();
  const texts = {
    variants: t("FritzHansen_Project_ConsumersAndProfessionals_Cart_Variants"),
  };

  const [commerceMarket] = useCommerceMarket();
  const [language] = useGlobalStore((state) => [state.language], shallow);

  return (
    <div className="product-line">
      <div className="product-line__image">
        {ProductImageUrl ? (
          <figure className="product-image">
            <img src={ProductImageUrl} alt={FriendlyName} />
          </figure>
        ) : (
          <ProductImage imageId={FriendlyName} />
        )}
      </div>

      <div className="product-line__quantity">
        <p>{Quantity} &times;</p>
      </div>

      <div className="product-line__text">
        <h3>{ModelGroupName}</h3>
        <p>
          {texts.variants}:{" "}
          {ItemType === 1 ? <>
            <span>{SkuVariant?.Name}</span>
          </> : <>
            {Materials.map((item, index) => (
              <span key={`prodMat${index}`}>
                {item.Name}
                {index <= Materials.length - 2 ? ", " : ""}
              </span>
            ))}
          </>}  
        </p>
        <h6>{FriendlyName}</h6>

      </div>

      <div className="product-line__prices">
        {Quantity===1?"":
        <p>{Quantity}&nbsp;&times;&nbsp;{getFormattedPrice(language, commerceMarket, currency, Price)}</p>}
        {
        TotalDiscount===0?
        <h4>{getFormattedPrice(language, commerceMarket, currency, Total)}</h4>:
        <>
          <h5>{getFormattedPrice(language, commerceMarket, currency, Total + TotalDiscount)}</h5>
          <p>{texts.lineDiscount} - {getFormattedPrice(language, commerceMarket, currency, TotalDiscount)}</p>
          <h4>{getFormattedPrice(language, commerceMarket, currency, Total)}</h4>
        </>
        }
      </div>
    </div>
  );
};
ProductLine.propTypes = {};

export default ProductLine;
