import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import classnames from "classnames";

import "./progress-bar.scss";
const ProgressBar = ({ fields, id = 0 }) => {
  const [activeStep, setActiveStep] = useState(id);

  let nextStep = activeStep + 1;
  let prevStep = activeStep - 1;

  return (
    <div className="progress-bar">
      <ul className="progress-bar__wrapper">
        {fields?.stepList?.length &&
          fields.stepList.map((step, index) => {
            return (
              <Step
                key={`progressStep${index}`}
                id={step.id}
                activeStep={activeStep}
                label={step.name}
                link={step.url}
                interactive={step.interactive}
              />
            );
          })}
      </ul>

      {fields.showTestButtons && (
        <div className="test-buttons" style={{ marginTop: "5rem" }}>
          <div>Test:</div>
          <button onClick={() => setActiveStep(nextStep)}>Next</button>
          <button onClick={() => setActiveStep(prevStep)}>Back</button>
        </div>
      )}
    </div>
  );
};
// ProgressBar.propTypes = {};

export default ProgressBar;

const Step = ({ label, link, activeStep, id, interactive }) => {
  const classList = classnames({
    "progress-bar__step": true,
    "is-idle": id > activeStep, // CHECKOUT_STATUS.IDLE
    "is-active": id === activeStep, // CHECKOUT_STATUS.ACTIVE
    "is-complete": id < activeStep, // CHECKOUT_STATUS.COMPLETE
  });
  return (
    <>
      {interactive ? (
        <NavLink to={link} className={classList}>
          <li id={id}>
            <span className="progress-bar__dot"></span>
            <p className="progress-bar__label">{label}</p>
          </li>
        </NavLink>
      ) : (
        <div className={classList}>
          <li id={id}>
            <span className="progress-bar__dot"></span>
            <p className="progress-bar__label">{label}</p>
          </li>
        </div>
      )}
    </>
  );
};
