import React from "react";
import NavList from "../NavList";
import { motion } from "framer-motion";
import { LINKTYPE } from "@constants";
import AnimationWrapper from "@assets/js/AnimationWrapper";

import "./page-siblings.scss";

const PageSiblings = ({ fields }) => {
  const isAutoMode = fields.data?.__type?.name === "PageSiblingsAuto";

  const feed = fields.data?.feed;

  if (!feed) {
    return <></>;
  }

  const primaryList = isAutoMode
    ? feed.value?.map((item) => {
        return {
          fields: {
            link: {
              value: {
                href: item.link,
                text: item.text,
                linktype: LINKTYPE.INTERNAL,
              },
            },
            image: item.image?.jss?.fields?.image,
          },
        };
      })
    : feed.field?.value?.map((item) => {
        return { fields: { link: item.link.jss, image: item.image.jss } };
      });
  const headline = fields.data?.headline.field;
  const seeAllLink = fields.data?.seeAllLink?.field?.value;
  const backgroundColorHex = fields.data?.backgroundColorHex?.field;
  const isInverted = fields.data?.isInverted?.field?.value;

  return (
    <>
      {fields && (
        <AnimationWrapper>
          <NavList
            fields={{
              primaryList: primaryList,
              headline: headline,
              seeAllLink: seeAllLink,
              backgroundColorHex: backgroundColorHex,
              isInverted: isInverted,
            }}
          />
        </AnimationWrapper>
      )}
    </>
  );
};

export default PageSiblings;
