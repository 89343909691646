import React, { useEffect, useRef } from "react";
import shallow from "zustand/shallow";
import useGlobalStore from "@store/global";
import "./trust-pilot-script.scss";

const TrustPilotScript = ({}) => {
  const ref = useRef(null);
  const [commerceMarket, language] = useGlobalStore(
    (store) => [store.commerceMarket, store.language],
    shallow
  );

  useEffect(() => {
    if (window.Trustpilot && ref.current && commerceMarket && language) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, [commerceMarket, language]);
  return (
    <>
      {commerceMarket && language && (
        <div
          id="trustbox"
          className="trustpilot-widget"
          data-locale={`${language}-${commerceMarket.toUpperCase()}`}
          data-template-id={process.env.REACT_APP_TRUSTPILOT_TEMPLATE_ID}
          data-businessunit-id={
            process.env.REACT_APP_TRUSTPILOT_BUSINESSUNIT_ID
          }
          data-style-height="20px"
          data-style-width="auto"
          data-theme="light"
          ref={ref}
        >
          <a
            href="https://dk.trustpilot.com/review/fritzhansen.com"
            target="_blank"
            rel="noopener"
          ></a>
        </div>
      )}
    </>
  );
};
TrustPilotScript.propTypes = {};

export default TrustPilotScript;
