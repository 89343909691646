import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import uniqueId from "@assets/js/get-unique-id";

import "./radio.scss";

const Radio = ({ name, register, error, id = uniqueId, value, label, type, checked, disabled, required, labelLeft, changeHandler }) => {
  const [isChecked, setChecked] = useState(checked);

  const onChangeHandler = () => {
    let newValue = !isChecked;
    console.log("radio " + value + " click | checked: " + newValue);
    setChecked(newValue);
    if (changeHandler) changeHandler(newValue);
  };

  const classList = classnames({
    radio: true,
    "radio--label-left": labelLeft,
  });

  return (
    <div className={classList}>
      <div className="radio__wrapper">
        <input
          className="radio__box"
          name={name}
          type="radio"
          id={id}
          disabled={disabled}
          required={required}
          onChange={onChangeHandler}
          value={value}
          ref={register}
        />
        <label className="radio__label" htmlFor={id}>
          {label}
          {required && <span>*</span>}
        </label>
      </div>
      {error && <p className="radio__message">{error.message}</p>}
    </div>
  );
};

Radio.propTypes = {};

export default Radio;
