import create from "zustand";
import { devtools } from "zustand/middleware";
import axios from "axios";
import Cookies from "universal-cookie";
import path from "@assets/js/browser-path";
import { dataLayerCommercePush } from "@assets/js/gtm";
import globalErrorHandler from "@assets/js/global-error-handler";
import { log } from "@assets/js/utils";
import { COOKIES, STATUS } from "@constants";
import globalStore from "@store/global";

//import basket from "@assets/js/icons/basket";

export const basketDataProxy = (data) => {
  let priceView = {
    totalWithoutDiscount: data.TotalWithoutDiscount,
    subTotal: data.SubTotal,
    taxTotal: data.TaxTotal,
    discountTotal: data.DiscountTotal,
    orderTotal: data.OrderTotal,
    shippingTotal: data.ShippingTotal,
    vat: data.Vat,
    currency: data.Currency,
  };

  if (data?.OrderProperties?.voucherCodes) {
    let codesSplit = data.OrderProperties.voucherCodes.split(",");
    codesSplit = codesSplit.filter((item) => item != "");
    priceView.voucherCode = codesSplit[0];
  }

  return {
    pendingRemovalProducts: [],
    lineItems: data.LineItems,
    priceView: priceView,
    loadStatus: STATUS.LOADING_IDLE,
    hasProducts: data.LineItems.length > 0,
  };
};

const store = create(
  devtools(
    (set, get) => ({
      loadStatus: STATUS.LOADING_IDLE,

      basketId: null,
      lineItems: null,

      pendingRemovalProducts: [],

      priceView: null,

      getBasket: async (commerceMarket, basketId = null) => {
        if (process.env.NODE_ENV === "development") log("BASKET | getBasket");
        const response = await axios
          .get(
            `${path.join(
              process.env.REACT_APP_ROOTAPI,
              "basketapi/get/",
              basketId ? basketId : get().basketId
            )}`
          )
          .catch((error) => globalErrorHandler(error, "BASKET GET"));

        if (response) {
          set({
            ...basketDataProxy(response.data),
          });
        } else {
          if (process.env.NODE_ENV === "development")
            log("BASKET | getBasket failed - basket not found");

          get().createBasket(commerceMarket);
        }
      },

      createBasket: async (commerceMarket) => {
        log("BASKET | createBasket");

        const commerceMarketSupportsEcom = globalStore.getState()
          .commerceMarketSupportsEcom;
        if (!commerceMarketSupportsEcom) return false;

        const response = await axios
          .post(
            `${path.join(
              process.env.REACT_APP_ROOTAPI,
              "basketapi/createemptybasket"
            )}?marketCountryCode=${commerceMarket}`
          )
          .catch((error) => globalErrorHandler(error, "BASKET CREATE"));
        if (response) {
          const cookies = new Cookies();
          cookies.set(COOKIES.basket.name, response.data.BasketId, {
            maxAge: 24 * 3600,
            path: "/",
          });

          set({
            basketId: response.data.BasketId,
          });
        }
      },

      addProduct: async (
        payload,
        successHandler = null,
        errorHandler = null
      ) => {
        if (process.env.NODE_ENV === "development") log("BASKET | addProduct");
        set({ loadStatus: STATUS.LOADING });
        const basketId = payload.basketId || get().basketId || null;
        const quantity = payload.quantity || 1;

        if (basketId) {
          const response = await axios
            .post(
              `${path.join(
                process.env.REACT_APP_ROOTAPI,
                "basketapi/add/",
                basketId
              )}?productId=${payload.productId}&quantity=${quantity}`
            )
            .catch((error) => globalErrorHandler(error, "BASKET ADD"));

          if (response) {
            set(basketDataProxy(response.data));
            if (successHandler) successHandler();
            if (errorHandler) errorHandler();
          } else {
            if (errorHandler) errorHandler();
          }
        } else {
          if (errorHandler) errorHandler();
          globalErrorHandler("No basket id found", "BASKET ADD");
        }
      },

      setQuantity: async (body) => {
        set({ loadStatus: STATUS.LOADING });
        const response = await axios.post(
          `${process.env.REACT_APP_ROOTAPI}basketapi/UpdateQuantity/${body.basketId}?orderLineId=${body.OrderLineId}&quantity=${body.Quantity}`
        );
        set(basketDataProxy(response.data));
      },

      removeLine: async ({ basketId, OrderLineId, gtm, currency }) => {
        set({ loadStatus: STATUS.LOADING });
        const response = await axios
          .post(
            `${process.env.REACT_APP_ROOTAPI}basketapi/Remove/${basketId}?orderLineId=${OrderLineId}`
          )
          .catch((error) => globalErrorHandler(error, "BASKET REMOVE"));
        if (response) {
          set(basketDataProxy(response.data));
          // GTM Tracking
          dataLayerCommercePush("removeFromCart", {
            currencyCode: currency,
            remove: gtm,
          });
        }
      },

      submitVoucher: async (body, errorHandler, successHandler) => {
        set({ loadStatus: STATUS.LOADING });
        const response = await axios
          .post(
            `${process.env.REACT_APP_ROOTAPI}basketapi/AddVoucher?basketId=${body.basketId}&voucherCode=${body.voucherCode}`
          )
          .catch((error) => globalErrorHandler(error, "BASKET ADD VOUCHER"));
        if (response) {
          if (response.data.Item2 == false) {
            // globalErrorHandler("VOUCHER WRONG!", "BASKET ADD VOUCHER");
            if (errorHandler) errorHandler();
          } else {
            set(basketDataProxy(response.data.Item1));
            if (successHandler) successHandler();
          }
        }
      },

      removeVoucher: async (body) => {
        set({ loadStatus: STATUS.LOADING });
        const response = await axios.post(
          `${process.env.REACT_APP_ROOTAPI}basketapi/RemoveVoucher?basketId=${body.basketId}&voucherCode=${body.voucherCode}`
        );
        set(basketDataProxy(response.data));
      },

      changeBasketMarketCountry: async (
        newCommerceMarket,
        basketId,
        languageReloadUrl
      ) => {
        if (newCommerceMarket && basketId) {
          const response = await axios
            .post(
              path.join(
                process.env.REACT_APP_ROOTAPI,
                "basketapi/changemarketcountry/",
                `?basketId=${basketId}&marketCountryCode=${newCommerceMarket}&force=false`
              ),
              {}
            )
            .catch((error) =>
              globalErrorHandler(error, "BASKET CHANGE MARKET")
            );

          if (response) {
            if (response?.data?.Item2?.length > 0) {
              if (languageReloadUrl) {
                //no need to show modal, just continue to the resetBasket call
                get().resetBasket(
                  newCommerceMarket,
                  basketId,
                  languageReloadUrl
                );
              } else {
                set({ pendingRemovalProducts: response.data.Item2 });
              }
            } else {
              if (languageReloadUrl) {
                //no need to update basket, will happen automatically
                window.location.pathname = languageReloadUrl;
                // alert("RELOAD! " + languageReloadUrl);
              } else {
                set(basketDataProxy(response.data.Item1));
              }
            }
          }
        } else {
          globalErrorHandler(
            "No commerce market or basket ID",
            "BASKET CHANGE MARKET"
          );
        }
      },

      resetBasket: async (newCommerceMarket, basketId, languageReloadUrl) => {
        const response = await axios
          .post(
            path.join(
              process.env.REACT_APP_ROOTAPI,
              "basketapi/changemarketcountry/",
              `?basketId=${basketId}&marketCountryCode=${newCommerceMarket}&force=true`
            ),
            {}
          )
          .catch((error) => globalErrorHandler(error, "BASKET RESET"));

        if (languageReloadUrl) {
          //no need to update basket, will happen automatically
          window.location.pathname = languageReloadUrl;
          // alert("RELOAD! " + languageReloadUrl);
        } else if (response?.data?.Item1) {
          //items need to be removed
          set(basketDataProxy(response.data.Item1));
        }
      },
    }),
    "Basket"
  )
);

export default store;
