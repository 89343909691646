export default {
  hasVideo: {
    fields: {
      headline: "Neque et temporibus sunt",
      bodyText:
        "Sit explicabo eum labore voluptatem, neque et temporibus sunt, culpa tempora ratione quasi nostrum. Sit explicabo eum labore voluptatem, neque et temporibus sunt, culpa tempora ratione quasi nostrum. Sit explicabo eum labore voluptatem, neque et temporibus sunt, culpa tempora ratione quasi nostrum. Sit explicabo eum labore voluptatem, neque et temporibus sunt, culpa tempora ratione quasi nostrum. Sit explicabo eum labore voluptatem, neque et temporibus sunt, culpa tempora ratione quasi nostrum.",
      media: {
        videoFocalPoint: {
          x: "",
          y: "",
        },
        overlayStrength: "30",
        video: {
          value: {
            href:
              "https://file-examples-com.github.io/uploads/2017/04/file_example_MP4_1280_10MG.mp4",
          },
        },
        image: {
          value: {
            src: "",
            alt: "Lorem ipsum",
            focalpoint: "50, 50",
            height: "1000",
            width: "500",
          },
        },
      },
    },
  },
  hasImage: {
    fields: {
      headline: "Neque et temporibus sunt",
      bodyText:
        "Sit explicabo eum labore voluptatem, neque et temporibus sunt, culpa tempora ratione quasi nostrum. Sit explicabo eum labore voluptatem, neque et temporibus sunt, culpa tempora ratione quasi nostrum. Sit explicabo eum labore voluptatem, neque et temporibus sunt, culpa tempora ratione quasi nostrum. Sit explicabo eum labore voluptatem, neque et temporibus sunt, culpa tempora ratione quasi nostrum. Sit explicabo eum labore voluptatem, neque et temporibus sunt, culpa tempora ratione quasi nostrum.",
      overlayStrength: "30",
      media: {
        image: {
          value: {
            src: "https://picsum.photos/id/166/400/600",
            alt: "Lorem ipsum",
            focalpoint: "50, 50",
            height: "1000",
            width: "1000",
          },
        },
      },
    },
  },
  customInverted: {
    fields: {
      headline: "Neque et temporibus sunt",
      bodyText:
        "Sit explicabo eum labore voluptatem, neque et temporibus sunt, culpa tempora ratione quasi nostrum. Sit explicabo eum labore voluptatem, neque et temporibus sunt, culpa tempora ratione quasi nostrum. Sit explicabo eum labore voluptatem, neque et temporibus sunt, culpa tempora ratione quasi nostrum. Sit explicabo eum labore voluptatem, neque et temporibus sunt, culpa tempora ratione quasi nostrum. Sit explicabo eum labore voluptatem, neque et temporibus sunt, culpa tempora ratione quasi nostrum.",
      overlayStrength: "30",
      backgroundColorHex: "#cccccc",
      isInverted: true,
      media: {
        image: {
          value: {
            src: "https://picsum.photos/id/166/400/600",
            alt: "Lorem ipsum",
            focalpoint: "50, 50",
            height: "1000",
            width: "1000",
          },
        },
      },
    },
  },
};
