import React from "react";
import "./icons.scss";

export default ({ className, isInverted }) => (
  <svg
    className={`svg-icon svg-icon--arrow-small ${className || ""} ${
      isInverted ? "is-inverted" : ""
    }`}
    width="13"
    height="13"
    viewBox="0 0 13 13"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M13 6.16L6.84 0L6.14 0.71L11.43 6H0V7H11.43L6.14 12.29L6.85 13L13 6.85V6.16Z" />
  </svg>
);
