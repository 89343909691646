import MediaFocal from "./media-focal";
import NavButton from "./nav-button";

export default {
  default: {
    fields: {
      bodyText: "Lorem ipsum dolor sit amet consectetur adipiscing elit quam",
      headline: "Images from case",
      footerButton: NavButton.link.fields,
      images: [
        {
          fields: {
            media: MediaFocal.default,
          },
        },
        {
          fields: {
            media: MediaFocal.default,
          },
        },
        {
          fields: {
            media: MediaFocal.default,
          },
        },
        {
          fields: {
            media: MediaFocal.default,
          },
        },
        {
          fields: {
            media: MediaFocal.default,
          },
        },
      ],
    },
  },
  customInverted: {
    fields: {
      bodyText: "Lorem ipsum dolor sit amet consectetur adipiscing elit quam",
      headline: "Images from case",
      footerButton: NavButton.linkInverted.fields,
      images: [
        {
          fields: {
            media: MediaFocal.default,
          },
        },
        {
          fields: {
            media: MediaFocal.default,
          },
        },
        {
          fields: {
            media: MediaFocal.default,
          },
        },
        {
          fields: {
            media: MediaFocal.default,
          },
        },
        {
          fields: {
            media: MediaFocal.default,
          },
        },
      ],
      backgroundColorHex: "#e45cee",
      isInverted: true,
    },
  },
};
