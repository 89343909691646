export default {
  default: {
    fields: {
      image: {
        value: {
          src: "https://picsum.photos/id/166/400/600",
          alt: "Lorem ipsum",
          focalpoint: "50, 50",
          height: "1000",
          width: "1000",
        },
      },
    },
  },
  withOverlay: {
    fields: {
      overlayStrength: "30",
      image: {
        value: {
          src: "https://picsum.photos/id/166/400/600",
          alt: "Lorem ipsum",
          focalpoint: "50, 50",
          height: "1000",
          width: "1000",
        },
      },
    },
  },
  portrait: {
    fields: {
      image: {
        value: {
          src: "https://picsum.photos/id/166/400/600",
          alt: "Lorem ipsum",
          focalpoint: "50, 50",
          height: "1000",
          width: "500",
        },
      },
    },
  },
  landscape: {
    fields: {
      image: {
        value: {
          src: "https://picsum.photos/id/166/400/600",
          alt: "Lorem ipsum",
          focalpoint: "50, 50",
          height: "500",
          width: "1000",
        },
      },
    },
  },
  landscapeWithOverlay: {
    fields: {
      overlayStrength: "30",
      image: {
        value: {
          src: "https://picsum.photos/id/166/400/600",
          alt: "Lorem ipsum",
          focalpoint: "50, 50",
          height: "1000",
          width: "500",
        },
      },
    },
  },
  video: {
    fields: {
      src: "",
      poster: "",
      focus: "",
      isVideo: true,
      hasControls: true,
      overlayStrength: "30",
      image: {
        value: {
          src: "https://picsum.photos/id/166/400/600",
          alt: "Lorem ipsum",
          focalpoint: "50, 50",
          height: "1000",
          width: "500",
        },
      },
    },
  },
};
