import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import classnames from "classnames";

import PropTypes from "prop-types";
import ActionMenu from "../../ActionMenu";
import ActionButton from "../../ActionButton";
import { BUTTONSTYLE, ASPECT } from "@assets/js/constants";
import useGlobalStore from "@store/global";
import path from "@assets/js/browser-path";
import getUniqueId from "@assets/js/get-unique-id";

import "./portfolio-media-tile.scss";

const PortfolioMediaTile = ({ data, canEdit, onRemove, heightCssVar }) => {
  const { t } = useTranslation();
  const texts = {
    info: t("FritzHansen_Project_ConsumersAndProfessionals_Portfolio_Info"),
    removeFromWishlist: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Portfolio_RemoveFromWishlist"
    ),
  };
  const [dropped, setDropped] = useState(false);

  const [productsFromImage, setProductsFromImage] = useState(null);
  const lang = useGlobalStore((state) => state.sc_lang);
  useEffect(() => {
    async function loadProducts() {
      const response = await axios.get(
        path.join(
          process.env.REACT_APP_PRODUCTSAPI,
          "Configuration",
          lang,
          "solrformat",
          "get",
          data.imageProductIds.join("|")
        )
      );
      setProductsFromImage(response.data);
    }
    if (data.imageProductIds) loadProducts();
  }, []);

  let actionList = [
    {
      icon: "RemoveSvg",
      label: texts.removeFromWishlist,
      callback: () => {
        onRemove(data);
      },
    },
  ];
  const getRandomArbitrary = (min, max) => {
    return Math.random() * (max - min) + min;
  };

  const aspect = data.imageAspect ? data.imageAspect : getRandomArbitrary(0, 2);
  let aspectType;
  if (aspect <= 0.8) aspectType = ASPECT.TALL;
  else if (aspect > 0.8 && aspect < 1.2) aspectType = ASPECT.SQUARE;
  else aspectType = ASPECT.WIDE;

  const prefix = getUniqueId();

  const classList = classnames({
    "portfolio-media-tile": true,
    "is-tall": aspectType === ASPECT.TALL,
    "is-square": aspectType === ASPECT.SQUARE,
    "is-wide": aspectType === ASPECT.WIDE,
  });

  return (
    <div
      className={classList}
      onMouseLeave={() => setDropped(false)}
      style={{ "--portfolio-media-tile-height": heightCssVar }}
    >
      {canEdit && !dropped && (
        <ActionButton
          className="portfolio-media-tile__button"
          buttonType={BUTTONSTYLE.GHOST}
          backgroundColorHex="#c6c6c6"
          textColorHex="white"
          text={texts.info}
          icon="PlusSvg"
          clickHandler={() => setDropped(!dropped)}
        />
      )}
      {canEdit && <ActionMenu actionList={actionList} isActive={dropped} />}

      <div className="portfolio-media-tile__image">
        <img src={data.imageUrl} />
      </div>

      <div className="portfolio-media-tile__text">
        <h3>{data.imageTitle}</h3>
        {/* <p>{data.imageDescription}</p> */}
        {/* <p>ASPECT: {aspectType}</p> */}

        {productsFromImage && productsFromImage.length && (
          <ul className="portfolio-media-tile__products">
            {productsFromImage.map((item, index) => {
              return (
                <li key={`${prefix}ProductLink${index}`}>
                  {item && (
                    <a href={item.urlSlug_s}>
                      <span className="text">
                        <span>{`${item.ModelTypeFullName_s} – ${item.ModelTypeDescription_s}`}</span>
                      </span>
                    </a>
                  )}
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
};
PortfolioMediaTile.propTypes = {
  canEdit: PropTypes.bool,
};

export default PortfolioMediaTile;
