export default {
  default: {
    fields: {
      headlineType: "h2",
      headline: "Minus asperiores error,",
      bodyText:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi consequuntur quos minus asperiores error, eveniet facere. Ut ab debitis quisquam molestias ad impedit eveniet rerum, culpa commodi. Dolorem, nihil maiores.",
    },
  },
  materialGroups: {
    fields: {
      headlineType: "h2",
      headline: "Materials & colours library",
    },
  },
  references: {
    fields: {
      headlineType: "h2",
      headline: "References & Cases",
      bodyText:
        "At Fritz Hansen, our focus isn’t limited to furniture. Or to function. It goes beyond objects to creating an aesthetic environment that impacts your company, your brand, your vision and ambition.",
    },
  },
};
