import create from "zustand";
import { devtools } from "zustand/middleware";
import axios from "axios";

import globalErrorHandler from "@assets/js/global-error-handler";
import path from "@assets/js/browser-path";
import persist from "@assets/js/persist";
import { log } from "@assets/js/utils";
import { STATUS } from "@constants";

const pricesPLPUrl = (commerceMarket) => path.join(process.env.REACT_APP_PRICESAPI, "PriceService", "getrsp", commerceMarket);

const expirationTimeMinutes = 60;

const timeStampHasExpired = (dt1, dt2) => {
  var diff = (dt2 - dt1) / 1000;
  diff /= 60;
  log(
    "PRICES | in minutes: elapsed=" +
      Math.round(diff * 1000) / 1000 +
      ", expiration=" +
      expirationTimeMinutes
  );
  return diff > expirationTimeMinutes;
};

const store = create(
  devtools(
    persist(
      {
        key: "prices",
      },
      (set, get) => ({
        loadStatus: STATUS.READY,

        timeStamp: null,

        plpPrices: {},

        fetchPLPPrices: async (commerceMarket) => {
          if (commerceMarket && commerceMarket.length > 0) {
            let storeTS = get().timeStamp;
            const prices = get().plpPrices;
            let todayTS = new Date().getTime();
            let newPrices;
            let plpPricesResponse;

            if (storeTS && prices && prices.hasOwnProperty(commerceMarket.toLowerCase())) {
              // log("PRICES | timeStamp exists");
              let expired = timeStampHasExpired(storeTS, todayTS);

              let priceCount = Object.keys(prices[commerceMarket.toLowerCase()]).length;

              if (expired && priceCount > 0) {
                log("PRICES | timeStamp e&xists | too old – fetch");
                storeTS = todayTS;

                //if local data is old, load new
                plpPricesResponse = await axios
                  .get(pricesPLPUrl(commerceMarket))
                  .catch((error) => globalErrorHandler(error, "PRICES GET, UPDATE"));

                if (plpPricesResponse) {
                  // log("PRICES | fetch response", plpPricesResponse);
                  newPrices = plpPricesResponse.data;
                }
              } else {
                // log("PRICES | timeStamp exists | still fresh");
                // log("storeTS", storeTS, "todayTS", todayTS);
                //if local data is still fresh, just use that
                newPrices = get().plpPrices;
              }
            } else {
              // log("PRICES | timeStamp not found | create it – fetch");
              plpPricesResponse = await axios
                .get(pricesPLPUrl(commerceMarket))
                .catch((error) => globalErrorHandler(error, "PRICES GET, NEW"));

              if (plpPricesResponse) {
                // log("PRICES | fetch response", plpPricesResponse);

                storeTS = todayTS;
                newPrices = plpPricesResponse.data;
              }
            }
            set({ plpPrices: newPrices, timeStamp: storeTS });
          } else {
            globalErrorHandler("No commerce market value", "PRICES GET, NEW");
          }
        },
      })
    ),
    "Price"
  )
);

export const usePLPPrices = () => [
  store((store) => store.plpPrices),
  store((store) => store.fetchPLPPrices),
];

export default store;
