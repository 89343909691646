export default {
  default: {
    facets: [
      {
        label: "Type",
        id: "nm470fdg",
        active: false,
        values: [
          {
            label: "New arrivals",
            count: "3",
            active: true,
            id: "nm470fdg1",
          },
          {
            label: "Armchair",
            count: "36",
            active: false,
            id: "nm470fdg2",
          },
          {
            label: "Barstool",
            count: "5",
            active: false,
            id: "nm470fdg3",
          },
        ],
      },
      {
        label: "Series",
        id: "v987bcv9b7",
        active: false,
        values: [
          {
            label: "Lorem",
            count: "666",
            active: false,
            id: "v987bcv9b71",
          },
        ],
      },
      {
        label: "Models",
        id: "lj6dg90",
        active: false,
        values: [
          {
            label: "3301",
            active: false,
            id: "lj6dg901",
            image: "https://picsum.photos/400/600",
          },
          {
            label: "3302",
            active: false,
            id: "lj6dg902",
            image: "https://picsum.photos/400/600",
          },
          {
            label: "3303",
            active: false,
            id: "lj6dg903",
            image: "https://picsum.photos/400/600",
          },
          {
            label: "3304",
            active: false,
            id: "lj6dg904",
            image: "https://picsum.photos/400/600",
          },
          {
            label: "3305",
            active: false,
            id: "lj6dg905",
            image: "https://picsum.photos/400/600",
          },
          {
            label: "3306",
            active: false,
            id: "lj6dg906",
            image: "https://picsum.photos/400/600",
          },
          {
            label: "3306",
            active: false,
            id: "lj6dg906",
            image: "https://picsum.photos/400/600",
          },
          {
            label: "3306",
            active: false,
            id: "lj6dg906",
            image: "https://picsum.photos/400/600",
          },
          {
            label: "3306",
            active: false,
            id: "lj6dg906",
            image: "https://picsum.photos/400/600",
          },
        ],
      },
      {
        label: "Designer",
        id: "xv678dsg98",
        active: false,
        values: [
          {
            label: "Lorem",
            count: "666",
            active: false,
            id: "xv678dsg981",
          },
        ],
      },
      {
        label: "Colour",
        id: "cxvcx786cvx",
        active: false,
        values: [
          {
            label: "Lorem",
            count: "666",
            active: false,
            id: "cxvcx786cvx1",
          },
        ],
      },
      {
        label: "Material",
        id: "dsf9j45kjds",
        active: false,
        values: [
          {
            label: "Lorem",
            count: "666",
            active: false,
            id: "dsf9j45kjds1",
          },
        ],
      },
    ],
  },
};
