import React from "react";
import "./icons.scss";

export default ({ className, isInverted, fillHex, strokeHex }) => (
  <svg
    className={`svg-icon svg-icon--heart-large ${className || ""} ${
      isInverted ? "is-inverted" : ""
    }`}
    width="36"
    height="31"
    viewBox="0 0 36 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.628 5.229l.372.414.372-.414C20.514 2.845 23.238.5 26.342.5c5.69 0 9.158 4.687 9.158 8.474 0 4.175-2.526 8.27-6.07 12.01-3.441 3.634-7.769 6.857-11.43 9.407-3.661-2.55-7.989-5.772-11.43-9.406C3.026 17.245.5 13.149.5 8.974.5 5.187 3.97.5 9.659.5c3.103 0 5.825 2.345 7.97 4.729z"
      stroke={strokeHex}
      fill={fillHex}
    />
  </svg>
);
