export default {
  default: {
    fields: {
      noParse: true,
      type: "product",
      media: {
        size: "regular",
        src: "https://picsum.photos/400/450",
        alt: "Lorem ipsum",
      },
      value: {
        href: "#product",
        productId: "98gd89g7dfg",
        title: "SWAN<sup>TM</sup>",
        material: "Christianshavn Grey",
        model: "3320 Textile",
        priceOriginal: "€ 3,215.00",
        priceAdjusted: "€ 3,213.00",
        linkText: "72 variants",
      },
    },
  },
};
