import React, { useEffect, useState, Fragment, useRef } from "react";
import { useTranslation } from "react-i18next";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import PropTypes from "prop-types";
import getUniqueId from "@assets/js/get-unique-id";
import produce from "immer";
import PDPConfiguratorFilters from "../PDPConfiguratorFilters";
import PDPSwatchGroup from "../PDPSwatchGroup";

import ViewportSizes from "@assets/js/viewport-sizes";

import {
  useAvailableMaterialSetIds,
  useAllowedMaterialSetIds,
  useActiveMaterialIndex,
} from "@store/pdp";

import "./pdp-material-set-selector.scss";
const PDPMaterialSetSelector = ({
  materialSets,
  itemClickHandler,
  currentViewport,
}) => {
  const { t } = useTranslation();
  const rootRef = useRef(null);
  const texts = {
    selectMaterialSet: t(
      "FritzHansen_Project_ConsumersAndProfessionals_PDP_SelectMaterialSet"
    ),
  };

  const [
    availableMaterialSetIds,
    setAvailableMaterialSetIds,
  ] = useAvailableMaterialSetIds();

  const [
    allowedMaterialSetIds,
    setAllowedMaterialSetIds,
  ] = useAllowedMaterialSetIds();

  const [
    activeMaterialIndex,
    setActiveMaterialIndex,
  ] = useActiveMaterialIndex();

  // ====================================================================================

  const isAvailableMaterialSet = (item) => {
    const foundIndex = availableMaterialSetIds[activeMaterialIndex].indexOf(
      item.materialSetId
    );
    const isAvailable = foundIndex > -1;

    // if (!isAvailable) console.log(`materialSetId "${item.materialSetId}" is not available`);

    return isAvailable;
  };

  const isAllowedMaterialSet = (item) => {
    const foundIndex = allowedMaterialSetIds[activeMaterialIndex].indexOf(
      item.materialSetId
    );
    const isAllowed = foundIndex > -1;
    // if (!isAllowed) console.log(`materialSetId "${item.materialSetId}" is not allowed`);

    return isAllowed;
  };

  // ====================================================================================

  //parse used material types
  let filters = [];
  materialSets.map((item) => {
    if (item && item.materialSetTypeNew && item.materialSetTypeNew != "") {
      filters.push({
        id: item.materialSetTypeNew,
        label: item.materialSetTypeNew,
        isActive: false,
      });
    }
  });
  //remove duplicates
  filters = Array.from(new Set(filters.map((a) => a.id))).map((id) => {
    return filters.find((a) => a.id === id);
  });

  const [filterList, setFilterList] = useState(filters);
  const [activeFilter, setActiveFilter] = useState(null);

  const filterClickHandler = (item) => {
    const newFilters = produce(filterList, (tmpList) => {
      tmpList.forEach((filter) => {
        if (item.id == filter.id) filter.isActive = !filter.isActive;
        else if (item.id !== filter.id) filter.isActive = false;
      });
    });
    setFilterList(newFilters);
    if (activeFilter && activeFilter.id == item.id) {
      setActiveFilter(null);
    } else {
      setActiveFilter(item);
    }
  };

  const isActiveFilterType = (item) => {
    return !activeFilter || item.materialSetTypeNew === activeFilter.id;
  };
  useEffect(() => {
    if (
      currentViewport.name === ViewportSizes.SMALL.name ||
      currentViewport.name === ViewportSizes.MEDIUM.name
    ) {
      disableBodyScroll(rootRef.current);
    }
    return () => {
      enableBodyScroll(rootRef.current);
    };
  }, []);

  // ====================================================================================

  return (
    <div className="pdp-material-set-selector">
      <h3>{texts.selectMaterialSet}</h3>

      {filterList && filterList.length > 1 && (
        <PDPConfiguratorFilters
          list={filterList}
          filterClickHandler={filterClickHandler}
        />
      )}

      <div className="pdp-material-set-selector__content" ref={rootRef}>
        {materialSets && (
          <div className="pdp-material-set-selector__groups">
            {materialSets.map((item) => {
              return (
                <Fragment key={`materialSetTile${item.materialSetId}`}>
                  {item &&
                    isAvailableMaterialSet(item) &&
                    isActiveFilterType(item) && (
                      <PDPSwatchGroup
                        {...item}
                        item={item}
                        itemTranslationKey="FritzHansen_Project_ConsumersAndProfessionals_PDP_Materials"
                        itemSingularTranslationKey="FritzHansen_Project_ConsumersAndProfessionals_PDP_Material"
                        isUnallowed={!isAllowedMaterialSet(item)}
                        itemClickHandler={itemClickHandler}
                      />
                    )}
                </Fragment>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

PDPMaterialSetSelector.propTypes = {
  materialSets: PropTypes.array,
  filters: PropTypes.object,
};

export default PDPMaterialSetSelector;
