import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import produce from "immer";

import MaterialSetTile from "../MaterialSetTile";
import SpotTile from "../SpotTile";
import TagList from "../TagList";
import ActionButton from "../ActionButton";

import { BUTTONSTYLE } from "@constants";

import useGlobalStore from "@store/global";
import useMaterialsStore from "@store/materials";

import "./material-set-list.scss";

const MaterialSetListController = ({ fields }) => {
  const tagList = fields.data?.tags?.list?.values;
  const spotTile = fields.data?.inspirationSpot?.value?.fields;
  const taxonomy = fields.data?.texts?.taxonomy?.field?.value
  const language = useGlobalStore((state) => state.language);

  const itemList = useMaterialsStore((store) => store.materialsList);

  try {
    spotTile.media.fields = spotTile.media.value.fields;
  } catch (e) {}

  const textFields = {
    moreTagsText: fields.data?.texts?.moreTagsText,
    loadMoreText: fields.data?.texts?.loadMoreText,
    tagListName: fields.data?.texts?.filterText.field.value,
  };

  const fetchMaterials = useMaterialsStore((store) => store.getMaterialSets);

  useEffect(() => {
    if (itemList.length == 0) {
      fetchMaterials(language, taxonomy);

      if (tagList && tagList.length) {
        tagList.forEach((tag) => {
          try {
            tag.active = false;
          } catch (er) {}
        });
      }
    }
  }, []);

  return <MaterialSetList spotTile={spotTile} fields={textFields} tagList={tagList} />;
};

const MaterialSetList = ({ fields, spotTile, tagList }) => {
  const itemList = useMaterialsStore((store) => store.materialsList);
  const itemsCount = itemList.length;
  const tileCountOffset = spotTile ? 14 : 15;

  const [pagesShown, setPagesShown] = useState(0);
  const [itemsShownCount, setItemsShownCount] = useState(14);
  const [availableTags, setAvailableTags] = useState(tagList);
  const [filteredItems, setFilteredItems] = useState([]);

  const { t } = useTranslation();

  const texts = {
    loadMore: t("FritzHansen_Project_ConsumersAndProfessionals_General_LoadMore"),
  };

  const resetTags = () => {
    const newAvailableTags = produce(availableTags, (tmpTags) => {
      tmpTags.forEach((tag) => {
        tag.active = false;
      });
    });

    setAvailableTags(newAvailableTags);
  };

  const loadMoreHandler = () => {
    setPagesShown(pagesShown + 1);
    resetTags();
  };

  const tagUpdateHandler = (tagId) => {
    //Toggle tag active

    let locatedTagIndex = availableTags.indexOf(
      availableTags.find((tag) => tag.id.field.value === tagId.field.value)
    );

    const newAvailableTags = produce(availableTags, (tmpTags) => {
      if (locatedTagIndex > -1) tmpTags[locatedTagIndex].active = !tmpTags[locatedTagIndex].active;

      tmpTags.forEach((tag) => {
        if (tmpTags.indexOf(tag) != locatedTagIndex) {
          tag.active = false;
        }
      });
    });

    setAvailableTags(newAvailableTags);
  };

  useEffect(() => {
    const activeTags = availableTags.filter((tag) => tag.active);
    const activeTagsCount = activeTags.length;

    if (activeTagsCount === 0) {
      setItemsShownCount(tileCountOffset + pagesShown * 15);
      setFilteredItems(itemList.slice(0, itemsShownCount));
    } else {
      setItemsShownCount(itemsCount);

      // setExpanded(true);
      let matchingItems = [];

      //locate matching item

      for (let h = 0; h < activeTagsCount; h++) {
        const currentTag = activeTags[h];

        for (let i = 0; i < itemsCount; i++) {
          const item = itemList[i];

          const itemTagCount = item.tags.values.length;
          for (let j = 0; j < itemTagCount; j++) {
            let t = item.tags.values[j];
            if (t.id.value === currentTag.id.field.value) {
              matchingItems.push(JSON.parse(JSON.stringify(item)));
              break;
            }
          }
        }
      }
      matchingItems = matchingItems.slice(0, itemsShownCount);

      //state updates
      setFilteredItems(matchingItems);
    }
  }, [availableTags, itemList, pagesShown, itemsShownCount]);

  return (
    <div className="material-set-list">
      <TagList
        fields={{
          buttonText: fields.moreTagsText?.field,
          label: fields?.tagListName,
        }}
        tagList={availableTags}
        tagUpdateHandler={tagUpdateHandler}
      />

      <div className="material-set-list__tiles">
        {filteredItems.map((item, index) => {
          return (
            <Fragment key={`materialSetListItem${index}`}>
              <MaterialSetTile fields={item.materialSetTile.fields} />
              {index == 4 && spotTile && <SpotTile fields={spotTile} />}
            </Fragment>
          );
        })}
      </div>

      <div className="material-set-list__footer">
        <h4>{filteredItems.length} results</h4>
        {itemsShownCount < itemsCount && (
          <ActionButton
            text={texts.loadMore}
            buttonType={BUTTONSTYLE.GHOST}
            clickHandler={loadMoreHandler}
          />
        )}
      </div>
    </div>
  );
};

export default MaterialSetListController;
