import NavButton from "./nav-button";
import AccordionModule from "./accordion-module";
import ActionButton from "./action-button";
import ArticleModule from "./article-module";
import Checkbox from "./checkbox";
import ContactList from "./contact-list";
import ContactLinksGroup from "./contact-links-group";
import FeatureBlock from "./feature-block";
import FeatureFullWidth from "./feature-full-width";
import FeatureSmall from "./feature-small";
import HeroCover from "./hero-cover";
import HeroLinear from "./hero-linear";
import HeroStaggered from "./hero-staggered";
// import Icons from "./icons";
import ImageGallery from "./image-gallery";
import InputField from "./input-field";
import InputFieldwButton from "./input-field-w-button";
import InspirationSlider from "./inspiration-slider";
import ListHero from "./list-hero";
import ListTitle from "./list-title";
import MaterialSetTile from "./material-set-tile";
import NavModule from "./nav-module";
import NavListButton from "./nav-list-button";
import NavList from "./nav-list";
import NavHighlightBlock from "./nav-highlight-block";
import NavTile from "./nav-tile";
import NavTilesBlock from "./nav-tiles-block";
import NavTilesSlider from "./nav-tiles-slider";
import NavPanel from "./nav-panel";
import NavPanelsBlock from "./nav-panels-block";
import NewsletterSignup from "./newsletter-signup";
import NotificationsController from "./notifications-controller";
import NotificationCountrySelector from "./notification-country-selector";
import NotificationGeolocation from "./notification-geolocation";
import NotificationItemAdded from "./notification-item-added";
import NotificationServiceMessage from "./notification-service-message";
import ProductSeriesTile from "./product-series-tile";
import PageSiblings from "./page-siblings";
import PDP from "./pdp";

import PortfolioMaterialTile from "./portfolio-material-tile";
import PortfolioMediaTile from "./portfolio-media-tile";
import PortfolioProductTile from "./portfolio-product-tile";
import PortfolioLayoutController from "./portfolio-layout-controller";

import ProductTile from "./product-tile";
import ProductTilesSliderController from "./product-tiles-slider-controller";
import ProductFiltersBlock from "./product-filters-block";
import ProductFiltersList from "./product-filters-list";
import Radio from "./radio";
import ReferencesController from "./references-controller";
import RichTextModule from "./rich-text-module";
import TagList from "./tag-list";
import TextTile from "./text-tile";
import SpotTile from "./spot-tile";
import Select from "./select";
import SiteFooter from "./site-footer";
import SimpleGallery from "./simple-gallery";
import StoriesController from "./stories-controller";
import SubNavigation from "./sub-navigation";
import MiniBasketAddon from "./mini-basket-addon";
import MiniBasketProduct from "./mini-basket-product";
import MiniBasketCalculation from "./mini-basket-calculation";
import OrderReceipt from "./order-receipt";
import ProductSeriesSlider from "./product-series-slider";
import ProgressBar from "./progress-bar";
import QuantitySelector from "./quantity-selector";
import QuoteModule from "./quote-module";

export default {
  NavButton: NavButton,
  AccordionModule: AccordionModule,
  ActionButton: ActionButton,
  ArticleModule: ArticleModule,
  Checkbox: Checkbox,
  ContactLinksGroup: ContactLinksGroup,
  ContactList: ContactList,

  FeatureBlock: FeatureBlock,
  FeatureFullWidth: FeatureFullWidth,
  FeatureSmall: FeatureSmall,

  HeroCover: HeroCover,
  HeroLinear: HeroLinear,
  HeroStaggered: HeroStaggered,

  // Icons: Icons,

  ImageGallery: ImageGallery,

  InputField: InputField,
  InputFieldwButton: InputFieldwButton,

  InspirationSlider: InspirationSlider,

  ListHero: ListHero,
  ListTitle: ListTitle,

  MaterialSetTile: MaterialSetTile,

  NavModule: NavModule,

  NavHighlightBlock: NavHighlightBlock,
  NewsletterSignup: NewsletterSignup,
  MiniBasketAddon: MiniBasketAddon,
  MiniBasketCalculation: MiniBasketCalculation,
  MiniBasketProduct: MiniBasketProduct,

  NavListButton: NavListButton,
  NavList: NavList,

  NavTile: NavTile,
  NavTilesBlock: NavTilesBlock,
  NavTilesSlider: NavTilesSlider,

  NavPanel: NavPanel,
  NavPanelsBlock: NavPanelsBlock,

  NotificationsController: NotificationsController,
  NotificationCountrySelector: NotificationCountrySelector,
  NotificationGeolocation: NotificationGeolocation,
  NotificationItemAdded: NotificationItemAdded,
  NotificationServiceMessage: NotificationServiceMessage,

  PageSiblings: PageSiblings,

  PDP: PDP,

  PortfolioMaterialTile: PortfolioMaterialTile,
  PortfolioMediaTile: PortfolioMediaTile,
  PortfolioProductTile: PortfolioProductTile,
  PortfolioLayoutController: PortfolioLayoutController,

  ProductSeriesTile: ProductSeriesTile,

  ProductTile: ProductTile,
  ProductTilesSliderController: ProductTilesSliderController,
  ProductFiltersBlock: ProductFiltersBlock,
  ProductFiltersList: ProductFiltersList,
  Radio: Radio,
  ReferencesController: ReferencesController,
  RichTextModule: RichTextModule,

  Select: Select,
  SiteFooter: SiteFooter,
  SimpleGallery: SimpleGallery,
  SpotTile: SpotTile,
  StoriesController: StoriesController,
  SubNavigation: SubNavigation,

  TagList: TagList,
  TextTile: TextTile,

  OrderReceipt: OrderReceipt,

  ProductSeriesSlider: ProductSeriesSlider,
  ProgressBar: ProgressBar,

  QuantitySelector: QuantitySelector,
  QuoteModule: QuoteModule,
};
