import React from "react";
import { useTranslation } from "react-i18next";
import { Text } from "@sitecore-jss/sitecore-jss-react";

import "./status-bar.scss";
const StatusBar = ({ fields }) => {
  const { t } = useTranslation();

  const label = fields?.label?.value
    ? fields.label
    : { value: t("FritzHansen_Project_ConsumersAndProfessionals_Order_Status") };
  const status = fields?.status?.value ? fields.status : { value: "" };
  return (
    <div className="status-bar">
      {/* <Text tag="strong" field={label} /> */}
      {/* <Text tag="span" field={status} /> */}
    </div>
  );
};
StatusBar.propTypes = {};

export default StatusBar;
