import MediaFocal from "./media-focal";
import NavButton from "./nav-button";

export default {
  default: {
    fields: {
      tag: null,
      href: "",
      headline: "Neque et temporibus sunt",
      bodyText:
        "Sit explicabo eum labore voluptatem, neque et temporibus sunt, culpa tempora ratione quasi nostrum.",
      button: {},
      isButtonEnabled: false,
      // contact: ContactLinksGroup.default,
      phone: "+45 22 33 44 55",
      email: "sdfsdfdf@dsfsdfds.com",
      media: MediaFocal.default,
    },
  },
  noContact: {
    fields: {
      tag: null,
      href: "#",
      headline: "Culpa tempora ratione quasi",
      bodyText:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit explicabo eum labore voluptatem, neque et temporibus sunt?",
      phone: "", //USE THIS
      email: "", //USE THIS
      button: NavButton.link,
      isButtonEnabled: true,
      media: MediaFocal.default,
    },
  },
  noContactInverted: {
    fields: {
      tag: null,
      href: "#",
      headline: "Culpa tempora ratione quasi",
      bodyText:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit explicabo eum labore voluptatem, neque et temporibus sunt?",
      phone: "", //USE THIS
      email: "", //USE THIS
      button: NavButton.linkInverted,
      isButtonEnabled: true,
      media: MediaFocal.default,
    },
  },
  onlyText: {
    fields: {
      tag: null,
      href: "#",
      headline: "Adipisicing elit",
      bodyText:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit explicabo eum labore voluptatem, neque et temporibus.",
      phone: "",
      email: "",
      button: {},
      isButtonEnabled: false,
      media: MediaFocal.default,
    },
  },
  noText: {
    fields: {
      interactive: true,
      tag: null,
      // href: "#tile",
      headline: {},
      bodyText: {},
      // href: "#tile",
      contact: {},
      // phone: "+45 22 33 44 55", //USE THIS
      // email: "sdfsdfdf@dsfsdfds.com", //USE THIS
      button: {},
      isButtonEnabled: false,
      media: MediaFocal.default,
    },
  },
  notInteractive: {
    fields: {
      tag: null,
      href: "#",
      headline: "Neque et temporibus sunt",
      bodyText:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit explicabo eum labore voluptatem, neque et temporibus sunt, culpa tempora ratione quasi nostrum, nesciunt veniam veritatis minima laborum expedita reprehenderit autem?",
      button: {},
      isButtonEnabled: false,
      // contact: ContactLinksGroup.default,
      phone: "",
      email: "",
      media: MediaFocal.default,
    },
  },
};
