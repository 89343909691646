import React, { useEffect, useState } from "react";
import { Text, RichText } from "@sitecore-jss/sitecore-jss-react";
import { useTranslation } from "react-i18next";
import urlParams from "@assets/js/url-params";

import ProgressBar from "../checkout/ProgressBar";
import BasketSummary from "../checkout/BasketSummary";
import DeliveryForm from "../checkout/DeliveryForm";
import ClientForm from "../checkout/ClientForm";
import OrderReceipt from "../checkout/OrderReceipt";
import MessageBlock from "../MessageBlock";
import { log } from "@assets/js/utils";
import { dataLayerCommercePush, checkoutSignature } from "@assets/js/gtm";

import useCheckoutStore, {
  useCheckoutInfoData,
  useOrderDetailsData,
} from "@store/checkout";
import useGlobalStore, {
  useCommerceMarketSupportsEcom,
  useCommerceMarket,
  useMarketInfo,
} from "@store/global";
import useBasketStore from "@store/basket";
import { QUERY_PARAMETER, CHECKOUT_STEPS } from "@constants";

import "./checkout-flow.scss";

const CheckoutFlowController = ({ fields, pageFields }) => {
  return (
    <CheckoutFlow
      fields={fields}
      homeHref={pageFields?.continueShoppingLink?.value?.href}
      termsHref={pageFields?.termsAndConditionsLink?.value?.href}
    />
  );
};

const CheckoutFlow = ({ fields, homeHref, termsHref }) => {
  const { t } = useTranslation();

  const texts = {
    noEcomHeader: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Checkout_NoEcomHeader"
    ),
    noEcomBody: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Checkout_NoEcomBody"
    ),
    noProductsHeadline: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Checkout_NoProductsHeadline"
    ),
    noProductsButton: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Checkout_NoProductsButton"
    ),
  };

  const data = fields?.data?.item;

  const [commerceMarketSupportsEcom] = useCommerceMarketSupportsEcom();
  const currentMarket = useGlobalStore((state) => state.currentMarket);
  const basketId = useBasketStore((store) => store.basketId);
  const [commerceMarket] = useCommerceMarket();
  const [marketInfo] = useMarketInfo();
  const setCurrentStepNumber = useCheckoutStore(
    (state) => state.setCurrentStepNumber
  );

  const [trackedStep, setTrackedStep] = useState();

  const headline = data?.headline?.field?.value ? data.headline.field : {};

  const bodyText = data?.manchet?.field?.value ? data.manchet.field : {};

  const stepName = data?.currentStepName;
  const allSteps = data?.allSteps?.values;
  const foundStep = allSteps
    ? allSteps.filter((step) => step.text === stepName)[0]
    : null;
  const stepNum = foundStep
    ? allSteps.indexOf(foundStep)
    : CHECKOUT_STEPS.DETAILS;

  let stepList;
  if (data?.allSteps?.values?.length > 0) {
    stepList = data.allSteps.values.map((item, index) => {
      return {
        id: index,
        name: item.text,
        url: item.url,
        interactive: false,
      };
    });
  }

  const [, , fetchCheckoutInfoData] = useCheckoutInfoData();
  const [orderDetailsData, , fetchOrderDetailsData] = useOrderDetailsData();
  const basketLineItems = useBasketStore((store) => store.lineItems);

  useEffect(() => {
    if (basketId) {
      const orderId = urlParams.get().get(QUERY_PARAMETER.ORDER_ID)
        ? urlParams.get().get(QUERY_PARAMETER.ORDER_ID)
        : null;
      if (stepNum < CHECKOUT_STEPS.RECIEPT) {
        fetchCheckoutInfoData(basketId);
      } else {
        if (orderId) {
          fetchOrderDetailsData(orderId, marketInfo);
        } else {
          log("No order id");
        }
      }
      setCurrentStepNumber(stepList.find((item) => item.id === stepNum));
    }
  }, [basketId, marketInfo]);

  // GTM Tracking
  useEffect(() => {
    // only clientform and deliveryMethod
    if (trackedStep !== stepNum && stepNum !== CHECKOUT_STEPS.RECIEPT) {
      if (basketLineItems) {
        dataLayerCommercePush(
          "checkout",
          checkoutSignature({
            currencyCode: currentMarket?.currency?.value,
            basketLineItems,
            stepNum,
          })
        );
      }
      setTrackedStep(stepNum);
    }
  }, [stepNum, trackedStep, basketLineItems, currentMarket, orderDetailsData]);

  return (
    <div className="checkout-flow" key={data.currentStepID}>
      {stepNum === CHECKOUT_STEPS.PAYMENT && (
        <>
          <div className="checkout-flow__progress">
            <ProgressBar fields={{ stepList: stepList }} id={stepNum} />
          </div>
          <div className="checkout-flow__inner checkout-flow__inner--altapay">
            <form id="PensioPaymentForm"></form>
          </div>
        </>
      )}
      {(stepNum === CHECKOUT_STEPS.DETAILS ||
        stepNum === CHECKOUT_STEPS.DELIVERY) && (
        <>
          {basketLineItems && (
            <>
              {basketLineItems.length > 0 ? (
                <>
                  <div className="checkout-flow__progress">
                    <ProgressBar fields={{ stepList: stepList }} id={stepNum} />
                  </div>

                  <div className="checkout-flow__inner">
                    <>
                      {commerceMarketSupportsEcom ? (
                        <header className="checkout-flow__header">
                          <Text tag="h3" field={headline} />
                          <RichText field={bodyText} />
                        </header>
                      ) : (
                        <header className="checkout-flow__header">
                          <Text
                            tag="h3"
                            field={{ value: texts.noEcomHeader }}
                          />
                          <Text tag="p" field={{ value: texts.noEcomBody }} />
                        </header>
                      )}
                    </>

                    <aside className="checkout-flow__aside">
                      <BasketSummary
                        editBasketLink={data?.basketLink?.jss?.value?.href}
                      />
                    </aside>

                    <section className="checkout-flow__main">
                      {commerceMarketSupportsEcom &&
                        fields &&
                        basketId &&
                        commerceMarket && (
                          <>
                            {stepNum === CHECKOUT_STEPS.DETAILS && (
                              <ClientForm fields={data} />
                            )}
                            {stepNum === CHECKOUT_STEPS.DELIVERY && (
                              <DeliveryForm
                                fields={data}
                                termsHref={termsHref}
                                stepNum={stepNum}
                                basketLineItems={basketLineItems}
                                currencyCode={currentMarket?.currency?.value}
                              />
                            )}
                          </>
                        )}
                    </section>
                  </div>
                </>
              ) : (
                <MessageBlock
                  headline={texts.noProductsHeadline}
                  buttonText={texts.noProductsButton}
                  buttonHref={homeHref}
                />
              )}
            </>
          )}
        </>
      )}
      {stepNum === 3 && (
        <>
          <div className="checkout-flow__progress">
            <ProgressBar fields={{ stepList: stepList }} id={stepNum} />
          </div>

          <div className="checkout-flow__inner">
            <header className="checkout-flow__header">
              <Text tag="h3" field={headline} />
              <RichText tag="p" field={bodyText} />
            </header>

            <section className="checkout-flow__main">
              {orderDetailsData.statusBar && (
                <OrderReceipt data={orderDetailsData} stepNumber={stepNum} />
              )}
            </section>
          </div>
        </>
      )}
    </div>
  );
};

CheckoutFlowController.propTypes = {};

export default CheckoutFlowController;
