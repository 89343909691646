import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import PropTypes from "prop-types";
import Radio from "../forms/Radio";
import InputField from "../forms/InputField";
import Select from "../forms/Select";
import { useForm } from "react-hook-form";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import useCheckoutStore from "@store/checkout";
import ActionButton from "../ActionButton";
import Cookies from "universal-cookie";
import { COOKIES, STATUS } from "@constants";

import { log } from "@assets/js/utils";
import globalErrorHandler from "@assets/js/global-error-handler";
import useGlobalStore, { useMarketInfo } from "@store/global";

import "./account-details.scss";
const cookies = new Cookies();

const AccountDetails = ({ fields, pageFields }) => {
  const { t } = useTranslation();
  const texts = {
    loginInfoMessage: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Account_LoginInfoMessage"
    ),
    btnSaveDetails: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Account_ButtonSaveDetails"
    ),
    btnDetailsSaving: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Account_ButtonSaving"
    ),
    btnDetailsSaved: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Account_ButtonDetailsSaved"
    ),
    radioPrivateCustomer: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Account_PrivateCustomerRadio"
    ),
    radioProfessionalCustomer: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Account_ProfessionalCustomerRadio"
    ),
    fieldName: t("FritzHansen_Project_ConsumersAndProfessionals_Checkout_Name"),
    fieldAddress: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Checkout_AddressField"
    ),
    fieldPostalCode: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Checkout_PostalCode"
    ),
    fieldCity: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Checkout_CityField"
    ),
    fieldCountryDefault: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Checkout_CountrySelectDefault"
    ),
    fieldEmail: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Checkout_EmailField"
    ),
    fieldMobilePhone: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Checkout_MobilePhone"
    ),
    errorNoValue: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Checkout_ErrorNoValue"
    ),
    errorNotNumber: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Checkout_ErrorNotNumber"
    ),
    errorEmailsWrong: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Checkout_ErrorEmailWrong"
    ),
  };

  const [marketInfo] = useMarketInfo();
  const checkPostalCode = useCheckoutStore((store) => store.checkPostalCode);

  const userAuthenticated = useGlobalStore((state) => state.userAuthenticated);
  const [countriesList, setCountriesList] = useState([]);
  const [accountData, setAccountData] = useState(null);
  const [loadingState, setLoadingState] = useState(STATUS.LOADING_IDLE);

  const [defaultValues] = useState({
    accounttype: 1,
    email: "",
    name: "",
    mobile: "",
    address: "",
    address2: "",
    postal: "",
    city: "",
    country: "",
  });
  const {
    register,
    handleSubmit,
    trigger,
    errors,
    getValues,
    setValue,
    watch,
    formState,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: defaultValues,
  });
  const watchAll = watch();
  const { isValid, isDirty } = formState;

  const postalCodeHandler = (value) => {
    const isDenmark = getValues("country") == "DK";
    if (isDenmark && value.length === 4) {
      checkPostalCode(value, (city) => {
        setValue("city", city, {
          shouldDirty: true,
        });
      });
    }
  };

  useEffect(() => {
    if (marketInfo) {
      let markets = marketInfo?.markets?.values || [];

      let countries = markets.map((item) => {
        return {
          id: item?.commerceMarketId?.value,
          optionLabel: item?.countryName?.value,
          optionValue: item?.commerceMarket?.value,
        };
      });
      setCountriesList(countries);
    }
  }, [marketInfo]);

  useEffect(() => {
    const getDetails = async () => {
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_ROOTAPI}accountapi/details`,
        headers: {
          Authorization: `Bearer ${cookies.get(COOKIES.jwt.name)}`,
        },
      }).catch((error) => {
        globalErrorHandler(error, "USER ACCOUNT");
      });
      setAccountData(response.data);
      // test
      // setAccountData({
      //   Address1: "Andebyvej 103",
      //   AddressCity: "Andeby",
      //   AddressCountry: "DK",
      //   AddressZip: "1212",
      //   Email: "anders.and@pengetanken.com",
      //   FullName: "Von And aps.",
      //   PhoneNumber: "12345678",
      //   State: 2,
      // });
    };

    if (userAuthenticated) {
      getDetails();
    } else {
      // setAccountData({
      //   State: 1,
      //   Email: "tnf@alpha-solutions.dk",
      //   FullName: "TNF",
      // });
    }
  }, [userAuthenticated]);

  useEffect(() => {
    if (accountData) {
      setLoadingState(STATUS.LOADING_IDLE);
      log({ accountData });

      setValue(
        "accounttype",
        accountData.State === 0 ? 1 : accountData.State?.toString(),
        {
          shouldDirty: true,
          shouldValidate: true,
        }
      );
      {
        accountData.Email &&
          setValue("email", accountData.Email, {
            shouldDirty: true,
            shouldValidate: true,
          });
      }
      {
        accountData.FullName &&
          setValue("name", accountData.FullName, {
            shouldDirty: true,
            shouldValidate: true,
          });
      }
      {
        accountData.PhoneNumber &&
          setValue("mobile", accountData.PhoneNumber, {
            shouldDirty: true,
            shouldValidate: true,
          });
      }
      {
        accountData.Address1 &&
          setValue("address", accountData.Address1, {
            shouldDirty: true,
            shouldValidate: true,
          });
      }
      {
        accountData.AddressZip &&
          setValue("postal", accountData.AddressZip, {
            shouldDirty: true,
            shouldValidate: true,
          });
      }
      {
        accountData.AddressCity &&
          setValue("city", accountData.AddressCity, {
            shouldDirty: true,
            shouldValidate: true,
          });
      }

      setValue("country", accountData.AddressCountry, {
        shouldDirty: true,
        shouldValidate: true,
      });
      setTimeout(
        function () {
          setValue("country", accountData.AddressCountry, {
            shouldDirty: true,
            shouldValidate: true,
          });
        }.bind(this),
        200
      );
    }
  }, [accountData, marketInfo]);

  let requiredEmpty = {
    value: true,
    message: texts.errorNoValue,
  };
  let validateTooLong = {
    value: 20,
    message: texts.errorTooLong,
  };
  let validateNotANumber = {
    value: /^[0-9]+$/,
    message: texts.errorNotNumber,
  };

  const submitFormHandler = () => {
    setLoadingState(STATUS.LOADING);
    const values = getValues();
    log({ values });

    const postData = {
      State: values.accounttype,
      FullName: values.name,
      PhoneNumber: values.mobile,
      Address1: values.address,
      AddressZip: values.postal,
      AddressCity: values.city,
      AddressCountry: values.country,
    };

    const sendDetails = async (postData) => {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_ROOTAPI}accountapi/details/save`,
        headers: {
          Authorization: `Bearer ${cookies.get(COOKIES.jwt.name)}`,
        },
        data: postData,
      }).catch((error) => {
        globalErrorHandler(error, "USER ACCOUNT");
        setLoadingState(STATUS.LOADING_IDLE);
      });
      setLoadingState(STATUS.SUCCESS);
      // after 1.8sec set button state back to normal
      window.setTimeout(() => {
        setLoadingState(STATUS.LOADING_IDLE);
      }, 1800);
    };
    sendDetails(postData);
  };

  const onSave = async () => {
    const result = await trigger();
    const vals = getValues();
    log({ vals });
    if (result) {
      setLoadingState(STATUS.LOADING);
      submitFormHandler();
    }
  };

  const getButtonTexts = (state) => {
    let text = "";
    switch (state) {
      case STATUS.LOADING_IDLE:
        text = texts.btnSaveDetails;
        break;
      case STATUS.LODAING:
        text = texts.btnDetailsSaving;
        break;
      case STATUS.SUCCESS:
        text = texts.btnDetailsSaved;
        break;
      default:
        text = texts.btnSaveDetails;
    }
    return text;
  };
  return (
    <div className="account-details">
      <Text
        tag="h1"
        field={pageFields.pageTitle}
        className="account-details__headline"
      />

      <form
        className="account-details__form"
        onSubmit={handleSubmit(submitFormHandler)}
      >
        <div className="account-details__radio-buttons">
          <Radio
            name="accounttype"
            id="AccountPrivate"
            value="1"
            label={texts.radioPrivateCustomer}
            register={register({
              required: requiredEmpty,
            })}
          />
          <Radio
            name="accounttype"
            id="AccountProfessional"
            value="2"
            label={texts.radioProfessionalCustomer}
            register={register({
              required: requiredEmpty,
            })}
          />
        </div>

        <div className="account-details__form-row">
          <InputField
            id="AccountEmail"
            name="email"
            type="email"
            hasValue={true}
            label={texts.fieldEmail}
            disabled={true}
            register={register()}
          />

          <p>{texts.loginInfoMessage}</p>
        </div>

        <div className="account-details__form-row">
          <InputField
            id="AccountFirstName"
            name="name"
            hasValue={accountData?.FullName}
            autoComplete="name"
            type="text"
            label={texts.fieldName}
            error={errors.name}
            register={register({
              required: requiredEmpty,
            })}
          />
        </div>

        <div className="account-details__form-row">
          <InputField
            id="AccountPhone"
            name="mobile"
            hasValue={accountData?.PhoneNumber}
            autocomplete="tel"
            type="tel"
            label={texts.fieldMobilePhone}
            error={errors.mobile}
            register={register({
              required: requiredEmpty,
              pattern: validateTooLong,
            })}
          />
        </div>

        <hr />

        <div className="account-details__form-row">
          <InputField
            id="AccountAddress"
            name="address"
            hasValue={accountData?.Address1}
            autoComplete="street-address"
            type="text"
            label={texts.fieldAddress}
            error={errors.address}
            register={register({
              required: requiredEmpty,
            })}
          />
        </div>

        <div className="account-details__form-row account-details__form-row--dual">
          <InputField
            id="AccountPostalCode"
            name="postal"
            hasValue={accountData?.AddressZip}
            autoComplete="postal-code"
            type="text"
            label={texts.fieldPostalCode}
            error={errors.postal}
            register={register({
              required: requiredEmpty,
              pattern: validateNotANumber,
            })}
            blurHandler={postalCodeHandler}
          />
          <InputField
            id="AccountCity"
            name="city"
            hasValue={accountData?.AddressCity}
            autoComplete="address-level2"
            type="text"
            label={texts.fieldCity}
            error={errors.city}
            register={register({
              required: requiredEmpty,
            })}
          />
        </div>

        <div className="account-details__form-row">
          <Select
            id="AccountCountry"
            defaultLabel={texts.fieldCountryDefault}
            name="country"
            hasValue={accountData?.AddressCountry}
            options={countriesList}
            register={register()}
          />
        </div>
        <div className="account-details__form-row">
          <ActionButton
            text={getButtonTexts(loadingState)}
            buttonType="ghost"
            clickHandler={onSave}
            isDisabled={loadingState === STATUS.LOADING || !isDirty || !isValid}
          />
        </div>
      </form>
    </div>
  );
};
AccountDetails.propTypes = {};

export default AccountDetails;
