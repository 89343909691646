import { CONFIGURATOR_MODE } from "@constants";
import NavButton from "./nav-button";

const productThumbs = {
  images: [
    { imageUrl: "https://picsum.photos/id/166/500/500" },
    { imageUrl: "https://picsum.photos/id/165/500/500" },
    { imageUrl: "https://picsum.photos/id/164/500/500" },
    { imageUrl: "https://picsum.photos/id/163/500/500" },
  ],
};

/*

FROM MODEL:

info: {
  stackable: content.ChairStackableBoolean_s === "yes",
  dimensions: {
    imperial: {
      baseWidth: content.BaseWidthInch_d,
      baseDepth: content.BaseDepthInch_d,
      baseHeight: content.BaseHeightInch_d,
      baseWeight: content.BaseWeightLb_d,
    },
    metric: {
      baseWidth: content.BaseWidth_d,
      baseHeight: content.BaseHeight_d,
      baseDepth: content.BaseDepth_d,
      baseWeight: content.BaseWeight_d,
    },
  },
},
*/

const productSpecification = {
  button: NavButton.outlined.fields,
  bottomInfo: "Delivery time: 5-6 weeks for online products",
  items: [
    {
      headline: "Product Details",
      bodyText:
        "The Series 7™ chair is an icon in modern furniture history, designed by Arne Jacobsen in 1955. Its unique shape is timeless and incredibly versatile, displaying character without overpowering the eye. ",
      tableRowContent: [
        {
          topic: "Assembly",
          valueLinkText: "Download here",
          valueLinkHref: "#",
        },
        { topic: "Fabric Composition", value: "80% Cotton, 15% Polyester" },
        { topic: "Fabric Composition", value: "80% Cotton, 15% Polyester" },
      ],
    },
    {
      headline: "Dimensions",
      bodyText: "",
      tableRowContent: [
        // { topic: "Højde", value: "2cm" },
        // { topic: "Bredde", value: "3cm" },
        // { topic: "Længde", value: "4cm" },
        { topic: "Højde", value: "2cm" },
        { topic: "Bredde", value: "3cm" },
        { topic: "Længde", value: "4cm" },
      ],
      image: "https://picsum.photos/id/166/400/600",
    },
    {
      headline: "Downloads",
      bodyText: "",
      links: [
        {
          linkText: "download1",
          linkHref: "#1",
        },
        {
          linkText: "download2",
          linkHref: "#2",
        },
        {
          linkText: "download3",
          linkHref: "#3",
        },
      ],
    },
    {
      headline: "Care- and maintenance",
      bodyText:
        "For maintenance of oiled wood surfaces, use oil, an abrasive sponge and a cloth (lint-free). For oak, use white pigmented oils and for walnut, use clear oil. The oil will penetrate the grain of the wood  building dirt and create a water-repellent coat that protects the wood and gives it finish The oil also cares for and maintains the light colour of the oak or ash, and leaves a smooth and silk matt  surface. View Care and Maintenance Guide",
      links: [
        {
          linkText: "View Care and Maintenance Guide",
          linkHref: "#",
        },
      ],
    },
  ],
};

const configuratorOptions = {
  mode: CONFIGURATOR_MODE.ADVANCED,
};
const modelSwatchTile = {
  centerImage: true,
  isActive: false,
  label: "3300",
  imageUrl: "https://picsum.photos/id/166/300/300",
};
const materialSwatchTile = {
  centerImage: false,
  isActive: false,
  label: "Hot pink",
  imageUrl: "https://picsum.photos/id/166/300/300",
};
const materialSwatchGroup = {
  isActive: false,
  label: "Christianshavn",
  description1: "68% new wool, 26% cotton",
  description2: "Martindale 40.000",
  imageUrl: "https://picsum.photos/200/100",
  materials: [
    { imageUrl: "https://picsum.photos/id/166/40/40" },
    { imageUrl: "https://picsum.photos/id/165/40/40" },
    { imageUrl: "https://picsum.photos/id/164/40/40" },
  ],
  colorCount: 12,
};
const modelFilters = {
  list: [
    {
      label: "No upholstery",
      isActive: true,
      id: "modelFilters1",
    },
    {
      label: "Full upholstery",
      isActive: false,
      id: "modelFilters2",
    },
    {
      label: "Front upholstered",
      count: "5",
      isActive: false,
      id: "modelFilters3",
    },
  ],
};
const materialFilters = {
  list: [
    {
      label: "Textile",
      isActive: true,
      id: "materialFilters1",
    },
    {
      label: "Wood",
      isActive: false,
      id: "materialFilters2",
    },
    {
      label: "Glass",
      count: "5",
      isActive: false,
      id: "materialFilters3",
    },
  ],
};
const microSwatches = {
  options: [
    { imageUrl: "https://picsum.photos/id/166/40/40", isActive: false },
    { imageUrl: "https://picsum.photos/id/165/40/40", isActive: true },
    { imageUrl: "https://picsum.photos/id/164/40/40", isActive: false },
  ],
};
const modelSelector = {
  filters: modelFilters,
  tiles: [
    { ...modelSwatchTile, isActive: true },
    modelSwatchTile,
    modelSwatchTile,
    modelSwatchTile,
    modelSwatchTile,
    modelSwatchTile,
    modelSwatchTile,
  ],
  title: "Lorem ipsum",
};

const materialSelectorTiles = {
  filters: materialFilters,
  tiles: [
    { ...materialSwatchTile, isActive: true },
    materialSwatchTile,
    materialSwatchTile,
    materialSwatchTile,
    materialSwatchTile,
    materialSwatchTile,
    materialSwatchTile,
  ],
  title: "Lorem ipsum",
};

const materialSelectorGroups = {
  filters: materialFilters,
  groups: [
    { ...materialSwatchGroup, isActive: true },
    materialSwatchGroup,
    materialSwatchGroup,
    materialSwatchGroup,
    materialSwatchGroup,
  ],
  title: "Lorem ipsum",
};

export default {
  productSpecification: productSpecification,
  productThumbs: productThumbs,
  microSwatches: microSwatches,
  configuratorOptions: configuratorOptions,
  swatchTile: { model: modelSwatchTile, material: materialSwatchTile },
  swatchGroup: { material: materialSwatchGroup },
  configuratorFilters: { materials: materialFilters, models: modelFilters },
  modelSelector: modelSelector,
  materialSelector: {
    tiles: materialSelectorTiles,
    groups: materialSelectorGroups,
  },
};
