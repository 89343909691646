import React from "react";
import { NavLink } from "react-router-dom";
import { LINKTYPE } from "@constants";
import classnames from "classnames";
import { ArrowSmallSvg } from "@assets/js/icons";

import "./nav-list-button.scss";

const NavListButton = ({ fields }) => {
  let linkType = fields?.link?.value?.linktype?.toUpperCase();
  if (!linkType) linkType = LINKTYPE.EXTERNAL;

  const text = fields?.link?.value?.text;
  const href = fields?.link?.value?.href;
  const title = fields?.link?.value?.title;
  // const hasArrow = fields?.hasArrow?.value;

  const classList = classnames({
    "nav-list-button": true,
  });
  return (
    <>
      {linkType !== LINKTYPE.JAVASCRIPT ? (
        <>
          {linkType === LINKTYPE.INTERNAL ? (
            <NavLink className={classList} to={href}>
              <span className="text">{text}</span>
              <span className="icon">
                <ArrowSmallSvg />
              </span>
            </NavLink>
          ) : (
            <a className={classList} href={href} title={title}>
              <span className="text">{text}</span>
              <span className="icon">
                <ArrowSmallSvg />
              </span>
            </a>
          )}
        </>
      ) : (
        <button className={classList} onClick={href}>
          <span className="text">{text}</span>
          <span className="icon">
            <ArrowSmallSvg />
          </span>
        </button>
      )}
    </>
  );
};

export default NavListButton;
