import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import { getPoint, getRelativePoint } from "../pdp-helpers";
import "./pdp-product-image-zoom.scss";
import getViewportSize from "@assets/js/get-viewport-size";
import ViewportSizes from "@assets/js/viewport-sizes";

import usePDPStore, { usePendingProductData } from "@store/pdp";

import { log } from "@assets/js/utils";

const PDPProductImageZoom = ({
  zooming,
  relPos,
  currentFrame,
  frameNode,
  images,
  productId,
  onStopZoom,
}) => {
  const imageData = usePDPStore((state) => state.productImage);
  const [canvasData, setCanvasData] = useState({});
  const [swipeStartX, setSwipeStartX] = useState(null);
  const [swipeStartY, setSwipeStartY] = useState(null);
  const [imgStartX, setImgStartX] = useState(null);
  const [imgStartY, setImgStartY] = useState(null);

  const componentRef = useRef();
  const canvasRef = useRef();
  let canvasCtx;
  let dragging = false;

  useEffect(() => {
    if (zooming) handleStartZoom(relPos);
  }, [zooming, handleStartZoom, relPos]);

  useLayoutEffect(() => {
    if (canvasData.size) loadZoom();
  }, [canvasData, loadZoom]);

  const handleStartZoom = (relPos) => {
    let currentViewport = getViewportSize();
    const sizes = imageData.resolutions.sort();
    const smallestSize = sizes[0];
    const largestSize = sizes[sizes.length - 1];

    const useSize =
      currentViewport.name === ViewportSizes.SMALL.name
        ? smallestSize
        : largestSize;
    const size = useSize;
    const tileSize = useSize / 8; //why is this always 8?
    const canvasData = {
      size,
      tileWidth: tileSize,
      tileHeight: tileSize,
      focusPosition: relPos || { x: 0.5, y: 0.5 },
      frameNode,
      priorityTile: {
        x: Math.round((size / tileSize - 1) * relPos.x),
        y: Math.round((size / tileSize - 1) * relPos.y),
      },
    };
    setCanvasData(canvasData);
  };

  const handleStopZoom = () => {
    if (onStopZoom) onStopZoom();
  };

  const loadZoom = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    canvasCtx = canvas.getContext("2d");

    canvasCtx.drawImage(
      canvasData.frameNode,
      0,
      0,
      canvasData.size,
      canvasData.size
    );

    var priorityImage = new Image();
    priorityImage.position = {
      x: canvasData.priorityTile.x,
      y: canvasData.priorityTile.y,
    };

    priorityImage.addEventListener("load", () => {
      drawCanvas(priorityImage);

      for (var y = 0; y < canvasData.size / canvasData.tileHeight; y++) {
        for (var x = 0; x < canvasData.size / canvasData.tileWidth; x++) {
          if (
            !(
              y === canvasData.priorityTile.y && x === canvasData.priorityTile.x
            )
          ) {
            // Skip priority tile (already requested)

            const img = new Image();
            img.position = { x, y };
            img.addEventListener("load", function () {
              drawCanvas(this);
            });
            img.src = `${images[currentFrame]}&tilex=${x}&tiley=${y}&size=${canvasData.size}`;
          }
        }
      }
    });
    priorityImage.src = `${images[currentFrame]}&tilex=${canvasData.priorityTile.x}&tiley=${canvasData.priorityTile.y}&size=${canvasData.size}`;

    focusMagnifier(canvasData.focusPosition);
  };

  const drawCanvas = (img) => {
    canvasCtx.drawImage(
      img,
      0,
      0,
      img.width,
      img.height,
      img.position.x * canvasData.tileWidth,
      img.position.y * canvasData.tileHeight,
      img.width,
      img.height
    );
  };

  const focusMagnifier = (relPos) => {
    var sizeOfMagnifier = componentRef.current.getBoundingClientRect();
    var sizeOfCanvas = canvasRef.current.getBoundingClientRect();

    var x = -relPos.x * (sizeOfCanvas.width - sizeOfMagnifier.width);
    var y = -relPos.y * (sizeOfCanvas.height - sizeOfMagnifier.height);

    canvasRef.current.style = `top: ${y}px; left: ${x}px;`;
  };

  const handleMousemove = (e) => {
    log("mouse move");
    e.preventDefault();

    const relPos = getRelativePoint(componentRef.current, e);
    focusMagnifier(relPos);
  };

  const handleTouchStart = (e) => {
    log("touch start");
    e.preventDefault();

    magnifierStartMove(getPoint(e).eventX, getPoint(e).eventY);
  };

  const handleTouchMove = (e) => {
    log("touch move");
    e.preventDefault();

    dragging = true;
    magnifierMove(getPoint(e).eventX, getPoint(e).eventY);
  };

  const handleTouchEnd = (e) => {
    log("touch end");
    e.preventDefault();

    if (!dragging) handleStopZoom(e);
    dragging = false;
    magnifierMove(getPoint(e).eventX, getPoint(e).eventY);
    magnifierEndMove();
  };

  const magnifierStartMove = (eventX, eventY) => {
    setSwipeStartX(eventX);
    setSwipeStartY(eventY);

    setImgStartX(parseInt(getComputedStyle(canvasRef.current).left));
    setImgStartY(parseInt(getComputedStyle(canvasRef.current).top));
  };

  const magnifierMove = (eventX, eventY) => {
    if (swipeStartX != null) {
      var deltaX = eventX - swipeStartX;
      var deltaY = eventY - swipeStartY;

      var sizeOfMagnifier = componentRef.current.getBoundingClientRect();
      var sizeOfCanvas = canvasRef.current.getBoundingClientRect();

      var newImgX = imgStartX + deltaX;
      var newImgY = imgStartY + deltaY;

      newImgX = Math.min(
        0,
        Math.max(newImgX, sizeOfMagnifier.width - sizeOfCanvas.width)
      );
      newImgY = Math.min(
        0,
        Math.max(newImgY, sizeOfMagnifier.height - sizeOfCanvas.height)
      );

      canvasRef.current.style = `top: ${newImgY}px; left: ${newImgX}px;`;
    }
  };

  const magnifierEndMove = () => {
    setSwipeStartX(null);
  };

  return (
    <div
      className={`pdp-product-image-zoom ${zooming ? "show" : ""}`}
      ref={componentRef}
      onClick={handleStopZoom}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      onMouseMove={handleMousemove}
    >
      <canvas
        width={canvasData.size}
        height={canvasData.size}
        ref={canvasRef}
      />
    </div>
  );
};

export default PDPProductImageZoom;
