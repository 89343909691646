export default function (layoutId) {
  if (!layoutId) {
    return false;
  }
  if (!process?.env) {
    return false;
  }
  return (
    `{${layoutId.toLowerCase()}}` == process.env.REACT_APP_SITECORE_LAYOUT_CHECKOUT.toLowerCase()
  );
}
