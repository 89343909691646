import React from "react";
import { NavLink } from "react-router-dom";
import { LINKTYPE, BUTTONSTYLE, OVERLAY } from "@constants";
import classnames from "classnames";
import * as icons from "@assets/js/icons";
import useHeaderStore from "@store/header";

import isTrue from "@assets/js/is-true";
import "./nav-button.scss";

const NavButton = ({ fields }) => {
  const setMenuOverlayActive = useHeaderStore((state) => state.setMenuOverlayActive);
  let linkType = LINKTYPE.EXTERNAL;

  if (fields?.link?.value?.linktype) {
    linkType = fields?.link?.value?.linktype?.toUpperCase();
  }
  let style;
  if (fields?.style?.value) {
    style = fields?.style?.value?.toUpperCase();
  }

  const text = fields?.link?.value?.text || fields?.title?.value;
  const href = fields?.link?.value?.href;
  const title = fields?.link?.value?.title;

  const hasArrow = isTrue(fields?.hasArrow);
  const isInverted = isTrue(fields?.isInverted);
  const Icon = fields?.link?.value?.icon ? icons[fields?.link.value.icon] : null;

  const ArrowSmallSvg = icons["ArrowSmallSvg"];
  // console.log(linkType, style, text, href, title, hasArrow, isInverted);

  const classList = classnames({
    "nav-button": true,
    "is-outlined": style === BUTTONSTYLE.GHOST,
    "is-link": style === BUTTONSTYLE.LINK,
    "is-small": style === BUTTONSTYLE.SMALL,
    "is-inverted": isInverted,
  });

  const onActionLinkClick = () => {
    if (linkType === LINKTYPE.JAVASCRIPT && href) {
      href();
    }
    if (fields?.command && Object.values(OVERLAY).includes(fields.command.value.toUpperCase())) {
      setMenuOverlayActive(fields.command.value.toUpperCase());
    }
  };

  return (
    <>
      {linkType !== LINKTYPE.JAVASCRIPT && !fields?.command ? (
        <>
          {linkType === LINKTYPE.INTERNAL ? (
            <NavLink className={classList} to={href}>
              {text && <span className="text">{text}</span>}
              {Icon && (
                <span className="icon">
                  <Icon />
                </span>
              )}
              {hasArrow && (
                <span className="icon">
                  <ArrowSmallSvg />
                </span>
              )}
            </NavLink>
          ) : (
            <a className={classList} href={href} title={title} target={fields.link?.value?.target}>
              {text && <span className="text">{text.value || text}</span>}
              {Icon && (
                <span className="icon">
                  <Icon />
                </span>
              )}
              {hasArrow && (
                <span className="icon">
                  <ArrowSmallSvg />
                </span>
              )}
            </a>
          )}
        </>
      ) : (
        <button className={classList} onClick={onActionLinkClick}>
          {text && <span className="text">{text}</span>}
          {Icon && (
            <span className="icon">
              <Icon />
            </span>
          )}
          {hasArrow && (
            <span className="icon">
              <ArrowSmallSvg />
            </span>
          )}
        </button>
      )}
    </>
  );
};

export default NavButton;

export const ghostState = {
  fields: {
    hasArrow: {
      value: true,
    },
    isInverted: {
      value: false,
    },
    style: {
      value: "ghost",
    },
    link: {
      value: {
        href: "",
        text: "",
        linktype: "external",
      },
    },
  },
};
