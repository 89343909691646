export default {
  default: {
    fields: {
      noParse: true,
      type: "media",
      imageSrc: "https://placeimg.com/500/200/any",
      text1: "text1",
      text2: "text2",
      text3: "text3",
    },
  },
};
