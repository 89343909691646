import React, { useEffect, useState, Fragment } from "react";
import shallow from "zustand/shallow";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { dataLayerCommercePush, checkoutSignature } from "@assets/js/gtm";
import Radio from "../../forms/Radio";
import Checkbox from "../../forms/Checkbox";
import ActionButton from "../../ActionButton";

import { BUTTONSTYLE } from "@constants";
import getFormattedPrice from "@assets/js/get-formatted-price";
import { log } from "@assets/js/utils";

import useCheckoutStore from "@store/checkout";
import useBasketStore from "@store/basket";

import { useCheckoutInfoData, useShippingMethods } from "@store/checkout";
import useGlobalStore, {
  useCommerceMarket,
  useMarketInfo,
} from "@store/global";

import "./delivery-form.scss";

const DeliveryForm = ({
  fields,
  termsHref,
  basketLineItems,
  currencyCode,
  stepNum,
}) => {
  const { t } = useTranslation();

  const texts = {
    deliveryMethod: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Checkout_DeliveryMethod"
    ),
    delivery: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Checkout_Delivery"
    ),
    yourAddress: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Checkout_YourAddress"
    ),
    editAddress: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Checkout_EditAddress"
    ),
    noDeliveryOptions: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Checkout_NoDeliveryOptions"
    ),

    continueToPayment: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Checkout_ContinueToPayment"
    ),

    name: t("FritzHansen_Project_ConsumersAndProfessionals_Checkout_Name"),
    address: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Checkout_Address"
    ),
    city: t("FritzHansen_Project_ConsumersAndProfessionals_Checkout_City"),
    country: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Checkout_Country"
    ),
    telephone: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Checkout_Telephone"
    ),
    email: t("FritzHansen_Project_ConsumersAndProfessionals_Checkout_Email"),

    newsletterSignup: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Checkout_NewsletterSignup"
    ),
    termsAccept: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Checkout_TermsAccept"
    ),
    termsLink: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Checkout_TermsLink"
    ),

    errorMethodRequired: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Checkout_ErrorMethodRequired"
    ),
    errorTermsRequired: t(
      "FritzHansen_Project_ConsumersAndProfessionals_Checkout_ErrorTermsRequired"
    ),
  };

  const [marketInfo] = useMarketInfo();

  const [checkoutInfoData, ,] = useCheckoutInfoData();

  const [shippingMethods, , fetchShippingMethods] = useShippingMethods();

  const [countryName, setCountryName] = useState(null);

  const submitDeliveryMethod = useCheckoutStore(
    (store) => store.submitDeliveryMethod
  );
  const checkForInitiatePayment = useCheckoutStore(
    (store) => store.checkForInitiatePayment
  );

  const [commerceMarket] = useCommerceMarket();
  const [language] = useGlobalStore((state) => [state.language], shallow);

  const basketId = useBasketStore((store) => store.basketId);
  const priceView = useBasketStore((state) => state.priceView);
  const history = useHistory();

  const { register, handleSubmit, trigger, errors, getValues } = useForm({
    defaultValues: {
      newsletter: false,
      terms: false,
      deliverymethod: false,
    },
  });

  const [deliveryMethodCount, setDeliveryMethodCount] = useState(-1);

  useEffect(() => {
    try {
      let countryId = checkoutInfoData.ShipmentAddress.CountryId;

      let chosenCountry = marketInfo.markets.values.find(
        (c) => c.commerceMarketId.value === countryId
      );

      setCountryName(chosenCountry.countryName.value);
    } catch (e) {}
    if (checkoutInfoData?.ShippingMethodId) {
      log("set method will fail", checkoutInfoData.ShippingMethodId);
      // setValue("deliverymethod", checkoutInfoData.ShippingMethodId);
    }
    if (shippingMethods && shippingMethods.length > -1) {
      setDeliveryMethodCount(shippingMethods.length);
    } else if (basketId) {
      //fetchShippingMethods(basketId);
    }
  }, [
    marketInfo,
    checkoutInfoData,
    shippingMethods,
    basketId,
    fetchShippingMethods,
  ]);
  useEffect(() => {
    fetchShippingMethods(basketId);
  }, [basketId]);

  const editAddressHandler = () => {
    //EDIT ADDRESS
    history.push(fields?.allSteps?.values[0].url);
  };

  const deliveryRadioHandler = () => {
    const values = getValues();
    submitDeliveryMethod(values.deliverymethod, basketId);
  };

  const submitFormHandler = () => {
    const values = getValues();
    checkForInitiatePayment(values.newsletter, values.terms, basketId);
  };

  const getDeliveryMethodLabel = (item, index) => {
    // log(
    //   "DeliveryForm | getDeliveryMethodLabel",
    //   item.Id,
    //   checkoutInfoData.ShippingMethodId,
    //   priceView?.shippingTotal
    // );

    let label;
    if (
      priceView &&
      checkoutInfoData.ShippingMethodId &&
      item.Id === checkoutInfoData.ShippingMethodId
    ) {
      label = (
        <>
          <span>{item.Title}</span>
          <small>
            {item.Description && item.Description.length > 0
              ? item.Description
              : ""}
          </small>
        </>
      );
    } else {
      label = (
        <>
          <span>{item.Title}</span>
          <small>
            {item.Description && item.Description.length > 0
              ? item.Description
              : ""}
          </small>
        </>
      );
    }
    return label;
  };

  let requiredMethod = {
    value: true,
    message: texts.errorMethodRequired,
  };

  const onSubmitClick = async () => {
    const result = await trigger();
    if (result) {
      // GTM Tracking for selected Delivery option
      const values = getValues();
      dataLayerCommercePush("checkoutOption", {
        checkout_option: {
          actionField: {
            step: stepNum + 1,
            option: shippingMethods.find(
              (item) => item.Id === Number(values.deliverymethod)
            )?.Title,
          },
        },
      });
      if (basketLineItems) {
        dataLayerCommercePush(
          "checkout",
          checkoutSignature({
            currencyCode,
            stepNum: stepNum + 1,
            basketLineItems,
          })
        );
      }
      submitFormHandler();
    }
  };

  return (
    <div className="delivery-form">
      <form className="form" onSubmit={handleSubmit(submitFormHandler)}>
        <div className="form__group">
          <h4>{texts.deliveryMethod}</h4>

          {shippingMethods && deliveryMethodCount > 0 ? (
            <>
              <div className="form__row">
                {shippingMethods.map((item, index) => {
                  return (
                    <Fragment key={`methodRadio${index}`}>
                      <Radio
                        name="deliverymethod"
                        id={`methodRadio${index}`}
                        value={item.Id}
                        label={getDeliveryMethodLabel(item, index)}
                        changeHandler={deliveryRadioHandler}
                        register={register({
                          required: requiredMethod,
                        })}
                      />
                    </Fragment>
                  );
                })}
                <ErrorMessage
                  errors={errors}
                  name="deliverymethod"
                  render={({ message }) => (
                    <p className="radio__message">{message}</p>
                  )}
                />
              </div>
              <div className="form__row form__row--dual">
                <span>{texts.delivery}</span>
                <span className="t--right">
                  {getFormattedPrice(
                    language,
                    commerceMarket,
                    priceView?.currency,
                    priceView?.shippingTotal
                  )}
                </span>
              </div>
            </>
          ) : (
            <p>{texts.noDeliveryOptions}</p>
          )}
        </div>
        <div className="form__group">
          {checkoutInfoData?.ShipmentAddress && (
            <div className="form__row">
              <h4>{texts.yourAddress}</h4>
              <table>
                <tbody>
                  <tr>
                    <th>{texts.name}</th>
                    <td>
                      {checkoutInfoData.ShipmentAddress.FirstName}{" "}
                      {checkoutInfoData.ShipmentAddress.LastName}
                    </td>
                  </tr>
                  <tr>
                    <th>{texts.address}</th>
                    <td>{checkoutInfoData.ShipmentAddress.Line1}</td>
                  </tr>
                  <tr>
                    <th>{texts.city}</th>
                    <td>
                      {checkoutInfoData.ShipmentAddress.PostalCode}{" "}
                      {checkoutInfoData.ShipmentAddress.City}
                    </td>
                  </tr>
                  <tr>
                    <th>{texts.country}</th>
                    <td>{countryName}</td>
                  </tr>
                  <tr>
                    <th>{texts.email}</th>
                    <td>{checkoutInfoData.BillingAddress.EmailAddress}</td>
                  </tr>
                  <tr>
                    <th>{texts.telephone}</th>
                    <td>{checkoutInfoData.BillingAddress.MobilePhoneNumber}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
          <div className="form__row">
            <ActionButton
              text={texts.editAddress}
              buttonType={BUTTONSTYLE.LINK}
              clickHandler={editAddressHandler}
              hasArrow={true}
            />
          </div>
        </div>

        <div className="form__group">
          <Checkbox
            name="newsletter"
            id="newsletterCheck"
            type="checkbox"
            label={texts.newsletterSignup}
            register={register()}
          />
          <Checkbox
            name="terms"
            id="termsCheck"
            type="checkbox"
            label={texts.termsAccept}
            register={register({
              validate: (value) => value == true || texts.errorTermsRequired,
            })}
            link={
              <a
                className="basic-link terms-link"
                href={termsHref}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="text">{texts.termsLink}</span>
              </a>
            }
            error={errors.terms}
          />
        </div>
      </form>

      <footer className="checkout-flow__footer">
        <ActionButton
          text={texts.continueToPayment}
          buttonType={BUTTONSTYLE.SOLID}
          customClass="action-button--primary"
          clickHandler={onSubmitClick}
        />
      </footer>
    </div>
  );
};
DeliveryForm.propTypes = {};

export default DeliveryForm;
