import React from "react";
import MediaFocal from "../MediaFocal";

import "./simple-gallery.scss";

const SimpleGallery = ({ fields }) => {
  return (
    <>
      {fields && (
        <div className="simple-gallery">
          {fields?.images?.map((image, index) => {
            return (
              <div className="simple-gallery__image" key={`galleryImg${index}`}>
                <MediaFocal fields={image?.fields} />
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};
SimpleGallery.propTypes = {};

export default SimpleGallery;
