export default {
  default: {
    style: "solid",
    text: "Lorem ipsum",
    isInverted: false,
    hasArrow: false,
    clickHandler: () => {
      console.log("do it!");
    },
  },
  inverted: {
    style: "solid",
    text: "Lorem ipsum",
    isInverted: true,
    hasArrow: false,
    clickHandler: () => {
      console.log("do it!");
    },
  },
  outlined: {
    style: "ghost",
    text: "Lorem ipsum",
    isInverted: false,
    hasArrow: false,
    clickHandler: () => {
      console.log("do it!");
    },
  },
  outlinedInverted: {
    style: "ghost",
    text: "Lorem ipsum",
    isInverted: true,
    hasArrow: false,
    clickHandler: () => {
      console.log("do it!");
    },
  },
  link: {
    style: "link",
    text: "Lorem ipsum",
    isInverted: false,
    hasArrow: true,
    clickHandler: () => {
      console.log("do it!");
    },
  },
  loadMore: {
    style: "ghost",
    text: "Load more",
    isInverted: false,
    hasArrow: false,
  },
};
