export default {
  default: {
    // phoneHref: "tel:004522334455",
    // noParse: true,
    phone: {
      value: "+45 22 33 44 55",
    },
    // emailHref: "mailto:sdfsdfdf@dsfsdfds.com",
    email: {
      value: "sdfsdfdf@dsfsdfds.com",
    },
  },
  onlyPhone: {
    // phoneHref: "tel:004522334455",
    noParse: true,
    phone: {
      value: "+45 22 33 44 55",
    },
  },
  onlyEmail: {
    // emailHref: "mailto:sdfsdfdf@dsfsdfds.com",
    noParse: true,
    email: {
      value: "sdfsdfdf@dsfsdfds.com",
    },
  },
};
