export default {
  default: {
    fields: {
      data: {
        texts: {
          filterText: { value: "ReferencesControllerAuto taglist" },
          loadMoreText: { value: "load more references" },
        },
        tags: {
          list: {
            values: [
              { id: { value: "tag1" }, title: { value: "MyTag" } },
              { id: { value: "tag2" }, title: { value: "MyTag2" } },
            ],
          },
        },
        references: {
          values: [
            {
              tags: {
                values: [
                  { id: { value: "tag1" }, title: { value: "MyTag" } },
                  { id: { value: "tag2" }, title: { value: "MyTag2" } },
                ],
              },
              image: {
                value: {
                  id: "61080bfc-fb07-4abe-a983-eae9a8868d80",
                  url: "/components/105/ArticlePage1/Page-Components/Media",
                  fields: {
                    image: {
                      value: {
                        src:
                          "http://fritz.alpha-solutions.dk/-/media/002.ashx?h=900&iar=0&w=800",
                        alt: "Media002",
                        width: "800",
                        height: "900",
                        focalpoint: "8, 23",
                      },
                    },
                  },
                },
              },
              bodyText: { value: "ArticlePage1 description" },
              headline: { value: "ArticlePage1 title" },
              buttonText: { value: "ArticlePage1 button text" },
              secondaryMedia: {
                value: {
                  id: "61080bfc-fb07-4abe-a983-eae9a8868d80",
                  url: "/components/105/ArticlePage1/Page-Components/Media",
                  fields: {
                    image: {
                      value: {
                        src:
                          "http://fritz.alpha-solutions.dk/-/media/002.ashx?h=900&iar=0&w=800",
                        alt: "Media002",
                        width: "800",
                        height: "900",
                        focalpoint: "8, 23",
                      },
                    },
                  },
                },
              },
              componentBackground: { value: "" },
              link: "/en/components/105/ArticlePage1",
            },
            {
              tags: {
                values: [{ id: { value: "tag1" }, title: { value: "MyTag" } }],
              },
              image: {
                value: {
                  id: "0ae62da0-c117-4286-99d0-3ae421e9c927",
                  url: "/components/105/ArticlePage2/Page-Components/Media",
                  fields: {
                    image: {
                      value: {
                        src:
                          "http://fritz.alpha-solutions.dk/-/media/002.ashx?h=900&iar=0&w=800",
                        alt: "Media002",
                        width: "800",
                        height: "900",
                        focalpoint: "8, 23",
                      },
                    },
                  },
                },
              },
              bodyText: { value: "ArticlePage2 description" },
              headline: { value: "ArticlePage2 title" },
              buttonText: { value: "ArticlePage2 button text" },
              secondaryMedia: {
                value: {
                  id: "0ae62da0-c117-4286-99d0-3ae421e9c927",
                  url: "/components/105/ArticlePage2/Page-Components/Media",
                  fields: {
                    image: {
                      value: {
                        src:
                          "http://fritz.alpha-solutions.dk/-/media/002.ashx?h=900&iar=0&w=800",
                        alt: "Media002",
                        width: "800",
                        height: "900",
                        focalpoint: "8, 23",
                      },
                    },
                  },
                },
              },
              componentBackground: { value: "#eb4034" },
              link: "/en/components/105/ArticlePage2",
            },
            {
              tags: {
                values: [{ id: { value: "tag2" }, title: { value: "MyTag2" } }],
              },
              image: {
                value: {
                  id: "a02c8140-f153-405a-bc3f-a251c0bdd49d",
                  url: "/components/105/ArticlePage3/Page-Components/Media",
                  fields: {
                    image: {
                      value: {
                        src:
                          "http://fritz.alpha-solutions.dk/-/media/002.ashx?h=900&iar=0&w=800",
                        alt: "Media002",
                        width: "800",
                        height: "900",
                        focalpoint: "8, 23",
                      },
                    },
                  },
                },
              },
              bodyText: { value: "ArticlePage3 description" },
              headline: { value: "ArticlePage3 title " },
              buttonText: { value: "ArticlePage3 button text" },
              secondaryMedia: {
                value: {
                  id: "a02c8140-f153-405a-bc3f-a251c0bdd49d",
                  url: "/components/105/ArticlePage3/Page-Components/Media",
                  fields: {
                    image: {
                      value: {
                        src:
                          "http://fritz.alpha-solutions.dk/-/media/002.ashx?h=900&iar=0&w=800",
                        alt: "Media002",
                        width: "800",
                        height: "900",
                        focalpoint: "8, 23",
                      },
                    },
                  },
                },
              },
              componentBackground: { value: "" },
              link: "/en/components/105/ArticlePage3",
            },
            {
              tags: {
                values: [{ id: { value: "tag2" }, title: { value: "MyTag2" } }],
              },
              image: {
                value: {
                  id: "79bc71a1-bf96-46b6-99db-60beeabb3a5c",
                  url: "/components/105/ArticlePage4/Page-Components/Media",
                  fields: {
                    image: {
                      value: {
                        src:
                          "http://fritz.alpha-solutions.dk/-/media/002.ashx?h=900&iar=0&w=800",
                        alt: "Media002",
                        width: "800",
                        height: "900",
                        focalpoint: "8, 23",
                      },
                    },
                  },
                },
              },
              bodyText: { value: "ArticlePage4 description" },
              headline: { value: "ArticlePage4 title " },
              buttonText: { value: "ArticlePage4 button text" },
              secondaryMedia: {
                value: {
                  id: "79bc71a1-bf96-46b6-99db-60beeabb3a5c",
                  url: "/components/105/ArticlePage4/Page-Components/Media",
                  fields: {
                    image: {
                      value: {
                        src:
                          "http://fritz.alpha-solutions.dk/-/media/002.ashx?h=900&iar=0&w=800",
                        alt: "Media002",
                        width: "800",
                        height: "900",
                        focalpoint: "8, 23",
                      },
                    },
                  },
                },
              },
              componentBackground: { value: "" },
              link: "/en/components/105/ArticlePage4",
            },
            {
              tags: {
                values: [{ id: { value: "tag2" }, title: { value: "MyTag2" } }],
              },
              image: {
                value: {
                  id: "d1a6fd6b-3eb0-456d-9fda-00f1d8cd8d05",
                  url: "/components/105/ArticlePage5/Page-Components/Media",
                  fields: {
                    image: {
                      value: {
                        src:
                          "http://fritz.alpha-solutions.dk/-/media/002.ashx?h=900&iar=0&w=800",
                        alt: "Media002",
                        width: "800",
                        height: "900",
                        focalpoint: "8, 23",
                      },
                    },
                  },
                },
              },
              bodyText: { value: "ArticlePage5 description" },
              headline: { value: "ArticlePage5 title " },
              buttonText: { value: "ArticlePage5 button text" },
              secondaryMedia: {
                value: {
                  id: "d1a6fd6b-3eb0-456d-9fda-00f1d8cd8d05",
                  url: "/components/105/ArticlePage5/Page-Components/Media",
                  fields: {
                    image: {
                      value: {
                        src:
                          "http://fritz.alpha-solutions.dk/-/media/002.ashx?h=900&iar=0&w=800",
                        alt: "Media002",
                        width: "800",
                        height: "900",
                        focalpoint: "8, 23",
                      },
                    },
                  },
                },
              },
              componentBackground: { value: "" },
              link: "/en/components/105/ArticlePage5",
            },
          ],
        },
      },
    },
  },
};
