import React, { useEffect, useState, Fragment } from "react";
import produce from "immer";
import { useTranslation } from "react-i18next";

import NavTile from "../NavTile";
import TagList from "../TagList";
import ActionButton from "../ActionButton";

import { BUTTONSTYLE } from "@constants";
import { log } from "@assets/js/utils";

import "./stories.scss";



const StoriesController = ({ fields }) => {
  const { t } = useTranslation();
  const buttonText = t("FritzHansen_Project_ConsumersAndProfessionals_Stories_ButtonLoad");
  
  const [tagCount,setTagCount] = useState(-1);
  const [tagsList, setTagsList] = useState([]);
    
  const [allItemsList, setAllItemList] = useState([]);
  const [currentItemList, setCurrentItemList] = useState([]);
  const [filteredItemsList, setFilteredItemsList] = useState([]);
  
  const PAGE_SIZE = 9;

  const [totalItemCount, setTotalItemCount] = useState(-1);
  const [currentItemCount,setCurrentItemCount] = useState(PAGE_SIZE);

  const [allLoaded, setAllLoaded] = useState(false);
  
  const [filterActive, setFilterActive] = useState(false);

  const [textFields,setTextFields] = useState();
  
  
  useEffect(() => {    
    let tags = fields.data?.tags?.list?.values || [];
    setTagCount(tags.length)
    
    if (tags && tags.length > 0) {
      tags = tags.map((tag) => {
        if (tag && tag.active) {
          tag.active = false;
        }
        return tag;
      });

      setTagsList(tags);
    }
    
    let items = fields.data?.stories?.values;
    let itemsParsed = items.map((item) => parseItem(item));
    setTotalItemCount(items?.length);

    setAllItemList(itemsParsed);
    setCurrentItemList(itemsParsed.slice(0,currentItemCount));
    
    setTextFields({
      filterText: fields.data?.texts?.filterText?.field,
      loadMoreText:
        fields.data?.texts?.loadMoreText?.field?.value.length > 0
          ? fields.data?.texts?.loadMoreText
          : { value: buttonText },
    }); 

    log("Stories | itemCount="+items.length+", tagCount="+tags.length)
    
  },[fields]);


  useEffect(() => {
    if ( tagsList && tagsList.length ) {

      const activeTags = tagsList.filter((tag) => tag.active);
      const activeTagsCount = activeTags.length;
      
      if (activeTagsCount === 0) {
        setFilteredItemsList(currentItemList);
        setFilterActive(false);
      } else {
        setFilterActive(true);
        // setExpanded(true);
        let matchingItems = [];
        
        //locate matching item
        for (let h = 0; h < activeTagsCount; h++) {
          const currentTag = activeTags[h];
          
          for (let i = 0; i < totalItemCount; i++) {
            const item = allItemsList[i];
            
            const itemTagCount = item.tags.values.length;
            for (let j = 0; j < itemTagCount; j++) {
              let t = item.tags.values[j];
              if (t.id?.field?.value === currentTag.id?.field?.value) {
                matchingItems.push(JSON.parse(JSON.stringify(item)));
                break;
              }
            }
          }
        }
        
        //state updates
        setFilteredItemsList(matchingItems);
      }
    }
  }, [tagsList, allItemsList, currentItemList]);

  
  
  const resetTags = () => {
    const newAvailableTags = produce(tagsList, (tmpTags) => {
      tmpTags.forEach((tag) => {
        tag.active = false;
      });
    });

    setTagsList(newAvailableTags);    
  };


  const getMoreItems = ()=>{
    let newCount = currentItemCount + PAGE_SIZE;
    if ( newCount > totalItemCount ) {
      newCount = totalItemCount;      
      setAllLoaded(true);
    }
    
    setCurrentItemCount(newCount);
    setCurrentItemList(allItemsList.slice(0, newCount));
  };

  const tagUpdateHandler = (tagId) => {
    //Toggle tag active
    let locatedTagIndex = tagsList.indexOf(
      tagsList.find((tag) => tag.id?.field?.value === tagId?.field?.value)
    );

    const newAvailableTags = produce(tagsList, (tmpTags) => {
      if (locatedTagIndex > -1) tmpTags[locatedTagIndex].active = !tmpTags[locatedTagIndex].active;

      tmpTags.forEach((tag) => {
        if (tmpTags.indexOf(tag) != locatedTagIndex) {
          tag.active = false;
        }
      });
    });

    setTagsList(newAvailableTags);
  };


  const parseItem = function (item) {
    let tagsText = "";
    item.tags.values.forEach((tag, index) => {
      if (index > 0) tagsText += ", ";
      tagsText += tag.title.field.value;
    });
    item.navTile = {
      fields: {
        media: { fields: { image: item.image?.value?.fields?.image } },
        bodyText: item.bodyText?.field,
        tag: { value: tagsText },
        headline: item.headline?.field,
        button: { fields: { link: { value: { href: item.link } } } },
      },
    };

    return item;
  };

  const separateIntoLayoutGroups = function (arr) {
    let count = arr.length;
    let groups = Math.ceil(count / 10);
    let newArr = [];

    for (let h = 0; h < groups; h++) {
      let index = h * 10;
      let group = arr.slice(index, index + 10);

      newArr.push([group.slice(0, 3), group.slice(3, 5), group.slice(5, 8), group.slice(8, 10)]);
    }
    return newArr;
  };

  return (
    <div className="stories">
      {tagCount > 0 && (
        <TagList
          fields={{
            label: textFields.filterText,
            loadMoreText: textFields.loadMoreText,
          }}
          tagList={tagsList}
          tagUpdateHandler={tagUpdateHandler}
        />
      )}

      <div className="stories__tiles">
        {separateIntoLayoutGroups(filteredItemsList).map((group, index) => {
          return (
            <Fragment key={`storiesFrag${index}`}>
              {group[0] && (
                <div
                  className={`stories__layout ${
                    group[0].length === 1 ? "stories__layout--one" : "stories__layout--two-one"
                  }`}
                >
                  {group[0].map((item, index) => {
                    return (
                      <NavTile
                        key={`groupOne${index}`}
                        fields={item.navTile.fields}
                        isButtonEnabled={item?.link ? true : false}
                      />
                    );
                  })}
                </div>
              )}
              {group[1] && group[1].length > 0 && (
                <div
                  className={`stories__layout ${
                    group[1].length === 1 ? "stories__layout--one" : "stories__layout--one-one"
                  }`}
                >
                  {group[1].map((item, index) => {
                    return (
                      <NavTile
                        key={`groupTwo${index}`}
                        fields={item.navTile.fields}
                        isButtonEnabled={item?.link ? true : false}
                      />
                    );
                  })}
                </div>
              )}
              {group[2] && group[2].length > 0 && (
                <div
                  className={`stories__layout ${
                    group[2].length === 1 ? "stories__layout--one" : "stories__layout--one-two"
                  }`}
                >
                  {group[2].map((item, index) => {
                    return (
                      <NavTile
                        key={`groupThree${index}`}
                        fields={item.navTile.fields}
                        isButtonEnabled={item?.link ? true : false}
                      />
                    );
                  })}
                </div>
              )}
              {group[3] && group[3].length > 0 && (
                <div
                  className={`stories__layout ${
                    group[3].length === 1 ? "stories__layout--one" : "stories__layout--one-one"
                  }`}
                >
                  {group[3].map((item, index) => {
                    return (
                      <NavTile
                        key={`groupFour${index}`}
                        fields={item.navTile.fields}
                        isButtonEnabled={item?.link ? true : false}
                      />
                    );
                  })}
                </div>
              )}
            </Fragment>
          );
        })}
      </div>
      {totalItemCount > PAGE_SIZE && !allLoaded && !filteredItemsList.length == 0 && !filterActive && (
        <div className="stories__footer">
          <ActionButton
            text={textFields.loadMoreText?.field?.value}
            buttonType={BUTTONSTYLE.GHOST}
            clickHandler={() => getMoreItems()}
          />
        </div>
      )}
    </div>
  );
};

export default StoriesController;
