import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import urlParams from "@assets/js/url-params";

import {
  useActiveConfigurationSKU,
  useSkuProductList,
  useActiveProductData,
  useFullFriendlySKU,
} from "@store/pdp";

import "./pdp-micro-swatches.scss";

const PDPMicroSwatches = ({ props }) => {
  const [, setActiveProductData] = useActiveProductData();
  const [skuProductList] = useSkuProductList();
  const [activeConfigurationSKU, setActiveConfigurationSKU] = useActiveConfigurationSKU();
  const [, setFullFriendlySKU] = useFullFriendlySKU();

  const [activeIndex, setActiveIndex] = useState(0);

  const getActiveItem = () => {
    if (activeConfigurationSKU && skuProductList?.length > 0) {
      return skuProductList.find((item) => item.fullFriendlyItem === activeConfigurationSKU);
    }
    return null;
  };

  useEffect(() => {
    if (activeConfigurationSKU && skuProductList?.length > 0) {
      let item = getActiveItem();
      if (item) {
        let newIndex = skuProductList.indexOf(item);
        if (newIndex > -1) {
          setActiveIndex(newIndex);
        }
      }
    }
  }, [activeConfigurationSKU, skuProductList]);

  const clickHandler = (item, index) => {
    setActiveProductData(item);
    setActiveConfigurationSKU(item.fullFriendlyItem);
    setFullFriendlySKU(item.fullFriendlyItem);

    setActiveIndex(index);
    urlParams.set({ sku: item.fullFriendlyItem });
  };

  return (
    <>
      {skuProductList && skuProductList.length > 1 && (
        <div className="pdp-micro-swatches">
          {getActiveItem().displayName && <h4>{getActiveItem().displayName}</h4>}

          <div className="pdp-micro-swatches__list">
            <div className="pdp-micro-swatches__list__inner">
              {skuProductList.map((item, index) => {
                return (
                  <button
                    onClick={() => clickHandler(item, index)}
                    to={`?sku=${item.fullFriendlyItem}`}
                    key={`swatch${index}${item.displayName}`}
                    className={`pdp-micro-swatches__list-item ${
                      index === activeIndex ? "is-active" : ""
                    }`}
                  >
                    <div>
                      <img src={item.swatchUrl} alt="" />
                    </div>
                  </button>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
PDPMicroSwatches.propTypes = {
  options: PropTypes.array,
};

export default PDPMicroSwatches;
