import React from "react";
import "./icons.scss";

export default ({ className, isInverted }) => (
  <svg
    className={`svg-icon svg-icon--box ${className || ""} ${
      isInverted ? "is-inverted" : ""
    }`}
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path stroke="#000" fill="none" d="M.5.5h19v4H.5zM.5 4.5h19v14H.5z" />
    <path stroke="#000" fill="none" d="M7.5 4.5h6v3h-6z" />
  </svg>
);
