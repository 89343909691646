export const getPoint = e => {

  var eventX = e.pageX;
  var eventY = e.pageY;
  try {
      eventX = e.changedTouches[0].pageX;
      eventY = e.changedTouches[0].pageY;
  } catch (e) {
      //
  }

  return {eventX, eventY}
}

export const getClientPoint = e => {

  var eventX = e.clientX;
  var eventY = e.clientY;
  try {
      eventX = e.changedTouches[0].clientX;
      eventY = e.changedTouches[0].clientY;
  } catch (e) {
      //
  }

  return {eventX, eventY}
}

export const getRelativePoint = (element, e) => {
  var bounds = element.getBoundingClientRect();
  var cx = getClientPoint(e).eventX - bounds.left;
  var cy = getClientPoint(e).eventY - bounds.top;
  return {
    x: cx / bounds.width,
    y: cy / bounds.height
  }
}