import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import PDPMicroSwatches from "../PDPMicroSwatches";
import ProductImage from "../../ProductImage";

import {log} from "@assets/js/utils";
import getUniqueId from "@assets/js/get-unique-id";
import { CONFIGURATOR_MODE, IMAGE } from "@constants";

import usePDPStore, { useActiveProductData, useConfiguratorMode } from "@store/pdp";

import "./pdp-configurator-options.scss";

const PDPConfiguratorOptions = ({ activateModelHandler, activateMaterialHandler }) => {
  const { t } = useTranslation();
  const texts = {
    editModel: t("FritzHansen_Project_ConsumersAndProfessionals_PDP_EditModel"),
    editMaterial: t("FritzHansen_Project_ConsumersAndProfessionals_PDP_EditMaterial"),
  };
  const [activeProductData] = useActiveProductData();
  const [configuratorMode] = useConfiguratorMode();

  // const classNames = classnames({
  //   layout__container: true,
  //   "is-inactive": menuOverlayActive !== OVERLAY.NONE ? true : false,
  // });

  useEffect(() => {
    log(
      "ProductImage | activeProductData.model.configurationId=" +
        activeProductData?.model?.configurationId
    );
  }, [activeProductData]);

  return (
    <div className="pdp-configurator-options">
      {configuratorMode !== CONFIGURATOR_MODE.NONE && (
        <div className="pdp-configurator-options__inner">
          {configuratorMode === CONFIGURATOR_MODE.ADVANCED &&
            activeProductData &&
            activeProductData?.model && (
              <button
                className="pdp-configurator-options__item"
                onClick={() => activateModelHandler(activeProductData.model)}
              >
                <div>
                  <h5>{texts.editModel}</h5>
                  <p>
                    {activeProductData.model.label ||
                      `${activeProductData.model.groupId} model ${activeProductData.model.typeId}`}
                  </p>
                </div>
                {activeProductData?.model?.configurationId && (
                  <ProductImage
                    imageId={activeProductData.model.configurationId}
                    alt={activeProductData.model.typeId}
                    sw="35"
                    backgroundColor="ffffff"
                  />
                )}
              </button>
            )}

          {activeProductData?.materials &&
            activeProductData.materials.map((item, index) => {
              return (
                <button
                  key={getUniqueId()}
                  className="pdp-configurator-options__item"
                  onClick={() => activateMaterialHandler(index, item)}
                >
                  <div>
                    <h5>
                      {texts.editMaterial} {activeProductData.materials.length > 1 ? index + 1 : ""}
                    </h5>
                    {item?.activeMaterial?.materialSetLabel &&
                    item?.activeMaterial?.materialSetLabel?.length > 0 ? (
                      <p>
                        {item.activeMaterial.materialSetLabel +
                          " / " +
                          item.activeMaterial.materialLabel}
                      </p>
                    ) : (
                      <p>{item.activeMaterial.materialLabel}</p>
                    )}
                  </div>

                  <ProductImage
                    type={IMAGE.SWATCH}
                    imageId={item.activeMaterial.materialId}
                    alt={item.activeMaterial.materialLabel}
                    sw={"35"}
                  />
                </button>
              );
            })}
        </div>
      )}

      {configuratorMode === CONFIGURATOR_MODE.NONE && <PDPMicroSwatches />}
    </div>
  );
};

PDPConfiguratorOptions.propTypes = {
  activateModelHandler: PropTypes.func.isRequired,
  activateMaterialHandler: PropTypes.func.isRequired,
};

export default PDPConfiguratorOptions;
