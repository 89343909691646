import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";

import ProductTilesSlider from "../ProductTilesSlider";
import path from "@assets/js/browser-path";

import useGlobalStore, {useCommerceMarket} from "@store/global";


const fetchProducts = (body, lang) =>
  axios.post(
    path.join(process.env.REACT_APP_PRODUCTSAPI, "Product", lang, "category", "products"),
    body
  );

const ProductTilesSliderController = ({ fields }) => {
  const itemsShown = fields?.itemsShown?.value ? fields.itemsShown.value : 1;

  const taxonomy = fields?.taxonomy?.value ? fields.taxonomy.value : null;

  const sc_lang = useGlobalStore((state) => state.sc_lang);
  const [commerceMarket] = useCommerceMarket();

  const headline = fields?.headline ? fields.headline : { value: "Test headline" };

  //PARSE PRODUCTS
  const [tiles, setTiles] = useState([]);

  useEffect(() => {
    async function loadProducts() {
      const response = await fetchProducts(
        {
          docType: ["modeltype"],
          count: itemsShown,
          taxonomy: taxonomy,
          trimFields: true,
          market: commerceMarket
        },
        sc_lang
      );

      setTiles(response.data.productTiles);
    }

    if (taxonomy && sc_lang && tiles.length == 0) loadProducts();
  }, [sc_lang, commerceMarket]);

  return (
    <>
      {taxonomy && tiles.length > 0 ? (
        <ProductTilesSlider tiles={tiles} headline={headline} />
      ) : (
        <></>
      )}
    </>
  );
};

ProductTilesSliderController.propTypes = {
  fields: PropTypes.object,
};

export default ProductTilesSliderController;
