export default {
  default: {
    fields: {
      stepList: [
        {
          noParse: true,
          id: 1,
          name: "Dine informationer",
          url: "#",
        },
        {
          noParse: true,
          id: 2,
          name: "Levering",
          url: "#",
        },
        {
          noParse: true,
          id: 3,
          name: "Betaling",
          url: "#",
        },
        {
          noParse: true,
          id: 4,
          name: "Kvittering",
          url: "#",
        },
      ],
      showTestButtons: true,
    },
  },
};
