import MediaFocal from "./media-focal";

export default {
  default: {
    fields: {
      headline: "Headline",
      bodyText:
        "Ricotta mascarpone port-salut. Croque monsieur fondue stinking bishop cream cheese cheese and wine melted cheese cheesy grin fromage frais. Airedale dolcelatte edam chalk and cheese monterey jack macaroni cheese parmesan squirty cheese.",
      loadMoreButtonText: "buttonText",
      images: [
        {
          fields: MediaFocal.default.fields,
          id: "1",
        },
        {
          fields: MediaFocal.default.fields,
          id: "2",
        },
        {
          fields: MediaFocal.default.fields,
          id: "3",
        },
        {
          fields: MediaFocal.default.fields,
          id: "4",
        },
        {
          fields: MediaFocal.default.fields,
          id: "5",
        },
        {
          fields: MediaFocal.default.fields,
          id: "6",
        },
        {
          fields: MediaFocal.default.fields,
          id: "7",
        },
        {
          fields: MediaFocal.default.fields,
          id: "8",
        },
        {
          fields: MediaFocal.default.fields,
          id: "9",
        },
        {
          fields: MediaFocal.default.fields,
          id: "10",
        },
      ],
    },
  },
  customInverted: {
    fields: {
      headline: "Headline",
      bodyText:
        "Ricotta mascarpone port-salut. Croque monsieur fondue stinking bishop cream cheese cheese and wine melted cheese cheesy grin fromage frais. Airedale dolcelatte edam chalk and cheese monterey jack macaroni cheese parmesan squirty cheese. Bavarian bergkase port-salut st. agur blue cheese pepper jack parmesan halloumi smelly cheese edam. Camembert de normandie smelly cheese.",
      loadMoreButtonText: "buttonText",
      images: [
        {
          fields: MediaFocal.default.fields,
          id: "1",
        },
        {
          fields: MediaFocal.default.fields,
          id: "2",
        },
        {
          fields: MediaFocal.default.fields,
          id: "3",
        },
        {
          fields: MediaFocal.default.fields,
          id: "4",
        },
        {
          fields: MediaFocal.default.fields,
          id: "5",
        },
        {
          fields: MediaFocal.default.fields,
          id: "6",
        },
        {
          fields: MediaFocal.default.fields,
          id: "7",
        },
        {
          fields: MediaFocal.default.fields,
          id: "8",
        },
        {
          fields: MediaFocal.default.fields,
          id: "9",
        },
        {
          fields: MediaFocal.default.fields,
          id: "10",
        },
      ],
      backgroundColorHex: "#cccccc",
      isInverted: true,
    },
  },
};
