import React from "react";
import { LogoSvg } from "@assets/js/icons";
import "./login-module.scss";

const LoginModule = () => {
  return (
    <>
      <div className="container body-container">
        <div className="login-page">
          <div className="row">
            <div className="col-sm-6">
              <div className="card">
                <div className="card-header">
                  <h2>
                    <LogoSvg />
                  </h2>
                </div>
                <div class="alert alert-danger">
                  <strong>Error</strong>
                  <div
                    class="danger validation-summary-errors"
                    data-valmsg-summary="true"
                  >
                    <ul>
                      <li>Invalid username or password</li>
                    </ul>
                  </div>
                </div>
                <div className="card-body">
                  <form action="" method="post">
                    <input
                      type="hidden"
                      id="ReturnUrl"
                      name="ReturnUrl"
                      value="/connect/authorize/callback?client_id=mvc&amp;response_type=code&amp;scope=openid%20profile%20offline_access&amp;ui_locales=en&amp;acr_values=Consumers&amp;redirect_uri=https%3A%2F%2Fedit.fritzhansen.com%2Fconsumerslogin%2FConsumersCallback"
                    />

                    <div className="form-group">
                      <label for="Username">Email</label>
                      <input
                        className="form-control"
                        placeholder="Email"
                        autofocus=""
                        type="text"
                        data-val="true"
                        data-val-required="The Username field is required."
                        id="Username"
                        name="Username"
                        value=""
                      />
                    </div>
                    <div className="form-group">
                      <label for="Password">Password</label>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Password"
                        autocomplete="off"
                        data-val="true"
                        data-val-required="The Password field is required."
                        id="Password"
                        name="Password"
                      />
                    </div>
                    <div className="form-group">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          data-val="true"
                          data-val-required="The RememberLogin field is required."
                          id="RememberLogin"
                          name="RememberLogin"
                          value="true"
                        />
                        <label className="form-check-label" for="RememberLogin">
                          Remember username
                        </label>
                      </div>
                    </div>
                    <button
                      className="btn is-inverted"
                      name="button"
                      value="login"
                    >
                      Login
                    </button>
                    <a
                      href="SignUp?returnUrl=%2Fconnect%2Fauthorize%2Fcallback%3Fclient_id%3Dmvc%26response_type%3Dcode%26scope%3Dopenid%2520profile%2520offline_access%26ui_locales%3Den%26acr_values%3DConsumers%26redirect_uri%3Dhttps%253A%252F%252Fedit.fritzhansen.com%252Fconsumerslogin%252FConsumersCallback"
                      className="btn is-inverted"
                      name="button"
                    >
                      Sign up
                    </a>
                    <button
                      className="btn is-outlined"
                      name="button"
                      value="cancel"
                    >
                      Cancel
                    </button>
                    <input
                      name="__RequestVerificationToken"
                      type="hidden"
                      value="CfDJ8MOM6NDyzntClaifwWi9xj4wtR3ODsCUEKcbEKZjZ74UMBHocRFKLMiPI5kou_SqJMR1abv4nWhUTtX9RM1fj6aKQL4L0v8aWNjHmu6vegBhcQzAe_rzlTnPgIlHLXm0oCERMzI7lyOqKMSWF12t3Zw"
                    />
                    <input name="RememberLogin" type="hidden" value="false" />
                  </form>
                  <a href="ForgotPassword?returnUrl=%2Fconnect%2Fauthorize%2Fcallback%3Fclient_id%3Dmvc%26response_type%3Dcode%26scope%3Dopenid%2520profile%2520offline_access%26ui_locales%3Den%26acr_values%3DConsumers%26redirect_uri%3Dhttps%253A%252F%252Fedit.fritzhansen.com%252Fconsumerslogin%252FConsumersCallback">
                    Forgot password?
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="body-container">
        <div className="login-page">
          <div className="lead"></div>

          <div className="row">
            <div className="col-sm-6">
              <div className="card">
                <div className="card-header">
                  <h2>
                    <LogoSvg />
                  </h2>
                </div>

                <div className="card-body">
                  <form action="" method="post" className="form">
                    <input
                      type="hidden"
                      id="ReturnUrl"
                      name="ReturnUrl"
                      value="/connect/authorize/callback?client_id=mvc&amp;response_type=code&amp;scope=openid%20profile&amp;ui_locales=en&amp;acr_values=Consumers&amp;redirect_uri=https%3A%2F%2Ftest-edit.fritzhansen.com%2Flogin%2FConsumersCallback"
                    />

                    <div className="form-group">
                      <input
                        className="form-control"
                        placeholder="Email"
                        autofocus=""
                        type="text"
                        data-val="true"
                        data-val-required="The Username field is required."
                        id="Username"
                        name="Username"
                        // value=""
                      />
                      <label className="label" htmlFor="Username">
                        Email
                      </label>
                    </div>
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Password"
                        autoComplete="off"
                        data-val="true"
                        data-val-required="The Password field is required."
                        id="Password"
                        name="Password"
                      />

                      <label className="label" htmlFor="Password">
                        Password
                      </label>
                    </div>
                    <div className="form-group form-group--checkbox">
                      <div className="form-check">
                        <input
                          className="form-check-input "
                          type="checkbox"
                          data-val="true"
                          data-val-required="The RememberLogin field is required."
                          id="RememberLogin"
                          name="RememberLogin"
                          value="true"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="RememberLogin"
                        >
                          Remember username
                        </label>
                      </div>
                    </div>

                    <div className="buttons">
                      <button
                        className="btn is-inverted"
                        name="button"
                        value="login"
                      >
                        Login
                      </button>
                      <a
                        href="SignUp?returnUrl=%2Fconnect%2Fauthorize%2Fcallback%3Fclient_id%3Dmvc%26response_type%3Dcode%26scope%3Dopenid%2520profile%26ui_locales%3Den%26acr_values%3DConsumers%26redirect_uri%3Dhttps%253A%252F%252Ftest-edit.fritzhansen.com%252Flogin%252FConsumersCallback"
                        className="btn is-inverted"
                        name="button"
                      >
                        Sign up
                      </a>
                      <button
                        className="btn is-outlined"
                        name="button"
                        value="cancel"
                      >
                        Cancel
                      </button>
                    </div>
                    <input
                      name="__RequestVerificationToken"
                      type="hidden"
                      value="CfDJ8IY_nm9ru1lEl1p8OCuM14lk0uV0ydNmHnFfBR0y5Zg-ePiCDpRa8F6qBDlybQnGONr2bst2Sza_I4vX3Fs97Dg_fR3K3mb6a3HsyQl9WiXBw_7ZOofr1h_AGNCnBL46N73czGRU8EIbRk8gIv5Jduc"
                    />
                    <input name="RememberLogin" type="hidden" value="false" />
                  </form>
                  <a href="ForgotPassword?returnUrl=%2Fconnect%2Fauthorize%2Fcallback%3Fclient_id%3Dmvc%26response_type%3Dcode%26scope%3Dopenid%2520profile%26ui_locales%3Den%26acr_values%3DConsumers%26redirect_uri%3Dhttps%253A%252F%252Ftest-edit.fritzhansen.com%252Flogin%252FConsumersCallback">
                    Forgot password?
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="body-container">
        <div className="login-page">
          <div className="lead"></div>

          <div className="row">
            <div className="col-sm-6">
              <div className="card">
                <div className="card-header">
                  <h2>
                    <LogoSvg />
                  </h2>
                </div>

                <div className="card-body">
                  <form action="" method="post">
                    <input
                      type="hidden"
                      id="ReturnUrl"
                      name="ReturnUrl"
                      value="/connect/authorize/callback?client_id=mvc&amp;response_type=code&amp;scope=openid%20profile&amp;ui_locales=en&amp;acr_values=Consumers&amp;redirect_uri=https%3A%2F%2Ftest-edit.fritzhansen.com%2Flogin%2FConsumersCallback"
                    />

                    <div className="form-group">
                      <input
                        className="form-control"
                        placeholder="Email"
                        autoComplete="off"
                        // value=""
                        type="text"
                        data-val="true"
                        data-val-required="The Email field is required."
                        id="Email"
                        name="Email"
                      />
                      <label htmlFor="Email" className="label">
                        Email
                      </label>
                    </div>
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Password"
                        autoComplete="off"
                        data-val="true"
                        data-val-required="The Password field is required."
                        id="Password"
                        name="Password"
                      />
                      <label htmlFor="Password" className="label">
                        Password
                      </label>
                    </div>
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Confirm password"
                        autoComplete="off"
                        data-val="true"
                        data-val-required="The ConfirmPassword field is required."
                        id="ConfirmPassword"
                        name="ConfirmPassword"
                      />
                      <label htmlFor="ConfirmPassword" className="label">
                        Confirm password
                      </label>
                    </div>

                    <div className="buttons">
                      <button
                        className="btn is-inverted"
                        name="button"
                        value="signup"
                      >
                        Sign up
                      </button>

                      <button
                        className="btn is-outlined"
                        name="button"
                        value="cancelSignup"
                      >
                        Cancel
                      </button>
                    </div>

                    <input
                      name="__RequestVerificationToken"
                      type="hidden"
                      value="CfDJ8IY_nm9ru1lEl1p8OCuM14nqsG4rimAIJ9s8Cy9M4GmW4SZcgySqy8tHR4iZIrMy76oqhROd141MeVsYhP0ewB7wwncl0_mK8tktYy0KfnxLjyJOTw5R7_FWlVlBkOwO6Ik__gq0jt_jvNAc4enYKbE"
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginModule;
