import React from "react";
import MediaFocal from "../MediaFocal";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import classnames from "classnames";

import "./feature-full-width.scss";
const FeatureFullWidth = ({ fields }) => {
  const classList = classnames({
    "feature-full-width": true,
    "feature-full-width--only-media": !fields?.headline && !fields?.bodyText,
    "feature-full-width--inverted": fields?.isInverted?.value,
  });

  return (
    <>
      {fields && (
        <div
          style={
            fields.backgroundColorHex && {
              "--feature-full-width-bgc": fields.backgroundColorHex.value,
            }
          }
          className={classList}
        >
          {fields?.media?.fields && (
            <div className="feature-full-width__media">
              <MediaFocal fields={fields.media.fields} />
            </div>
          )}
          {fields.headline && (
            <div className="feature-full-width__headline">
              <Text tag="h2" field={fields.headline} />
            </div>
          )}
          {fields.bodyText && (
            <div className="feature-full-width__bodytext">
              <Text tag="p" field={fields.bodyText} />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default FeatureFullWidth;
