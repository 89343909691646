import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import MediaFocal from "../MediaFocal";
import NavButton from "../NavButton";
import RichTextModule from '../RichTextModule'
import classnames from "classnames";
import isTrue from "@assets/js/is-true";

import "./quote-module.scss";
const QuoteModule = ({ fields }) => {

  const classList = classnames({
    "quote-module": true,
    "quote-module--inverted": isTrue(fields?.isInverted),
    "quote-module--no-trumpet-and-name":
      !fields?.trumpet?.value && !fields?.quotedPerson?.value,
    "quote-module--has-bgcolor": fields?.backgroundColorHex?.value,
  });

  return (
    <>
      {fields && (
        <div
          className={classList}
          style={
            fields.backgroundColorHex && {
              "--quote-module-bgc": fields?.backgroundColorHex.value,
            }
          }
        >

          {fields.media?.fields && (
            <div className="quote-module__image">
              <MediaFocal fields={fields.media.fields} />
            </div>
          )}


          {(fields?.trumpet?.value || fields?.quotedPerson?.value) && (
            <div className="quote-module__tag-and-name">
              {fields?.trumpet && (
                <div className="quote-module__tag">
                  <Text tag="h6" field={fields.trumpet} />
                </div>
              )}
              {fields?.quotedPerson && (
                <div className="quote-module__name">
                  <Text tag="h4" field={fields.quotedPerson} />
                </div>
              )}
            </div>
          )}

          {fields?.quote && (
            <Text
              tag="h2"
              className="quote-module__quote"
              field={fields.quote}
            />
          )}

          <div className="quote-module__bodytext">
            <RichTextModule fields={fields} />
          </div>

          {fields?.button && (
            <div className="quote-module__button">
              <NavButton fields={fields.button.fields} />
            </div>
          )}

        </div>
      )}
    </>
  );
};

export default QuoteModule;
