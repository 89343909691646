import React from "react";
import { useTranslation } from "react-i18next";
import { Text } from "@sitecore-jss/sitecore-jss-react";

import uuid from "uuid";

import "./info-panel.scss";
const InfoPanel = ({ fields }) => {
  const { t } = useTranslation();

  const texts =
    fields?.texts?.length > 0
      ? fields.texts.map((item) => {
          return { title: item.title, content: item.content };
        })
      : [
          {
            title: { value: "Lorem" },
            content: { value: "Lorem ipsum\nDolor sit amet" },
          },
          {
            title: { value: "Ipsum" },
            content: {
              value: "Lorem ipsum\nDolor sit ametLorem ipsum\nDolor sit amet",
            },
          },
          {
            title: { value: "Dolor sit amet consectetur" },
            content: {
              value: "Lorem ipsum\nDolor sit ametLorem ipsum\nDolor sit amet",
            },
          },
          {
            title: { value: "Ipsum" },
            content: {
              value: "Lorem ipsum\nDolor sit ametLorem ipsum\nDolor sit amet",
            },
          },
          {
            title: { value: "Dolor sit amet consectetur" },
            content: { value: "Lorem ipsum" },
          },
        ];

  return (
    <div className="info-panel">
      {texts.map((item) => {
        return (
          <div className="info-panel__section" key={uuid()}>
            {item.title.value && <Text tag="h5" field={item.title.value} />}
            {item.title.translationKey && (
              <Text tag="h5" field={{ value: t(item.title.translationKey) }} />
            )}
            <Text tag="p" field={item.content} />
          </div>
        );
      })}
    </div>
  );
};
InfoPanel.propTypes = {};

export default InfoPanel;
