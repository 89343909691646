import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import "./content-block.scss";

/**
 * A simple Content Block component, with a heading and rich text block.
 * This is the most basic building block of a content site, and the most basic
 * JSS component that's useful.
 */
const ContentBlock = ({ fields }) => {
  return (
    <React.Fragment>
      <div className="content-block">
        <p>Generic content block</p>

        <h2 className="display-4">{fields.heading.value}</h2>
        <Text tag="h2" className="display-4" field={fields.heading} />
      </div>
    </React.Fragment>
  );
};

export default ContentBlock;
