import create from "zustand";
import { devtools } from "zustand/middleware";
import axios from "axios";

import globalErrorHandler from "@assets/js/global-error-handler";
import {
  STATUS,
  CONFIGURATOR_MODE,
  CONFIGURATOR_SELECTOR,
  CONFIGURATOR_TYPE,
  CONFIGURATOR_SKU,
  FILTER_NO_TYPE,
  QUERY_PARAMETER,
} from "@constants";
import urlParams from "@assets/js/url-params";
import { log } from "@assets/js/utils";
import globalStore from "@store/global";
import path from "@assets/js/browser-path";

const facetsStr =
  "materialset1id_s,materialset2id_s,materialset3id_s,materialset4id_s,materialset5id_s,material1id_s,material2id_s,material3id_s,material4id_s,material5id_s";

const configurationFilterUrl = (lang) =>
  path.join(
    process.env.REACT_APP_PRODUCTSAPI,
    "Configuration",
    lang,
    "search/ConfigurationsFilter"
  );
const configurationCousinsUrl = (lang) =>
  path.join(
    process.env.REACT_APP_PRODUCTSAPI,
    "Configuration",
    lang,
    "search/ConfigurationCousins"
  );
const materialsUrl = (lang) =>
  path.join(process.env.REACT_APP_PRODUCTSAPI, "Material", lang, "search");

const modelSpecsUrl = (modelTypeId, lang) =>
  path.join(process.env.REACT_APP_ASSETSAPI, "products", modelTypeId, lang);

const productImageUrl = (modelTypeId) =>
  path.join(
    process.env.REACT_APP_PRODUCT_IMAGE_API,
    "api",
    "product",
    modelTypeId
  );

const getFieldKey = (fields, prop) => {
  if (!fields) return null;
  return fields.find((f) => f.Id === prop)
    ? fields.find((f) => f.Id === prop).Key
    : "NONE";
};

const store = create(
  devtools(
    (set, get) => ({
      loadStatus: STATUS.LOADING_IDLE,

      resetPDPStore: () => {
        set({
          configurationType: { type: { value: null }, id: { value: null } },
          activeProductData: {},
          activeModelData: {},
          activeConfigurationSKU: "",
          fullFriendlySKU: "",
          basicConfiguration: {},
          pendingProductData: {},
          skuProductList: {},
          availableMaterialSetIds: [],
          availableMaterialIds: [],
          modelTypes: [],
          materialSets: [],
          allowedMaterialIds: [],
          activeMaterialIndex: -1,
          activeMaterialSetIndex: -1,
          activeMaterialSetMaterialIndex: -1,
          activeMaterialIsUnallowed: false,
          activeSelector: CONFIGURATOR_SELECTOR.NONE,
          configuratorMode: CONFIGURATOR_MODE.ADVANCED,
        });
      },

      //configuration type, either ModelType, HeroConfiguration or VariationGroup
      configurationType: { type: { value: null }, id: { value: null } },
      setConfigurationType: (type) => set({ configurationType: type }),

      //active sku, ex. 3107FRU-CA815-CA0974-DBPC
      activeConfigurationSKU: "",
      setActiveConfigurationSKU: (sku) => set({ activeConfigurationSKU: sku }),

      //with material set ids 3107-(M199)ASH001-(BAS3107)CST
      fullFriendlySKU: "",
      setFullFriendlySKU: (sku) => set({ fullFriendlySKU: sku }),

      //if Product Specifications collapsible should be open
      productSpecificationsOpen: false,
      setProductSpecificationsOpen: (sku) =>
        set({ productSpecificationsOpen: sku }),

      //active product data, info about dimensions, model and materials
      activeProductData: {},
      setActiveProductData: (data) => set({ activeProductData: data }),

      //active model data, resources, downloads
      activeModelData: {},
      setActiveModelData: (data) => set({ activeModelData: data }),

      //pending product data, info about dimensions, model and materials - used for product viewer
      pendingProductData: {},
      setPendingProductData: (data) => set({ pendingProductData: data }),

      //placeholder for all config data in first api call
      basicConfiguration: {},
      setBasicConfiguration: (config) => set({ basicConfiguration: config }),

      // ####################################################################################

      //list of sku products (only image, full friendly)
      skuProductList: {},
      setSkuProductList: (data) => set({ skuProductList: data }),

      // ####################################################################################

      //array of allowed material set ids - parsed from basic config
      availableMaterialSetIds: [],
      setAvailableMaterialSets: (sets) =>
        set({ availableMaterialSetIds: sets }),

      //array of available material ids - also parsed from basic config
      availableMaterialIds: [],
      setAvailableMaterialIds: (sets) => set({ availableMaterialIds: sets }),

      // ####################################################################################

      //array of model types for model selector - parsed from configuration cousins api call
      modelTypes: [],
      setModelTypes: (types) => set({ modelTypes: types }),

      //array of material sets types for material set and material selectors - parsed from material sets api call
      materialSets: [],
      setMaterialSets: (sets) => set({ materialSets: sets }),

      //array of allowed material ids -  parsed from custom config before calling material sets
      allowedMaterialIds: [],
      setAllowedMaterialIds: (sets) => set({ allowed: sets }),

      // ####################################################################################

      //active position in model types array
      activeModelTypeIndex: -1,
      setActiveModelTypeIndex: (index) => set({ activeModelTypeIndex: index }),

      // ####################################################################################

      //signifies which material number is manipulated, corresponds to number in materialSets_ss# in config model, 1-5

      activeMaterialIndex: -1,
      setActiveMaterialIndex: (index) => set({ activeMaterialIndex: index }),

      //active position in material sets array
      activeMaterialSetIndex: -1,
      setActiveMaterialSetIndex: (index) =>
        set({ activeMaterialSetIndex: index }),

      activeMaterialSetMaterialIndex: -1,
      setActiveMaterialSetMaterialIndex: (index) =>
        set({ activeMaterialSetMaterialIndex: index }),

      activeMaterialIsUnallowed: false,
      setActiveMaterialIsUnallowed: (isAllowed) =>
        set({ activeMaterialIsUnallowed: isAllowed }),

      // ####################################################################################

      //mode of operation for configurator, advanced is model + materials, simple is just a few material swatches
      configuratorMode: CONFIGURATOR_MODE.ADVANCED,
      setConfiguratorMode: (mode) => set({ configuratorMode: mode }),

      //which selector is shown currently
      activeSelector: CONFIGURATOR_SELECTOR.NONE,
      setActiveSelector: (selector) => set({ activeSelector: selector }),

      // ####################################################################################
      // ####################################################################################
      // ####################################################################################

      //product image data
      productImage: {},

      fetchProductImageData: async (typeId) => {
        set({ loadStatus: STATUS.LOADING });

        let modelTypeId = typeId
          ? typeId
          : get().activeProductData?.model?.typeId;

        if (!modelTypeId) {
          log("PDP | fetchProductImageData | NO CONFIGURATION ID");
        } else {
          const response = await axios.get(productImageUrl(modelTypeId));
          set({ productImage: response.data, loadStatus: STATUS.LOADING_IDLE });
        }
      },
      /**
       * fetches Basic configuration
       * @param {String} confType
       * @param {fn} errorHandler
       * @param {String} sc_lang
       * @param {String} currentMarket
       * @returns
       */
      fetchBasicConfiguration: async (
        confType,
        errorHandler,
        sc_lang,
        currentMarket
      ) => {
        set({ loadStatus: STATUS.LOADING });

        //else {
        //   // Use modelTypeId from path, ex .../series7/3107/3107FRU
        //   // get model type id from path

        //   // modelTypeId = window.location.split('/').pop();
        //   modelTypeId = "3107FU";
        // }

        // log('***', modelTypeId);

        // const sc_lang = globalStore.getState().sc_lang;
        // const market = globalStore.getState().currentMarket;

        let basicPostData = {
          market: currentMarket,
          facets: facetsStr,
        };

        let configurationPostData = {
          market: currentMarket,
          facets: facetsStr,
        };

        // const confType = get().configurationType;

        if (!confType?.type?.value) {
          console.error("NO CONFIGURATION TYPE!");
          return false;
        }

        /*
        TEST URLS:

        Variationsgruppe: http://localhost:3000/categories/by-series/series-7/3107_series7_front_upholstered_leather
        HeroConf: http://localhost:3000/categories/by-series/series-7/3107FRU-CA195-EXTRME-CST
        ModelType: http://localhost:3000/categories/by-series/series-7/3107CS
        ModelType: http://localhost:3000/categories/by-series/ant/3101
        */
        let extractedSKUParams;

        const configurationType = confType?.type?.value
          ? confType.type.value
          : CONFIGURATOR_TYPE.MODEL_TYPE;

        if (configurationType === CONFIGURATOR_TYPE.SKU) {
          log("CONFIGURATION TYPE: SKU");
          get().fetchSkuConfiguration(
            confType,
            errorHandler,
            sc_lang,
            currentMarket
          );
          return false;
        }

        log("CONFIGURATION TYPE: Not SKU, so what is it?");

        switch (configurationType) {
          case CONFIGURATOR_TYPE.MODEL_TYPE:
            log("CONFIGURATION TYPE: MODEL_TYPE");
            basicPostData.modelTypeId = confType.id.value;
            break;
          case CONFIGURATOR_TYPE.VARIATION_GROUP:
            log("CONFIGURATION TYPE: VARIATION_GROUP");
            basicPostData.variationGroupId = confType.id.value;
            break;
          case CONFIGURATOR_TYPE.HERO_CONFIGURATION:
            log("CONFIGURATION TYPE: HERO_CONFIGURATION");
            extractedSKUParams = urlParams.getSKU(confType.id.value);
            if (extractedSKUParams.modelTypeId != CONFIGURATOR_SKU.NONE) {
              basicPostData.modelTypeId = extractedSKUParams.modelTypeId;
            }
            break;
        }

        log({ basicPostData });

        let error;

        const basicResponse = await axios
          .post(configurationFilterUrl(sc_lang), basicPostData)
          .catch((err) => {
            error = err;
            log({ err });
          });

        log({ basicResponse });

        if (basicResponse?.data) {
          let fullConfigurationParams;
          const customUrlSKUParams = urlParams.getSKU();
          const basicResponseSKUParams = urlParams.getSKU(
            basicResponse?.data?.response?.docs[0]?.id
          );

          if (
            customUrlSKUParams &&
            customUrlSKUParams?.modelTypeId != CONFIGURATOR_SKU.NONE
          ) {
            //if there's a ?sku=xxx-xxx-xxx-xxx query, use that
            fullConfigurationParams = customUrlSKUParams;
          } else if (
            configurationType == CONFIGURATOR_TYPE.HERO_CONFIGURATION &&
            extractedSKUParams?.modelTypeId != CONFIGURATOR_SKU.NONE
          ) {
            //when using a hero configuration, use those params
            fullConfigurationParams = extractedSKUParams;
          } else {
            //otherwise use default configuration for model type
            fullConfigurationParams = basicResponseSKUParams;
          }

          log({ fullConfigurationParams });

          // log({ basicPostData });
          // log({ fullConfigurationParams });

          let configurationResponse = {};

          if (!fullConfigurationParams) {
            let text = "NO CONFIGURATION FOUND FOR:\n";
            if (basicPostData.modelTypeId)
              text += "– MODEL TYPE: " + basicPostData.modelTypeId;
            if (basicPostData.variationGroupId)
              text += "– VARIATION GROUP " + basicPostData.variationGroupId;
            text +=
              "\n– FACETS: '" +
              basicPostData.facets.split(",").join(", ") +
              "'";
              text +=
              "\n– MESSAGE: '" +
              basicResponse?.data?.response?.docs[0]?.modelTypeName_s +
              "'";
            errorHandler({
              text: text,
            });
          } else {
            if (configurationType == CONFIGURATOR_TYPE.VARIATION_GROUP) {
              configurationPostData.variationGroupId = confType.id.value;
            }

            for (const param of Object.entries(fullConfigurationParams)) {
              configurationPostData[param[0]] = param[1];
            }

            configurationResponse = await axios.post(
              configurationFilterUrl(sc_lang),
              configurationPostData
            );
            set(basicConfigurationProxy(basicResponse, configurationResponse));
          }
        } else {
          errorHandler(error);
        }
      },

      // ####################################################################################
      // SKU products
      // ####################################################################################

      /**
       * Fetch basic sku configuration
       * @param {String} confType
       * @param {fn} errorHandler
       * @param {String} sc_lang
       * @param {String} market
       * @returns
       */
      fetchSkuConfiguration: async (
        confType,
        errorHandler,
        sc_lang,
        currentMarket
      ) => {
        set({ loadStatus: STATUS.LOADING });

        if (!confType?.type?.value) {
          console.error("NO CONFIGURATION TYPE!");
          return false;
        }

        let basicPostData = {
          market: currentMarket,
          facets: facetsStr,
          modelTypeId: confType.id.value,
        };

        log({ basicPostData });

        const skuResponse = await axios
          .post(configurationFilterUrl(sc_lang), basicPostData)
          .catch((error) => {
            errorHandler(error);
          });

        log({ skuResponse });

        if (skuResponse?.data) {
          set(skuConfigurationProxy(skuResponse));
        } else {
          errorHandler("NO RESPONSE DATA FOR SKU");
        }
      },

      // ####################################################################################
      // Model data
      // ####################################################################################

      fetchActiveModelData: async (lang = "da") => {
        set({ loadStatus: STATUS.LOADING });

        let modelTypeId = get().activeProductData?.model?.typeId;

        if (!modelTypeId) return null;

        let fetchUrl = `${modelSpecsUrl(modelTypeId, lang)}.json`;

        const modelsResponse = await axios.get(fetchUrl);

        set(modelDataProxy(modelsResponse));
      },

      // ####################################################################################

      fetchModelTypes: async (modelGroupId) => {
        const sc_lang = globalStore.getState().sc_lang;
        const commerceMarket = globalStore.getState().commerceMarket;
        set({ loadStatus: STATUS.LOADING });
        const skuStr = get().activeConfigurationSKU;
        const postData = {
          configurationId: skuStr,
          modelGroupId: modelGroupId,
          rows: 1000,
          distance: 0,
          groupModelTypes: true,
          market: commerceMarket,
        };
        const modelsResponse = await axios.post(
          configurationCousinsUrl(sc_lang),
          postData
        );
        set(modelTypesDataProxy(modelsResponse, get().activeProductData));
      },

      // ####################################################################################

      /**
       *
       * @param {String} materialIndex
       * @param {String} modelTypeId
       * @param {String} materialsList
       * @param {String} sc_lang
       * @param {String} currentMarket
       */
      fetchMaterialSets: async (
        materialIndex,
        modelTypeId,
        materialSetGroupingEnabled,
        materialsList,
        sc_lang,
        currentMarket
      ) => {
        let customPostData = {
          facets: facetsStr,
          modelTypeId: modelTypeId,
          market: currentMarket,
        };
        materialsList.map((item, index) => {
          if (index != materialIndex) {
            customPostData[`material${index + 1}Id`] =
              item.activeMaterial.materialId;
          }
        });

        //get custom configuration with other materials
        const customConfigurationResponse = await axios.post(
          configurationFilterUrl(sc_lang),
          customPostData
        );

        // const facets = customConfigurationResponse.data.facets.facetFields;

        // let availableMaterialSetIds = Object.keys(
        //   facets["materialset" + (materialIndex + 1) + "id_s"]
        // );
        let materialsPostData = {
          materialSetIds: get().availableMaterialSetIds[materialIndex].join(
            " OR "
          ),
          pageSize: get().availableMaterialSetIds[materialIndex].length,
          materialSetGroupingIsEnabled: materialSetGroupingEnabled
        };

        const materialsResponse = await axios.post(
          materialsUrl(sc_lang),
          materialsPostData
        );
        set(
          materialSetsDataProxy(materialsResponse, customConfigurationResponse)
        );
      },

      // ####################################################################################
      /**
       *
       * @param {Object} params
       * @param {String} sc_lang
       * @param {Boolean} resetMaterials
       */
      fetchCustomConfiguration: async (params, sc_lang, resetMaterials) => {
        // const sc_lang = globalStore.getState().sc_lang;
        set({ loadStatus: STATUS.LOADING });

        let updatedPostData = {
          facets: facetsStr,
        };
        for (const param of Object.entries(params)) {
          updatedPostData[param[0]] = param[1];
        }
        const customResponse = await axios.post(
          configurationFilterUrl(sc_lang),
          updatedPostData
        );
        set(
          customConfigurationProxy(
            customResponse,
            resetMaterials,
            get().activeProductData
          )
        );
      },
    }),
    "PDP"
  )
);

export const useConfigurationType = () => [
  store((store) => store.configurationType),
  store((store) => store.setConfigurationType),
];

export const useConfiguratorMode = () => [
  store((store) => store.configuratorMode),
  store((store) => store.setConfiguratorMode),
];

export const useProductSpecificationsOpen = () => [
  store((store) => store.productSpecificationsOpen),
  store((store) => store.setProductSpecificationsOpen),
];

export const useActiveConfigurationSKU = () => [
  store((store) => store.activeConfigurationSKU),
  store((store) => store.setActiveConfigurationSKU),
];

export const useFullFriendlySKU = () => [
  store((store) => store.fullFriendlySKU),
  store((store) => store.setFullFriendlySKU),
];

export const useActiveProductData = () => [
  store((store) => store.activeProductData),
  store((store) => store.setActiveProductData),
];

export const useSkuProductList = () => [
  store((store) => store.skuProductList),
  store((store) => store.setSkuProductList),
  store((store) => store.fetchSkuConfiguration),
];

export const useActiveModelData = () => [
  store((store) => store.activeModelData),
  store((store) => store.setActiveModelData),
  store((store) => store.fetchActiveModelData),
];

export const usePendingProductData = () => [
  store((store) => store.pendingProductData),
  store((store) => store.setPendingProductData),
];

export const useBasicConfiguration = () => [
  store((store) => store.basicConfiguration),
  store((store) => store.setBasicConfiguration),
  store((store) => store.fetchBasicConfiguration),
];

export const useCustomConfiguration = () => [
  store((store) => store.customConfiguration),
  store((store) => store.setCustomConfiguration),
  store((store) => store.fetchCustomConfiguration),
];

export const useModelTypes = () => [
  store((store) => store.modelTypes),
  store((store) => store.setModelTypes),
  store((store) => store.fetchModelTypes),
];

export const useActiveModelTypeIndex = () => [
  store((store) => store.activeModelTypeIndex),
  store((store) => store.setActiveModelTypeIndex),
];

export const useMaterialSets = () => [
  store((store) => store.materialSets),
  store((store) => store.setMaterialSets),
  store((store) => store.fetchMaterialSets),
];

export const useAvailableMaterialIds = () => [
  store((store) => store.availableMaterialIds),
  store((store) => store.setAvailableMaterialIds),
];

export const useAllowedMaterialIds = () => [
  store((store) => store.allowedMaterialIds),
  store((store) => store.setAllowedMaterialIds),
];

export const useAvailableMaterialSetIds = () => [
  store((store) => store.availableMaterialSetIds),
  store((store) => store.setAvailableMaterialSetIds),
];

export const useAllowedMaterialSetIds = () => [
  store((store) => store.allowedMaterialSetIds),
  store((store) => store.setAllowedMaterialSetIds),
];

export const useActiveMaterialIndex = () => [
  store((store) => store.activeMaterialIndex),
  store((store) => store.setActiveMaterialIndex),
];

export const useActiveMaterialSetIndex = () => [
  store((store) => store.activeMaterialSetIndex),
  store((store) => store.setActiveMaterialSetIndex),
];

export const useActiveMaterialSetMaterialIndex = () => [
  store((store) => store.activeMaterialSetMaterialIndex),
  store((store) => store.setActiveMaterialSetMaterialIndex),
];

export const useActiveMaterialIsUnallowed = () => [
  store((store) => store.activeMaterialIsUnallowed),
  store((store) => store.setActiveMaterialIsUnallowed),
];

export const useActiveSelector = () => [
  store((store) => store.activeSelector),
  store((store) => store.setActiveSelector),
];

export default store;

// ####################################################################################
// ####################################################################################
// ####################################################################################

const basicConfigurationProxy = (
  basicConfigurationResponse,
  skuConfigurationResponse
) => {
  const content = skuConfigurationResponse.data.response.docs[0];

  let productData = {
    title: "NONE",
    description: "NONE",
  };
  let mode;

  if (!content || typeof content === "undefined") {
    mode = CONFIGURATOR_MODE.NONE;
  } else {
    productData = {
      title: content.modelGroupName_s,
      description: content.shortDescription_t,
      modelTypeDescription: content.ModelTypeDescription_s,
      shortDescription: content.ModelTypeShortDescription_s,
      longDescription: content.ModelTypeLongDescription_s,
      heroImages: [
        content.ModelTypeHeroZoomImage1_s,
        content.ModelTypeHeroZoomImage2_s,
        content.ModelTypeHeroZoomImage3_s,
        content.ModelTypeHeroZoomImage4_s,
      ],
    };

    productData = {
      ...productData,
      info: parseProductDataInfo(content),
    };

    //can not be configured
    mode = CONFIGURATOR_MODE.NONE;

    if (content.UseOnlySwatches_b === true) {
      //can only configure materials
      mode = CONFIGURATOR_MODE.BASIC;
    } else if (content.ModelTypeIsSku_b !== true) {
      //can configure materials and model
      mode = CONFIGURATOR_MODE.ADVANCED;
    }

    if (mode !== CONFIGURATOR_MODE.NONE) {
      productData = {
        ...productData,
        model: parseProductDataModel(content),
        materials: parseProductDataMaterials(content),
      };
    }
  }

  const facets = basicConfigurationResponse.data.facets.facetFields;

  const materialIds = parseMaterialIds(content, facets);
  const materialSetIds = parseMaterialSetIds(content, facets);

  let skuStr = CONFIGURATOR_SKU.NONE;

  if (productData && productData.model) skuStr = productData.model.typeId;
  if (
    productData &&
    productData.materials &&
    productData.materials.length > 0
  ) {
    for (let i = 0; i < productData.materials.length; i++) {
      skuStr += "-" + productData.materials[i].activeMaterial.materialId;
    }
  }

  // urlParams.set({ sku: skuStr });

  return {
    configuratorMode: mode,
    activeConfigurationSKU: skuStr,
    fullFriendlySKU: content.fullFriendlyItem_s,

    availableMaterialSetIds: materialSetIds,
    availableMaterialIds: materialIds,

    activeProductData: productData,
    pendingProductData: productData,

    loadStatus: STATUS.READY,
  };
};

// ####################################################################################

const skuConfigurationProxy = (skuResponse) => {
  const docs = skuResponse?.data?.response?.docs;

  const skuUrlParam = urlParams.get().get(QUERY_PARAMETER.SKU);
  log("SKU | skuUrlParam?", skuUrlParam);

  let skuStr = skuUrlParam ? skuUrlParam : null;

  let activeItem;

  let products = docs.map((item, index) => {
    let parsedItem = {
      title: item.ModelTypeFullName_s,
      description: item.shortDescription_t,
      modelTypeDescription: item.ModelTypeDescription_s,
      shortDescription: item.ModelTypeShortDescription_s,
      longDescription: item.ModelTypeLongDescription_s,
      info: parseProductDataInfo(item),
      imageUrl: item.imageUrl_s,
      swatchUrl: item.swatchImageUrl_s || item.imageUrl_s,
      fullFriendlyItem: item.fullFriendlyItem_s,
      displayName: item.displayName_t,
      model: parseProductDataModel(item),
      heroImages: [
        item.ModelTypeHeroZoomImage1_s,
        item.ModelTypeHeroZoomImage2_s,
        item.ModelTypeHeroZoomImage3_s,
        item.ModelTypeHeroZoomImage4_s,
      ],
    };
    if (parsedItem.fullFriendlyItem === skuStr) activeItem = parsedItem;

    return parsedItem;
  });

  if (!skuStr)
    skuStr = products.length > 0 ? products[0].fullFriendlyItem : null;

  return {
    configuratorMode: CONFIGURATOR_MODE.NONE,
    activeConfigurationSKU: skuStr,
    activeProductData: activeItem
      ? activeItem
      : products[0] || CONFIGURATOR_SKU.NONE,
    fullFriendlySKU: skuStr,
    skuProductList: products,
  };
};

// ####################################################################################

const customConfigurationProxy = (
  customConfigurationResponse,
  resetMaterials,
  activeProductData
) => {
  const content = customConfigurationResponse?.data?.response?.docs[0];

  if (!content || typeof content === "undefined") {
    globalErrorHandler("NO CONFIGURATION AVAILABLE FOR THIS MODEL", "PDP");

    return {
      pendingProductData: activeProductData,
      activeProductData: activeProductData,

      loadStatus: STATUS.READY,
    };
  } else {
    let productData = {
      title: "NONE",
      description: "NONE",
    };

    productData = {
      title: content.modelGroupName_s,
      description: content.shortDescription_t,
      modelTypeDescription: content.ModelTypeDescription_s,
      shortDescription: content.ModelTypeShortDescription_s,
      longDescription: content.ModelTypeLongDescription_s,
      info: parseProductDataInfo(content),
      model: parseProductDataModel(content),
      materials: parseProductDataMaterials(content),
      heroImages: [
        content.ModelTypeHeroZoomImage1_s,
        content.ModelTypeHeroZoomImage2_s,
        content.ModelTypeHeroZoomImage3_s,
        content.ModelTypeHeroZoomImage4_s,
      ],
    };

    let skuStr = "NOSKU";
    if (productData && productData.model) skuStr = productData.model.typeId;
    if (productData && productData.materials) {
      for (let i = 0; i < productData.materials.length; i++) {
        skuStr += "-" + productData.materials[i].activeMaterial.materialId;
      }
    }

    urlParams.set({ sku: skuStr });

    const facets = customConfigurationResponse?.data?.facets?.facetFields;
    if (resetMaterials && facets) {
      const materialIds = parseMaterialIds(content, facets);
      const materialSetIds = parseMaterialSetIds(content, facets);

      return {
        activeConfigurationSKU: skuStr,
        fullFriendlySKU: content.fullFriendlyItem_s,

        availableMaterialIds: materialIds,
        availableMaterialSetIds: materialSetIds,

        activeProductData: productData,
        pendingProductData: productData,

        loadStatus: STATUS.READY,
      };
    } else {
      return {
        activeConfigurationSKU: skuStr,
        fullFriendlySKU: content.fullFriendlyItem_s,

        activeProductData: productData,
        pendingProductData: productData,

        loadStatus: STATUS.READY,
      };
    }
  }
};

//

// ####################################################################################

const modelDataProxy = (modelResponse, productData) => {
  const data = modelResponse?.data;

  let modelData = {};
  if (data?.ParentModel && data.ParentModel?.Resources?.length) {
    modelData.resource = {
      url: data.ParentModel.Resources[0].Url,
      name: data.ParentModel.Resources[0].Name,
    };
  }
  if (data?.HtmlLinks && data.HtmlLinks?.length) {
    modelData.downloads = data.HtmlLinks.map((item) => {
      return {
        url: item.Url,
        name: item.DisplayName || item.Name,
      };
    });
  }
  modelData.availableMarkets = data?.OnlyAvailableInMarkets;
  modelData.specifications = data?.GroupedSpecifications;
  modelData.deliveryKey = `FritzHansen_Project_ConsumersAndProfessionals_PIM_Delivery_${getFieldKey(
    data?.Fields,
    "ModelTypeDelivery"
  ).trim()}`;
  modelData.productRelations = data?.ProductRelations;

  let pictogramResource = data?.Resources.find(
    (item) => item.SubType === "MEASUREMENTSPICTOGRAM"
  );
  if (pictogramResource?.Url)
    modelData.resourceImageUrl = pictogramResource.Url;

  return {
    activeModelData: modelData,
    loadStatus: STATUS.READY,
  };
};

// ####################################################################################

const modelTypesDataProxy = (modelsResponse, activeProductData) => {
  const models = modelsResponse?.data?.grouped?.modelTypeId_s?.groups;
  if (!models) return {};

  let modelTypesList = models.map((item) => {
    let data = item.doclist.docs[0];
    if ( data.configurationId_s ) {
      const materials = data.configurationId_s ? data.configurationId_s.split("-") : [];
      if (materials) {
        materials.shift();
      }
      return {
        isActive: false,
        typeId: data.modelTypeId_s,
        configurationId: data.configurationId_s, //for image-sources
        imageId: data.imageId_s, //for image-sources
        groupId: data.modelGroupId_s,
        label: data.modelTypeId_s ? data.modelTypeId_s : data.modelName_s,
        typeText: data.ModelTypeModelTypeDifferentiatorValue_s
        ? data.ModelTypeModelTypeDifferentiatorValue_s
        : "_NO_TYPE_",
        definitionName: data.ModelTypeModelDefinitionName_s
        ? data.ModelTypeModelDefinitionName_s
        : "_NO_NAME_",
        centerImage: true,
        materials: materials.map((materialId) => ({
          activeMaterial: { materialId },
        })),
      };
    } else { return false; }
  });

  //remove invalid items (like seat cushions etc.)
  modelTypesList = modelTypesList.filter(el=>el.configurationId)

  modelTypesList = modelTypesList.sort((a, b) =>
    a.typeId < b.typeId ? -1 : 1
  );

  return {
    modelTypes: modelTypesList,
    loadStatus: STATUS.READY,
  };
};

// ####################################################################################

const materialSetsDataProxy = (materialsResponse, configResponse) => {
  const items = materialsResponse.data.response.docs;

  let materialSetsList = items.map((item) => {
    if (!item.materials_ss) return;
    return {
      isActive: false,
      materialSetId: item.materialSetId_s,
      materialSetType: item.MaterialSetType_s || FILTER_NO_TYPE,
      materialSetTypeNew: item.MaterialSetTypeNew_s || FILTER_NO_TYPE,
      label:
        item.MaterialSetDisplayName_s ||
        item.MaterialSetDescription_s ||
        item.MaterialSetName_s ||
        null,
      label2: item.MaterialSetType_s || FILTER_NO_TYPE,
      label3: item.MaterialSetTypeNew_s || FILTER_NO_TYPE,
      description: item.MaterialSetDescription_s,
      isPrimary: item.MaterialSetIsPrimary_b === true,
      urlSlug:
        !!item.MaterialSetIsPrimary_b &&
        item.taxonomies_ss?.length &&
        item.urlSlug_s
          ? item.urlSlug_s
          : null,
      materials: item.materials_ss.map((material) => {
        return {
          materialId: material.split("|")[1],
          materialLabel: material.split("|")[0],
          materialDisplayName: "(10030)", // missing
          materialSetId: item.materialSetId_s,
          materialSetLabel: item.materialSetName_t,
          centerImage: false,
        };
      }),
    };
  });

  // undefined MaterialSetType_s are removed
  // const filteredMaterialSetsList = materialSetsList.filter((item) => item);

  const facets = configResponse?.data?.facets?.facetFields;
  const content = configResponse?.data?.response?.docs[0];

  if (content && facets) {
    const materialIds = parseMaterialIds(content, facets);
    const materialSetIds = parseMaterialSetIds(content, facets);

    let list = materialSetsList.filter((item) => item);
    return {
      materialSets: list,
      allowedMaterialIds: materialIds,
      allowedMaterialSetIds: materialSetIds,
      loadStatus: STATUS.READY,
    };
  } else {
    globalErrorHandler("NO MATERIAL SETS FOR THIS MODEL", "PDP");

    return {
      loadStatus: STATUS.READY,
    };
  }
};

// ####################################################################################

const parseMaterialSetIds = (content, facets) => {
  let materialSetIds = [];
  if (content?.materials_ss) {
    content.materials_ss.map((item, index) => {
      let facetMaterialSetIds = facets["materialset" + (index + 1) + "id_s"]
        ? facets["materialset" + (index + 1) + "id_s"]
        : [];
      materialSetIds.push(Object.keys(facetMaterialSetIds));
    });
  }
  return materialSetIds;
};

const parseMaterialIds = (content, facets) => {
  let materialIds = [];
  if (content?.materials_ss) {
    content.materials_ss.map((item, index) => {
      let facetMaterialSetIds = facets["material" + (index + 1) + "id_s"]
        ? facets["material" + (index + 1) + "id_s"]
        : [];
      materialIds.push(Object.keys(facetMaterialSetIds));
    });
  }
  return materialIds;
};
const parseProductDataModel = (content) => {
  let obj = {
    typeId: content.modelTypeId_s,
    groupId: content.modelGroupId_s,
    configurationId: content.friendlyItem_s,
    label: content.ModelTypeModelDefinitionName_s,
  };

  return obj;
};

const parseProductDataMaterials = (content) => {
  let obj = content.materials_ss.map((item, index) => {
    let materialParts = item.split("|");
    let materialSetParts = content?.materialSetDisplayValues_ss[index].split(
      "|"
    );
    return {
      activeMaterial: {
        materialId: materialParts[1],
        materialLabel: materialParts[0],
        materialSetId: materialSetParts[1],
        materialSetLabel: materialSetParts[0],
        imageUrl: `https://via.placeholder.com/300/939db1/000000?text=${materialParts[1]}`,
      },
    };
  });

  return obj;
};

const parseProductDataInfo = (content) => {
  // let general = content.

  let obj = {
    stackable: content.ChairStackableBoolean_s === "yes",
    stackSize: content.ChairStack_s ? content.ChairStack_s : 0,
    materialSetGroupingEnabled: content.ModelTypeGroupping_b ? content.ModelTypeGroupping_b : false,
    dimensions: {
      imperial: {
        baseWidth: content.BaseWidthInch_d,
        baseDepth: content.BaseDepthInch_d,
        baseHeight: content.BaseHeightInch_d,
        baseWeight: content.BaseWeightLb_d,
      },
      metric: {
        baseWidth: content.BaseWidth_d,
        baseHeight: content.BaseHeight_d,
        baseDepth: content.BaseDepth_d,
        baseWeight: content.BaseWeight_d,
      },
    },
  };

  return obj;
};
