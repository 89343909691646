import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import path from "@assets/js/browser-path";
import axios from "axios";
import ProductTilesSlider from "../ProductTilesSlider";

import { useActiveConfigurationSKU, useActiveModelData } from "@store/pdp";
import useGlobalStore from "@store/global";

const fetchProducts = (body, lang) =>
  axios.post(
    path.join(
      process.env.REACT_APP_PRODUCTSAPI,
      "Configuration",
      lang,
      "search/ConfigurationRelations"
    ),
    body
  );

const PDPRelatedProducts = ({ fields }) => {
  const relationType = fields?.relationType?.value ? fields.relationType.value : null;
  const sc_lang = useGlobalStore((state) => state.sc_lang);
  const headline = fields?.headline ? fields.headline : { value: "Test headline" };

  const [activeConfigurationSKU, ,] = useActiveConfigurationSKU();
  const [activeModelData] = useActiveModelData();

  const [tiles, setTiles] = useState([]);

  const getModelTypeIds = () => {
    let items = activeModelData?.productRelations?.filter(
      (item) => item?.RelationType === relationType
    );
    let modelTypeIds = items?.map((item) => item.TargetId);

    return modelTypeIds;
  };

  useEffect(() => {
    let modelTypeIds = getModelTypeIds();

    async function loadProducts() {
      const response = await fetchProducts(
        {
          configurationId: activeConfigurationSKU,
          modelTypeIds: modelTypeIds,
        },
        sc_lang
      );
      let items = response?.data?.grouped?.modelTypeId_s?.groups.map(
        (item) => item?.doclist?.docs[0]
      );
      setTiles(items);
    }

    if (
      sc_lang &&
      activeConfigurationSKU &&
      activeModelData?.productRelations &&
      tiles.length == 0 &&
      modelTypeIds.length > 0
    )
      loadProducts();
  }, [sc_lang, activeConfigurationSKU, activeModelData]);

  return <>{!!activeModelData?.productRelations?.length && <ProductTilesSlider tiles={tiles} headline={headline} />}</>;
};

export default PDPRelatedProducts;
