import MediaFocal from "./media-focal";
import NavButton from "./nav-button";

export default {
  default: {
    fields: {
      media1: MediaFocal.default,
      headline1: "Craftmanship",
      bodyText1:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi consequuntur quos minus asperiores error, eveniet facere. Ut ab debitis quisquam molestias ad impedit eveniet rerum, culpa commodi. Dolorem, nihil maiores.",
      isButtonEnabled1: true,
      button1: NavButton.link,
      phone1: "",
      email1: "",

      media2: MediaFocal.default,
      headline2: "Sustainability",
      bodyText2:
        "lorem ipsum bodytext, lorem ipsum bodytext, lorem ipsum bodytext",
      isButtonEnabled2: true,
      button2: NavButton.link,
      phone2: "45 45 45 45",
      email2: "",

      isInverted: false,
      backgroundColorHex: null,
    },
  },
  customBg: {
    fields: {
      media1: MediaFocal.default,
      headline1: "Craftmanship",
      bodyText1:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi consequuntur quos minus asperiores error, eveniet facere. Ut ab debitis quisquam molestias ad impedit eveniet rerum, culpa commodi. Dolorem, nihil maiores.",
      isButtonEnabled1: true,
      button1: NavButton.link,
      phone1: "",
      email1: "lksdfj@lkjdsf.com",

      media2: MediaFocal.default,
      headline2: "Sustainability",
      bodyText2:
        "lorem ipsum bodytext, lorem ipsum bodytext, lorem ipsum bodytext",
      isButtonEnabled2: true,
      button2: NavButton.linkInverted,
      phone2: "",
      email2: "",

      isInverted: true,
      backgroundColorHex: "#66bb99",
    },
  },
};
