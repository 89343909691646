import React from "react";
import MediaFocal from "../MediaFocal";
import "./product-series-tile.scss";

const ProductSeriesTile = ({ fields }) => {
  return (
    <div className="product-series-tile">
      <a href={fields.href}>
        <MediaFocal className={"product-series-tile__img"} fields={fields.media?.field?.fields} />
      </a>

      <div className="product-series-tile__text">
        <a href={fields.href}>
          <h3>{fields.title?.field?.value}</h3>
        </a>
      </div>
    </div>
  );
};

export default ProductSeriesTile;
