import React, { Fragment } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import MediaFocal from "../MediaFocal";
import classnames from "classnames";
import { motion } from "framer-motion";
import {
  revealFromTop,
  defaultOpacity,
  revealFromRight,
  defaultReveal,
} from "@assets/js/animation-variations";

import "./list-hero.scss";

const ListHero = ({ fields, rendering }) => {
  const LAYOUTTYPES = {
    SPLIT: "Split",
    TALL: "TextOverlapping",
    TEXTTOP: "TextTop",
  };
  const { uid } = rendering;

  const data = fields?.data?.fields;
  const layout = data?.layout?.fields?.value;

  const classList = classnames({
    "list-hero": true,
    "list-hero--tall": layout === LAYOUTTYPES.TALL,
    "list-hero--split": layout === LAYOUTTYPES.SPLIT,
    "list-hero--text-top": layout === LAYOUTTYPES.TEXTTOP,
  });

  return (
    <>
      {data && (
        <div className={classList} key={uid}>
          <div className="list-hero__content">
            {data?.headline?.field?.value && (
              <motion.div
                className="list-hero__headline"
                initial="initial"
                exit="exit"
                animate="enter"
                variants={defaultReveal}
                transition={{ ...defaultReveal.transition, delay: 0.8 }}
              >
                <Text tag="h1" field={data.headline.field} />
              </motion.div>
            )}
            {data?.bodyText?.field?.value && (
              <motion.div
                className="list-hero__text"
                initial="initial"
                exit="exit"
                animate="enter"
                variants={revealFromRight}
                transition={{ ...revealFromRight.transition, delay: 1.1 }}
              >
                <Text tag="p" field={data.bodyText.field} />
              </motion.div>
            )}

            {data.media?.field?.fields && (
              <motion.div
                className="list-hero__image"
                variants={revealFromTop}
                initial="initial"
                exit="exit"
                animate="enter"
                transition={{ ...revealFromTop.transition, delay: 1.5 }}
              >
                <MediaFocal fields={data.media.field.fields} />
              </motion.div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ListHero;
