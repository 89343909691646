import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import "./pdp-product-thumbs.scss";

import {
  useActiveConfigurationSKU,
  useConfiguratorMode,
  useActiveProductData,
} from "@store/pdp";
import getUniqueId from "@assets/js/get-unique-id";
import ProductImage from "../../ProductImage";
import { IMAGE, CONFIGURATOR_MODE } from "@constants";

const PDPProductThumbs = (props) => {

  const { t } = useTranslation();

  const texts = {
    priceDisclaimer: t(
      "FritzHansen_Project_ConsumersAndProfessionals_PDP_PriceDisclaimer"
    ),
  };


  const [configuratorMode] = useConfiguratorMode();
  const [activeProductData] = useActiveProductData();
  const [productImages, setProductImages] = useState([]);

  const [activeConfigurationSKU] = useActiveConfigurationSKU();

  useEffect(() => {
    if (activeProductData.heroImages) {
      setProductImages(activeProductData.heroImages);
    }
  }, [activeConfigurationSKU, activeProductData]);

  return (
    <div className="pdp-product-thumbs">

      <p className="pdp-product-thumbs__note d-n-vp5">
        {texts.priceDisclaimer}
      </p>

      <div className="pdp-product-thumbs__inner">
        {activeConfigurationSKU && (
          <>
            {configuratorMode !== CONFIGURATOR_MODE.NONE &&
              productImages.map((item) => {
                return (
                  <div
                    key={getUniqueId()}
                    className="pdp-product-thumbs__image"
                  >
                    <ProductImage
                      imageId={activeConfigurationSKU}
                      unsafeQueryString={item}
                      alt="zoom product image"
                      type={IMAGE.THUMB}
                    />
                  </div>
                );
              })}
            {configuratorMode === CONFIGURATOR_MODE.NONE &&
              productImages.map((item) => {
                return (
                  <Fragment key={getUniqueId()}>
                    {item && item.indexOf("?") !== 0 && (
                      <div
                        
                        className="pdp-product-thumbs__image"
                      >
                        <figure className="product-image">
                          <img
                            className="product-image__image"
                            src={item}
                            alt="zoom"
                          />
                        </figure>
                      </div>
                    )}
                  </Fragment>
                );
              })}
          </>
        )}
      </div>
    </div>
  );
};
PDPProductThumbs.propTypes = {
  images: PropTypes.array,
};

export default PDPProductThumbs;
