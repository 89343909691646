import React from "react";
import "./icons.scss";

export default ({ className, isInverted }) => (
  <svg
    className={`svg-icon svg-icon--arrow-medium ${className || ""} ${
      isInverted ? "is-inverted" : ""
    }`}
    width="20"
    height="19"
    viewBox="0 0 20 19"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M20 9.26L10.74 0L10.03 0.71L18.32 9H0V10H18.32L10.02 18.29L10.73 19L20 9.73V9.26Z" />
  </svg>
);
