import React from "react";
import "./icons.scss";
export default ({ className, isInverted }) => (
  <svg
    className={`svg-icon svg-icon--times ${className || ""} ${isInverted ? "is-inverted" : ""}`}
    width="36"
    height="36"
    viewBox="0 0 36 36"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M26.609 8.707L8.707 26.609M8.708 9.194L26.61 27.096" />
  </svg>
);
