import React, { useEffect, useState, Fragment } from "react";
import LazyLoad from "react-lazyload";
import classnames from "classnames";
import { LazyLoadImage } from "react-lazy-load-image-component";
import PropTypes from "prop-types";
import useGlobalStore from "@store/global";
import { IMAGE } from "@constants";
import "./product-image.scss";

const imgFallback =
  "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCA0MDAgNDAwIj48cGF0aCBmaWxsPSIjOTk5IiBkPSJNMTg1LjczIDE4OS43NWgyNy43N3YyMi43NWgtMjcuNzd2LTIyLjc1em0xIDF2MjAuNzVoMjUuNzd2LTIwLjc1aC0yNS43N3oiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZmlsbC1ydWxlPSJldmVub2RkIi8+PHBhdGggZmlsbD0iIzk5OSIgZD0iTTE5NC42MTQgMTk3LjkyN2w3LjYwMSA3LjYgMTAuNTQ4LTUuNjc5LjQ3NC44OC0xMS4yMDMgNi4wMzMtNy40NTYtNy40NTYtOC4wMTMgNy4yMTEtLjY2OS0uNzQzIDguNzE4LTcuODQ2eiIgY2xpcC1ydWxlPSJldmVub2RkIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48cGF0aCBmaWxsPSIjOTk5IiBkPSJNMjAyLjk2MSAxOTkuMjg5YTIuMzQ2IDIuMzQ2IDAgMTAwLTQuNjkyIDIuMzQ2IDIuMzQ2IDAgMDAwIDQuNjkyem0wIDFhMy4zNDYgMy4zNDYgMCAxMDAtNi42OTIgMy4zNDYgMy4zNDYgMCAwMDAgNi42OTJ6IiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==";

const imgFallbackLarge =
  "data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyOSAyNCI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0uNzMuNzVIMjguNVYyMy41SC43M1YuNzV6bTEgMVYyMi41SDI3LjVWMS43NUgxLjczeiIgZmlsbD0iIzk5OSIvPjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNOS42MTQgOC45MjdsNy42MDEgNy42IDEwLjU0OC01LjY3OS40NzQuODgtMTEuMjAzIDYuMDMzLTcuNDU2LTcuNDU2LTguMDEzIDcuMjExLS42NjktLjc0MyA4LjcxOC03Ljg0NnoiIGZpbGw9IiM5OTkiLz48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTE3Ljk2MSAxMC4yODlhMi4zNDYgMi4zNDYgMCAxMDAtNC42OTIgMi4zNDYgMi4zNDYgMCAwMDAgNC42OTJ6bTAgMWEzLjM0NiAzLjM0NiAwIDEwMC02LjY5MiAzLjM0NiAzLjM0NiAwIDAwMCA2LjY5MnoiIGZpbGw9IiM5OTkiLz48L3N2Zz4=";

export const renderedImagePath = ({
  imageId,
  type = IMAGE.PRODUCT,
  sw,
  cw,
  ch,
  cx,
  cy,
  rotation = 0,
  tileX,
  tileY,
  trim = false,
  unsafeQueryString = null,
  backgroundColor,
}) => {
  const webPSupport = useGlobalStore.getState().webPSupport;
  const params = new URLSearchParams();
  let img;
  const format = webPSupport ? "webp" : "jpg";
  switch (type) {
    case IMAGE.PRODUCT:
      img = `${process.env.REACT_APP_PRODUCT_IMAGE_API}r/${imageId}-r${rotation}.${format}`;
      break;
    case IMAGE.THUMB:
      img = `${process.env.REACT_APP_PRODUCT_IMAGE_API}r/${imageId}-r${rotation}.${format}`;
      break;
    case IMAGE.SWATCH:
      img = `${process.env.REACT_APP_PRODUCT_IMAGE_API}r/SWATCH-${imageId}.${format}`;
  }
  params.append("sw", sw);
  if (cy) {
    params.append("cy", cy);
  }
  if (cx) {
    params.append("cx", cx);
  }
  if (cw) {
    params.append("cw", cw);
  }
  if (ch) {
    params.append("ch", ch);
  }
  if (tileX) {
    params.append("tilex", tileX);
  }
  if (tileY) {
    params.append("tiley", tileY);
  }
  if (trim) {
    params.append("trim", true);
  }
  if (type === IMAGE.PRODUCT) {
    params.append("trim", true);
    params.append("bgcolor", backgroundColor);
  }
  if (type === IMAGE.THUMB) {
    params.append("size", 4096);
  }

  return `${img}?${params.toString()}${
    unsafeQueryString
      ? "&" + new URLSearchParams(unsafeQueryString).toString()
      : ""
  }`;
};

const ProductImage = ({
  imageId,
  sw = 500,
  rotation = 2,
  alt,
  type = IMAGE.PRODUCT,
  tileX = null,
  tileY = null,
  ch,
  cw,
  cx,
  cy,
  unsafeQueryString = null,
  trim = false,
  backgroundColor = "efeeeb",
  onErrorHandler,
  onSuccessHandler,
  useLargeFallback = false,
}) => {
  const webPSupport = useGlobalStore((state) => state.webPSupport);
  const format = webPSupport ? "webp" : "jpg";
  // imageId = "VM114-WAL001-CST";
  // imageId = "3107FU-DIM120-DRNIGR";
  // imageId = "Sofa-Fabric_Grey-Fabric_Pattern-{{Seat_Pillows}}-{{Side_Pillows}}-{{Legs}}-r{{angle}}.{{format}}"

  const [imageLoaded, setImageLoaded] = useState(false);
  const [loadingError, setLoadingError] = useState(false);
  const [imgUrl, setImgUrl] = useState();

  const onError = (e) => {
    setImgUrl(useLargeFallback ? imgFallbackLarge : imgFallback);
    setLoadingError(true);
    if (onErrorHandler) onErrorHandler();
  };
  const loadedHandler = () => {
    setImageLoaded(true);
    if (onSuccessHandler) onSuccessHandler();
  };
  const css = classnames({
    "product-image": true,
    "product-image--reveal": imageLoaded,
    "product-image--error": loadingError,
  });

  useEffect(() => {
    setImgUrl(
      renderedImagePath({
        type,
        imageId,
        tileX,
        tileY,
        rotation,
        sw,
        format,
        cw,
        ch,
        cx,
        cy,
        unsafeQueryString,
        backgroundColor,
      })
    );
  }, [imageId]);

  return (
    <figure className={css}>
      <LazyLoadImage
        className="product-image__image"
        onError={onError}
        alt={alt}
        src={imgUrl} // use normal <img> attributes as props
        effect="opacity"
        afterLoad={loadedHandler}
      />
    </figure>
  );
};
ProductImage.propTypes = {
  imageId: PropTypes.string.isRequired,
  sw: PropTypes.string,
  rotation: PropTypes.number,
  alt: PropTypes.string,
  trim: PropTypes.bool,
};

export default ProductImage;
