import React from "react";
import { useTranslation } from "react-i18next";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import MiniBasketController from "../checkout/MiniBasketController";

import './basket.scss';

const Basket = () => {
  const { t } = useTranslation();

  const texts = {
    shoppingCart: t("FritzHansen_Project_ConsumersAndProfessionals_Cart_ShoppingCart"),
  };

  return (
    <div className="basket">
      <MiniBasketController noFooter={true}/>
    </div>
  );
}

export default Basket;
