export default {
  manual: {
    fields: {
      headline: { value: "Nav slider manual" },
      tiles: [
        {
          id: "5c6b4bc5-1f3c-423d-83a8-2a7d43dcc39e",
          fields: {
            phone: { value: "1234" },
            email: { value: "navtile1@email.com" },
            hideButtonAndContactSection: { value: false },
            media: {
              id: "5f056b5b-6d79-43ab-8678-05703230d72b",
              url:
                "/components/98/Page-Components/NavTilesCarousel1/NavTile1/Media",
              fields: {
                image: {
                  value: {
                    src: "https://picsum.photos/id/192/400/600",
                    alt: "Media001",
                    width: "311",
                    height: "400",
                    focalpoint: "21, 63",
                  },
                },
                overlayStrength: { value: "" },
                video: { value: { href: "" } },
              },
            },
            isButtonEnabled: { value: true },
            headline: { value: "headline1" },
            bodyText: { value: "bodyText1" },
            button: {
              id: "101f714a-1b6f-4955-ad6b-7784eaf3b718",
              url:
                "/components/98/Page-Components/NavTilesCarousel1/NavTile1/Button",
              fields: {
                style: { value: "link" },
                hasArrow: { value: true },
                isEnabled: { value: false },
                link: {
                  value: {
                    href: "/en/",
                    text: "Home",
                    anchor: "",
                    linktype: "internal",
                    class: "",
                    title: "",
                    querystring: "",
                    id: "{A7CB067A-8857-5945-8BC8-B82014AE7BAA}",
                  },
                },
                isInverted: { value: false },
              },
            },
          },
        },
        {
          id: "0707091b-0361-4d12-ab0c-2fd322a97e86",
          fields: {
            phone: { value: "1234" },
            email: { value: "navtile2@email.com" },
            hideButtonAndContactSection: { value: false },
            media: {
              id: "7f1ec962-80fd-47d6-9b34-e27e14b7e572",
              url:
                "/components/98/Page-Components/NavTilesCarousel1/NavTile2/Media",
              fields: {
                image: {
                  value: {
                    src: "https://picsum.photos/id/192/400/600",
                    alt: "Media001",
                    width: "311",
                    height: "400",
                    focalpoint: "21, 63",
                  },
                },
                overlayStrength: { value: "" },
                video: { value: { href: "" } },
              },
            },
            isButtonEnabled: { value: true },
            headline: { value: "headline2" },
            bodyText: { value: "bodyText2" },
            button: {
              id: "49217a11-b1a8-41e6-b4ef-a3e9d6431894",
              url:
                "/components/98/Page-Components/NavTilesCarousel1/NavTile2/Button",
              fields: {
                style: { value: "link" },
                hasArrow: { value: true },
                isEnabled: { value: false },
                link: {
                  value: {
                    href: "/en/",
                    text: "Home",
                    anchor: "",
                    linktype: "internal",
                    class: "",
                    title: "",
                    querystring: "",
                    id: "{A7CB067A-8857-5945-8BC8-B82014AE7BAA}",
                  },
                },
                isInverted: { value: false },
              },
            },
          },
        },
        {
          id: "1fef96db-44ae-4256-af5f-737de35b67a2",
          fields: {
            phone: { value: "1234" },
            email: { value: "navtile3@email.com" },
            hideButtonAndContactSection: { value: false },
            media: {
              id: "b6d5d18e-cfcd-45df-8cc8-91e951c6082a",
              url:
                "/components/98/Page-Components/NavTilesCarousel1/NavTile3/Media",
              fields: {
                image: {
                  value: {
                    src: "https://picsum.photos/id/192/400/600",
                    alt: "Media001",
                    width: "311",
                    height: "400",
                    focalpoint: "21, 63",
                  },
                },
                overlayStrength: { value: "" },
                video: { value: { href: "" } },
              },
            },
            isButtonEnabled: { value: true },
            headline: { value: "headline3" },
            bodyText: { value: "bodyText3" },
            button: {
              id: "8a47a599-8b4d-4d80-99d2-b87327dd77db",
              url:
                "/components/98/Page-Components/NavTilesCarousel1/NavTile3/Button",
              fields: {
                style: { value: "link" },
                hasArrow: { value: true },
                isEnabled: { value: false },
                link: {
                  value: {
                    href: "/en/",
                    text: "Home",
                    anchor: "",
                    linktype: "internal",
                    class: "",
                    title: "",
                    querystring: "",
                    id: "{A7CB067A-8857-5945-8BC8-B82014AE7BAA}",
                  },
                },
                isInverted: { value: false },
              },
            },
          },
        },
        {
          id: "5c6b4bc5-1f3c-423d-83a8-2a7d43dcc39e",
          fields: {
            phone: { value: "1234" },
            email: { value: "navtile1@email.com" },
            hideButtonAndContactSection: { value: false },
            media: {
              id: "5f056b5b-6d79-43ab-8678-05703230d72b",
              url:
                "/components/98/Page-Components/NavTilesCarousel1/NavTile1/Media",
              fields: {
                image: {
                  value: {
                    src: "https://picsum.photos/id/192/400/600",
                    alt: "Media001",
                    width: "311",
                    height: "400",
                    focalpoint: "21, 63",
                  },
                },
                overlayStrength: { value: "" },
                video: { value: { href: "" } },
              },
            },
            isButtonEnabled: { value: true },
            headline: { value: "headline1" },
            bodyText: { value: "bodyText1" },
            button: {
              id: "101f714a-1b6f-4955-ad6b-7784eaf3b718",
              url:
                "/components/98/Page-Components/NavTilesCarousel1/NavTile1/Button",
              fields: {
                style: { value: "link" },
                hasArrow: { value: true },
                isEnabled: { value: false },
                link: {
                  value: {
                    href: "/en/",
                    text: "Home",
                    anchor: "",
                    linktype: "internal",
                    class: "",
                    title: "",
                    querystring: "",
                    id: "{A7CB067A-8857-5945-8BC8-B82014AE7BAA}",
                  },
                },
                isInverted: { value: false },
              },
            },
          },
        },
        {
          id: "0707091b-0361-4d12-ab0c-2fd322a97e86",
          fields: {
            phone: { value: "1234" },
            email: { value: "navtile2@email.com" },
            hideButtonAndContactSection: { value: false },
            media: {
              id: "7f1ec962-80fd-47d6-9b34-e27e14b7e572",
              url:
                "/components/98/Page-Components/NavTilesCarousel1/NavTile2/Media",
              fields: {
                image: {
                  value: {
                    src: "https://picsum.photos/id/192/400/600",
                    alt: "Media001",
                    width: "311",
                    height: "400",
                    focalpoint: "21, 63",
                  },
                },
                overlayStrength: { value: "" },
                video: { value: { href: "" } },
              },
            },
            isButtonEnabled: { value: true },
            headline: { value: "headline2" },
            bodyText: { value: "bodyText2" },
            button: {
              id: "49217a11-b1a8-41e6-b4ef-a3e9d6431894",
              url:
                "/components/98/Page-Components/NavTilesCarousel1/NavTile2/Button",
              fields: {
                style: { value: "link" },
                hasArrow: { value: true },
                isEnabled: { value: false },
                link: {
                  value: {
                    href: "/en/",
                    text: "Home",
                    anchor: "",
                    linktype: "internal",
                    class: "",
                    title: "",
                    querystring: "",
                    id: "{A7CB067A-8857-5945-8BC8-B82014AE7BAA}",
                  },
                },
                isInverted: { value: false },
              },
            },
          },
        },
        {
          id: "1fef96db-44ae-4256-af5f-737de35b67a2",
          fields: {
            phone: { value: "1234" },
            email: { value: "navtile3@email.com" },
            hideButtonAndContactSection: { value: false },
            media: {
              id: "b6d5d18e-cfcd-45df-8cc8-91e951c6082a",
              url:
                "/components/98/Page-Components/NavTilesCarousel1/NavTile3/Media",
              fields: {
                image: {
                  value: {
                    src: "https://picsum.photos/id/192/400/600",
                    alt: "Media001",
                    width: "311",
                    height: "400",
                    focalpoint: "21, 63",
                  },
                },
                overlayStrength: { value: "" },
                video: { value: { href: "" } },
              },
            },
            isButtonEnabled: { value: true },
            headline: { value: "headline3" },
            bodyText: { value: "bodyText3" },
            button: {
              id: "8a47a599-8b4d-4d80-99d2-b87327dd77db",
              url:
                "/components/98/Page-Components/NavTilesCarousel1/NavTile3/Button",
              fields: {
                style: { value: "link" },
                hasArrow: { value: true },
                isEnabled: { value: false },
                link: {
                  value: {
                    href: "/en/",
                    text: "Home",
                    anchor: "",
                    linktype: "internal",
                    class: "",
                    title: "",
                    querystring: "",
                    id: "{A7CB067A-8857-5945-8BC8-B82014AE7BAA}",
                  },
                },
                isInverted: { value: false },
              },
            },
          },
        },
      ],
      bodyText: { value: "" },
    },
  },
  auto: {
    data: {
      __type: { name: "NavTilesSliderAuto" },
      item: {
        headline: { field: { value: "Nav Slider Auto" } },
        bodyText: { field: { value: "nav slider auto body" } },
      },
      tiles: {
        values: [
          {
            headline: { field: { value: "Child1 headline" } },
            bodyText: { field: { value: "Child1 bodyText" } },
            buttonText: { field: { value: "Child1 link" } },
            media: {
              value: {
                id: "32b6db1c-847a-4952-91fb-5897b06c4779",
                url: "/components/98/Child1/Page-Components/Media",
                fields: {
                  image: {
                    value: {
                      src:
                        "http://fritz.alpha-solutions.dk/-/media/002.ashx?h=900&iar=0&w=800",
                      alt: "Media002",
                      width: "800",
                      height: "900",
                      focalpoint: "8, 23",
                    },
                  },
                },
              },
            },
            link: "/en/components/98/Child1",
          },
          {
            headline: { field: { value: "Child2 headline" } },
            bodyText: { field: { value: "Child2 bodyText" } },
            buttonText: { field: { value: "Child2 link" } },
            media: {
              value: {
                id: "d823e9e2-3f87-4daa-be39-f2ad1e0b363e",
                url: "/components/98/Child2/Page-Components/Media",
                fields: {
                  image: {
                    value: {
                      src:
                        "http://fritz.alpha-solutions.dk/-/media/002.ashx?h=900&iar=0&w=800",
                      alt: "Media002",
                      width: "800",
                      height: "900",
                      focalpoint: "8, 23",
                    },
                  },
                },
              },
            },
            link: "/en/components/98/Child2",
          },
          {
            headline: { field: { value: "Child3 headline" } },
            bodyText: { field: { value: "Child3 bodyText" } },
            buttonText: { field: { value: "Child3 link" } },
            media: {
              value: {
                id: "db18fe1b-f748-4b5b-8a82-9bf6aa707530",
                url: "/components/98/Child3/Page-Components/Media",
                fields: {
                  image: {
                    value: {
                      src:
                        "http://fritz.alpha-solutions.dk/-/media/002.ashx?h=900&iar=0&w=800",
                      alt: "Media002",
                      width: "800",
                      height: "900",
                      focalpoint: "8, 23",
                    },
                  },
                },
              },
            },
            link: "/en/components/98/Child3",
          },
        ],
      },
    },
  },
  exploreProducts: {
    fields: {
      headline: { value: "Explore products by room" },
      tiles: [
        {
          id: "5c6b4bc5-1f3c-423d-83a8-2a7d43dcc39e",
          fields: {
            phone: { value: "1234" },
            email: { value: "navtile1@email.com" },
            hideButtonAndContactSection: { value: false },
            media: {
              id: "5f056b5b-6d79-43ab-8678-05703230d72b",
              url:
                "/components/98/Page-Components/NavTilesCarousel1/NavTile1/Media",
              fields: {
                image: {
                  value: {
                    src: "https://picsum.photos/id/192/400/600",
                    alt: "Media001",
                    width: "311",
                    height: "400",
                    focalpoint: "21, 63",
                  },
                },
                overlayStrength: { value: "" },
                video: { value: { href: "" } },
              },
            },
            isButtonEnabled: { value: true },
            headline: {},
            bodyText: {},
            button: {
              id: "101f714a-1b6f-4955-ad6b-7784eaf3b718",
              url:
                "/components/98/Page-Components/NavTilesCarousel1/NavTile1/Button",
              fields: {
                style: { value: "link" },
                hasArrow: { value: true },
                isEnabled: { value: false },
                link: {
                  value: {
                    href: "/en/",
                    text: "Home office",
                    anchor: "",
                    linktype: "internal",
                    class: "",
                    title: "",
                    querystring: "",
                    id: "{A7CB067A-8857-5945-8BC8-B82014AE7BAA}",
                  },
                },
                isInverted: { value: false },
              },
            },
          },
        },
        {
          id: "5c6b4bc5-1f3c-423d-83a8-2a7d43dcc39e",
          fields: {
            phone: { value: "1234" },
            email: { value: "navtile1@email.com" },
            hideButtonAndContactSection: { value: false },
            media: {
              id: "5f056b5b-6d79-43ab-8678-05703230d72b",
              url:
                "/components/98/Page-Components/NavTilesCarousel1/NavTile1/Media",
              fields: {
                image: {
                  value: {
                    src: "https://picsum.photos/id/192/400/600",
                    alt: "Media001",
                    width: "311",
                    height: "400",
                    focalpoint: "21, 63",
                  },
                },
                overlayStrength: { value: "" },
                video: { value: { href: "" } },
              },
            },
            isButtonEnabled: { value: true },
            headline: {},
            bodyText: {},
            button: {
              id: "101f714a-1b6f-4955-ad6b-7784eaf3b718",
              url:
                "/components/98/Page-Components/NavTilesCarousel1/NavTile1/Button",
              fields: {
                style: { value: "link" },
                hasArrow: { value: true },
                isEnabled: { value: false },
                link: {
                  value: {
                    href: "/en/",
                    text: "Dining room",
                    anchor: "",
                    linktype: "internal",
                    class: "",
                    title: "",
                    querystring: "",
                    id: "{A7CB067A-8857-5945-8BC8-B82014AE7BAA}",
                  },
                },
                isInverted: { value: false },
              },
            },
          },
        },
        {
          id: "5c6b4bc5-1f3c-423d-83a8-2a7d43dcc39e",
          fields: {
            phone: { value: "1234" },
            email: { value: "navtile1@email.com" },
            hideButtonAndContactSection: { value: false },
            media: {
              id: "5f056b5b-6d79-43ab-8678-05703230d72b",
              url:
                "/components/98/Page-Components/NavTilesCarousel1/NavTile1/Media",
              fields: {
                image: {
                  value: {
                    src: "https://picsum.photos/id/192/400/600",
                    alt: "Media001",
                    width: "311",
                    height: "400",
                    focalpoint: "21, 63",
                  },
                },
                overlayStrength: { value: "" },
                video: { value: { href: "" } },
              },
            },
            isButtonEnabled: { value: true },
            headline: {},
            bodyText: {},
            button: {
              id: "101f714a-1b6f-4955-ad6b-7784eaf3b718",
              url:
                "/components/98/Page-Components/NavTilesCarousel1/NavTile1/Button",
              fields: {
                style: { value: "link" },
                hasArrow: { value: true },
                isEnabled: { value: false },
                link: {
                  value: {
                    href: "/en/",
                    text: "Livingroom",
                    anchor: "",
                    linktype: "internal",
                    class: "",
                    title: "",
                    querystring: "",
                    id: "{A7CB067A-8857-5945-8BC8-B82014AE7BAA}",
                  },
                },
                isInverted: { value: false },
              },
            },
          },
        },
        {
          id: "5c6b4bc5-1f3c-423d-83a8-2a7d43dcc39e",
          fields: {
            phone: { value: "1234" },
            email: { value: "navtile1@email.com" },
            hideButtonAndContactSection: { value: false },
            media: {
              id: "5f056b5b-6d79-43ab-8678-05703230d72b",
              url:
                "/components/98/Page-Components/NavTilesCarousel1/NavTile1/Media",
              fields: {
                image: {
                  value: {
                    src: "https://picsum.photos/id/192/400/600",
                    alt: "Media001",
                    width: "311",
                    height: "400",
                    focalpoint: "21, 63",
                  },
                },
                overlayStrength: { value: "" },
                video: { value: { href: "" } },
              },
            },
            isButtonEnabled: { value: true },
            headline: {},
            bodyText: {},
            button: {
              id: "101f714a-1b6f-4955-ad6b-7784eaf3b718",
              url:
                "/components/98/Page-Components/NavTilesCarousel1/NavTile1/Button",
              fields: {
                style: { value: "link" },
                hasArrow: { value: true },
                isEnabled: { value: false },
                link: {
                  value: {
                    href: "/en/",
                    text: "Bedroom",
                    anchor: "",
                    linktype: "internal",
                    class: "",
                    title: "",
                    querystring: "",
                    id: "{A7CB067A-8857-5945-8BC8-B82014AE7BAA}",
                  },
                },
                isInverted: { value: false },
              },
            },
          },
        },
      ],
      bodyText: { value: "" },
    },
  },
  latestStories: {
    fields: {
      headline: { value: "Nav slider manual" },
      tiles: [
        {
          id: "5c6b4bc5-1f3c-423d-83a8-2a7d43dcc39e",
          fields: {
            phone: { value: "1234" },
            email: { value: "navtile1@email.com" },
            hideButtonAndContactSection: { value: false },
            media: {
              id: "5f056b5b-6d79-43ab-8678-05703230d72b",
              url:
                "/components/98/Page-Components/NavTilesCarousel1/NavTile1/Media",
              fields: {
                image: {
                  value: {
                    src: "https://picsum.photos/id/192/400/600",
                    alt: "Media001",
                    width: "311",
                    height: "400",
                    focalpoint: "21, 63",
                  },
                },
                overlayStrength: { value: "" },
                video: { value: { href: "" } },
              },
            },
            isButtonEnabled: { value: false },
            headline: { value: "Hotel Odeon" },
            bodyText: {
              value:
                "Oplev 128 siders inspiration fra Fritz Hansens designkollektion, som inkluderer lys, møbler og accessories. sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
            },
            button: {
              id: "101f714a-1b6f-4955-ad6b-7784eaf3b718",
              url:
                "/components/98/Page-Components/NavTilesCarousel1/NavTile1/Button",
              fields: {
                style: { value: "link" },
                hasArrow: { value: true },
                isEnabled: { value: false },
                link: {
                  value: {
                    href: "/en/",
                    text: "Home",
                    anchor: "",
                    linktype: "internal",
                    class: "",
                    title: "",
                    querystring: "",
                    id: "{A7CB067A-8857-5945-8BC8-B82014AE7BAA}",
                  },
                },
                isInverted: { value: false },
              },
            },
          },
        },
        {
          id: "0707091b-0361-4d12-ab0c-2fd322a97e86",
          fields: {
            phone: { value: "1234" },
            email: { value: "navtile2@email.com" },
            hideButtonAndContactSection: { value: false },
            media: {
              id: "7f1ec962-80fd-47d6-9b34-e27e14b7e572",
              url:
                "/components/98/Page-Components/NavTilesCarousel1/NavTile2/Media",
              fields: {
                image: {
                  value: {
                    src: "https://picsum.photos/id/192/400/600",
                    alt: "Media001",
                    width: "311",
                    height: "400",
                    focalpoint: "21, 63",
                  },
                },
                overlayStrength: { value: "" },
                video: { value: { href: "" } },
              },
            },
            isButtonEnabled: { value: false },
            headline: { value: "Sheraton Towers Hotel" },
            bodyText: {
              value:
                "Oplev 128 siders inspiration fra Fritz Hansens designkollektion, som inkluderer lys, møbler og accessories. sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
            },
            button: {
              id: "49217a11-b1a8-41e6-b4ef-a3e9d6431894",
              url:
                "/components/98/Page-Components/NavTilesCarousel1/NavTile2/Button",
              fields: {
                style: { value: "link" },
                hasArrow: { value: true },
                isEnabled: { value: false },
                link: {
                  value: {
                    href: "/en/",
                    text: "Home",
                    anchor: "",
                    linktype: "internal",
                    class: "",
                    title: "",
                    querystring: "",
                    id: "{A7CB067A-8857-5945-8BC8-B82014AE7BAA}",
                  },
                },
                isInverted: { value: false },
              },
            },
          },
        },
        {
          id: "1fef96db-44ae-4256-af5f-737de35b67a2",
          fields: {
            phone: { value: "1234" },
            email: { value: "navtile3@email.com" },
            hideButtonAndContactSection: { value: false },
            media: {
              id: "b6d5d18e-cfcd-45df-8cc8-91e951c6082a",
              url:
                "/components/98/Page-Components/NavTilesCarousel1/NavTile3/Media",
              fields: {
                image: {
                  value: {
                    src: "https://picsum.photos/id/192/400/600",
                    alt: "Media001",
                    width: "311",
                    height: "400",
                    focalpoint: "21, 63",
                  },
                },
                overlayStrength: { value: "" },
                video: { value: { href: "" } },
              },
            },
            isButtonEnabled: { value: false },
            headline: { value: "The Alchemist" },
            bodyText: {
              value:
                "Oplev 128 siders inspiration fra Fritz Hansens designkollektion, som inkluderer lys, møbler og accessories. sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
            },
            button: {
              id: "8a47a599-8b4d-4d80-99d2-b87327dd77db",
              url:
                "/components/98/Page-Components/NavTilesCarousel1/NavTile3/Button",
              fields: {
                style: { value: "link" },
                hasArrow: { value: true },
                isEnabled: { value: false },
                link: {
                  value: {
                    href: "/en/",
                    text: "Home",
                    anchor: "",
                    linktype: "internal",
                    class: "",
                    title: "",
                    querystring: "",
                    id: "{A7CB067A-8857-5945-8BC8-B82014AE7BAA}",
                  },
                },
                isInverted: { value: false },
              },
            },
          },
        },
        {
          id: "5c6b4bc5-1f3c-423d-83a8-2a7d43dcc39e",
          fields: {
            phone: { value: "1234" },
            email: { value: "navtile1@email.com" },
            hideButtonAndContactSection: { value: false },
            media: {
              id: "5f056b5b-6d79-43ab-8678-05703230d72b",
              url:
                "/components/98/Page-Components/NavTilesCarousel1/NavTile1/Media",
              fields: {
                image: {
                  value: {
                    src: "https://picsum.photos/id/192/400/600",
                    alt: "Media001",
                    width: "311",
                    height: "400",
                    focalpoint: "21, 63",
                  },
                },
                overlayStrength: { value: "" },
                video: { value: { href: "" } },
              },
            },
            isButtonEnabled: { value: true },
            headline: { value: "headline1" },
            bodyText: { value: "bodyText1" },
            button: {
              id: "101f714a-1b6f-4955-ad6b-7784eaf3b718",
              url:
                "/components/98/Page-Components/NavTilesCarousel1/NavTile1/Button",
              fields: {
                style: { value: "link" },
                hasArrow: { value: true },
                isEnabled: { value: false },
                link: {
                  value: {
                    href: "/en/",
                    text: "Home",
                    anchor: "",
                    linktype: "internal",
                    class: "",
                    title: "",
                    querystring: "",
                    id: "{A7CB067A-8857-5945-8BC8-B82014AE7BAA}",
                  },
                },
                isInverted: { value: false },
              },
            },
          },
        },
        {
          id: "0707091b-0361-4d12-ab0c-2fd322a97e86",
          fields: {
            phone: { value: "1234" },
            email: { value: "navtile2@email.com" },
            hideButtonAndContactSection: { value: false },
            media: {
              id: "7f1ec962-80fd-47d6-9b34-e27e14b7e572",
              url:
                "/components/98/Page-Components/NavTilesCarousel1/NavTile2/Media",
              fields: {
                image: {
                  value: {
                    src: "https://picsum.photos/id/192/400/600",
                    alt: "Media001",
                    width: "311",
                    height: "400",
                    focalpoint: "21, 63",
                  },
                },
                overlayStrength: { value: "" },
                video: { value: { href: "" } },
              },
            },
            isButtonEnabled: { value: true },
            headline: { value: "headline2" },
            bodyText: { value: "bodyText2" },
            button: {
              id: "49217a11-b1a8-41e6-b4ef-a3e9d6431894",
              url:
                "/components/98/Page-Components/NavTilesCarousel1/NavTile2/Button",
              fields: {
                style: { value: "link" },
                hasArrow: { value: true },
                isEnabled: { value: false },
                link: {
                  value: {
                    href: "/en/",
                    text: "Home",
                    anchor: "",
                    linktype: "internal",
                    class: "",
                    title: "",
                    querystring: "",
                    id: "{A7CB067A-8857-5945-8BC8-B82014AE7BAA}",
                  },
                },
                isInverted: { value: false },
              },
            },
          },
        },
        {
          id: "1fef96db-44ae-4256-af5f-737de35b67a2",
          fields: {
            phone: { value: "1234" },
            email: { value: "navtile3@email.com" },
            hideButtonAndContactSection: { value: false },
            media: {
              id: "b6d5d18e-cfcd-45df-8cc8-91e951c6082a",
              url:
                "/components/98/Page-Components/NavTilesCarousel1/NavTile3/Media",
              fields: {
                image: {
                  value: {
                    src: "https://picsum.photos/id/192/400/600",
                    alt: "Media001",
                    width: "311",
                    height: "400",
                    focalpoint: "21, 63",
                  },
                },
                overlayStrength: { value: "" },
                video: { value: { href: "" } },
              },
            },
            isButtonEnabled: { value: true },
            headline: { value: "headline3" },
            bodyText: { value: "bodyText3" },
            button: {
              id: "8a47a599-8b4d-4d80-99d2-b87327dd77db",
              url:
                "/components/98/Page-Components/NavTilesCarousel1/NavTile3/Button",
              fields: {
                style: { value: "link" },
                hasArrow: { value: true },
                isEnabled: { value: false },
                link: {
                  value: {
                    href: "/en/",
                    text: "Home",
                    anchor: "",
                    linktype: "internal",
                    class: "",
                    title: "",
                    querystring: "",
                    id: "{A7CB067A-8857-5945-8BC8-B82014AE7BAA}",
                  },
                },
                isInverted: { value: false },
              },
            },
          },
        },
      ],
      bodyText: { value: "" },
    },
  },
};
