import create from "zustand";
import { devtools } from "zustand/middleware";
import Cookies from "universal-cookie";
import globalErrorHandler from "@assets/js/global-error-handler";

import {
  SITE_MODE,
  NOTIFICATION,
  STATUS,
  LAYOUT,
  COOKIES,
  QUERY_PARAMETER,
} from "@constants";
import urlParams from "@assets/js/url-params";
import { log } from "@assets/js/utils";

const getIpInfo = () => {
  log("GLOBAL | getIpInfo");
  return fetch(process.env.REACT_APP_IPINFOAPI)
    .then((a) => {
      // https://alphasolutionsdk.atlassian.net/browse/FHBC-1219 uncomment this to simulate JP market
      // MUST BE USED FOR TESTING MARKET LOGIC
      // return {
      //   "ip": "221.189.116.253",
      //   "country_code": "JP",
      //   "country_name": "Japan",
      //   "region_code": "04",
      //   "region_name": "Miyagi",
      //   "city": "",
      //   "zip_code": "",
      //   "time_zone": "Asia/Tokyo",
      //   "latitude": 38.2732,
      //   "longitude": 140.873,
      //   "metro_code": 0
      // }
      if (a.ok) return a.json();
    })
    .catch((err) => {
      console.error("GLOBAL | Ip lookup failed ", err);
    });
};

const parseMarketInfo = async (marketInfo, sc_lang) => {
  if (!marketInfo || !marketInfo.markets) {
    return {
      marketInfo: null,
    };
  } else {
    /*
      PROCESS:
      1. set market (intermittently) to env default market
      2. get ipinfo + cookies
      3. look for query parameter, if found, set cookie and skip the rest
      4. test for user chosen market cookie - if available, set market
      5. look for ip info country in markets
      6. look for international market in markets
      7. if ip info country located, use that
      8. otherwise, if international market located, use that
      9. if all fails, use default
    */

    //#1
    let approximatedMarket = process.env.REACT_APP_COMMERCE_DEFAULT_MARKET;

    //#2
    const ipInfo = await getIpInfo();
    const cookies = new Cookies();

    let cookieMarket = cookies.get(COOKIES.market.name);
    let queryMarket = urlParams.get().get(QUERY_PARAMETER.MARKET);

    let userManuallyChosenCountry = true;

    //#6
    const internationalMarket = marketInfo?.markets?.values.filter(
      (item) =>
        (item.commerceMarket &&
          item.commerceMarket.value.toLowerCase() === "international") ||
        (item.countryName &&
          item.countryName.value.toLowerCase() == "international")
    );

    if (queryMarket) {
      //#3
      approximatedMarket = queryMarket;
      cookies.set(COOKIES.market.name, queryMarket, {
        path: "/",
      });
      log(
        "GLOBAL | urlParams.get()",
        urlParams.get().get(QUERY_PARAMETER.MARKET)
      );
      urlParams.get().delete(QUERY_PARAMETER.MARKET);
      log(
        "GLOBAL | urlParams.get()",
        urlParams.get().get(QUERY_PARAMETER.MARKET)
      );
    } else if (cookieMarket) {
      //#4
      approximatedMarket = cookieMarket;
    } else {
      userManuallyChosenCountry = false;

      //#5
      const ipInfoCountry = ipInfo?.country_code?.toUpperCase()
        ? ipInfo.country_code.toUpperCase()
        : "NONE";
      const locatedMarketCountry = marketInfo.markets.values.filter(
        (item) =>
          item.iPDetectedCountryCodes?.value
            ?.toUpperCase()
            .indexOf(ipInfoCountry) > -1
      );

      log("GLOBAL |ipInfoCountry", ipInfoCountry);

      if (locatedMarketCountry && locatedMarketCountry.length > 0) {
        //#7
        approximatedMarket = locatedMarketCountry[0].commerceMarket.value;
      } else if (internationalMarket && internationalMarket.length > 0) {
        //#8
        approximatedMarket = internationalMarket[0].commerceMarket.value;
      }
    }

    log("GLOBAL | approximatedMarket", approximatedMarket);
    let marketObject = marketInfo.markets.values.find(
      (item) =>
        item.commerceMarket.value.toLowerCase() ===
        approximatedMarket.toLowerCase()
    );

    if (
      !marketObject &&
      internationalMarket &&
      internationalMarket.length > 0
    ) {
      marketObject = internationalMarket[0];
    }
    log("GLOBAL | marketObject", marketObject);

    //show geoloc conflict
    let locationConflict =
      !cookieMarket && sc_lang !== marketObject.defaultLanguage.value;
    log("cookieMarket", cookieMarket);
    log("sc_lang", sc_lang);
    log(
      "marketObject.defaultLanguage.value",
      marketObject.defaultLanguage.value
    );
    log("locationConflict", locationConflict);

    let geolocationPosition = locationConflict ? NOTIFICATION.GEO_TOP : null;
    return {
      currentMarket: marketObject,
      marketInfo: marketInfo,
      ipInfo: ipInfo || "NO IP INFO",

      locationConflict: locationConflict,
      showNotificationGeolocation: geolocationPosition,

      commerceMarketSupportsEcom:
        marketObject?.marketSupportsEcom?.value === true,
      commerceMarket: marketObject?.commerceMarket?.value?.toLowerCase(),
      userManuallyChosenCountry: userManuallyChosenCountry,
    };
  }
};

const store = create(
  devtools(
    (set, get) => ({
      //for sitecore urls ex en-UK, for api endpoints
      sc_lang: "",

      layoutId: LAYOUT.ID,
      loadStatus: STATUS.INITIALIZING,
      ipInfo: null,

      userManuallyChosenCountry: false,
      setUserManuallyChosenCountry: (value) =>
        set({ userManuallyChosenCountry: value }),

      //only language en, da, de etc
      language: process.env.REACT_APP_SITECORE_DEFAULT_LANGUAGE,
      setLanguage: (lang) => set({ language: lang }),

      //used for prices
      commerceMarket: null,
      setCommerceMarket: (market) => set({ commerceMarket: market }),

      //has geolocation conflict
      locationConflict: false,
      setLocationConflict: (value) => set({ locationConflict: value }),

      //can user do business in market
      commerceMarketSupportsEcom: null,
      setCommerceMarketSupportsEcom: (value) =>
        set({ commerceMarketSupportsEcom: value }),

      //current selected market object from sitecore
      currentMarket: {},
      setCurrentMarket: (value) => set({ currentMarket: value }),

      // market info from sitecore
      marketInfo: null,
      setMarketInfo: async (data) => {
        set(await parseMarketInfo(data, get().sc_lang));
      },

      countrySelectMarket: null,
      setCountrySelectMarket: (market) => set({ countrySelectMarket: market }),

      countrySelectLanguage: null,
      setCountrySelectLanguage: (lang) => set({ countrySelectLanguage: lang }),

      userKey: null,
      setUserKey: (userKey) => set({ userKey }),

      userAuthenticated: false,
      setUserAuthenticated: (userAuthenticated, userKey) =>
        set({ userAuthenticated, userKey }),

      isScrolled: false,
      setIsScrolled: (value) => set({ isScrolled: value }),

      logoMustContract: false,
      setLogoMustContract: (value) => set({ logoMustContract: value }),

      //value is position of geolocation
      showNotificationGeolocation: null,
      setShowNotificationGeolocation: (position) =>
        set({
          showNotificationGeolocation: position,
        }),

      currentSiteMode: SITE_MODE.RESIDENTIAL,
      setCurrentSiteMode: (value) => set({ currentSiteMode: value }),

      webPSupport: false,
    }),
    "Global"
  )
);

// Log out the entire Store

// currentSiteMode
export const useCurrentSiteMode = () => [
  store((store) => store.currentSiteMode),
  store((store) => store.setCurrentSiteMode),
];

export const useLanguage = () => [
  store((store) => store.language),
  store((store) => store.setLanguage),
];

export const useLocationConflict = () => [
  store((store) => store.locationConflict),
  store((store) => store.setLocationConflict),
];

export const useMarketInfo = () => [
  store((store) => store.marketInfo),
  store((store) => store.setMarketInfo),
];

export const useCurrentMarket = () => [
  store((store) => store.currentMarket),
  store((store) => store.setCurrentMarket),
];

export const useCommerceMarket = () => [
  store((store) => store.commerceMarket),
  store((store) => store.setCommerceMarket),
];

export const useCountrySelectMarket = () => [
  store((store) => store.countrySelectMarket),
  store((store) => store.setCountrySelectMarket),
];

export const useCountrySelectLanguage = () => [
  store((store) => store.countrySelectLanguage),
  store((store) => store.setCountrySelectLanguage),
];

export const useCommerceMarketSupportsEcom = () => [
  store((store) => store.commerceMarketSupportsEcom),
  store((store) => store.setCommerceMarketSupportsEcom),
];

export const useShowNotificationGeolocation = () => [
  store((store) => store.showNotificationGeolocation),
  store((store) => store.setShowNotificationGeolocation),
];

// isScrolled
export const isScrolled = () => [
  store((store) => store.isScrolled),
  store((store) => store.setIsScrolled),
];

export const useLogoMustContract = () => [
  store((store) => store.logoMustContract),
  store((store) => store.setLogoMustContract),
];

export const useUserManuallyChosenCountry = () => [
  store((store) => store.userManuallyChosenCountry),
  store((store) => store.setUserManuallyChosenCountry),
];

// authentication
export const useUserAuthenticated = () => [
  store((store) => store.userAuthenticated),
  store((store) => store.setUserAuthenticated),
];

export default store;
