import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import classnames from "classnames";
import getUniqueId from "@assets/js/get-unique-id";
import { IMAGE, FILTER_NO_TYPE } from "@constants";
import ProductImage from "../../ProductImage";

import "./pdp-swatch-group.scss";
const PDPSwatchGroup = ({
  isActive,
  label,
  label2,
  label3,
  description,
  imageUrl,
  materials,
  isUnallowed,
  itemTranslationKey,
  itemSingularTranslationKey,
  item,
  itemClickHandler,
}) => {
  const { t } = useTranslation();
  let texts = {
    items: t(itemTranslationKey),
    itemsSingular: t(itemSingularTranslationKey),
    material: t("FritzHansen_Project_ConsumersAndProfessionals_PDP_Material"),
    other: t("FritzHansen_Project_ConsumersAndProfessionals_General_Other"),
  };
  const firstMeaningfullImgId = item.materials[0].materialId;
  const materialsCount = materials.length;
  const thumbsCount = materialsCount > 4 ? 4 : materialsCount;
  const additionalCount = materialsCount - thumbsCount;

  let countText;
  if (additionalCount > 0) {
    countText = `+${additionalCount} ${texts.items.toLowerCase()}`;
  } else {
    countText = `${materialsCount} ${
      materialsCount === 1 ? texts.itemsSingular.toLowerCase() : texts.items.toLowerCase()
    }`;
  }

  const classList = classnames({
    "pdp-swatch-group": true,
    "is-active": isActive,
    "is-unallowed": isUnallowed,
  });
  return (
    <button className={classList} onClick={() => itemClickHandler(item)}>
      <div className="pdp-swatch-group__image">
        <ProductImage imageId={firstMeaningfullImgId} type={IMAGE.SWATCH} alt={label} sw="200" />
      </div>
      <div className="pdp-swatch-group__info">
        <h4>
          <span>{label}</span>
        </h4>
        <p>
          {/* <span>{label2}</span> */}

          <span>
            {texts.material}: {label3 === FILTER_NO_TYPE ? texts.other : label3}
          </span>
          {/* {label3 && (
            <span>
              <br />
              {label3}
            </span>
          )} */}
        </p>
        <footer>
          {materials.map((item, index) => {
            return (
              <Fragment key={getUniqueId()}>
                {index < thumbsCount && (
                  <span>
                    <ProductImage
                      imageId={item.materialId}
                      type={IMAGE.SWATCH}
                      alt={item.materialLabel}
                      sw="30"
                    />
                  </span>
                )}
              </Fragment>
            );
          })}
          <h5>{countText}</h5>
        </footer>
      </div>
    </button>
  );
};
PDPSwatchGroup.propTypes = {
  isActive: PropTypes.bool,
  isUnallowed: PropTypes.bool,
  label: PropTypes.string,
  label3: PropTypes.string,
  imageUrl: PropTypes.string,
  materials: PropTypes.array,
  item: PropTypes.object.isRequired,
  itemClickHandler: PropTypes.func.isRequired,
};

export default PDPSwatchGroup;
