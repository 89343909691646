import React, { useState } from "react";
import PropTypes from "prop-types";
import debounce from "lodash-es/debounce";
import "./quantity-selector.scss";

const QuantitySelector = ({ min, max, defaultValue, changeHandler }) => {
  const [showRemove, setShowRemove] = useState(false);

  const onChange = (value) => {
    if (value) {
      let debouncedUpdate = debounce(
        (e) => {
          setShowRemove(value < 1);
          changeHandler(value);
        },
        500,
        { leading: true }
      );
      debouncedUpdate();
    }
  };

  return (
    <div className="quantity-selector">
      {showRemove && (
        <button
          onClick={() => changeHandler(0)}
          className="mini-basket-product__link quantity-selector__remove-button"
        >
          Remove product?{" "}
        </button>
      )}
      <input
        type="number"
        value={defaultValue}
        min="0"
        max={max}
        onChange={({ target: { value } }) => onChange(value)}
      />
    </div>
  );
};

QuantitySelector.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  defaultValue: PropTypes.number,
  changeHandler: PropTypes.func,
};

export default QuantitySelector;
