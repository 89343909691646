import React, { useEffect } from "react";
import { loader } from "graphql.macro";
import { useQuery } from "react-apollo";
import shallow from "zustand/shallow";

import dataLayerPush from "@assets/js/gtm";
import globalJs, { initBasket } from "@assets/js/global";
import { log } from "@assets/js/utils";

import useGlobalStore from "@store/global";
import { usePLPPrices } from "@store/price";

const gqlQuery = loader("./assets/js/gql/country-selector.graphql");

/**
 * This is the first functional component in the solution and also where initial API calls
 * and non React related JS are initiated.
 *
 */

const AppEssentials = ({ children }) => {
  const [
    setMarketInfo,
    commerceMarket,
    commerceMarketSupportsEcom,
  ] = useGlobalStore(
    (state) => [
      state.setMarketInfo,
      state.commerceMarket,
      state.commerceMarketSupportsEcom,
    ],
    shallow
  );

  const [, fetchPLPPrices] = usePLPPrices();

  //load markets
  useQuery(gqlQuery, {
    variables: {
      siteRoot: process.env.REACT_APP_QUERY_VARIABLES_SITE_ROOT,
    },
    errorPolicy: "all",
    onCompleted: (response) => {
      log("AppEssentials | market query loaded");
      //when loaded, set in global store
      setMarketInfo(response.item || {});
    },
  });

  useEffect(() => {
    if (commerceMarket?.length) {
      log("AppEssentials | commerceMarket=" + commerceMarket);
      // once commerce market populates, load plp prices     
      fetchPLPPrices(commerceMarket);
      // get or create basket
      if (commerceMarketSupportsEcom) initBasket(commerceMarket);
      // GTM tracking
      dataLayerPush({
        userCountry: commerceMarket || "INT",
      });
    }
  }, [commerceMarket]);

  useEffect(() => {
    // initiate global non React related JS
    globalJs();
  }, []);

  return children;
};

export default AppEssentials;
