import React, { Fragment } from "react";
import { SwiperSlide } from "swiper/react";
import ProductTile from "../ProductTile";
import Carousel from "../Carousel";

import "./product-tiles-slider.scss";
const ProductTilesSlider = ({ tiles, headline }) => {
  const swiperArgs = {
    fields: { headline: headline },
    className: "product-tiles-slider",
    children: (
      <>
        {tiles?.map((tile, index) => {
          return (
            <Fragment key={`ProdSlideFrag${index}`}>
              <SwiperSlide
                key={`ProdSlide${index}`}
                className={"product-tiles-slider__slide"}
              >
                <ProductTile {...tile} />
              </SwiperSlide>
            </Fragment>
          );
        })}
      </>
    ),
  };

  return <Carousel {...swiperArgs} />;
};

export default ProductTilesSlider;
