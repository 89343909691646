import React, { useEffect, useState } from "react";
import {
  Placeholder,
  VisitorIdentification,
} from "@sitecore-jss/sitecore-jss-react";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import shallow from "zustand/shallow";
import { motion } from "framer-motion";
import { useHistory } from "react-router-dom";
import { NOTIFICATION, SITE_MODE, OVERLAY } from "@constants";
import { NavLink } from "react-router-dom";
// import MainFooter from "./components/MainFooter";
import DebugBar from "./components/checkout/DebugBar";
import NotificationGeolocation from "./components/notifications/NotificationGeolocation";
import MarketChangeModal from "./components/MarketChangeModal";
import SiteHeader from "./components/SiteHeader";
import ModeSwitch from "./components/ModeSwitch";
import NotificationsController from "./components/notifications/NotificationsController";

import useHeaderStore, { useMenuOverlayActive } from "@store/header";
import useGlobalStore, { useCurrentSiteMode } from "@store/global";
import useNotificationStore from "@store/notifications";
import loginWindow from "@assets/js/login-window";
import { placeholderReplace } from "@assets/js/utils";

import isCheckoutLayout from "@assets/js/is-checkout-layout";
import { useUserAuthenticated } from "./store/global";
import MessageBlock from "./components/MessageBlock";
import ActionButton from "./components/ActionButton";

// Using bootstrap is completely optional. It's used here to provide a clean layout for samples,
// without needing extra CSS in the sample app. Remove it in package.json as well if it's removed here.

// import './assets/app.scss';

/*
  APP LAYOUT
  This is where the app's HTML structure and root placeholders should be defined.

  All routes share this root layout by default (this could be customized in RouteHandler),
  but components added to inner placeholders are route-specific.
*/

const Layout = ({ route, context }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [menuOverlayActive] = useMenuOverlayActive();
  const [currentSiteMode, setCurrentSiteMode] = useCurrentSiteMode();
  const [setActiveNotification] = useNotificationStore(
    (state) => [state.setActiveNotification],
    shallow
  );
  const [userAuthenticated, setUserAuthenticated] = useUserAuthenticated();
  const [restriced, setRestriced] = useState(false);

  const texts = {
    restrictedTrumpet: t(
      "FritzHansen_Project_ConsumersAndProfessionals_General_Restricted_Trumpet"
    ),
    restrictedButton: t(
      "FritzHansen_Project_ConsumersAndProfessionals_General_Restricted_Button"
    ),
    restrictedHeadline: t(
      "FritzHansen_Project_ConsumersAndProfessionals_General_Restricted_Headline"
    ),
    restrictedBody: t(
      "FritzHansen_Project_ConsumersAndProfessionals_General_Restricted_Body"
    ),
    login: t("FritzHansen_Project_ConsumersAndProfessionals_Account_Login"),
  };
  const classNames = classnames({
    layout__container: true,
    "is-inactive": menuOverlayActive !== OVERLAY.NONE ? true : false,
  });

  const residentialClasses = classnames({
    "mode-container": true,
    "is-inactive": currentSiteMode === SITE_MODE.PROFESSIONAL,
  });

  const professionalClasses = classnames({
    "mode-container": true,
    "is-inactive": currentSiteMode === SITE_MODE.RESIDENTIAL,
  });

  /**
   * open the login window and set the userAuth status in the state
   */
  const promtForLogin = async () => {
    setRestriced(true);
    try {
      const jwtCookie = await loginWindow({
        url: placeholderReplace(process.env.REACT_APP_IDENTITY_LINK, {
          locale: context.language,
          redirectUri: process.env.REACT_APP_IDENTITY_REDIRECT,
        }),
        title: "xtf",
        w: "470",
        h: "675",
      });
      setRestriced(false);
      setUserAuthenticated(true, jwtCookie);
    } catch (err) {
      console.warn(err);
    }
  };
  useEffect(() => {
    // restricted page
    if (route?.fields?.pageIsRestricted?.value && !userAuthenticated) {
      setRestriced(true);
      // promtForLogin();
    } else {
      setRestriced(false);
    }
    // switch to proff/residential
    if (route.fields?.switchToProfessionalMode?.value) {
      setCurrentSiteMode(SITE_MODE.PROFESSIONAL);
      setActiveNotification({ type: NOTIFICATION.SITE_MODE });
    }
    try {
      document.body.classList.toggle(
        "no-modeswitch",
        isCheckoutLayout(route.layoutId)
      );
      window.scrollTo(0, 0);
    } catch (err) {}
  }, [route]);

  return (
    <>
      {/* react-helmet enables setting <head> contents, like title and OG meta tags */}
      <Helmet>
        <title>
          {(route.fields &&
            route.fields.pageTitle &&
            route.fields.pageTitle.value) ||
            route.displayName || 
            route.name}
        </title>
        <script
          type="text/javascript"
          src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
          async
        ></script>
      </Helmet>
      {/*
          VisitorIdentification is necessary for Sitecore Analytics to determine if the visitor is a robot.
          If Sitecore XP (with xConnect/xDB) is used, this is required or else analytics will not be collected for the JSS app.
          For XM (CMS-only) apps, this should be removed.

          VI detection only runs once for a given analytics ID, so this is not a recurring operation once cookies are established.
          */}
      <VisitorIdentification />
      <NotificationsController />
      <MarketChangeModal />

      {route && route.layoutId && !isCheckoutLayout(route.layoutId) && (
        <ModeSwitch />
      )}

      {route && route.layoutId && (
        <SiteHeader
          fields={route.fields}
          layoutId={route.layoutId}
          mode={currentSiteMode}
        />
      )}
      {/* <NavLink to="/en/Consumers-and-Professionals/Products/Furniture/Chairs">
        cat
      </NavLink>
      <NavLink to="/en">home</NavLink> */}
      {!restriced ? (
        <motion.div
          className="layout"
          exit={{ opacity: 0, transition: { duration: 1 } }}
        >
          {/* root placeholder for the app, which we add components to using route data */}

          <div className={classNames}>
            {currentSiteMode === SITE_MODE.RESIDENTIAL && (
              <div className={residentialClasses}>
                <Placeholder
                  name="main-residential"
                  rendering={route}
                  {...context}
                  pageFields={route.fields}
                />
                <Placeholder
                  name="main-residential-footer"
                  rendering={route}
                  {...context}
                  pageFields={route.fields}
                />
              </div>
            )}
            {currentSiteMode === SITE_MODE.PROFESSIONAL && (
              <div className={professionalClasses}>
                <Placeholder
                  name="main-professional"
                  rendering={route}
                  {...context}
                  pageFields={route.fields}
                />
                <Placeholder
                  name="main-professional-footer"
                  rendering={route}
                  {...context}
                  pageFields={route.fields}
                />
              </div>
            )}
          </div>

          {/* animation fallback if   */}
          {/* <motion.div
            className=""
            initial={{ opacity: 0.999 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0.999 }}
            transition={{ duration: 0.001 }}
          ></motion.div> */}
        </motion.div>
      ) : (
        <div className="layout">
          <MessageBlock
            trumpet={texts.restrictedTrumpet}
            headline={route.fields.pageTitle.value || texts.restrictedHeadline}
            bodyText={texts.restrictedBody}
            buttonText={texts.restrictedButton}
            buttonHref="/"
            ActionButton={
              <ActionButton
                text={`${texts.login} ${
                  route.fields.pageTitle.value || texts.restrictedHeadline
                }`}
                buttonType="ghost"
                customClass="nav-button ml2"
                clickHandler={promtForLogin}
              />
            }
          />
        </div>
      )}
      <NotificationGeolocation position={NOTIFICATION.GEO_BOTTOM} />
    </>
  );
};

export default Layout;
