import React from "react";
import NavPanel from "../NavPanel/index.js";
import classnames from "classnames";
import isTrue from "@assets/js/is-true";
import "./nav-panels-block.scss";

const NavPanelsBlock = ({ fields = {} }) => {
  const navPanelClasses = classnames({
    "nav-panels-block": true,
    "nav-panels-block--inverted": isTrue(fields.isInverted),
  });

  return (
    <>
      {fields && (
        <div
          className={navPanelClasses}
          style={
            fields.backgroundColorHex && {
              "--nav-panels-bgc": fields.backgroundColorHex.value,
            }
          }
        >
          {fields.tiles.map((panel, index) => {
            return (
              <NavPanel
                key={index}
                fields={panel.fields}
                isButtonEnabled={fields.isButtonEnabled}
                isInverted={fields?.isInverted}
              />
            );
          })}
        </div>
      )}
    </>
  );
};

export default NavPanelsBlock;
