import React, { Fragment } from "react";
import { SwiperSlide } from "swiper/react";
import MediaFocal from "../MediaFocal";
import Carousel from "../Carousel";

import "./inspiration-slider.scss";

const InspirationSlider = ({ fields }) => {
  let parsedFields = fields || {};

  parsedFields.footerButton = fields?.footerButton?.fields;

  const swiperArgs = {
    fields: parsedFields,
    className: "inspiration-slider",
    children: (
      <>
        {fields?.images?.map((tile, index) => {
          return (
            <Fragment key={`sliderFrag${index}`}>
              <SwiperSlide
                key={`sliderSlide${index}`}
                className="inspiration-slider__slide"
              >
                <div className="inspiration-slider__img">
                  <MediaFocal fields={tile.fields?.media?.fields} />
                </div>
              </SwiperSlide>
            </Fragment>
          );
        })}
      </>
    ),
  };

  return <>{fields && <Carousel {...swiperArgs} />}</>;
};

export default InspirationSlider;
