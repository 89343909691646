export default {
  default: {
    statusBar: "statusbar",
    infoPanels: ["1", "2"],
    productLines: [
      {
        Price: "price",
        FullFriendlyName: "name",
        Materials: [
          {
            Name: "FakeName",
            MaterialSetName: "FakeSetName",
            Id: "FakeId",
            MaterialSetId: "FakeSetId",
            ConfigurationTypeName: "FakeConfig",
          },
          {
            Name: "FakeName2",
            MaterialSetName: "FakeSetName",
            Id: "FakeId",
            MaterialSetId: "FakeSetId",
            ConfigurationTypeName: "FakeConfig",
          },
        ],
        Quantity: "quantity",
        currency: "DKK",
      },
    ],
    calculation: "calculation",
  },
};
