export default {
  default: {
    label: "",
    name: "default",
    selected: "2",
    // errorMessage: "error!",
    // successMessage: "success!",
    options: [
      {
        id: "1",
        optionLabel: "Denmark / DKK",
        optionValue: "denmark",
      },
      {
        id: "2",
        optionLabel: "Spain / Euro",
        optionValue: "spain",
      },
      {
        id: "3",
        optionLabel: "France / Euro",
        optionValue: "france",
      },
      {
        id: "4",
        optionLabel: "UK / GBP",
        optionValue: "uk",
      },
    ],
  },
  country: {
    label: "",
    name: "country",
    selected: "2",
    // errorMessage: "error!",
    // successMessage: "success!",
    options: [
      {
        id: "1",
        optionLabel: "Denmark",
        optionValue: "denmark",
      },
      {
        id: "2",
        optionLabel: "England",
        optionValue: "england",
      },
      {
        id: "3",
        optionLabel: "Germany",
        optionValue: "germany",
      },
      {
        id: "4",
        optionLabel: "Spain",
        optionValue: "spain",
      },
    ],
  },
  language: {
    label: "",
    name: "language",
    selected: "1",
    // errorMessage: "error!",
    // successMessage: "success!",
    options: [
      {
        id: "1",
        optionLabel: "English",
        optionValue: "english",
      },
      {
        id: "2",
        optionLabel: "Spanish",
        optionValue: "spanish",
      },
      {
        id: "3",
        optionLabel: "German",
        optionValue: "german",
      },
      {
        id: "4",
        optionLabel: "Japanese",
        optionValue: "japanese",
      },
    ],
  },
  sorting: {
    isSortSelector: true,
    label: "Sort by:",
    name: "sorting",
    selected: "2",
    // errorMessage: "error!",
    // successMessage: "success!",
    options: [
      {
        id: "1",
        optionLabel: "Relevance",
        optionValue: "relevance",
      },
      {
        id: "2",
        optionLabel: "Price",
        optionValue: "price",
      },
      {
        id: "3",
        optionLabel: "Name A-Z",
        optionValue: "name_desc",
      },
      {
        id: "4",
        optionLabel: "Name Z-A",
        optionValue: "name_asc",
      },
    ],
  },
};
