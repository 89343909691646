import React from "react";
import { useTranslation } from "react-i18next";
import cuid from "cuid";
import path from "@assets/js/browser-path";

import { useSubmissionMode, useSubmissionContent } from "@store/portfolio";
import useHeaderStore from "@store/header";

import { PORTFOLIO_TYPE, OVERLAY } from "@constants";
import { MagnifierSvg } from "@assets/js/icons";

import "./material-tile.scss";

const MaterialTile = ({ item, materialSetId }) => {
  const [, setSubmissionMode] = useSubmissionMode();
  const [, setSubmissionContent] = useSubmissionContent();

  const setMenuOverlayActive = useHeaderStore((store) => store.setMenuOverlayActive);

  const { t } = useTranslation();
  const texts = {
    closerLook: t("FritzHansen_Project_ConsumersAndProfessionals_Materials_CloserLook"),
  };

  const getFieldValue = (prop) => {
    return item.Fields.find((f) => f.Id === prop)
      ? item.Fields.find((f) => f.Id === prop).Value
      : "NONE";
  };

  const getImageUrl = (square) => {
    let res = item.Resources.filter((f) => f.SubType.toUpperCase() === "SWATCH")[0];
    return res
      ? res.Url
      : path.join(
          process.env.REACT_APP_PRODUCT_IMAGE_API,
          "r",
          `SWATCH-${getFieldValue("MaterialId")}.jpg`
        );
  };

  const openSaveOverlay = () => {
    let content = {
      id: cuid(),
      imageSrcHigh: getImageUrl(true),
      imageSrcLow: getImageUrl(true),
      imageWidth: 1000,
      imageHeight: 1000,

      headline: { value: getFieldValue("MaterialDisplayName") },
      bodyText: { value: getFieldValue("MaterialDisplayCode") },

      portfolioItem: {
        materialId: getFieldValue("MaterialId"),
        materialSetId: materialSetId,
        materialName: getFieldValue("MaterialName"),
      },
    };

    setSubmissionContent(content);
    setSubmissionMode(PORTFOLIO_TYPE.MATERIAL);
    setMenuOverlayActive(OVERLAY.MATERIAL);
  };

  return (
    <li className="material-tile">
      <button onClick={openSaveOverlay}>
        <div className="material-tile__image">
          {/* <img src={`urlSlug/${item.Id}`} alt="Material"/> */}
          <img src={getImageUrl()} alt={getFieldValue("MaterialId")} />
          
          {getFieldValue("MaterialDisplayCode") && <span className="tag">{getFieldValue("MaterialDisplayCode")}</span>}

          <span className="magnify">
            <span className="icon">
              <MagnifierSvg />
            </span>
            <span className="text">{texts.closerLook}</span>
          </span>
        </div>
        <div className="material-tile__text">
          <p>{getFieldValue("MaterialDisplayName")}</p>
        </div>
      </button>
    </li>
  );
};

MaterialTile.propTypes = {};

export default MaterialTile;
