import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";

import "./contact-links-group.scss";

const ContactLinksGroup = ({ phone = {}, email = {} }) => {
  return (
    <span className="contact-links-group">
      {phone?.value && (
        <span className="contact-links-group__item">
          <a href={"tel:" + phone.value}>
            <Text tag="span" field={phone} />
          </a>
        </span>
      )}

      {email?.value && (
        <span className="contact-links-group__item">
          <a href={"mailto:" + email.value}>
            <Text tag="span" field={email} />
          </a>
        </span>
      )}
    </span>
  );
};

export default ContactLinksGroup;
