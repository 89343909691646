import NavPanel from "./nav-panel";

export default {
  default: {
    fields: {
      tiles: [NavPanel.default, NavPanel.noContact],
    },
  },
  customBg: {
    fields: {
      tiles: [NavPanel.default, NavPanel.noContact],
      isInverted: true,
      backgroundColorHex: "#5599DD",
    },
  },
  threePanels: {
    fields: {
      tiles: [NavPanel.default, NavPanel.onlyText, NavPanel.noContact],
    },
  },
  fivePanels: {
    fields: {
      tiles: [
        NavPanel.default,
        NavPanel.onlyText,
        NavPanel.noContact,
        NavPanel.default,
        NavPanel.onlyText,
      ],
    },
  },
};
