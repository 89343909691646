import React, { useEffect, useState } from "react";
import { RichText } from "@sitecore-jss/sitecore-jss-react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Collapsible from "../../Collapsible";

import { ArrowSmallSvg } from "@assets/js/icons";
import getUniqueId from "@assets/js/get-unique-id";
import parseSpecifications from "@assets/js/parse-specifications";

import useGlobalStore, { useCommerceMarketSupportsEcom } from "@store/global";

import {
  useActiveProductData,
  useActiveModelData,
  useActiveConfigurationSKU,
  useProductSpecificationsOpen,
} from "@store/pdp";

import "./pdp-product-specification.scss";

const PDPProductSpecification = ({
  items,
  button,
  bottomInfo,
  careAndMaintenance = "<a></a>",
}) => {
  const { t } = useTranslation();

  const texts = {
    productDetails: t(
      "FritzHansen_Project_ConsumersAndProfessionals_PDP_ProductDetails"
    ),
    assembly: t("FritzHansen_Project_ConsumersAndProfessionals_PDP_Assembly"),
    downloadHere: t(
      "FritzHansen_Project_ConsumersAndProfessionals_PDP_DownloadHere"
    ),
    fabricComposition: t(
      "FritzHansen_Project_ConsumersAndProfessionals_PDP_FabricComposition"
    ),
    woodType: t("FritzHansen_Project_ConsumersAndProfessionals_PDP_WoodType"),
    chairsStackable: t(
      "FritzHansen_Project_ConsumersAndProfessionals_PDP_ChairsStackable"
    ),
    yes: t("FritzHansen_Project_ConsumersAndProfessionals_PDP_Yes"),
    no: t("FritzHansen_Project_ConsumersAndProfessionals_PDP_No"),
    chairsStackedCount: t(
      "FritzHansen_Project_ConsumersAndProfessionals_PDP_ChairsStackedCount"
    ),
    productId: t("FritzHansen_Project_ConsumersAndProfessionals_PDP_ProductId"),

    dimensions: t(
      "FritzHansen_Project_ConsumersAndProfessionals_PDP_Dimensions"
    ),
    width: t("FritzHansen_Project_ConsumersAndProfessionals_PDP_Width"),
    height: t("FritzHansen_Project_ConsumersAndProfessionals_PDP_Height"),
    depth: t("FritzHansen_Project_ConsumersAndProfessionals_PDP_Depth"),
    weight: t("FritzHansen_Project_ConsumersAndProfessionals_PDP_Weight"),
    cm: t("FritzHansen_Project_ConsumersAndProfessionals_PDP_Cm"),
    kg: t("FritzHansen_Project_ConsumersAndProfessionals_PDP_Kg"),
    inch: t("FritzHansen_Project_ConsumersAndProfessionals_PDP_Inch"),
    lbs: t("FritzHansen_Project_ConsumersAndProfessionals_PDP_Lbs"),

    downloads: t("FritzHansen_Project_ConsumersAndProfessionals_PDP_Downloads"),

    careAndMaintenance: t(
      "FritzHansen_Project_ConsumersAndProfessionals_PDP_CareAndMaintenance"
    ),
    deliveryAndReturn: t(
      "FritzHansen_Project_ConsumersAndProfessionals_PDP_DeliveryAndReturn"
    ),

    priceDisclaimer: t(
      "FritzHansen_Project_ConsumersAndProfessionals_PDP_PriceDisclaimer"
    ),
  };
  const sc_lang = useGlobalStore((state) => state.sc_lang);

  const [commerceMarketSupportsEcom] = useCommerceMarketSupportsEcom();
  const [activeProductData, setActiveProductData] = useActiveProductData();

  const [
    activeModelData,
    setActiveModelData,
    fetchActiveModelData,
  ] = useActiveModelData();

  const [
    activeConfigurationSKU,
    setActiveConfigurationSKU,
  ] = useActiveConfigurationSKU();

  const [
    productSpecificationsOpen,
    setProductSpecificationsOpen,
  ] = useProductSpecificationsOpen();

  const [specAccordions, setSpecAccordions] = useState([]);

  useEffect(() => {
    if (activeModelData) {
      let s = parseSpecifications(
        activeModelData.specifications,
        sc_lang,
        true
      );

      setSpecAccordions(s);
    }
  }, [activeModelData]);

  return (
    <div className="pdp-product-specification" id="productSpecifications">
      <Collapsible
        preExpanded={productSpecificationsOpen}
        triggerComponent={
          <>
            <h2>{texts.productDetails}</h2>
            <span className="pdp-product-specification__icon">
              <ArrowSmallSvg />
            </span>
          </>
        }
        contentComponent={
          <>
            {activeProductData?.longDescription && (
              <p className="pdp-product-specification__bodytext" dangerouslySetInnerHTML={{__html: activeProductData.longDescription}}></p>
            )}
            {activeModelData?.resourceImageUrl && (
              <img
                className="pdp-product-specification__image"
                src={activeModelData.resourceImageUrl}
                alt="measurement pictogram"
              />
            )}
          </>
        }
      />

      {specAccordions &&
        specAccordions.length > 0 &&
        specAccordions.map((item, index) => {
          return (
            <Collapsible
              key={`specAccordion${index}`}
              preExpanded={false}
              triggerComponent={
                <>
                  <h2>{item.key}</h2>
                  <span className="pdp-product-specification__icon">
                    <ArrowSmallSvg />
                  </span>
                </>
              }
              contentComponent={
                <table className="pdp-product-specification__table">
                  <tbody>
                    {item.value.map((item2, index2) => {
                      return (
                        <tr key={`specAccordionInner${index2}`}>
                          <th>{item2?.name?.toString() || ""}</th>
                          <td>
                            {item2?.value?.toString() || ""}{" "}
                            {item2?.unit?.toString() || ""}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              }
            />
          );
        })}

      {activeModelData && activeModelData.downloads && (
        <Collapsible
          preExpanded={false}
          triggerComponent={
            <>
              <h2>{texts.downloads}</h2>
              <span className="pdp-product-specification__icon">
                <ArrowSmallSvg />
              </span>
            </>
          }
          contentComponent={
            <>
              {activeModelData.downloads &&
                activeModelData.downloads.map((link) => {
                  return (
                    <a
                      key={getUniqueId()}
                      className="pdp-product-specification__link pdp-product-specification__link--not-in-table"
                      href={link.url}
                    >
                      {link.name}
                    </a>
                  );
                })}
            </>
          }
        />
      )}

      {commerceMarketSupportsEcom && activeModelData?.deliveryKey && (
        <Collapsible
          preExpanded={false}
          triggerComponent={
            <>
              <h2>{texts.deliveryAndReturn}</h2>
              <span className="pdp-product-specification__icon">
                <ArrowSmallSvg />
              </span>
            </>
          }
          contentComponent={
            <>
              <p className="pdp-product-specification__bodytext">
                {t(activeModelData.deliveryKey)}
              </p>
            </>
          }
        />
      )}

      {careAndMaintenance?.value && (
        <Collapsible
          preExpanded={false}
          triggerComponent={
            <>
              <h2>{texts.careAndMaintenance}</h2>
              <span className="pdp-product-specification__icon">
                <ArrowSmallSvg />
              </span>
            </>
          }
          contentComponent={
            <RichText
              field={careAndMaintenance}
              className="pdp-product-specification__bodytext"
            />
          }
        />
      )}

      {/* <NavButton fields={button} />
      <p className="pdp-product-specification__bodytext pdp-product-specification__bodytext--bottom">
        {bottomInfo}
      </p> */}
    </div>
  );
};
PDPProductSpecification.propTypes = {
  items: PropTypes.array,
  button: PropTypes.object,
  bottomInfo: PropTypes.string,
};

export default PDPProductSpecification;
