export default function (specs, sc_lang, arrayValue) {
  // console.log("PARSE SPECS", specs, sc_lang, arrayValue);

  let s = [];

  if (specs) {
    let e = Object.entries(specs);

    for (let i = 0; i < e.length; i++) {
      let name = e[i][0];
      let content = e[i][1];
      if (name && name.length > 0) {
        let newItem = {
          key: name,
        };
        if (arrayValue) {
          newItem.value = [];
        } else {
          newItem.value = "";
        }
        content = content.sort((a, b) => {
          return a.Index < b.Index ? -1 : 1;
        });

        for (let j = 0; j < content.length; j++) {
          let value = content[j];

          if (arrayValue) {
            //use key-value pairs
            let obj = {};

            let unit;
            if (value.UnitName && value.UnitName.stringMap) {
              unit = value.UnitName.stringMap[sc_lang];
            } else {
              unit = null;
            }

            let name;
            if (value.Name) {
              name = value.Name;
            } else {
              name = null;
            }
            obj = { name: name, unit: unit };

            //iterate through values
            if (value.Value && typeof value.Value === "Object") {
              for (let k = 0; k < value.Value.length; k++) {
                obj.value = value.Value[k];
              }
            } else if (value.Value) {
              obj.value = value.Value;
            }

            if (obj.value) {
              newItem.value.push(obj);
            }
          } else {
            //build coherent string with

            if (value.Value && typeof value.Value === "Object") {
              newItem.value += value.Value.join("\n");
            } else if (value.Value) {
              newItem.value += value.Value;
            }

            if (j < content.length - 1) {
              newItem.value += "\n";
            }

            if (value.Value && value.UnitName && value.UnitName.stringMap) {
              newItem.value += " " + value.UnitName.stringMap[sc_lang];
            }
          }
        }
        if (newItem.value) s.push(newItem);
      }
    }
  }
  return s;
}
