import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useHistory } from "react-router-dom";
import onMediaChanged from "@assets/js/on-media-changed";
import getViewportSize from "@assets/js/get-viewport-size";
import ViewportSizes from "@assets/js/viewport-sizes";
import { useLogoMustContract } from "@store/global";

import "./logo-animation.scss";

const LogoAnimation = ({ isInverted = false }) => {
  // const [isOpen, setOpen] = useState(false);
  const history = useHistory();
  const [logoMustContract] = useLogoMustContract();

  // ===== RESPONSIVE ===== //

  const [isMobile, setMobile] = useState(false);
  const [closed, setClosed] = useState(true);

  onMediaChanged((newViewport) => {
    switch (newViewport.name) {
      case ViewportSizes.SMALL.name:
      case ViewportSizes.MEDIUM.name:
        setMobile(true);
        break;
      case ViewportSizes.LARGE.name:
      case ViewportSizes.XLARGE.name:
      default:
        setMobile(false);
        break;
    }
  });

  useEffect(() => {
    const currentViewport = getViewportSize();
    setMobile(
      currentViewport.name === ViewportSizes.SMALL.name ||
        currentViewport.name === ViewportSizes.MEDIUM.name
    );
    onMediaChanged(currentViewport);
    const listen = history.listen((location, action) => {
      setClosed(true);
    });
    setClosed(false);
    return listen;
  }, []);

  let animationFactor = 0.5;

  let hansenVariant = {
    open: {
      width: "150px",
      paddingLeft: "9px",
      transition: { duration: animationFactor * 1, ease: [0.75, 0, 0.4, 1] },
    },
    closed: {
      width: "25px",
      paddingLeft: "2px",
      transition: { duration: animationFactor * 1.3, ease: [0.75, 0, 0.4, 1] },
    },
  };
  let fritzVariant = {
    open: { width: "100px", transition: { duration: animationFactor * 1.5 } },
    closed: {
      width: "19px",
      transition: { duration: animationFactor * 1.2 },
    },
  };

  let dotVariants = {
    open: { opacity: 0, transition: { duration: 0, delay: 0 } },
    closed: {
      opacity: 1,
      transition: {
        duration: animationFactor * 0.8,
        delay: animationFactor * 0.8,
      },
    },
  };

  return (
    <>
      <motion.div
        initial="closed"
        animate={!isMobile && logoMustContract ? "closed" : "open"}
        className="logo-animation"
      >
        <div className="logo-animation__outer">
          <div className="logo-animation__wrapper">
            <motion.div
              initial="open"
              variants={fritzVariant}
              className="logo-animation__fritz"
            >
              {/* <svg
                className="logo-animation__fritz-svg"
                viewBox="0 0 723 121"
                width="723"
                height="121"
                xmlns="http://www.w3.org/2000/svg">
                <defs><path id="a" d="M0 0h722v120.119H0z"/></defs>
                <g transform="translate(.5)" fill="none" fill-rule="evenodd">

                  <path
                    className={`${isInverted ? "logo-animation--inverted" : ""} `}
                    d="M722 3.397v113.385h-27.92l-56.62-96.878h-1.08l.31 96.878h-17.28V3.397h27.93l56.61 96.879h1.08l-.31-96.879H722zm-129.15 97.649v15.736h-84.23V3.397h83.61v15.736h-65.87v31.778h60.16v15.118h-60.16v35.017h66.49zM380.236 85.469l17.739-6.633c3.085 14.656 12.495 25.917 36.716 25.917 21.129 0 30.699-7.712 30.699-17.74 0-31.007-80.837-4.327-80.837-53.074C384.553 13.42 403.992 0 431.452 0 462 0 478.04 16.044 481.59 34.402l-17.74 6.631c-3.09-14.5-11.42-25.607-32.709-25.607-18.973 0-28.538 7.714-28.538 17.74 0 31.008 80.837 4.327 80.837 53.076 0 20.517-19.44 33.937-49.06 33.937-34.553 0-50.597-16.042-54.144-34.71zM355.922 3.397v113.385h-27.923l-56.614-96.878h-1.08l.308 96.878h-17.278V3.397h27.923l56.614 96.879h1.081l-.31-96.879h17.279zM195.236 64.178l-17.895-47.206-18.05 47.206h35.945zm6.015 15.889h-47.976l-13.885 36.715h-18.048L165.924 3.397h23.448l44.584 113.385h-18.821l-13.884-36.715zm-99.226-76.67v113.385H84.284V66.647H18.413v50.135H.672V3.397h17.741v46.896h65.871V3.397h17.741z"/>
                </g>
              </svg> */}

              <svg
                className="logo-animation__fritz-svg"
                width="95"
                height="25"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 470.7 113.4"
              >
                <path
                  className={`${isInverted ? "logo-animation--inverted" : ""} `}
                  d="M17.7 16v32.4h60.2v15.5H17.7v49.5H0V0h83.6v16zM202.9 113.4H184l-3.7-25.3c-2.2-15-8.3-22.1-22.2-22.1H122v47.4h-17.7V0h56.6c26.2 0 40.6 12.2 40.6 32.1 0 14.5-7.9 23.6-19.6 27.3 8.8 3.5 14 10.8 16.2 24.1l4.8 29.9zm-43.1-62c15.3 0 23.1-5.9 23.1-17.9 0-12.2-7.9-18.1-23.1-18.1H122v35.9h37.8zM230.5 0h17.7v113.4h-17.7zM367.6 16.2h-42.4v97.2h-17.7V16.2h-41.8V0h101.9zM470.7 97.2v16.2h-90V101L446 16.2h-63.7V0h86.8v12.3l-65.3 84.9z"
                />
              </svg>
            </motion.div>

            <motion.div
              initial="open"
              variants={hansenVariant}
              className={` logo-animation__hansen ${
                isMobile ? "padding-reset" : ""
              }`}
            >
              <svg
                className="logo-animation__hansen-svg"
                width="145"
                height="25"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 721.3 120.2"
              >
                <path
                  className={`${isInverted ? "logo-animation--inverted" : ""} `}
                  d="M101.4 3.4v113.4H83.6V66.6H17.7v50.2H0V3.4h17.7v46.9h65.9V3.4zM200.6 80.1h-48l-13.9 36.7h-18L165.3 3.4h23.4l44.6 113.4h-18.8l-13.9-36.7zm-6-15.9L176.7 17l-18 47.2h35.9zM355.3 3.4v113.4h-28l-56.6-96.9h-1.1l.3 96.9h-17.2V3.4h27.9l56.6 96.9h1.1L338 3.4zM379.6 85.5l17.7-6.6c3.1 14.7 12.5 25.9 36.7 25.9 21.1 0 30.7-7.7 30.7-17.7 0-31-80.8-4.3-80.8-53.1 0-20.5 19.4-33.9 46.9-33.9 30.5 0 46.6 16 50.1 34.4L463.2 41c-3.1-14.5-11.4-25.6-32.7-25.6-19 0-28.5 7.7-28.5 17.7 0 31 80.8 4.3 80.8 53.1 0 20.5-19.4 33.9-49.1 33.9-34.5.1-50.6-16-54.1-34.6zM592.2 101v15.8h-84.3V3.4h83.7v15.7h-65.9v31.8h60.1V66h-60.1v35zM721.3 3.4v113.4h-27.9l-56.6-96.9h-1.1l.3 96.9h-17.3V3.4h28l56.6 96.9h1.1L704 3.4z"
                />
              </svg>
            </motion.div>

            <motion.div
              initial="open"
              variants={dotVariants}
              className="logo-animation__dot-a"
            >
              <svg
                className="logo-animation__dot-a-svg"
                width="4"
                height="4"
                viewBox="0 0 5 5"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className={`${isInverted ? "logo-animation--inverted" : ""} `}
                  d="M.369 5H4.63V.738H.369V5z"
                />
              </svg>
            </motion.div>
            <motion.div
              initial="open"
              variants={dotVariants}
              className="logo-animation__dot-b"
            >
              <svg
                className="logo-animation__dot-b-svg"
                width="4"
                height="4"
                viewBox="0 0 5 5"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className={`${isInverted ? "logo-animation--inverted" : ""} `}
                  d="M.369 5H4.63V.738H.369V5z"
                />
              </svg>
            </motion.div>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default LogoAnimation;
