export default {
  default: {
    id: "id",
    name: "input",
    type: "text",
    placeholder: "Search",
    ariaDescribedBy: "info success",
    iconType: "magnifier",
    errorMessage: "error",
    successMessage: "success",
    isInverted: false,
  },
  email: {
    id: "id",
    name: "input",
    type: "email",
    placeholder: "Sign up",
    ariaDescribedBy: "info success",
    iconType: "arrow",
    errorMessage: "error",
    successMessage: "success",
    isInverted: false,
  },
};
