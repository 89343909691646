import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import * as variants from "@assets/js/animation-variations";

const transition = {
  type: "tween",
  ease: [0.43, 0.13, 0.23, 0.96],
};

/**
 * TODO: add type if we want different type of intrance animations
 *
 */

function AnimationWrapper({ children, type = "defaultReveal" }) {
  const [ref, inView] = useInView({
    threshold: 0,
  });
  const controls = useAnimation();

  const animationVariants = {
    initial: { opacity: 0, x: type === "normal" ? -50 : 0 },
    enter: { opacity: 1, x: 0, transition: { duration: 0.6 } },
    exit: {
      opacity: 0,
      x: type === "normal" ? -50 : 0,
      transition: { duration: 0.3 },
    },
  };

  useEffect(() => {
    if (inView) controls.start("enter");
  }, [inView]);

  return (
    <motion.div
      variants={variants[type]}
      initial="initial"
      animate={controls}
      exit="exit"
      transition={{ ...variants.defaultTransition, delay: 0.3 }}
      ref={ref}
    >
      {children}
    </motion.div>
  );
}
AnimationWrapper.propTypes = {
  type: PropTypes.string,
};
export default AnimationWrapper;
