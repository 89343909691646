import Select from "./select";
import NavButton from "./nav-button";

export default {
  default: {
    button: NavButton.inverted,
    countrySelect: { ...Select.country, noParse: true },
    languageSelect: { ...Select.language, noParse: true },
    fields: {
      headline: {
        value: "Not in Denmark?",
      },
      bodytext: {
        value: "Choose shipping country and language here.",
      },
      button: NavButton.inverted,
      consumersButton: NavButton.inverted,
      residentialButton: NavButton.inverted,
      consumersOrResidential: false,
    },
  },
};
