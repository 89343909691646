import NavButton from "./nav-button";

export default {
  default: {
    fields: {
      data: {
        backgroundColorHex: "",
        headline: "Page Siblings headline",
        feed: {
          field: {
            value: [
              {
                link: {
                  jss: {
                    value: {
                      href: "/en/Consumers-and-Professionals/Materials",
                      text: "Lorem ipsum",
                      anchor: "",
                      linktype: "external",
                      class: "",
                      title: "",
                      querystring: "",
                      id: "{510CF308-58B4-458E-AC2C-6F7B798A2EBC}",
                    },
                  },
                },
                image: {
                  jss: {
                    value: {
                      src: "https://picsum.photos/400/600",
                      alt: "new colours",
                      width: "923",
                      height: "482",
                    },
                  },
                },
              },
              {
                link: {
                  jss: {
                    value: {
                      href: "/en/Consumers-and-Professionals/Materials",
                      text: "Dolor sit amet",
                      anchor: "",
                      linktype: "external",
                      class: "",
                      title: "",
                      querystring: "",
                      id: "{510CF308-58B4-458E-AC2C-6F7B798A2EBC}",
                    },
                  },
                },
                image: {
                  jss: {
                    value: {
                      src: "https://picsum.photos/400/600",
                      alt: "new colours",
                      width: "923",
                      height: "482",
                    },
                  },
                },
              },
            ],
          },
        },
      },
    },
  },
  customBg: {
    fields: {
      data: {
        backgroundColorHex: { field: { value: "#DD6622" } },
        isInverted: { field: { value: { value: true } } },
        headline: "Page Siblings headline",
        feed: {
          field: {
            value: [
              {
                link: {
                  jss: {
                    value: {
                      href: "/en/Consumers-and-Professionals/Materials",
                      text: "Lorem ipsum",
                      anchor: "",
                      linktype: "external",
                      class: "",
                      title: "",
                      querystring: "",
                      id: "{510CF308-58B4-458E-AC2C-6F7B798A2EBC}",
                    },
                  },
                },
                image: {
                  jss: {
                    value: {
                      src: "https://picsum.photos/400/600",
                      alt: "new colours",
                      width: "923",
                      height: "482",
                    },
                  },
                },
              },
              {
                link: {
                  jss: {
                    value: {
                      href: "/en/Consumers-and-Professionals/Materials",
                      text: "Dolor sit amet",
                      anchor: "",
                      linktype: "external",
                      class: "",
                      title: "",
                      querystring: "",
                      id: "{510CF308-58B4-458E-AC2C-6F7B798A2EBC}",
                    },
                  },
                },
                image: {
                  jss: {
                    value: {
                      src: "https://picsum.photos/400/600",
                      alt: "new colours",
                      width: "923",
                      height: "482",
                    },
                  },
                },
              },
            ],
          },
        },
      },
    },
  },
  popularProductCategories: {
    fields: {
      data: {
        backgroundColorHex: "",
        headline: "Popular product categories",
        seeAllLink: NavButton.link,
        feed: {
          field: {
            value: [
              {
                link: {
                  jss: {
                    value: {
                      href: "/en/Consumers-and-Professionals/Materials",
                      text: "CHAIRS",
                      anchor: "",
                      linktype: "external",
                      class: "",
                      title: "",
                      querystring: "",
                      id: "{510CF308-58B4-458E-AC2C-6F7B798A2EBC}",
                    },
                  },
                },
                image: {
                  jss: {
                    value: {
                      src: "https://picsum.photos/id/42/400/600",
                      alt: "new colours",
                      width: "923",
                      height: "482",
                    },
                  },
                },
              },
              {
                link: {
                  jss: {
                    value: {
                      href: "/en/Consumers-and-Professionals/Materials",
                      text: "LOUNGE CHAIRS",
                      anchor: "",
                      linktype: "external",
                      class: "",
                      title: "",
                      querystring: "",
                      id: "{510CF308-58B4-458E-AC2C-6F7B798A2EBC}",
                    },
                  },
                },
                image: {
                  jss: {
                    value: {
                      src: "https://picsum.photos/id/47/400/600",
                      alt: "new colours",
                      width: "923",
                      height: "482",
                    },
                  },
                },
              },
              {
                link: {
                  jss: {
                    value: {
                      href: "/en/Consumers-and-Professionals/Materials",
                      text: "PENDANTS",
                      anchor: "",
                      linktype: "external",
                      class: "",
                      title: "",
                      querystring: "",
                      id: "{510CF308-58B4-458E-AC2C-6F7B798A2EBC}",
                    },
                  },
                },
                image: {
                  jss: {
                    value: {
                      src: "https://picsum.photos/id/41/400/600",
                      alt: "new colours",
                      width: "923",
                      height: "482",
                    },
                  },
                },
              },
              {
                link: {
                  jss: {
                    value: {
                      href: "/en/Consumers-and-Professionals/Materials",
                      text: "TABLES",
                      anchor: "",
                      linktype: "external",
                      class: "",
                      title: "",
                      querystring: "",
                      id: "{510CF308-58B4-458E-AC2C-6F7B798A2EBC}",
                    },
                  },
                },
                image: {
                  jss: {
                    value: {
                      src: "https://picsum.photos/id/44/400/600",
                      alt: "new colours",
                      width: "923",
                      height: "482",
                    },
                  },
                },
              },
            ],
          },
        },
      },
    },
  },
};
